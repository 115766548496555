<!-- <ng-scrollbar> -->
<div class="recent-notification-container pt-0">
    <div class="heading-container d-flex align-items-center justify-content-between  text-align-right-rtl">
        <i class="icon icon-Close2 cursor-pointer" (click)="closeFunction.emit(true)" placement="left"
            ngbTooltip="{{'Close' | translate}}" tooltipClass="info-custom-tooltip"></i>
    </div>
    <ng-container *ngIf='allNotificationsData?.length'>
        <section class="d-flex flex-column">
            <div class="container-body mb-3 mt-0" id="recentNotification" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                [infiniteScrollContainer]="'#recentNotification'" [fromRoot]="true" (scrolled)="onScroll()">
                <div class="notification-item" *ngFor="let note of allNotificationsData">
                    <div class="notification-head">
                        <div class="start-data"
                            [ngClass]="{'positive':note.attributes.postive==true,'negative':note.attributes.postive==false}">
                            <span class="badge badge-dark" translate>{{note?.attributes?.metric_alt_name}}</span>
                            <span class="detal-value" [ngClass]="{'positive-delta':note?.attributes?.positive,'negative-delta':!note?.attributes?.positive}">{{note.attributes.message}}</span>
                         </div>
                    </div>
                    <div class="notification-info">
                        <div class="name text-align-right-rtl" showTooltipOnTextOverflow [data]="note.attributes.name | translate"></div>
                        <div class="marketplace d-flex align-items-center gap-6">
                            <app-marketplace-store-logo [storeName]="note.attributes.marketplace">
                            </app-marketplace-store-logo>
                            <span class="text" showTooltipOnTextOverflow [data]="note.attributes.marketplace">{{note.attributes.marketplace}}</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="notification-description text-align-right-rtl" [innerHtml]="note.attributes.description"></div>
                        <div class="clear-filter d-flex align-items-center gap-8 cursor-pointer" *ngIf="permissions.write_access" (click)="clearNotifications(note.id)">
                            <i class="icon icon-Chat"></i>
                            <span translate>Read</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="clear-filter d-flex align-items-center gap-8 cursor-pointer" *ngIf="permissions.write_access" (click)="clearNotifications(null)">
                    <i class="icon icon-Refresh"></i>
                    <span>Read All Recent Notifications</span>
                </div>
            </div>
        </section>
    </ng-container>
    <div *ngIf='!allNotificationsData?.length' class="no-data-common">
        <div *ngIf='isRecentPresent' class="no-data-found" translate>
                <div class="no-data-text" translate>No data present</div>
        </div>
        <div *ngIf='!isRecentPresent' class="loading-panel">
            <img src="/assets/images/spinner.svg" alt="">
        </div>
    </div> 
</div>
<!-- </ng-scrollbar> -->
