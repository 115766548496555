import { TargetKPIOperatorMode } from "@ds-shared/models/notification.model";
import { TargetKPIOperator1 } from "@ds-shared/enums/notification.enum";

export const TargteKPI1Operators: TargetKPIOperatorMode[] = [
	{
		name: TargetKPIOperator1["<"],
		value: TargetKPIOperator1["<"]
	},
	{
		name: TargetKPIOperator1["<="],
		value: TargetKPIOperator1["<="]
	},
	{
		name: TargetKPIOperator1[">"],
		value: TargetKPIOperator1[">"]
	},
	{
		name: TargetKPIOperator1[">="],
		value: TargetKPIOperator1[">="]
	},
	{
		name: TargetKPIOperator1["="],
		value: TargetKPIOperator1["="]
	}
];

export const TargteKPI2Operators: TargetKPIOperatorMode[] = [
	{
		name: TargetKPIOperator1["<"],
		value: TargetKPIOperator1["<"]
	},
	{
		name: TargetKPIOperator1["<="],
		value: TargetKPIOperator1["<="]
	}
];
