<nav aria-label="breadcrumb" class="breadcrumb-container">
	<ol class="breadcrumb m-0">
		<ng-container *ngFor="let item of data;let i=index">
			<li
				class="breadcrumb-item text-uppercase"
				[ngStyle]="{'max-width': maxLength + 'rem'}"
				[ngClass]="{'active':i === (data.length - 1)}"
				(click)="navigateBack(i)"
				translate
				showTooltipOnTextOverflow
				[data]="item  | translate"
			>
				{{item}}
			</li>
		</ng-container>
	</ol>
</nav>
