import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { UserManagementService } from "@ds-private-layouts/user-management/user-management.service";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { AccessMatrixRolesModel } from "@ds-models/access-matrix.model";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";

@Component({
	selector: "app-roles-detail",
	templateUrl: "./roles-detail.component.html",
	styleUrls: ["./roles-detail.component.scss"]
})
export class RolesDetailComponent implements OnInit {
	@Input() rolesData: any;
	@Output() onSuccessAction: EventEmitter<any> = new EventEmitter();
	@Output() onCancelAction: EventEmitter<any> = new EventEmitter();
	public accessModuleMapping = new Map();
	public moduleCodes: any = [];
	public accessMatrixKeys = [
		"module_code",
		"read_access",
		"export_access",
		"full_access"
	];
	public moduleNameMapping = {};
	public rolesForm: UntypedFormGroup;
	public actionType: string = "";
	public submitted: boolean = false;
	public addSubscription: Subscription;
	public updateSubscription: Subscription;
	public allModuleAccessStatus: AccessMatrixRolesModel[] = [];
	public tempAccessModuleMapping = new Map();
	public check: any = {
		di: false,
		da: false,
		s: false,
		um: false,
		r: false,
		se: false,
		a: false,
		pr: false,
		c: false,
		cq: false,
		n: false
	};
	constructor(
		private route: ActivatedRoute,
		private userManagementService: UserManagementService,
		private notificationService: NotificationService,
		private localStorageService: LocalStorageService,
		private commonFunctions: CommonFunctionsService,
		private accessMatrixService: AccessMatrixService,
		private dataTransferService: DataTransferService,
		private mixpanelService: MixpanelService
	) {
		this.moduleNameMapping = this.accessMatrixService.moduleNameMapping;
	}

	ngOnInit(): void {
		this.actionType = this.commonFunctions.getURLSegments()[3];
		this.allModuleAccessStatus = this.accessMatrixService.getModulesList();
		this.getRolesMatrix();
		this.rolesForm = new UntypedFormGroup({
			role_name: new UntypedFormControl(this.getDefaultValue("role_name"), [
				Validators.required,
				this.validateName.bind(this)
			])
		});
		if (this.actionType === "edit") {
			this.getDefaultAccessMatrix();
		}
	}
	getDefaultValue(forLabel: string): string {
		if (this.actionType === "edit") {
			return this.rolesData?.[forLabel];
		}
		return null;
	}

	getDefaultAccessMatrix() {
		this.rolesData?.modules?.forEach((item: any, index: any) => {
			const newObj = item;
			delete newObj["module_name"];
			this.accessModuleMapping.set(item.module_code, newObj);
		});
		this.tempAccessModuleMapping = new Map(this.accessModuleMapping);
	}

	getRolesMatrix() {
		this.allModuleAccessStatus.forEach((item: any, index: any) => {
			this.accessModuleMapping.set(item.module_code, {
				module_code: item.module_code,
				read_access: false,
				write_access: false,
				export_access: false,
				full_access: false
			});
		});
		this.moduleCodes = Array.from(this.accessModuleMapping.keys());
	}
	updateAccessModuleMapping(code: any, key: any) {
		let updatedAccessModuleMapping: AccessMatrixRolesModel = {
			...this.accessModuleMapping.get(code)
		};
		updatedAccessModuleMapping[key] = !this.accessModuleMapping.get(code)[key];
		if (key === "full_access") {
			updatedAccessModuleMapping = this.grantFullAccess(
				updatedAccessModuleMapping,
				updatedAccessModuleMapping[key]
			);
		} else if (key === "write_access" || key === "export_access") {
			updatedAccessModuleMapping.read_access =
				updatedAccessModuleMapping.write_access ||
				updatedAccessModuleMapping.export_access;
			if (updatedAccessModuleMapping.write_access)
				updatedAccessModuleMapping = this.grantFullAccess(
					updatedAccessModuleMapping,
					updatedAccessModuleMapping.write_access
				);
		}
		if (
			updatedAccessModuleMapping.read_access &&
			updatedAccessModuleMapping.write_access &&
			updatedAccessModuleMapping.export_access &&
			!updatedAccessModuleMapping.full_access
		) {
			updatedAccessModuleMapping.full_access = true;
		}
		this.accessModuleMapping.set(code, updatedAccessModuleMapping);
		if (this.actionType === "edit") {
			const currentVal = this.tempAccessModuleMapping.get(code);
			const newVal = this.accessModuleMapping.get(code);
			this.check[code] =
				currentVal.read_access !== newVal.read_access ||
				currentVal.write_access !== newVal.write_access ||
				currentVal.export_access !== newVal.export_access ||
				currentVal.full_access !== newVal.full_access;
		}
	}

	private grantFullAccess(
		updatedAccessModuleMapping,
		status
	): AccessMatrixRolesModel {
		updatedAccessModuleMapping.read_access = status;
		updatedAccessModuleMapping.write_access = status;
		updatedAccessModuleMapping.export_access = status;
		return updatedAccessModuleMapping;
	}
	onResponseAction(response: any) {
		if (response) {
			this.onSuccessAction.emit();
			this.notificationService.setMessage(
				response["context_code"],
				response["summary"]
			);
		}
	}

	onSubmit() {
		this.submitted = true;
		if (this.rolesForm.status === "VALID") {
			const newMap = this.getOnlyActiveModules();
			const data = {
				name: this.rolesForm.get("role_name").value,
				modules: Array.from(newMap.values())
			};
			if (this.actionType === "edit") {
				this.updateSubscription?.unsubscribe();
				this.updateSubscription = this.userManagementService
					.updateRole(data, this.rolesData.role_id)
					.subscribe(
						(res: any) => {
							if (res) {
								this.mixpanelService.track("Role edited", {});
								this.onResponseAction(res);
								this.updateAdminUserData(res);
							}
						},
						(err: any) => {
							this.notificationService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			} else {
				this.addSubscription?.unsubscribe();
				this.addSubscription = this.userManagementService
					.createRole(data)
					.subscribe(
						(res: any) => {
							if (res) {
								this.mixpanelService.track("Role created", {});
								this.onResponseAction(res);
							}
						},
						(err: any) => {
							this.notificationService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			}
		}
	}
	getOnlyActiveModules() {
		const tempMap = new Map(this.accessModuleMapping);
		this.accessModuleMapping.forEach((value, key) => {
			if (
				!value?.read_access &&
				!value?.write_access &&
				!value?.export_access &&
				!value?.full_access
			) {
				tempMap.delete(key);
			}
		});
		return tempMap;
	}
	onCloseAction() {
		this.onCancelAction.emit();
	}

	updateAdminUserData(response: any) {
		const checkForLocalStorage = this.localStorageService.get("user")
			? JSON.parse(
					this.commonFunctions.getDecodedData(
						this.localStorageService.get("user")
					)
			  )
			: null;
		if (checkForLocalStorage.data.role.id === this.rolesData.role_id) {
			this.accessMatrixService.userRoles = response?.data?.modules;
			this.dataTransferService.sendRefreshOnAdminDataRoleUpdate(true);
		}
	}
	checkUpdate() {
		if (this.actionType === "edit") {
			let temp = false;
			this.moduleCodes.forEach((item: any) => {
				temp = temp || this.check[item];
			});
			return !(
				temp ||
				this.rolesData?.role_name !== this.rolesForm.get("role_name").value
			);
		}
		return false;
	}
	validateName(c: UntypedFormControl): { [s: string]: string } {
		const val = c.value;
		if (!new RegExp("^[A-Za-z0-9 ]*$").test(val)) {
			return { fieldMessage: "Special Characters and Emojis are not allowed" };
		} else return null;
	}

	ngOnDestroy(): void {
		if (this.addSubscription) this.addSubscription.unsubscribe();
		if (this.updateSubscription) this.updateSubscription.unsubscribe();
	}
}
