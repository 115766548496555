<div class="header-container text-align-right-rtl">
	<span translate
		>{{actionType === 'edit' ? 'Edit Team Member' : 'Add Team Member'}}</span
	>
</div>
<form
	[formGroup]="userForm"
	class="userForm text-align-right-rtl"
	(ngSubmit)="onSubmit()"
	(keydown.enter)="$event.preventDefault()"
>
	<div class="row">
		<div class="input-container col-6">
			<label for="firstName" translate>First Name <sup>*</sup></label>
			<input
				type="text"
				id="firstName"
				placeholder="{{'Enter Name' | translate}}"
				autocomplete="off"
				[ngClass]="{ error: userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched || submitted) }"
				formControlName="first_name"
			/>
			<div
				class="errorLable"
				*ngIf=" userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched || submitted) "
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="userForm.controls['first_name'].errors.required" translate
					>Please Enter Name</span
				>
				<span
					*ngIf="userForm.controls['first_name'].errors.fieldMessage"
					translate
					>{{userForm.controls['first_name'].errors.fieldMessage}}</span
				>
			</div>
		</div>
		<div class="input-container col-6">
			<label for="lastName" translate>Last Name <sup>*</sup></label>
			<input
				type="text"
				id="lastName"
				placeholder="{{'Enter Last Name' | translate}}"
				autocomplete="off"
				[ngClass]="{ error: userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched || submitted) }"
				formControlName="last_name"
			/>
			<div
				class="errorLable"
				*ngIf=" userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched || submitted) "
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="userForm.controls['last_name'].errors.required" translate
					>Please Enter Last Name</span
				>
				<span
					*ngIf="userForm.controls['last_name'].errors.fieldMessage"
					translate
					>{{userForm.controls['last_name'].errors.fieldMessage}}</span
				>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="input-container col-6">
			<label translate>Email <sup>*</sup></label>
			<input
				type="text"
				autocomplete="new-email"
				name="teamMemberEmail"
				placeholder="{{'Enter Email' | translate}}"
				formControlName="email"
				[ngClass]="{error:userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched || submitted)}"
			/>
			<div
				class="errorLable"
				*ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="userForm.controls['email'].errors.required" translate
					>Please Enter Email</span
				>
				<span *ngIf="!userForm.controls['email'].errors.required" translate
					>Enter valid email address</span
				>
			</div>
		</div>
		<div class="input-container contact-container col-6">
			<label translate>Contact <sup>*</sup></label>
			<span class="plus">+</span>
			<input
				pattern="^(?=.*\d)(?=.*[1-9]).{2,3}$"
				maxlength="3"
				type="text"
				class="countryCode"
				(keypress)="numberOnly($event)"
				(paste)="onPasswordPaste()"
				formControlName="countryCode"
				(keypress)="noSpace($event)"
				[ngClass]="{error:userForm.controls['countryCode'].invalid && (userForm.controls['countryCode'].dirty || userForm.controls['countryCode'].touched || submitted) || userForm.controls['contact'].invalid && (userForm.controls['contact'].dirty || userForm.controls['contact'].touched)}"
			/>
			<input
				pattern="^(?=.*\d)(?=.*[1-9]).{6,15}$"
				maxlength="15"
				type="text"
				(keypress)="numberOnly($event)"
				(paste)="onPasswordPaste()"
				placeholder="{{'Enter Contact' | translate}}"
				formControlName="contact"
				(keypress)="noSpace($event)"
				class="contact"
				[ngClass]="{error:userForm.controls['contact'].invalid && (userForm.controls['contact'].dirty || userForm.controls['contact'].touched || submitted) || userForm.controls['countryCode'].invalid && (userForm.controls['countryCode'].dirty || userForm.controls['countryCode'].touched)}"
			/>
			<div
				class="errorLable"
				*ngIf="userForm.controls['contact'].invalid && (userForm.controls['contact'].dirty || userForm.controls['contact'].touched || submitted) || userForm.controls['countryCode'].invalid && (userForm.controls['countryCode'].dirty || userForm.controls['countryCode'].touched)"
			>
				<i class="icon icon-Error"></i>
				<span translate>Please Enter Valid Contact</span>
			</div>
		</div>
		<!-- <div class="input-container position-relative col-6">
			<label class="d-flex align-items-center password-content" translate
				>Password
				<i
					class="icon icon-Info-circle"
					placement="top"
					ngbTooltip="{{notificationInfo | translate}}"
					tooltipClass="info-custom-tooltip"
				></i
				><sup>*</sup></label
			>
			<input
				pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!%$^&*()~` ]).{8,}$"
				[type]="showPassword ? 'password' : 'text'"
				autocomplete="new-password"
				name="teamMemberPassword"
				placeholder="{{'Enter Password' | translate}}"
				formControlName="password"
				(keypress)="noSpace($event)"
				(paste)="onPasswordPaste()"
				[ngClass]="{error:userForm.controls['password'].invalid && (userForm.controls['password'].dirty || userForm.controls['password'].touched || submitted)}"
			/>
			<div
				class="errorLable"
				*ngIf="userForm.controls['password'].invalid && (userForm.controls['password'].dirty || userForm.controls['password'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="userForm.controls['password'].errors.required" translate
					>Please Enter Password</span
				>
				<span
					*ngIf="!userForm.controls['password'].errors.valid && !userForm.controls['password'].errors.required"
					translate
					>Invalid</span
				>
			</div>
			<span
				*ngIf="actionType!=='edit'"
				(click)="showPassword=!showPassword"
				[ngClass]="showPassword ? 'icon-Eye' : 'icon-Eye-closed'"
				class="icon display-password form-control-icon"
			></span>
		</div> -->
	</div>
	<div class="permissions-header" translate>Data Permissions</div>
	<div class="">
		<div class="toggle_title" translate>
			Select Role <sup>* </sup> <span translate>(Select any 1)</span>
		</div>
		<div class="toggle_radio_gap">
			<div *ngFor="let role of roleList; let i = index">
				<input
					type="radio"
					id="role-{{i}}"
					[value]="role"
					formControlName="select_role"
					[ngClass]="{ 'active': role.id === userForm.get('select_role')?.value?.id }"
				/>
				<label for="role-{{i}}" (click)="updateRoleSelected(role)">
					<i class="icon icon-Check"></i>
					<p>{{role?.attributes?.name}}</p>
				</label>
			</div>
		</div>
	</div>

	<div class="store-footer">
		<button
			class="btn btn-secondary btn-lg"
			type="button"
			(click)="onCloseAction()"
			translate
		>
			Cancel
		</button>
		<button
			class="btn btn-primary btn-lg"
			type="submit"
			[disabled]="checkUpdate()"
			translate
		>
			{{actionType === "edit" ? 'Submit' : 'Add Team Member' }}
		</button>
	</div>
</form>
