/* eslint-disable */
import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { LineChartModel } from "@ds-models/charts.model";
import { Subject, filter, takeUntil } from "rxjs";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
let componentContext;
@Component({
  selector: 'app-combined-chart',
  templateUrl: './combined-chart.component.html',
  styleUrls: ['./combined-chart.component.scss']
})
export class CombinedChartComponent implements OnInit {

  @Input() public data: LineChartModel[];
  @Input() public categories;
  @Input() public isMultipleYaxis = false;
  @Input() public leftYAxisTitle = "";
  @Input() public rightYAxisTitle = "";
  @Input() public caller = "default";
  @Input() public scrollbarThreshold = 12;
  @Input() public rightAxis = {y:-30, offset: -10};
  @Input() public leftAxis = {y:-20, offset: -63};
  private destroy$: Subject<boolean> = new Subject();
  public Highcharts: typeof Highcharts = Highcharts; // required
  public highchartsData: typeof Highcharts;
  chartOptions;
  chartRef;
  readonly MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  constructor(private dataTransferService: DataTransferService, private commonFunctions: CommonFunctionsService) {
    this.dataTransferService.secondaaryMenuToggleSignal$
      .pipe(
        filter((res) => res),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        setTimeout(() => {
          this.chartRef.reflow();
        }, 0);
      });
  }

  ngOnInit(): void {
    componentContext = this;
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
    this.renderSplineChart();
  }

  private renderSplineChart() {
    this.highchartsData = Highcharts;
    this.chartOptions = {
      chart: {
        // events: {
        // 	load: function () {
        // 		this.xAxis[0].setExtremes(0, 10);
        // 	}
        // }
        spacing: [55, 0, 5, 0],
        events: {
          load: () => {
            document
              .querySelectorAll(".highcharts-scrollbar-button")
              .forEach((el) => {
                el.remove();
              });
            document
              .querySelectorAll(".highcharts-scrollbar-arrow")
              .forEach((el) => {
                el.remove();
              });
          }
        }
      },
      title: {
        text: ""
      },
      legend: {
        enabled: false,
        symbolWidth: 16,
        symbolRadius: 0,
        squareSymbol: true
      },
      xAxis: {
        type: "category",
        categories: this.categories,
        gridLineWidth: 0,
        lineWidth: 1,
        tickWidth: 0,
        labels: {
          enabled: true,
          formatter: (ev) => {
            if (ev.value.toString()?.includes('-')) {
              return `${this.MONTHS[new Date(ev.value).getMonth()] + ' ' + new Date(ev.value).getDate()}`
            }
            return ev.value; // clean, unformatted number for year
          },
          style: {
            fontFamily: "Poppins Regular",
            fontWeight: "400",
            fontSize: "0.625rem",
            color: "#8D9199"
          },
          rotation: -30
        },
        scrollbar: {
          enabled: this.categories.length > this.scrollbarThreshold,
          barBackgroundColor: "#aaaaaae5",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "#B3B3B3",
          buttonBorderWidth: 0,
          buttonBorderRadius: 7,
          trackBackgroundColor: "none",
          trackBorderWidth: 0,
          trackBorderRadius: 8,
          trackBorderColor: "#CCC",
          rifleColor: "transparent"
        },
        tickLength: 0,
        gridLineColor: "transparent",
        max: (this.categories?.length > this.scrollbarThreshold) ? (this.scrollbarThreshold - 1) : (this.categories?.length - 1)
      },
      yAxis: this.isMultipleYaxis
        ? [
          {
            gridLineDashStyle: 'solid',
            minPadding: 0,
            maxPadding: 0,
            gridLineWidth: 0,
            gridLineColor: "#f5f5f5",
            // lineWidth: 1,
            title: {
              text: this.commonFunctions.getSpecificTranslation(this.rightYAxisTitle),
							align: "high",
							rotation: 0,
							y: this.rightAxis.y,
							// x:-20,
							offset: this.rightAxis.offset,
							style: {
								fontFamily: "Poppins Medium",
								fontSize: "0.625rem",
								fontWeight: "600",
								letterSpacing: "0.01em",
								color: "#1C2331"
							}
            },
            labels: {
              enabled: true,
              formatter: (ev) => {
                return this.commonFunctions.getSuffixedNumberFormat(ev.value);
              },
              style: {
                fontFamily: "Poppins Medium",
                fontWeight: "400",
                color: "#8D9199",
                fontSize: "0.625rem"
              }
            },
            opposite: true
          },
          {
            // lineWidth: 1,
            gridLineDashStyle: 'solid',
						title: {
							text: this.commonFunctions.getSpecificTranslation(this.leftYAxisTitle),
							align: "high",
							rotation: 0,
							y: this.leftAxis.y,
							offset: this.leftAxis.offset,
							style: {
								fontFamily: "Poppins Medium",
								fontSize: "0.625rem",
								fontWeight: "600",
								letterSpacing: "0.01em",
								color: "#1C2331"
							}
						},
            labels: {
              enabled: true,
              formatter: this.oppositeYAxisFormatter,
              style: {
                fontFamily: "Poppins Medium",
                fontWeight: "400",
                color: "#8D9199",
                fontSize: "0.625rem"
              }
            },
            opposite: false
          }
        ]
        : {
          labels: {
            format: "{value.:f}"
          }
        },
      tooltip: {
        enabled: true,
        split: false,
        backgroundColor: undefined,
        borderColor: undefined,
        borderWidth: 0,
        shadow: true,
        formatter: this.toolTipFormatter,
        shared: false,
        useHTML: true
      },
      plotOptions: {
        line: {
          events: {
            click: (ev) => {
              this.dataTransferService.sendGraphSliceData({ ...ev.point.series.userOptions, category: ev.point.category })
            }
          }
        },
        column: {
          grouping: false,
          shadow: false
        },
        series: {
          pointPadding: 0,
          cursor: 'pointer',
          borderWidth: 0,
					borderRadius: 2,
          dataLabels: {
            enabled: true,
            formatter: (ev) => {
              if (ev.value?.includes("-")) {
                return `${this.MONTHS[new Date(ev.value).getMonth()] +
                  " " +
                  new Date(ev.value).getDate()

                  }`;
              }
              return ev.value; // clean, unformatted number for year
            }
          },
          lineWidth: 2,
          marker: {
            enabled: false,
            symbol: "circle",
            shadow: false,
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: this.data,
      credits: false
    };

    // this.chartRef.reflow();
  }
  toolTipFormatter() {
    const dataLabel = this as Highcharts.DataLabelsOptions;
    switch (componentContext.caller) {
      case "onlineChannel":
        return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
			 				<div style="font-size:0.625rem;">On ${dataLabel.x} (${dataLabel['point']['mp']})</div>
              <div style="display:flex;" class="gap-16">
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${dataLabel['series']['userOptions']['type'] === 'column' ? componentContext.commonFunctions.numberFormat(dataLabel.y) : componentContext.commonFunctions.currencySelected.attributes.symbol + componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${dataLabel['series']['userOptions']['type'] === 'column' ? componentContext.commonFunctions.getSpecificTranslation('Quantity Sold') : componentContext.commonFunctions.getSpecificTranslation('Price')}</span>
							</div>
              <div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(dataLabel['point']['dealer_name'])}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Dealer')}</span>
							</div>
              </div>
						</div>`
      case "dailySales":
        return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:8rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
                <div style="font-size:0.625rem;">On ${dataLabel.x}</div>
              <div style="display:flex;" class="gap-16">
                <div style="display:flex;flex-direction: column;margin-top:0.5rem">
                  <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)}</span>
                  <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${dataLabel['series']['userOptions']['type'] === 'column' ? componentContext.commonFunctions.getSpecificTranslation('Quantity Sold') : componentContext.commonFunctions.getSpecificTranslation('Inventory Quantity')}</span>
                </div>
                ${
                  dataLabel['series']['userOptions']['type'] === 'column' ?
                  `
                    <div style="display:flex;flex-direction: column;margin-top:0.5rem">
                    <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${dataLabel['series']['userOptions']['currency']}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['series']['userOptions']['gmvDataPoint'][dataLabel['point']['index']])}</span>
                    <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;"> ${componentContext.commonFunctions.getSpecificTranslation('GMV')}</span>
                    </div>
                  `
                  : ''
                }
              </div>
            </div>`
      default:
        return "";
    }
  }
  oppositeYAxisFormatter() {
    const dataLabel = this as Highcharts.DataLabelsOptions;
    switch (componentContext.caller) {
      case "onlineChannel":
        return `${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}`;
      case "dailySales":
        return `${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}`;
      default:
        return "";
    }
  }
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  };
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
