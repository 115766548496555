import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
	UntypedFormControl,
	ValidationErrors,
	Validators
} from "@angular/forms";
import {
	HarmonisedListService,
	HarmonisationService
} from "@ds-private-layouts/configuration/harmonisation/harmonisation.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { CustomValidationsService } from "@ds-shared/common-services/utility-services/custom-validations.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription, takeUntil } from "rxjs";

@Component({
	selector: "app-add-harmonisation",
	templateUrl: "./add-harmonisation.component.html",
	styleUrls: ["./add-harmonisation.component.scss"]
})
export class AddHarmonisationComponent implements OnInit {
	@ViewChild("content") content: ElementRef;
	@Input() public inputData;
	public harmonisedName: UntypedFormControl;
	public subscription: Subscription;
	constructor(
		public modal: NgbModal,
		public activeModal: NgbActiveModal,
		private harmonisedListService: HarmonisedListService,
		private harmonisationService: HarmonisationService,
		private customValidationService: CustomValidationsService,
		private dataTransferService: DataTransferService,
		private notificationMessageService: NotificationService,
		private commonFunctions: CommonFunctionsService
	) {}

	ngOnInit(): void {
		this.harmonisedName = new UntypedFormControl(this.getDefaultValue(), [
			Validators.required,
			this.validateString.bind(this)
		]);
		if (this.inputData.prefill) this.harmonisedName.disable();
	}

	private validateString(control: UntypedFormControl): ValidationErrors | null {
		return this.customValidationService.validateInputString(control);
	}

	openModal(data) {
		this.harmonisedName.reset();
	}

	private getDefaultValue() {
		if (this.inputData.name) return this.inputData.name.trim();
		else return null;
	}

	public addHarmonisationData() {
		this.subscription?.unsubscribe();
		this.subscription = this.harmonisationService
			.addHarmonisation(this.inputData.type, {
				name: this.harmonisedName.value?.trim(),
				currency_id: this.commonFunctions.currencySelected?.id
			})
			.pipe()
			.subscribe(
				(res) => {
					if (res) {
						this.harmonisedName.reset();
						this.notificationMessageService.setMessage(
							res["context_code"],
							res["summary"]
						);
					}
				},
				(err) => {
					this.notificationMessageService.setMessage(
						err.error["context_code"],
						err.error["summary"]
					);
				}
			);
	}
}
