import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { LOCALFILTER } from "@ds-shared/enums/common.enum";
import { Subject, finalize, takeUntil } from "rxjs";

@Component({
	selector: "app-common-filters",
	templateUrl: "./common-filters.component.html",
	styleUrls: ["./common-filters.component.scss"]
})
export class CommonFiltersComponent implements OnInit {
	public filterForm: UntypedFormGroup;
	private $destroy: Subject<boolean> = new Subject();
	public isOpen: boolean = false;
	public isOptionalActive: boolean = false;

	@Output() filterChange = new EventEmitter();
	@Input() public optionalFilterParams: {
		style?;
		filterRequired: {
			marketplace: boolean;
			store: boolean;
			brand: boolean;
			category: boolean;
		};
	} = {
		style: null,
		filterRequired: {
			marketplace: false,
			store: true,
			brand: true,
			category: true
		}
	};
	@Input() set filterClear(value) {
		if (value.to_and_fro) {
			this.clearFilterPermanently();
		}
	}
	public filterList = {
		marketplace: {
			key: LOCALFILTER.RETAIL_CHANNEL,
			placeholder: "Channel",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "common/marketplaces",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			subscription: null
		},
		[LOCALFILTER.STORE]: {
			key: LOCALFILTER.STORE,
			placeholder: "Store",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "common/stores",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			subscription: null
		},
		[LOCALFILTER.BRAND]: {
			placeholder: "Brand",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "common/brands",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			subscription: null
		},
		[LOCALFILTER.CATEGORY]: {
			placeholder: "Category",
			list: [],
			meta: { key: "attributes.name" },
			multiple: true,
			selected: [],
			searchTerm: "",
			loading: false,
			path: "common/categories",
			query: [],
			clear: true,
			closeOnSelect: false,
			needAPIcall: true,
			subscription: null
		}
	};

	public filters: string[] = Object.keys(this.filterList);
	private _globalFilterParam;
	get globalFilterParam() {
		return this._globalFilterParam;
	}
	@Input() set globalFilterParam(value) {
		this._globalFilterParam = value;
		this.getLocalFilterData();
	}

	constructor(
		public commonFunctions: CommonFunctionsService,
		private globalService: GlobalService
	) {}

	ngOnInit(): void {
		this.filterForm = new UntypedFormGroup({
			marketplace: new UntypedFormControl([]),
			store: new UntypedFormControl([]),
			brand: new UntypedFormControl([]),
			category: new UntypedFormControl([])
		});
	}

	public onSubmit(): void {
		this.isOptionalActive = false;
		const tempObj = JSON.parse(JSON.stringify(this.filterList));
		this.filters.forEach((item) => {
			this.filterList[item].selected = this.filterForm.get(item).value;
			if (
				this.filterList[item].selected?.length ||
				(!this.filterList[item]?.multiple &&
					this.filterList[item].selected?.attributes)
			)
				this.isOptionalActive = true;
			if (["store", "brand", "category"].includes(item)) {
				const newData = [];
				this.filterList[item].selected.forEach((data) => {
					newData.push(...data["attributes"]["raw_data"]);
				});
				tempObj[item].selected = [...newData];
			} else {
				tempObj[item].selected = Array.isArray(this.filterList[item].selected)
					? [...this.filterList[item].selected]
					: this.filterList[item].selected;
			}
		});
		this.filterChange.emit(tempObj);
		this.isOpen = !this.isOpen;
	}

	private getLocalFilterData() {
		this.filters.forEach((item, i) => {
			if (
				this.optionalFilterParams.filterRequired[item] &&
				this.filterList[item].needAPIcall
			) {
				this.filterList[item].subscription?.unsubscribe();
				this.filterList[item].loading = true;
				this.filterList[item].list = [];
				this.filterList[item].subscription = this.globalService
					.getCommonFilters(this.filterList[item].path, this.globalFilterParam)
					.pipe(
						finalize(() => (this.filterList[item].loading = false)),
						takeUntil(this.$destroy)
					)
					.subscribe((res: any) => {
						this.filterList[item].list = res.data;
					});
			}
		});
	}

	public closeMultiDropdown(key) {
		this.filterList[key].searchTerm = "";
	}

	onMultiSearch(event, key: string) {
		this.filterList[key].searchTerm = event.term;
	}
	openFilter() {
		this.isOpen = !this.isOpen;
		if (this.isOpen) {
			this.filters.forEach((item) => {
				this.filterForm.get(item).setValue(this.filterList[item].selected);
			});
		}
	}
	clearFilter() {
		this.filters.forEach((item) => {
			this.filterForm.get(item).setValue([]);
		});
	}

	clearFilterPermanently() {
		this.filters.forEach((item) => {
			this.filterForm.get(item).setValue([]);
			this.filterList[item].selected = [];
		});
		this.isOptionalActive = false;
	}

	checkSelectedOption(value, item) {
		if (value) {
			return value.map((val) => val.id).includes(item.id);
		}
	}

	public ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
