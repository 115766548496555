<span
	*ngIf="data;else tipContent"
	class="tooltip-container"
	[ngClass]="ttPosition"
>
	<span
		[id]="unqiueId"
		class="ss-tooltip main-content"
		[ngClass]="changeHeight"
	>
		<span style="color: inherit" innerText="{{data}}"></span>
	</span>
	<span class="ss-tooltip main-tooltip" *ngIf="isVisible">
		<span class="dummy-triangle"></span>
		<span
			dir="ltr"
			class="ss-tooltip-text arrow-bottom tooltip-bottom"
			[innerText]="data"
		>
		</span>
	</span>
</span>

<ng-template #tipContent
	><span
		innerText="{{data}}"
		class="main-content-2"
		[ngClass]="changeHeight"
	></span
></ng-template>
<span *ngIf="!data">-</span>
