import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { DaterangepickerDirective, LocaleConfig } from 'ngx-daterangepicker-material';
import moment from 'moment';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { TIMELINE } from '@ds-shared/enums/common.enum';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service'
import { finalize, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'secondary-scorecard-filter',
  templateUrl: './secondary-scorecard-filter.component.html',
  styleUrls: ['./secondary-scorecard-filter.component.scss']
})
export class SecondaryScorecardFilterComponent implements OnInit {

  @Output("onDateChange") onDateChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  public selectedDate:any;
  calendarLocale: LocaleConfig;
  public maxDate = moment();
  public startDate = '';
  public endDate = '';
  public activeRange: 'today' | 'month' | 'week' | '-' = 'month';
  public rangeString: string;
  private isFirstChange = true;
	@Input() hideSelectedDateText = false;
	public filterParams: any;
  private $destroy: Subject<boolean> = new Subject();
  public isCustomDateChangeFirstCall = true;
  constructor( public commonFunctions: CommonFunctionsService,private dataTransferService: DataTransferService) { 
    this.calendarLocale = {
      applyLabel: this.gt("DONE"),
      clearLabel: this.gt("CANCEL"),
      daysOfWeek: [this.gt("Su"), this.gt("Mo"), this.gt("Tu"), this.gt("We"), this.gt("Th"), this.gt("Fr"), this.gt("Sa")],
      monthNames: [this.gt("January"), this.gt("February"), this.gt("March"), this.gt("April"), this.gt("May"), this.gt("June"), this.gt("July"), this.gt("August"), this.gt("September"), this.gt("October"), this.gt("November"), this.gt("December")]
    };
  }

  ngOnInit(): void {
    // this.setRange('month');
    this.dataTransferService.onDirectMappingChange$
    .pipe(takeUntil(this.$destroy))
    .subscribe((res) => {
      if (res) {
          if(!this.isCustomDateChangeFirstCall)
          this.rangeString = null;
          this.isCustomDateChangeFirstCall = false;
      }
    });
  }

  openCalendar() {
    
  }

  datesUpdated(range) {
    if(this.isFirstChange){this.isFirstChange = false; return;}
    if (range.startDate != null || range.endDate != null) {
      this.rangeString = null;
      this.startDate = this.selectedDate.startDate.format('MMM Do YYYY');
      this.endDate = this.selectedDate.endDate.format('MMM Do YYYY');
      let startDate = this.getNumberDate(this.selectedDate.startDate);
      let endDate = this.getNumberDate(this.selectedDate.endDate);
      let dateObj = { startDate: startDate, endDate: endDate }
      this.onDateChange.emit(dateObj);
      this.activeRange = '-';
    }
  }
  
  getNumberDate(date):any | void {
    let numberDate = date?.format('L').split('/');
    if (numberDate?.length == 3) { return numberDate[2]+ '-' + numberDate[0]+ '-' + numberDate[1] }
    return;
  }

  setRange(range) {
    this.selectedDate = ''
    this.activeRange = range;
    if (this.activeRange) {
      this.setDateFromRange(this.activeRange);
      this.dataTransferService.sendisSecondaryScorecardFilterSet(this.activeRange);
    }
  }

  setDateFromRange(rangeString) {
    this.rangeString = rangeString;
    if (rangeString == '-') { return }
    let todayDate = moment(), startDate = moment();
    let endDate = moment();
    if (rangeString == 'today') startDate = todayDate;
    if (rangeString == 'week') {
      startDate = startDate.subtract(7, "d")
      endDate = moment().subtract(1, "d")
    }
    if (rangeString == 'month') {
      startDate =  startDate.subtract(30, "d")
      endDate = moment().subtract(1, "d")
    }
    if (rangeString == '6 month') {
      startDate = startDate.subtract(180, "d");
      endDate = moment().subtract(1, "d")
    }
    if (rangeString == '3 month') {
      startDate = startDate.subtract(90, "d")
      endDate = moment().subtract(1, "d")
    }
    this.startDate = this.getDisplayDateString(startDate);
    this.endDate = this.getDisplayDateString(endDate);
    let startDateNum = this.getNumberDate(startDate);
    let endDateNum = this.getNumberDate(endDate);
    let dateObj = {startDate: startDateNum, endDate:endDateNum }
    this.onDateChange.emit(dateObj);
  }

  getDisplayDateString(momentDate) {
    if (momentDate) return momentDate.format('MMM Do YYYY');
  }

  gt(key: string): string{
    return this.commonFunctions.getSpecificTranslation(key);
  }
  
}
