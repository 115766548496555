import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-tags-dropdown',
  templateUrl: './tags-dropdown.component.html',
  styleUrls: ['./tags-dropdown.component.scss']
})
export class TagsDropdownComponent implements OnInit {
  @Input() item:string[]=[];
  @Input() dropdownType: string;

  constructor() { }

  ngOnInit(): void { }

}
