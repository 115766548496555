<span *ngIf="data;else tipContent" [ngClass]="ttPosition">
	<span
		class="ss-tooltip custom-js-indicator-position"
		style="position: initial"
		[ngClass]="{'top' : ttPosition == 'top'}"
	>
		<span style="color: inherit" innerText="{{data}}"></span>

		<span
			class="ss-tooltip"
			style="position: initial"
			[ngClass]="isVisible?'d-block':'d-none'"
		>
			<span
				class="dummy-triangle"
				[ngClass]="(ttPosition == 'top' ? 'arrowTop':'')"
			></span>
			<span
				class="ss-tooltip-text arrow-bottom tooltip-bottom"
				innerText="{{data}}"
			></span>
		</span>
	</span>
</span>
<ng-template #tipContent><span innerText="{{data}}"></span></ng-template>
<span *ngIf="!data && data !== 0">-</span>
