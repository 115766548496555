<div
	class="dashboard-widget-config-button d-flex"
	(click)="toggleConfig()"
	[class.isPanelOpen]="isOpen"
>
	<i
		class="icon icon-Settings-alt"
		ngbTooltip="{{'Dashboard Configuration' | translate}}"
		tooltipClass="info-custom-tooltip"
		placement="left"
	></i>
</div>

<ng-container *ngIf="isOpen">
	<!-- <div class="overlay"></div> -->
	<div class="right-panel">
		<div class="d-flex mt-3 justify-content-start align-items-center gap-8">
			<i class="icon icon-Layout"></i>
			<span translate>Dashboard Configuration</span>
		</div>
		<section class="mt-3">
			<div class="p-2">
				<label class="checkbox d-flex">
					<input
						type="checkbox"
						name="checkbox"
						[checked]="isEveryColumnChecked()"
						(change)="onSelectAllColumn($event)"
					/>
					<div class="checkbox-section"><i class="icon icon-Check"></i></div>
					<span class="text" showTooltipOnTextOverflow [data]="'Select All'"
						>Select All</span
					>
				</label>
			</div>
			<div *ngFor="let item of componentList; let i = index">
				<div *ngIf="!item.hide" class="p-2">
					<label class="checkbox d-flex" [for]="item.id">
						<input
							type="checkbox"
							name="{{item.name}}"
							[checked]="item.isChecked"
							[id]="item.id"
							(change)="onClickCheckbox(i)"
						/>
						<div class="checkbox-section"><i class="icon icon-Check"></i></div>
						<span class="text" showTooltipOnTextOverflow [data]="item.name"
							>{{item.name | translate}}</span
						>
					</label>
				</div>
			</div>
		</section>
		<div class="footer d-flex justify-content-end gap-16">
			<button
				class="btn btn-primary"
				(click)="onApply(); $event.stopPropagation()"
				[disabled]="checkDisable()"
				ngbTooltip="{{checkDisable() ? ('Please select atleast one component.' | translate) : null}}"
				tooltipClass="info-custom-tooltip"
			>
				Save
			</button>
			<button class="btn btn-secondary" (click)="toggleConfig()">Cancel</button>
		</div>
	</div>
</ng-container>
