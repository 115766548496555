export enum AccessMatrix{
    "Discover"="discover",
    "Dashboard"="dashboard",
    "Scorecard"="scorecard",
    "Profiles"="build",
    "Notifications"="notification",
    "Insights"="insights",
    "User Management" = "user-management",
    "Sales" = "sales",
    "Availability" = "availability",
    "Pricing" = "pricing",
    "Configuration" = "configuration",
    "Reporting"="reporting",
    "Content Quality"="content-quality",
    'Share of Search' = 'share-of-search'
}