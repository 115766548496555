<div class="modal-header" ngbAutoFocus>
    <h4 class="modal-title" translate>{{popupInfo.mainTitle}}</h4>
</div>
<div class="modal-body d-flex justify-content-center align-items-center flex-column">
    <i class="icon icon-Shuffle mb-2" ngbAutofocus></i>
    <p translate class="mb-1" [innerHTML]="popupInfo.description"></p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-lg" (click)="activeModal.close(false)"
        translate>Cancel</button>
    <button type="button" class="btn btn-primary btn-lg" (click)="activeModal.close(true)"
        translate>{{popupInfo.submitTitle}}</button>
</div>
