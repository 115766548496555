<!-- Commenting till we get value from backend -->
<span
	*ngIf="className && storeName && snClass"
	class="stores-logo-inner"
	translate
	[ngClass]="[snClass , className]"
	ngbTooltip="{{storeTooltip}}"
	placement="{{commonFunctions.rtlStatus ? 'left' : 'right'}}"
	tooltipClass="info-custom-tooltip"
>
	{{ storeName }}
</span>
<span
	*ngIf="!className && storeName && snClass"
	class="stores-logo-inner"
	translate
	[ngClass]="[snClass]"
	ngbTooltip="{{storeTooltip}}"
	placement="{{commonFunctions.rtlStatus ? 'left' : 'right'}}"
	tooltipClass="info-custom-tooltip"
>
	{{ storeName }}
</span>
