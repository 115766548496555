<!-- <app-common-in-app-messages [messageKey]="'scorecard_'+listType+'_info'"
	[messageType]="'warning'"></app-common-in-app-messages> -->
<section class="sticky-header py-3">
	<div class="d-flex justify-content-between align-items-center">
		<app-breadcrumb-common [data]="scorecardBredcrumb"></app-breadcrumb-common>
		<div class="d-flex align-items-center gap-8">
			<!-- <app-timeline-filters (selectedFilterData)="onFilterData($event)"
				[showFilter]="{showTimelineText: false, marketplace: false, timeline: false, customRange: false, kpiToggle: false, showCurrency: false }"></app-timeline-filters> -->
			<!-- <app-local-filters *ngIf="tabList[active].value === 'all'" [optionalFilterParams]="optionalFilterParams"
				(filterChange)="onOptionalFilterUpdate($event,'all')"></app-local-filters>
			<app-local-filters *ngIf="tabList[active].value === 'userOnly'"
				[optionalFilterParams]="optionalFilterParams"
				(filterChange)="onOptionalFilterUpdate($event,'userOnly')"></app-local-filters> -->
			<app-common-preferences [showCurrencyFilter]="showCurrencyFilter"></app-common-preferences>
		</div>
	</div>
</section>
<div class="custom-tab">
	<ul
		ngbNav
		#nav="ngbNav"
		[(activeId)]="active"
		class="nav-tabs"
		class="nav-pills d-flex justify-content-start nav"
		[ngClass]="{'mb-3': listType!=='keyword'}"
	>
		<ng-container *ngFor="let tab of tabList; let i = index">
			<li [ngbNavItem]="i" *ngIf="!tab.isHidden">
				<a ngbNavLink (click)="onTabChange(i)">
					<span class="p-3 text-capitalize" translate
						>{{ tab.name + (tab.value === 'harmonised' ? ' collection' :
						(tab.value === 'all' ? ' listing' : '')) }}</span
					>
					<span
						class="count"
						[ngClass]="{'non-selected-count-style':i!==active}"
						translate
						*ngIf="scorecardCounts && scorecardCounts[tab.countKey]"
						>{{scorecardCounts[tab.countKey] | numberSuffix : 2}}</span
					>
				</a>
			</li>
		</ng-container>
	</ul>
</div>
<div class="mt-3" *ngIf="tabList[active].value === 'harmonised'">
	<app-harmonisation [harmonisationType]="listType"></app-harmonisation>
</div>
<div class="grid-container mb-3" *ngIf="tabList[active].value !== 'harmonised'">
	<div class="grid-actions d-flex justify-content-between align-items-center">
		<div class="grid-name d-flex align-items-center gap-8 text-capitalize">
			<span *ngIf="tabList[active].value === 'all'">
				{{ listType === 'product' ? listType +'s listing' : (listType ===
				'category' ? 'Categories': listType + 's')}}
			</span>
			<span *ngIf="tabList[active].value !== 'all'" class="text-capitalize"
				>{{listType !== 'category' ? 'Tracked'+' '+listType+'s' : 'Tracked
				Categories'}}</span
			>
			<span
				*ngIf="listType==='keyword' && scorecardCounts && scorecardCounts[this.listType]"
				class="count non-selected-count-style"
				translate
				>{{scorecardCounts[this.listType] | numberSuffix : 2}}</span
			>
			<!-- <div class="ds-switch checked-only-switch" *ngIf="!disabledTypeGrids.includes(listType)">
				<div class="name" [ngClass]="{'active-color':defaultListType===GRIDTYPE.USERONLY}" translate>Show User Tracked
					{{listType!=='category'? (listType+'s'|titlecase) : 'Categories'}}</div>
                <label class="switch-container d-flex" for="switch-view" >
                    <input id="switch-view" [disabled]="disabledTypeGrids.includes(listType)" name="metric-view" [checked]="defaultListType===GRIDTYPE.ALL || defaultListType===GRIDTYPE.HARMONISED" type="checkbox"
					(change)="commonFunctions.clearScorecardLocalFilters();onListTypeChange((this.defaultListType===GRIDTYPE.ALL || this.defaultListType===GRIDTYPE.HARMONISED) ?GRIDTYPE.USERONLY:(isHarmonised ? GRIDTYPE.HARMONISED : GRIDTYPE.ALL));" />
                    <div class="toggle-content" [ngClass]="{'cursor-not-allowed':disabledTypeGrids.includes(listType)}">
                        <div class="toggle-bubble"></div>
                    </div>
                </label>
            </div>
			<span class="name" [ngClass]="{'active-color':defaultListType===GRIDTYPE.ALL || defaultListType===GRIDTYPE.HARMONISED}"
				translate>{{isHarmonised ? 'Show Harmonised ' : (['store','product'].includes(listType) ? 'Show All ' : '')}}{{listType!=='category'? (listType+'s'|titlecase)
				: 'Categories'}}</span>  -->
			<!-- <section
				*ngIf="optional"
				class="optional-filter-container d-flex align-items-center gap-6 flex-wrap pe-2"
			>
				<ng-container *ngFor="let filter of permittedLocalFilter">
					<div
						class="filter-badge d-flex align-items-center justify-content-center"
						*ngIf="localFilters[filter]?.selected?.length || (!localFilters[filter]?.multiple && localFilters[filter]?.selected?.attributes)"
						(click)="dataTransferService.sendOpenOptionalFilter(true)"
					>
						<span class="count"
							>{{localFilters[filter]?.multiple?localFilters[filter]?.selected?.length:1}}</span
						>
						<span class="name">{{localFilters[filter].path | titlecase}}</span>
					</div>
				</ng-container>
			</section> -->
		</div>
		<div class="d-flex align-items-center gap-8">
			<!-- Toggle Start -->
			<div
				class="d-flex align-items-center justify-content-end w-100 gap-8"
				*ngIf="tabList[active].value === GRIDTYPE.ALL && this.listType === 'product'"
			>
				<!-- <span class="view-by">View by</span> -->
				<!-- no brand dropdown -->
				<div class="product-type-dropdown">
					<ng-select
						[items]="productType"
						[(ngModel)]="defaultProductType"
						[notFoundText]="'No Data Found' | translate"
						bindLabel="label"
						placeholder="Timeline"
						[clearable]="false"
						(change)="callProductswithNoBrand($event)"
						[ngbTooltip]="'Timeline' | translate"
						tooltipClass="info-custom-tooltip"
					>
						<ng-template ng-label-tmp let-item="item">
							{{item.label | translate}}
						</ng-template>
						<ng-template ng-option-tmp let-item="item">
							<span class="float-start">{{item.label | translate}}</span>
						</ng-template>
					</ng-select>
				</div>
				<!--  Code ends here -->
			</div>
			<!-- Toggle End -->
			<app-search-common
				[searchObj]="searchObj"
				(onSearch)="onSearch($event)"
				[isScorecard]="true"
			></app-search-common>
			<!-- Local Filter Start -->
			<app-local-filters
				*ngIf="tabList[active].value === 'all'"
				[optionalFilterParams]="optionalFilterParams"
				(filterChange)="onOptionalFilterUpdate($event,'all')"
				[globalScorecardFilterParam]="globalScorecardFilterParam"
				[moduleSpecificMiscParams]="{'module': 'scorecardMainPage' , 'tab': 'all', 'scorecardType': listType}"
			></app-local-filters>
			<app-local-filters
				*ngIf="tabList[active].value === 'userOnly'"
				[optionalFilterParams]="optionalFilterParams"
				(filterChange)="onOptionalFilterUpdate($event,'userOnly')"
				[moduleSpecificMiscParams]="{'module': 'scorecardMainPage' , 'tab': 'userOnly', 'scorecardType': listType}"
			></app-local-filters>
			<!-- Local Filter End -->
			<div
				class="d-flex align-items-center transmission-action gap-8"
				*ngIf="tabList[active].value === GRIDTYPE.USERONLY"
			>
				<bulk-actions
					[listType]="listType"
					[buttonStyle]="{height: '2.25rem'}"
				></bulk-actions>
				<button
					class="btn super-btn btn-lg track-new-btn"
					[disabled]="!permissions.write_access"
					(click)="addProfile()"
					translate
				>
					<i class="icon icon-Location-plus"></i>
					<span>{{'Track New' | translate}}</span>
				</button>
			</div>
			<app-manage-columns
				*ngIf="toggleManageColumns"
				class="manage-columns"
				[moduleType]="moduleType"
				[columns]="cols"
				[isScorecardList]="true"
				[hideColumn]="hideColumn"
			></app-manage-columns>
			<app-common-action-dropdown
				[path]="exportPath"
				[params]="exportParams"
				[filename]="tabList[this.active].value ===GRIDTYPE.ALL?(listType | titlecase) +' list':(tabList[this.active].value===GRIDTYPE.HARMONISED ? 'Scorecard ' + listType + ' Harmonised List' :'Scorecard '+listType+' trackers')"
				[columns]="cols"
				[disableIcon]="NoData"
			></app-common-action-dropdown>
		</div>
	</div>
	<app-ngx-grid-revamp
		className="{{'scorecard-'+listType+'-'+tabList[this.active].value}}"
		[ngxCols]="cols"
		[ngxItems]="scorecardList"
		[moduleType]="moduleType"
		[column]="cols"
		[collection]="scorecardList"
		[loadingData]="loadingData"
		[noData]="NoData"
		(sortData)="sortData($event)"
		[itemPerPage]="itemPerPage"
		[isPaginationWithoutRoute]="true"
		(paginationNo)="onPageChange($event)"
		(paginationSize)="onPageSizeChange($event)"
		[showThreeOptionsForPageSize]="true"
		(refreshGrid)="onRefreshGrid($event)"
		(navigationTrigger)="onCountClick($event)"
		[isScorecardList]="true"
		[bulkActionParams]="bulkActionParams"
	></app-ngx-grid-revamp>
</div>
<app-common-grid-pop-up></app-common-grid-pop-up>
<app-insights
	[insightInfo]="insightInfo"
	*ngIf="['store', 'keyword', 'category'].includes(listType)"
></app-insights>

<router-outlet></router-outlet>
