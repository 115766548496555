import { Component, OnInit, OnDestroy, Input, SimpleChanges } from "@angular/core";
import { DocumentCollection } from "ngx-jsonapi";
import { Subject } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import {
  CustomerSegmentationService,
  ProfileScorecard,
} from "../../../../layouts/private-layouts/scorecard/profile-information/profile-information.service";
import { takeUntil , Subscription} from 'rxjs';
import { ColumnModel, CommonSearchModel, CurrencyTemplate, ModuleType } from "@ds-shared/models/common.model";
import moment from "moment";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";

@Component({
  selector: "app-segmentation",
  templateUrl: "./segmentation.component.html",
  styleUrls: ["./segmentation.component.scss"],
})

export class SegmentationComponent implements OnInit, OnDestroy {
  private _scorecardInfo: any;
  get scorecardInfo(): any {
    return this._scorecardInfo;
  }
  @Input() set scorecardInfo(value: any) {
    this._scorecardInfo = value;
    if (this.scorecardInfo) {
      this.segmentDetail = this.segmentType[this.scorecardInfo.type];
      this.mappedType = this.segmentDetail.list[0];
      this.resetData();
      this.setExtraServices(this.segmentDetail.path);
      this.initData();
    }
  }
  private _dateFilter: any;
	get dateFilter(): any {
		return this._dateFilter;
	}
  @Input() set dateFilter(value: any) {
		this._dateFilter = value;
		if (this.scorecardInfo && this.dateFilter) {
			// this.isCustomDate = false;
			this.getSegmentationSelectionList(1);
		}
	}
  @Input() currencyDetails: CurrencyTemplate;

  public segmentationDataLists: DocumentCollection<ProfileScorecard>;
  public cols: ColumnModel[];
  public NoData: boolean = false;
  public sort: string[] = [];
  public loadingData: boolean = false;
  public page: number = 1;
  public pageSize: number;
  public mappedType: string;
  public itemPerPage: number = 10;

  public moduleType: ModuleType = {
    name: "segmentation",
    type: '',
    style: {
      tableStyle: {
        height: '28.43rem'
      },
      tableWrapper: {
        'border': 'none'
      },
      noDataStyle: {
        height: '28.43rem',
      },
      paginationStyle: {
        'border': 'none',
        'padding': '1rem 0rem 0rem 0rem'
      }
    },
    pagination: {
      show: true
    },
    showHeader: false
  };
  public searchObj: CommonSearchModel = {
    text: '',
    placeholder: '',
    style: { width: '19rem' }
  }
  public $destroy: Subject<boolean> = new Subject();
  public segmentType = {
    "store": {
      list: ["brand", "category"], path: 'store-brand-segmentation', beforePath: 'scorecard'
    },
    "keyword": {
      list: ["store", "brand"], path: 'store-brand-segmentation', beforePath: 'scorecard'
    },
    "brand": {
      list: ["store", "category"], path: 'store-brand-segmentation', beforePath: 'scorecard'
    },
    "category": {
      list: ["store", "brand"], path: 'store-brand-segmentation', beforePath: 'scorecard'
    }
  }
  public segmentDetail;
	public clearLastCall: Subscription;
  @Input() permittedLocalFilter;

  // @Input() optionalParams;
  private _localFilters;
   get localFilters() {
     return this._localFilters;
   }
   @Input() set optionalFilters(value) {
     this._localFilters = value;
     if(this.permittedLocalFilter){if (this.permittedLocalFilter.some(item => { return this._localFilters[item] })) {
       this.getSegmentationSelectionList(1);
     }
    }
   }
  private _miscParams: any = {};
  get miscParams(): any {
    return this._miscParams;
  }
  @Input() set miscParams(value: any) {
    this._miscParams = value;
    if (this.scorecardInfo && Object.keys(this.miscParams).length) {
      this.getSegmentationSelectionList(1);
    }
  }
  constructor(
    private customerSegmentationService: CustomerSegmentationService,
    public commonFunctions: CommonFunctionsService,
    private dataTransferService: DataTransferService
  ) {
    customerSegmentationService.register();
    this.getCols();
  }
 
  ngOnInit(): void { 
  }

  private setExtraServices(type): void {
    this.customerSegmentationService.setType(type);
    this.customerSegmentationService.register();
  }

  onPageChange(eve) {
    this.page = eve;
    this.getSegmentationSelectionList(this.page);
  }

  onPageSizeChange(eve) {
    this.pageSize = eve;
    this.itemPerPage = eve;
    this.getSegmentationSelectionList(1);
  }

  getCols() {
    this.cols = [];
  }

  initData() {
    this.getSegmentationSelectionList(1);
  }

  onMappedTypeUpdate(val) {
    this.mappedType = val;
    this.resetData();
    this.initData();
  }

  public getDisplayDate(date){
    return this.getDisplayDateString(moment(date))
  }
  private getDisplayDateString(momentDate) {
    if (momentDate) return momentDate.format('MMM Do YYYY');
  }

  getSegmentationSelectionList(page: number) {
    this.segmentationDataLists = new DocumentCollection();
    let params: any = {};
    params[`${this.scorecardInfo.view}_id`] = this.scorecardInfo.id;
    params["scorecard_type"] = this.scorecardInfo.type;
    params["segmentation_type"] = this.mappedType;
    params["tag"] = "self";
    params['search'] = this.searchObj.text?.trim();
    params['start_date'] = this.dateFilter?.startDate;
    params['end_date'] = this.dateFilter?.endDate;
    params = { ...params, ...this.miscParams };
    if(this.permittedLocalFilter){this.permittedLocalFilter.forEach(item => {
			params[this.localFilters?.[item]?.payloadString] = this.commonFunctions.getMappedData(this.localFilters?.[item]?.selected, this.localFilters?.[item]?.meta?.key);
		})}
    params = this.commonFunctions.filterPayload(params);
    this.clearLastCall?.unsubscribe();
		this.clearLastCall = this.customerSegmentationService
      .all({
        page: { number: page || 1, size: this.pageSize || 10 },
        remotefilter: params,
        beforepath: this.segmentDetail.beforePath,
      }).pipe(takeUntil(this.$destroy))
      .subscribe(
        (segmentationDataLists) => {
          this.loadingData =
            segmentationDataLists.data.length === 0 ? true : false;
          if (
            segmentationDataLists.source === "server" &&
            segmentationDataLists.is_loading == false
          ) {
            if (segmentationDataLists.data.length == 0) {
              this.NoData = true;
              this.loadingData = true;
            } else {
              this.loadingData = false;
              this.NoData = false;
              segmentationDataLists.data = segmentationDataLists.data.map((item)=>{
                item['attributes']['currency'] = this.currencyDetails
                return item;
              })
              this.segmentationDataLists = segmentationDataLists;
            }
          }
          else {
            this.loadingData = true;
            this.NoData = false;
          }
        },
        (error: any): void => {
          this.loadingData = true;
          this.NoData = true;
        }
      );
  }

  sortData(data: any) {
    this.sort = data;
    this.getSegmentationSelectionList(this.page);
  }

  public onSearch(text: string): void {
    this.searchObj = { ...this.searchObj, text: text };
    this.initData();
  }
  public resetData(): void {
    this.moduleType = { ...this.moduleType, type: this.mappedType };
    this.searchObj = { ...this.searchObj, text: '', placeholder: `Search ${this.commonFunctions.capitalizeFirstLetter(this.mappedType)}` };
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }
}
