import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserManagementService } from '@ds-private-layouts/user-management/user-management.service';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { LocalStorageService } from '@ds-common-services/storage-services/local-storage.service';
import { NotificationService } from '@ds-common-services/utility-services/notification.service';
import { RolePermissions } from '@ds-models/access-matrix.model';
import { AccessMatrix } from '@ds-enums/access-matrix.enum';
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';

@Component({
  selector: 'app-roles-action',
  templateUrl: './roles-action.component.html',
  styleUrls: ['./roles-action.component.scss']
})
export class RolesActionComponent implements OnInit {

  @Input() public actionData: any;
  @Input() public componentDetail: any;
  @Input() public col: any;
  public permissions: RolePermissions = new RolePermissions();
  public deleteRoleSubscription: Subscription;
  public accessParams: any[] = [];
  public allModuleRoles: any;
  public rolesDataSubscription: Subscription;
  public roleData: any;
  public totalModule: number;
  public userData;
  public moduleNameMapping = {};
  constructor(
    private router: Router,
    public userManagementService: UserManagementService,
    private notificationMessageService: NotificationService,
    public commonFunctions: CommonFunctionsService,
    private accessMatrixService: AccessMatrixService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,private mixpanelService: MixpanelService
  ) { 
    this.permissions = this.accessMatrixService.getPermissionByRoute();
    this.moduleNameMapping = this.accessMatrixService.moduleNameMapping;
  }

  ngOnInit(): void {
    this.totalModule = Object.keys(AccessMatrix).length;
    if (this.localStorageService.get("user")) {
      this.userData = JSON.parse(
        this.commonFunctions.getDecodedData(
          this.localStorageService.get("user")
        )
      );
    }
  }
  openModal(content) {
    this.modalService.open(content, { centered: true, windowClass: "customModal" });
  }

  editProfileRightPanel() {
    this.router.navigate([
      `/user-management/access-matrix/roles/edit/${this.actionData.id}`,
    ]);
  }
  deleteRole() {
    this.deleteRoleSubscription?.unsubscribe();
    this.deleteRoleSubscription = this.userManagementService.deleteRole(this.actionData.id).subscribe((res: any) => {
      if (res) {
        this.mixpanelService.track('Role deleted',{});
        this.componentDetail.refreshData();
        this.notificationMessageService.setMessage(res['context_code'], res['summary']);
      }
    }, (err: any) => {
      this.notificationMessageService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    }
    );

  }
  getAccessParams(modules:any) {
    this.accessParams = [
      { code: 'F', name: 'Full Access' },
      { code: 'W', name: 'Write Access' },
      { code: 'R', name: 'Read Access' },
      { code: 'E', name: 'Export Access' }
    ];
    this.allModuleRoles = modules;
    const moduleList = this.accessMatrixService.getModulesList();
    this.allModuleRoles = this.allModuleRoles.filter(item =>
      moduleList.some(module => item.module_code === module.module_code)
    );
  }
  getAccessMatrix(module: any): any {
    const rights: any[] = [];
    if (module.full_access) {
      rights.push('F');
    }
    else if (module.write_access) {
      rights.push('R');
      rights.push('W');
    }
    else if (module.read_access || module.export_access) {
      if (module.read_access) rights.push('R');
      if (module.export_access) rights.push('E');
    }
    return rights;
  }
  openAccessModal(content: any) {
    this.getRoleData(this.actionData?.id,content);
  }
  getRoleData(roleId,content:any) {
    this.rolesDataSubscription?.unsubscribe();
    this.rolesDataSubscription = this.userManagementService
      .getRoleInfo(roleId)
      .subscribe((res: any) => {
        if (res)
          this.roleData = res.data;
        this.getAccessParams(res?.data?.modules);
        this.openModal(content);
      }, (err: any) => {
        this.roleData = null;
        this.notificationMessageService.setMessage(
          err?.error?.context_code,
          err?.error?.summary
        );
      });
  }
  getPermissionStatus(permission) {
    return (permission.full_access || permission.write_access );
  }

  ngOnDestroy(): void {
    this.rolesDataSubscription?.unsubscribe();
    this.deleteRoleSubscription?.unsubscribe();
  }


}
