import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { Subject, takeUntil, Subscription} from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { ModuleType } from "@ds-shared/models/common.model";
import { AccessMatrix } from "@ds-shared/enums/access-matrix.enum";
import { UpdateSegmentActionComponent } from "../update-segment-action/update-segment-action.component";
import { ScorecardService } from "@ds-private-layouts/scorecard/scorecard.service";
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';
import { SingleDiscardProduct } from "@ds-shared/models/scorecard.model";
import { SCORECARD_TYPE } from "../../../enums/common.enum";

@Component({
  selector: "app-builds-action",
  templateUrl: "./builds-action.component.html",
  styleUrls: ["./builds-action.component.scss"],
})
export class BuildsActionComponent implements OnInit {
  @Input() public moduleType: ModuleType;
  @Input() public actionData: any;
  @Input() public actionType: string;
  @Input() public col;
  @Output() bulkSelect = new EventEmitter();
  @Output() refreshGrid = new EventEmitter();
  private $destroy: Subject<boolean> = new Subject();
  public profileType: string = "";
  public type: string = "";
  public permissions: RolePermissions = new RolePermissions();
  private destroy$: Subject<boolean> = new Subject();
  permissionsForDiscover: RolePermissions = new RolePermissions();
	public clearLastCall: Subscription;
	public clearbulkDeleteCall: Subscription;
  public clearChangeProfileStatusCall: Subscription
  private productDiscardModalRef: NgbModalRef;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public scorecardService: ScorecardService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    public commonFunctions: CommonFunctionsService,
    private accessMatrixService: AccessMatrixService,
    public dataTransferService: DataTransferService,
    private mixpanelService: MixpanelService
  ) {
    this.permissions = this.accessMatrixService.getPermissionByRoute();
    this.permissionsForDiscover = this.accessMatrixService.getPermissionByModule(AccessMatrix.Discover);
  }

  public ngOnInit(): void {
    this.profileType = SCORECARD_TYPE[this.commonFunctions.getURLSegments()[1]];
  }
  public ngOnChanges(changes: SimpleChanges): void { }

  public editProfileRightPanel(profileId?:number): void {
    if( this.profileType === 'keyword') {
      this.router.navigate([
        `/share-of-search/${this.profileType}/edit-profile/${profileId?profileId:this.actionData.id}`,
      ]);
    }
    else if (this.profileType === 'category') {
      this.router.navigate([
        `/discover/share-of-category/edit-profile/${profileId?profileId:this.actionData.id}`,
      ]);
    }
    else {
      this.router.navigate([
        `/scorecard/${this.profileType}/edit-profile/${profileId?profileId:this.actionData.id}`,
      ]);
    }
  }

  public deleteProfile(): void {
    const payload = {
      'action_on_all': false,
      'profile_type': this.profileType,
      'profiles': [this.actionData.id]
    }
    this.clearbulkDeleteCall?.unsubscribe();
    this.clearbulkDeleteCall = this.scorecardService.bulkDelete('scorecard/profiles-delete', {}, payload).pipe(takeUntil(this.$destroy)).subscribe((res: any) => {
      if (res) {
        this.mixpanelService.track("Tracker Deleted", {});
        this.refreshGrid.emit(true);
        this.dataTransferService.sendfireMenuCount('scorecard');
        this.notificationService.setMessage(
          1200,
          this.commonFunctions.getSpecificTranslation('Product(s) will be removed throughout the system after next data run')
        );
        this.notificationService.setMessage(
          res["context_code"],
          res["summary"]
        );
      }
    }, (err: any) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }

  public openModal(content, view): void {
    if (view === 'deleteView') {
      this.modalService.open(content, {
        centered: true,
        windowClass: "customModal",
      });
    }
    else {
      this.modalService.open(content, {
        centered: true,
        windowClass: "customModal",
      });
    }
  }

  public getFormattedString(text: string): string {
    return (text.substring(0, text.length)).replace(/([a-z])/i, (str, firstLetter) => firstLetter.toUpperCase());
  }

  public goToManageProd(): void {
    const id = this.actionData.type === "profile-list" ? this.actionData.id : this.actionData.attributes.edit_details.profile_id;
    this.router.navigate(['manage-products'], {
      relativeTo: this.route,
      queryParams: {
        profileId: id,
        is_customer: true,
      },
      queryParamsHandling: "merge",
    });
  }

  public deactivateProfile(): void {
    const data = {
      "action_on_all": false,
      "is_active": !this.actionData?.attributes?.is_active,
      "profile_type": this.profileType,
      "profiles": [this.actionData?.id]
    }
    this.clearChangeProfileStatusCall?.unsubscribe();
		this.clearChangeProfileStatusCall = 
      this.scorecardService.changeProfileStatus('scorecard/start-stop-scraping', {}, data).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res) {
        if(this.actionData?.attributes?.is_active)
        this.mixpanelService.track(this.profileType + ' tracker paused',{});
        else
        this.mixpanelService.track(this.profileType + ' tracker resumed',{});
        this.notificationService.setMessage(
          res["context_code"],
          res["summary"]
        );
        this.refreshGrid.emit(true);
      }
    }, (err) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }

  public openNotification(): void {
    const noteObj = {
      marketplace: this.actionData?.attributes?.marketplace,
      name: this.actionData?.attributes?.name,
      id: this.actionData?.id
    }
    this.commonFunctions.redirectToNotification(['product','store'].includes(this.profileType)?this.profileType+'s':'profiles', this.commonFunctions.getEncodedData(JSON.stringify(noteObj)), this.route);
  }

  public addNewProfile(profileType: string) {
    const profileObj = {
      url: this.actionData.attributes?.[profileType==='store' ? 'store_url' : 'url'],
      name: this.actionData.attributes?.name
    }

    this.commonFunctions.redirectToProfile(profileType, this.commonFunctions.getEncodedData(JSON.stringify(profileObj)), this.route);
  }

  openStoreSegmentSelectionModal(){
    const modalRef = this.modalService.open(UpdateSegmentActionComponent, {
      centered: true,
      windowClass: "customModal",
      keyboard: false,
      backdrop: "static"
    });
    // modalRef.componentInstance.profileId = this.col.meta?.type==='storesAll'?null:this.actionData.id;
    modalRef.componentInstance.data = (this.col.meta?.type==='storesAll' || this.col?.meta?.type === 'updateSegmentButton') ? this.actionData.id:this.actionData.attributes.scorecard_id;
    modalRef.componentInstance.activeSegment = this.actionData.attributes.segment || this.actionData.attributes.segmentation_tag

    modalRef.result.then((result) => {
      if(!result) return;
      this.bulkSelect.emit(false);
      this.refreshGrid.emit(true);
    }, (reason) => {
    });
  }
  
  public confirmDiscard(discardProduct){
    this.productDiscardModalRef = this.modalService.open(discardProduct, {
      centered: true,
      windowClass: "customModal",
    });
  }

  public discardFromAllTrackers(){
    const product: SingleDiscardProduct = {
      product_id: this.actionData.id,
      is_discarded: true
    }
    this.scorecardService.discardFromAllTrackers([product]).pipe(takeUntil(this.destroy$)).subscribe((res)=>{
      if(res){
        this.refreshGrid.emit(true);
        this.productDiscardModalRef.close()
        this.notificationService.setMessage(
          res["context_code"],
          res["summary"]
        );
        this.mixpanelService.track(this.profileType + ' product discarded',{});
      }
    }, (err) => {
      this.notificationService.setMessage(
        err.error?.context_code,
        err.error?.summary
      );
    })
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}