import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessMatrixService } from '@ds-shared/common-services/utility-services/access-matrix.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { AccessMatrix } from '@ds-shared/enums/access-matrix.enum';
import { RolePermissions } from '@ds-shared/models/access-matrix.model';

@Component({
  selector: 'app-common-grid-actions',
  templateUrl: './common-grid-actions.component.html',
  styleUrls: ['./common-grid-actions.component.scss']
})
export class CommonGridActionsComponent implements OnInit {

  @Input() item;
  @Input() moduleType;
  @Input() col;
  permissions: RolePermissions = new RolePermissions();
  permissionsForDiscover: RolePermissions = new RolePermissions()
  constructor(public commonFunctions: CommonFunctionsService, private accessMatricService: AccessMatrixService, private route: ActivatedRoute) {
    this.permissions = this.accessMatricService.getPermissionByModule(AccessMatrix.Notifications);
    this.permissionsForDiscover = this.accessMatricService.getPermissionByModule(AccessMatrix.Discover);
  }

  ngOnInit(): void {
  }

  public openNotification(): void {
    const noteObj = {
      marketplace: this.item?.attributes?.marketplace,
      name: this.item?.attributes?.name,
      id: this.item?.id
    }
    this.commonFunctions.redirectToNotification(this.col.meta.type, this.commonFunctions.getEncodedData(JSON.stringify(noteObj)), this.route);
  }


}
