export const enum TargetKPIOperator1 {
	"=" = "=",
	"<=" = "<=",
	">=" = ">=",
	">" = ">",
	"<" = "<"
}

export enum NotificationTypes{
    'products'='Product',
    'stores'='Store',
    'keywords'='Keyword',
    'categories'='Category',
    'brands'='Brand',
  }