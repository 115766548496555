<form
	[formGroup]="brandForm"
	class="noteBrandForm text-align-right-rtl"
	(ngSubmit)="onSubmit()"
	(keydown.enter)="$event.preventDefault()"
>
	<div class="brand-container">
		<!-- <div class="type-container">
            <div class="ds-switch">
                <label class="switch-container d-flex" for="checkType">
                    <input id="checkType" formControlName="select_type" type="checkbox" (change)="onChange($event,'typeChange')" />
                    <div class="toggle-content">
                        <div class="toggle-bubble"></div>
                    </div>
                </label>
                <div class="switch-name" translate>
                    Competitor Notifications
                </div>
            </div>
        </div> -->
		<div class="select-container">
			<label translate>Channel <sup>*</sup></label>
			<ng-select
				class="select-text-wrap no-clear-all"
				[notFoundText]="'No Data Found' | translate"
				[loadingText]="'Loading...' | translate"
				[clearable]="false"
				placeholder="{{'Select Channel' | translate}}"
				[items]="marketplaceList"
				formControlName="select_marketplace"
				bindLabel="attributes.name"
				(scrollToEnd)="getMarketplaceList()"
				[ngClass]="{error:brandForm.controls['select_marketplace'].invalid && (brandForm.controls['select_marketplace'].dirty || brandForm.controls['select_marketplace'].touched || submitted)}"
				(change)="onChange($event,'marketplace')"
				[loading]="loadedMarketplaceList"
			>
			</ng-select>
			<div
				class="errorLable"
				*ngIf="brandForm.controls['select_marketplace'].invalid && (brandForm.controls['select_marketplace'].dirty || brandForm.controls['select_marketplace'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span
					*ngIf="brandForm.controls['select_marketplace'].errors.required"
					translate
					>Please select Channel</span
				>
			</div>
		</div>
		<div class="select-container">
			<label translate>Brand <sup>*</sup></label>
			<ng-select
				class="select-text-wrap no-clear-all"
				[notFoundText]="'No Data Found' | translate"
				[loadingText]="'Loading...' | translate"
				[clearable]="false"
				placeholder="{{'Select Brand' | translate}}"
				[items]="brandList"
				formControlName="select_brand"
				bindLabel="attributes.name"
				(scrollToEnd)="getBrandList(brandForm.controls['select_marketplace']?.value?.id)"
				[ngClass]="{error:brandForm.controls['select_brand'].invalid && (brandForm.controls['select_brand'].dirty || brandForm.controls['select_brand'].touched || submitted)}"
				(change)="onChange($event,'brand')"
				[loading]="loadedBrandList"
			>
			</ng-select>
			<div
				class="errorLable"
				*ngIf="brandForm.controls['select_brand'].invalid && (brandForm.controls['select_brand'].dirty || brandForm.controls['select_brand'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span
					*ngIf="brandForm.controls['select_brand'].errors.required"
					translate
					>Please select brand</span
				>
			</div>
		</div>
		<div class="select-container">
			<label translate>Target KPI <sup>*</sup></label>
			<ng-select
				class="select-text-wrap no-clear-all"
				[notFoundText]="'No Data Found' | translate"
				[loadingText]="'Loading...' | translate"
				[clearable]="false"
				placeholder="{{'Select Target KPI' | translate}}"
				[items]="metricList"
				formControlName="select_target_kpi"
				bindLabel="name"
				[ngClass]="{error:brandForm.controls['select_target_kpi'].invalid && (brandForm.controls['select_target_kpi'].dirty || brandForm.controls['select_target_kpi'].touched || submitted)}"
				(change)="onChange($event,'metric')"
				[loading]="loadedkpiList"
			>
				<ng-template ng-label-tmp let-item="item">
					<span translate>{{item.name}}</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index">
					<span>{{item.name | translate}}</span>
				</ng-template>
			</ng-select>
			<div
				class="errorLable"
				*ngIf="brandForm.controls['select_target_kpi'].invalid && (brandForm.controls['select_target_kpi'].dirty || brandForm.controls['select_target_kpi'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span
					*ngIf="brandForm.controls['select_target_kpi'].errors.required"
					translate
					>Please select KPI</span
				>
			</div>
		</div>
		<div>
			<div class="input-container">
				<label for="disabled" translate>Current KPI Value <sup>*</sup></label>
				<input
					type="text"
					name=""
					id="disabled"
					formControlName="current_kpi"
					placeholder="{{'Current KPI Value' | translate}}"
				/>
			</div>
			<div class="input-container range-box">
				<label for="compBrandName" translate
					>Target KPI Value <sup>*</sup></label
				>
				<div class="d-flex">
					<!-- operator1 -->
					<ng-select
						formControlName="target_kpi_operator_1"
						class="select-text-wrap border-right-none"
						[notFoundText]="'No Data Found' | translate"
						[loadingText]="'Loading...' | translate"
						[items]="targetKPIOperator1Data.list"
						placeholder="{{'Range' | translate}}"
						bindLabel="name"
						[loading]="targetKPIOperator1Data.isLoading"
						[closeOnSelect]="true"
						(change)="onFirstOperatorChange($event)"
						[clearable]="false"
					>
					</ng-select>
					<!-- operator1 ends-->
					<input
						type="number"
						id="compBrandName"
						placeholder="{{'Enter Value' | translate}}"
						min="0"
						autocomplete="off"
						formControlName="note_brand_kpi_value"
						(input)="onKpiChange($event)"
						class="border-both-radius-none"
						[ngClass]="{error:brandForm.controls['note_brand_kpi_value'].invalid && (brandForm.controls['note_brand_kpi_value'].dirty || brandForm.controls['note_brand_kpi_value'].touched || submitted)}"
					/>
					<!-- and -->
					<!-- operator2 -->
					<ng-select
						formControlName="target_kpi_operator_2"
						class="select-text-wrap border-both-none"
						[notFoundText]="'No Data Found' | translate"
						[loadingText]="'Loading...' | translate"
						[items]="targetKPIOperator2Data.list"
						placeholder="{{'Range' | translate}}"
						bindLabel="name"
						[loading]="targetKPIOperator2Data.isLoading"
						[closeOnSelect]="true"
						[clearable]="true"
						(change)="onSecondOperatorChange($event)"
					>
					</ng-select>
					<!-- kpi2 -->
					<input
						type="number"
						id="compBrandName2"
						placeholder="{{'Enter Value' | translate}}"
						min="0"
						autocomplete="off"
						formControlName="note_brand_kpi_value_2"
						(input)="onNoteBrandKpiValueChange2($event)"
						class="border-left-radius-none"
						[ngClass]="{error:((brandForm.controls['note_brand_kpi_value_2'].dirty || brandForm.controls['note_brand_kpi_value_2'].touched || submitted) && ((!brandForm.controls['note_brand_kpi_value'].invalid && brandForm.controls['target_kpi_operator_2'].value && brandForm.controls['note_brand_kpi_value_2'].value===null) || rangeError))}"
					/>
				</div>
				<div
					class="errorLable"
					*ngIf="brandForm.controls['note_brand_kpi_value'].invalid && (brandForm.controls['note_brand_kpi_value'].dirty || brandForm.controls['note_brand_kpi_value'].touched || submitted)"
				>
					<i class="icon icon-Error"></i>
					<span
						*ngIf="brandForm.controls['note_brand_kpi_value'].errors.required"
						translate
						>Please enter KPI value</span
					>
					<span
						*ngIf="!brandForm.controls['note_brand_kpi_value'].errors.required && brandForm.controls['note_brand_kpi_value'].errors.invalid"
						translate
						>Please enter valid number</span
					>
				</div>
				<div
					class="errorLable"
					*ngIf="brandForm.controls['note_brand_kpi_value_2'].invalid && (brandForm.controls['note_brand_kpi_value_2'].dirty || submitted)"
				>
					<i class="icon icon-Error"></i>
					<span
						*ngIf="brandForm.controls['note_brand_kpi_value_2'].errors.invalid"
						translate
						>Please enter valid number</span
					>
				</div>
				<div
					class="errorLable"
					*ngIf="!brandForm.controls['note_brand_kpi_value']?.errors?.required && (brandForm.controls['note_brand_kpi_value_2'].dirty && rangeError)"
				>
					<i class="icon icon-Error"></i>
					<span translate
						>Please enter number greater than target kpi value</span
					>
				</div>
				<div
					class="errorLable"
					*ngIf="(brandForm.controls['note_brand_kpi_value_2'].dirty || brandForm.controls['note_brand_kpi_value_2'].touched || submitted) && !brandForm.controls['note_brand_kpi_value'].invalid && brandForm.controls['target_kpi_operator_2'].value && brandForm.controls['note_brand_kpi_value_2'].value===null"
				>
					<i class="icon icon-Error"></i>
					<span translate>Please enter second target</span>
				</div>
			</div>
			<div class="toggle_title" translate>Frequency <sup>*</sup></div>
			<div class="radio-container">
				<label class="radio" for="repeat">
					<input
						type="radio"
						id="repeat"
						value="Repeat"
						formControlName="frequency"
					/>
					<div class="circle"></div>
					<span translate>Repeat</span>
				</label>
				<label class="radio" for="once">
					<input
						type="radio"
						id="once"
						value="Once"
						formControlName="frequency"
					/>
					<div class="circle"></div>
					<span translate>Once</span>
				</label>
			</div>
		</div>
		<div class="brand-footer">
			<button
				class="btn btn-secondary btn-lg"
				type="button"
				(click)="onCloseAction()"
				translate
			>
				Cancel
			</button>
			<button
				class="btn btn-primary btn-lg"
				type="submit"
				[disabled]="!permissions.write_access || updateButtonState()"
				translate
			>
				{{actionType=='edit'?'Update Notification':'Create Notification'}}
			</button>
		</div>
	</div>
</form>
