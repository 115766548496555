<div class="calendar-heatmap-container">
    <div class="heatmap">
        <div class="date-manager">
            <button (click)="changeMonth('prev')" [disabled]="activeMonth.isSame(dateBefore90Days, 'month') && activeMonth.isSame(dateBefore90Days, 'year')" class="ms-1">
                <i class="icon icon-Caret-left"></i>
            </button>
            <span class="date">{{activeMonth.format('MMMM') | translate}} {{activeMonth.year()}}</span>
            <button (click)="changeMonth('next')" [disabled]="activeMonth.isSame(currentDate, 'month') && activeMonth.isSame(currentDate, 'year')" class="me-1">
                <i class="icon icon-Caret-right"></i>
            </button>
        </div>
        <table *ngIf="calendarMapData?.length">
            <tr>
                <th translate>Sun</th>
                <th translate>Mon</th>
                <th translate>Tue</th>
                <th translate>Wed</th>
                <th translate>Thu</th>
                <th translate>Fri</th>
                <th translate>Sat</th>
            </tr>
            <tr *ngFor="let item of createRange(calendarSize); let i = index">
                <td *ngFor="let item of createRange(7); let j = index" (click)="onDateSelect(i*7+j)"
                    [ngClass]="{'hide':finalArray[i*7+j]?.styleClass=='hide','disabled':finalArray[i*7+j]?.styleClass=='disabled','low':finalArray[i*7+j]?.styleClass=='low','medium':finalArray[i*7+j]?.styleClass=='medium','high':finalArray[i*7+j]?.styleClass=='high','active':finalArray[i*7+j]?.date==dateSelected}">
                    {{finalArray[i*7+j]?.value}}</td>
            </tr>
        </table>
        <div *ngIf='!calendarMapData?.length' class="no-data-common">
            <div *ngIf="!isLoading" class="no-data-found" translate>
                <div class="no-data-text" translate>No data present</div>
            </div>
            <div *ngIf="isLoading" class="loading-panel">
                <img ngSrc="/assets/images/spinner.svg" alt=""  height="200" width="200"/>
            </div>
        </div>
    </div>
</div>