import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, Subject, takeUntil } from "rxjs";
import { ProfileInformationService } from "@ds-private-layouts/scorecard/profile-information/profile-information.service";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { ScorecardService } from "@ds-private-layouts/scorecard/scorecard.service";
import { CurrencyTemplate } from "@ds-shared/models/common.model";

@Component({
	selector: "app-catalog-header",
	templateUrl: "./catalog-header.component.html",
	styleUrls: ["./catalog-header.component.scss"]
})
export class CatalogHeaderComponent implements OnInit, OnDestroy {
	private _scorecardInfo: any;
	get scorecardInfo(): any {
		return this._scorecardInfo;
	}
	@Input() set scorecardInfo(value: any) {
		this._scorecardInfo = value;
		if (this.scorecardInfo) {
			if (this.scorecardInfo.view === "harmonised")
				this.getHarmonisedBannerData();
			else this.getBannerData();
		}
	}
	private _currencyDetails: CurrencyTemplate;
	get currencyDetails(): any {
		return this._currencyDetails;
	}
	@Input() set currencyDetails(value: CurrencyTemplate) {
		this._currencyDetails = value;
	}
	public permissionsForNotification: RolePermissions = new RolePermissions();
	public permissionsForBuild: RolePermissions = new RolePermissions();
	public bannerData = {
		data: null,
		loading: false
	};
	private $destroy: Subject<boolean> = new Subject();
	public permissionsForDiscover: RolePermissions = new RolePermissions();
	constructor(
		public router: Router,
		private profileInformationService: ProfileInformationService,
		public commonFunctions: CommonFunctionsService,
		private route: ActivatedRoute,
		private scorecardService: ScorecardService,
		private accessMatrixService: AccessMatrixService,
		private dataTransferService: DataTransferService
	) {
		this.permissionsForNotification =
			this.accessMatrixService.getPermissionByModule(
				AccessMatrix.Notifications
			);
		this.permissionsForBuild = this.accessMatrixService.getPermissionByModule(
			AccessMatrix.Scorecard
		);
		this.permissionsForDiscover =
			this.accessMatrixService.getPermissionByModule(AccessMatrix.Discover);
	}

	ngOnInit(): void {}

	getBannerData() {
		this.bannerData.data = null;
		this.bannerData.loading = true;
		this.profileInformationService
			.getCustomerProfileBanner(this.scorecardInfo.id)
			.pipe(
				finalize(() => {
					this.bannerData.loading = false;
				}),
				takeUntil(this.$destroy)
			)
			.subscribe((res: any) => {
				if (res) {
					this.bannerData.data = res.data;
					this.dataTransferService.sendScorecardBreadcrumb(
						this.bannerData.data?.name
					);
				}
			});
	}

	private getHarmonisedBannerData() {
		this.bannerData.data = null;
		this.bannerData.loading = true;
		this.scorecardService
			.getHarmonisedBannerData(this.scorecardInfo.type, this.scorecardInfo.id)
			.pipe(
				finalize(() => {
					this.bannerData.loading = false;
				})
			)
			.subscribe((res: any) => {
				if (res) {
					this.bannerData.data = res.data;
					this.dataTransferService.sendScorecardBreadcrumb(res.data?.name);
				}
			});
	}

	goToNotificationsProfile() {
		const noteObj = {
			marketplace: this.bannerData.data?.marketplace,
			name: this.bannerData.data?.name,
			id: this.bannerData.data?.id
		};
		this.commonFunctions.redirectToNotification(
			"profiles",
			this.commonFunctions.getEncodedData(JSON.stringify(noteObj)),
			this.route
		);
	}

	goToManageProducts() {
		let id = this.scorecardInfo.id;
		this.router.navigate([`manage-products`], {
			relativeTo: this.route,
			queryParams: {
				profileId: id,
				is_customer: true
			},
			queryParamsHandling: "merge"
		});
	}

	ngOnDestroy() {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}

	getEncodedData(data) {
		return this.commonFunctions.getEncodedData(data);
	}
}
