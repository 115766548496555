<div
	ngbDropdown
	class="d-inline-block position-static"
	display="dynamic"
	#manageDrop="ngbDropdown"
	[autoClose]="false"
>
	<span id="dropdownBasic12" ngbDropdownToggle class="cursor-pointer">
		<div
			class="advanced-filter cursor-pointer d-flex align-items-center justify-content-center position-relative"
			(click)="openFilter()"
		>
			<i
				class="icon icon-Filter"
				placement="bottom"
				ngbTooltip="{{'Filter' | translate}}"
				tooltipClass="info-custom-tooltip"
			></i>
			<div class="filter-active" *ngIf="isOptionalActive"></div>
		</div>
	</span>
	<div
		ngbDropdownMenu
		aria-labelledby="dropdownBasic12"
		class="manage-product-filter"
	>
		<h2>Filter Data</h2>
		<form
			[formGroup]="filterForm"
			class="filterForm text-align-right-rtl"
			(ngSubmit)="[manageDrop.close(), onSubmit()]"
			(keydown.enter)="$event.preventDefault()"
		>
			<ng-container *ngFor="let filter of filters">
				<div
					class="select-container mb-1"
					*ngIf="optionalFilterParams?.filterRequired[filter]"
				>
					<label translate>{{filterList[filter].placeholder}}</label>
					<ng-container *ngIf="!filterList[filter].multiple; else multiple">
						<ng-select
							class="select-text-wrap"
							[notFoundText]="'No Data Found' | translate"
							[loadingText]="'Loading...' | translate"
							[items]="filterList[filter].list"
							placeholder="{{('Select ' + filterList[filter].placeholder) | translate}}"
							bindLabel="attributes.name"
							formControlName="{{filter}}"
							[loading]="filterList[filter].loading"
							[closeOnSelect]="filterList[filter].closeOnSelect"
							[clearable]="filterList[filter].clear"
							[multiple]="filterList[filter].multiple"
						>
						</ng-select>
					</ng-container>
					<ng-template #multiple>
						<ng-select
							[items]="filterList[filter].list"
							[notFoundText]="'No Data Found' | translate"
							[loadingText]="'Loading...' | translate"
							[multiple]="true"
							(close)="closeMultiDropdown(filter)"
							[closeOnSelect]="filterList[filter].closeOnSelect"
							[clearable]="filterList[filter].clear"
							bindLabel="attributes.name"
							placeholder="{{('Select ' + filterList[filter].placeholder) | translate}}"
							[loading]="filterList[filter].loading"
							formControlName="{{filter}}"
							(search)="onMultiSearch($event,filter)"
						>
							<ng-template ng-multi-label-tmp
								><span class="placeholder-temp"
									>{{filterList[filter].searchTerm?.length ? '' :
									((filterList[filter].placeholder | translate) + " (" +
									filterForm.get(filter).value?.length + ")")}}</span
								></ng-template
							>
							<ng-template ng-option-tmp let-item="item" let-index="index">
								<div
									class="multi-select-option-container multi-text-wrap gap-16"
								>
									<!-- <app-marketplace-store-logo [storeName]="item.attributes.marketplace"></app-marketplace-store-logo> -->
									<span class="item-name">{{item.attributes.name}}</span>
									<!-- *ngIf="filterForm.get(filter).value.includes(item)" -->
									<i
										class="icon icon-Check"
										*ngIf="checkSelectedOption(filterForm.get(filter).value,item)"
									></i>
								</div>
							</ng-template>
						</ng-select>
					</ng-template>
				</div>
			</ng-container>
			<div
				class="filter-footer d-flex align-items-center justify-content-between mt-2"
			>
				<div
					class="clear-filter d-flex align-items-center gap-8 cursor-pointer"
					(click)="clearFilter()"
				>
					<i class="icon icon-Refresh"></i>
					<span translate>Clear All Filters</span>
				</div>
				<div class="d-flex align-items-center gap-16">
					<button
						class="btn btn-secondary btn-lg"
						type="button"
						(click)="[manageDrop.close(), isOpen=!isOpen]"
						translate
					>
						Cancel
					</button>
					<button class="btn btn-primary btn-lg" type="submit" translate>
						Apply Filter
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
