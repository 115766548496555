import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { ProfileInformationService } from "@ds-private-layouts/scorecard/profile-information/profile-information.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { Location } from "@angular/common";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";

@Component({
	selector: "app-manage-mapping",
	templateUrl: "./manage-mapping.component.html",
	styleUrls: ["./manage-mapping.component.scss"]
})
export class ManageMappingComponent implements OnInit {
	public productId: number;
	public addProductForm: UntypedFormGroup;
	public addKeywordForm: UntypedFormGroup;
	public marketplaceProductInfoSubscription: Subscription;
	public marketplaceProductInfo: any;
	public marketplaceProductDirectMappingSubscription: Subscription;
	public marketplaceProductDirectMapping = [];
	public marketplaceProductMappedKeywordSubscription: Subscription;
	public marketplaceProductMappedKeyword = [];
	public isMappedProductLoading: boolean = false;
	public isMappedKeywordLoading: boolean = false;
	public storeListSubscription: Subscription;
	public storeList: any[] = [];
	public initialStoreList = [];
	public storePageNumber: number = 1;
	public initialStorePageNumber = 1;

	public productListSubscription: Subscription;
	public productList: any[] = [];
	public initialProductList = [];
	public productPageNumber: number = 1;
	public initialProductPageNumber = 1;

	public keywordListSubscription: Subscription;
	public keywordList: any[] = [];
	public initialKeywordList = [];
	public keywordPageNumber: number = 1;
	public initialKeywordPageNumber = 1;

	public addProductSubscription: Subscription;
	public addKeywordSubscription: Subscription;
	public productInfoPresent: boolean = false;
	public permissions: RolePermissions = new RolePermissions();

	private storeSearchTerm: string = "";
	private productSearchTerm: string = "";

	public isStoreLoading: boolean = false;
	public isProductLoading: boolean = false;

	public tagsSubject$ = new Subject<{}>();
	public $destroy: Subject<boolean> = new Subject();
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private profileInformationService: ProfileInformationService,
		private notificationService: NotificationService,
		private accessMatrixService: AccessMatrixService,
		public commonFunctions: CommonFunctionsService
	) {
		this.permissions = this.accessMatrixService.getPermissionByModule(
			AccessMatrix.Scorecard
		);
	}

	ngOnInit(): void {
		this.addProductForm = new UntypedFormGroup({
			select_store: new UntypedFormControl(null, Validators.required),
			select_product: new UntypedFormControl(null, Validators.required)
		});
		this.addKeywordForm = new UntypedFormGroup({
			select_keyword: new UntypedFormControl(null, Validators.required)
		});
		this.route.queryParams.subscribe((params: any) => {
			this.productId = parseInt(params?.["customerProductId"]);
			this.getMarketplaceProductInfo();
			this.getMarketplaceProductDirectMapping();
			this.getMarketplaceProductMappedKeyword();
		});
		this.searchInit();
	}

	searchInit() {
		this.tagsSubject$
			.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.$destroy))
			.subscribe(
				(res: any) => {
					const { event, searchType } = res;
					this.onSearchFire(event, searchType);
				},
				(err) => {}
			);
	}

	getMarketplaceProductInfo() {
		this.marketplaceProductInfo = null;
		this.productInfoPresent = false;
		this.marketplaceProductInfoSubscription?.unsubscribe();
		this.marketplaceProductInfoSubscription = this.profileInformationService
			.getMarketplaceProductInfo(this.productId)
			.subscribe(
				(res: any) => {
					if (res) {
						this.marketplaceProductInfo = [res?.data];
						this.productInfoPresent = true;
					}
				},
				(err: any) => {
					this.marketplaceProductInfo = null;
					this.productInfoPresent = true;
					this.notificationService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);
	}

	getMarketplaceProductDirectMapping() {
		this.marketplaceProductDirectMappingSubscription?.unsubscribe();
		this.isMappedProductLoading = true;
		this.marketplaceProductDirectMappingSubscription =
			this.profileInformationService
				.getMarketplaceProductDirectMapping(this.productId)
				.subscribe(
					(res: any) => {
						if (res) this.marketplaceProductDirectMapping = res?.data;
						this.isMappedProductLoading = false;
					},
					(err: any) => {
						this.marketplaceProductDirectMapping = [];
						this.isMappedProductLoading = false;
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
					}
				);
	}

	getMarketplaceProductMappedKeyword() {
		this.marketplaceProductMappedKeywordSubscription?.unsubscribe();
		this.isMappedKeywordLoading = true;
		this.marketplaceProductMappedKeywordSubscription =
			this.profileInformationService
				.getMarketplaceProductMappedKeyword(this.productId)
				.subscribe(
					(res: any) => {
						if (res) this.marketplaceProductMappedKeyword = res?.data;
						this.isMappedKeywordLoading = false;
					},
					(err: any) => {
						this.marketplaceProductMappedKeyword = [];
						this.isMappedKeywordLoading = false;
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
					}
				);
	}

	onProductSubmit() {
		if (this.addProductForm.status === "VALID") {
			const data = [
				{
					customer_marketplace_product_id: this.productId,
					competitor_marketplace_product_id:
						this.addProductForm.controls["select_product"]?.value?.id
				}
			];
			this.addProductSubscription?.unsubscribe();
			this.addProductSubscription = this.profileInformationService
				.createDirectMatching(data)
				.subscribe(
					(res: any) => {
						if (res) {
							this.getMarketplaceProductDirectMapping();
							this.notificationService.setMessage(
								res["context_code"],
								res["summary"]
							);
						}
					},
					(err: any) => {
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
					}
				);
		}
	}
	onKeywordSubmit() {
		if (this.addKeywordForm.status === "VALID") {
			const data = [
				{
					customer_marketplace_product_id: this.productId,
					customer_profile_keyword_id:
						this.addKeywordForm.controls["select_keyword"]?.value?.id
				}
			];
			this.addKeywordSubscription?.unsubscribe();
			this.addKeywordSubscription = this.profileInformationService
				.createKeywordMapping(data)
				.subscribe(
					(res: any) => {
						if (res) {
							this.getMarketplaceProductMappedKeyword();
							this.notificationService.setMessage(
								res["context_code"],
								res["summary"]
							);
						}
					},
					(err: any) => {
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
					}
				);
		}
	}

	getStoreList() {
		if (this.storePageNumber) {
			this.isStoreLoading = true;
			this.storeListSubscription?.unsubscribe();
			this.storeListSubscription = this.profileInformationService
				.getStoreList(
					this.marketplaceProductInfo?.[0]?.marketplace_id,
					this.storePageNumber,
					this.storeSearchTerm,
					this.commonFunctions.currencySelected?.id
				)
				.subscribe(
					(res: any) => {
						if (res) {
							this.storeList = [...this.storeList, ...res["data"]];
							this.storePageNumber = res?.meta?.next_page;
							this.isStoreLoading = false;
							if (
								res?.meta?.current_page === 1 &&
								!this.initialStoreList.length
							) {
								this.initialStoreList = JSON.parse(
									JSON.stringify(this.storeList)
								);
								this.initialStorePageNumber = this.storePageNumber;
							}
						}
					},
					(err: any) => {
						this.storeList = [];
						this.initialStoreList = [];
						this.storePageNumber = 1;
						this.initialStorePageNumber = 1;
						this.isStoreLoading = false;
					}
				);
		}
	}
	getProductList() {
		if (this.productPageNumber) {
			this.isProductLoading = true;
			this.productListSubscription?.unsubscribe();
			this.productListSubscription = this.profileInformationService
				.getProductList(
					this.productPageNumber,
					this.productSearchTerm,
					this.commonFunctions.currencySelected?.id,
					null
				)
				.subscribe(
					(res: any) => {
						if (res) {
							this.productList = [...this.productList, ...res["data"]];
							this.productPageNumber = res?.meta?.next_page;
							this.isProductLoading = false;
							if (
								res?.meta?.current_page === 1 &&
								!this.initialProductList.length
							) {
								this.initialProductList = JSON.parse(
									JSON.stringify(this.productList)
								);
								this.initialProductPageNumber = this.productPageNumber;
							}
						}
					},
					(err: any) => {
						this.productList = [];
						this.initialProductList = [];
						this.productPageNumber = 1;
						this.initialProductPageNumber = 1;
						this.isProductLoading = false;
					}
				);
		}
	}
	getKeywordList() {
		if (this.keywordPageNumber) {
			this.keywordListSubscription?.unsubscribe();
			this.keywordListSubscription = this.profileInformationService
				.getKeywordList(
					this.marketplaceProductInfo?.[0]?.marketplace_id,
					this.storePageNumber
				)
				.subscribe(
					(res: any) => {
						if (res) {
							this.keywordList = [...this.keywordList, ...res["data"]];
							this.keywordPageNumber = res?.meta?.next_page;
							if (
								res?.meta?.current_page === 1 &&
								!this.initialKeywordList.length
							) {
								this.initialKeywordList = JSON.parse(
									JSON.stringify(this.keywordList)
								);
								this.initialKeywordPageNumber = this.keywordPageNumber;
							}
						}
					},
					(err: any) => {
						this.keywordList = [];
						this.initialKeywordList = [];
						this.keywordPageNumber = 1;
						this.initialKeywordPageNumber = 1;
					}
				);
		}
	}

	onDropdownOpen(addType: string) {
		this.addProductForm.reset();
		this.addKeywordForm.reset();
		this.storeList = [];
		this.initialStoreList = [];
		this.storePageNumber = 1;
		this.initialStorePageNumber = 1;
		this.productList = [];
		this.initialProductList = [];
		this.productPageNumber = 1;
		this.initialProductPageNumber = 1;
		this.keywordList = [];
		this.initialKeywordList = [];
		this.keywordPageNumber = 1;
		this.initialKeywordPageNumber = 1;
		if (addType === "product") this.getStoreList();
		else if (addType === "keyword") this.getKeywordList();
	}
	onChange(event: any, select_type) {
		if (select_type === "store") {
			this.productList = [];
			this.initialProductList = [];
			this.productPageNumber = 1;
			this.getProductList();
		}
	}
	onClose() {
		this.router.navigate([".."], { relativeTo: this.route });
	}
	onRefreshAction(event: any) {
		if (event === "competitor") this.getMarketplaceProductDirectMapping();
		else if (event === "keyword") this.getMarketplaceProductMappedKeyword();
	}

	searchData(event: any, searchType: any) {
		this.tagsSubject$.next({ event, searchType });
	}

	onSearchFire(event: any, searchType: any) {
		if (searchType == "store") {
			this.storeSearchTerm = event?.term;
			if (event?.term.length >= 3 || event?.term.length == 0) {
				this.storePageNumber = 1;
				this.storeList = [];
				this.getStoreList();
			}
		} else if (searchType == "product") {
			this.productSearchTerm = event?.term;
			if (event?.term.length >= 3 || event?.term.length == 0) {
				this.productPageNumber = 1;
				this.productList = [];
				this.getProductList();
			}
		}
	}

	onStoreDropdownClose() {
		this.storeList = this.initialStoreList;
		this.storePageNumber = this.initialStorePageNumber;
		this.storeSearchTerm = "";
	}

	onProductDropdownClose() {
		this.productList = this.initialProductList;
		this.productPageNumber = this.initialProductPageNumber;
		this.productSearchTerm = "";
	}

	onKeywordDropdownClose() {
		this.keywordList = this.initialKeywordList;
		this.keywordPageNumber = this.initialKeywordPageNumber;
	}

	ngOnDestroy() {
		this.marketplaceProductInfoSubscription?.unsubscribe();
		this.marketplaceProductDirectMappingSubscription?.unsubscribe();
		this.marketplaceProductMappedKeywordSubscription?.unsubscribe();
		this.storeListSubscription?.unsubscribe();
		this.productListSubscription?.unsubscribe();
		this.keywordListSubscription?.unsubscribe();
		this.addProductSubscription?.unsubscribe();
		this.addKeywordSubscription?.unsubscribe();
	}
}
