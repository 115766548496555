import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-common-services/http-services/http-request.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { PAYLOAD } from '@ds-shared/enums/common.enum';
import { Service, Resource } from 'ngx-jsonapi';
import { forkJoin, Observable } from 'rxjs';

// Common Resource
export class ProfileScorecard extends Resource {
  public attributes = {
    type: '',
    id: '',
  };
}

@Injectable({
  providedIn: 'root'
})
export class CustomerScrappedProducts extends Service<ProfileScorecard>{
  public resource = ProfileScorecard;
  public type = 'scrapped-products';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class CompetitorCatalogueService extends Service<ProfileScorecard>{
  public resource = ProfileScorecard;
  public type = 'products';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class CatalogueProductExtra extends Service<ProfileScorecard>{
  public resource = ProfileScorecard;
  public type = 'catalogue-product';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class ProductCatalogueService extends Service<ProfileScorecard>{
  public resource = ProfileScorecard;
  public type = 'products';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class ProductCatalogueExtraService extends Service<ProfileScorecard>{
  public resource = ProfileScorecard;
  public type = 'store-overview-products';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class CompetitorWatchListService extends Service<ProfileScorecard>{
  public resource = ProfileScorecard;
  public type = 'competition-watchlist';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerSegmentationService extends Service<ProfileScorecard> {
  public resource = ProfileScorecard;
  public type = '';
  public ttl = 1;

  public setType(val: any) {
    this.type = val;
  }
}

// Common Ends

@Injectable({
	providedIn: "root"
})
export class ProfileInformationService extends Service<ProfileScorecard> {
	constructor(public http: HttpRequestService, private commonFunctions: CommonFunctionsService) {
		super();
	}
	getCategoryPerformanceData(storeId, metric, marketplaceId) {
		return this.http.callGetApi(
			"dashboard/category-performance?store_id=" +
				storeId +
				"&metric=" +
				metric +
				"&marketplace_id=" +
				marketplaceId
		);
	}
	getMarketPlaceList(countryId) {
		return this.http.callGetApi(
			"brand-marketplaces?status[active]=true&filter[country]=" + countryId
		);
	}
	getActiveProducts(
		profile_id,
		is_customer,
		searchTerm,
		sort,
		page_size,
		page_no,
		payload
	) {
		let data = payload ? `&${this.commonFunctions.getPayloadFromObject(payload)}` : '';
		data = data[data.length - 1] === '&' ? data.substring(0, data.length - 1) : data
		if (sort && searchTerm) {
			return this.http.callGetApi(
				"scorecard/active-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&sort=" +
					sort +
					"&search=" +
					searchTerm +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		} else if (sort) {
			return this.http.callGetApi(
				"scorecard/active-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&sort=" +
					sort +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		} else if (searchTerm) {
			return this.http.callGetApi(
				"scorecard/active-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&search=" +
					searchTerm +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		} else {
			+"&page[size]=" + page_size + "&page[number]=" + page_no;
			return this.http.callGetApi(
				"scorecard/active-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		}
	}
	getDiscardedProducts(
		profile_id,
		is_customer,
		searchTerm,
		sort,
		page_size,
		page_no,
		payload
	) {
		let data = payload ? `&${this.commonFunctions.getPayloadFromObject(payload)}` : '';
		data = data[data.length - 1] === '&' ? data.substring(0, data.length - 1) : data
		if (sort && searchTerm) {
			return this.http.callGetApi(
				"scorecard/discarded-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&sort=" +
					sort +
					"&search=" +
					searchTerm +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		} else if (sort) {
			return this.http.callGetApi(
				"scorecard/discarded-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&sort=" +
					sort +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		} else if (searchTerm) {
			return this.http.callGetApi(
				"scorecard/discarded-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&search=" +
					searchTerm +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		} else {
			return this.http.callGetApi(
				"scorecard/discarded-products?id=" +
					profile_id +
					"&is_customer=" +
					is_customer +
					"&page[size]=" +
					page_size +
					"&page[number]=" +
					page_no +
					data
			);
		}
	}
	getCustomerProfileBanner(id?: number) {
		return this.http.callGetApi("dashboard/customer/banner?id=" + id);
	}
	getCompetitorProfileBanner(id?: number) {
		return this.http.callGetApi("dashboard/competitor/banner?id=" + id);
	}
	postActiveToAndFroDiscardProducts(queryParams, search, body) {
		queryParams = queryParams || {};
		if (search?.length) queryParams["search"] = search;
		let data = queryParams ? `?${this.commonFunctions.getPayloadFromObject(queryParams)}` : '';
		data = data[data.length - 1] === '&' ? data.substring(0, data.length - 1) : data
		return this.http.callPostApi(
			`scorecard/active-to-and-fro-discard-products${data}`,
			body
		);
	}
	getProfiles(pageSize, pageNumber, searchTerm) {
		return this.http.callGetApi(
			`dashboard/select-profiles?page[size]=${pageSize}&page[number]=${pageNumber}${
				searchTerm ? `&filter[search]=${searchTerm}` : ""
			}`
		);
	}
	getCustomerSegmentationData(profile_id) {
		return this.http.callGetApi(
			"dashboard/customer/segmentation?profile_id=" + profile_id
		);
	}
	getCalendarMapData(scorecard_id, metric, month, year, type) {
		let path = "";
		let id_key = "";
		if (type === "store" || type === "product") {
			path = `scorecard/store-calendar-heatmap`;
			id_key = `scorecard_id`;
		} else {
			path = `dashboard/customer/calendar-heatmap`;
			id_key = `profile_id`;
		}
		return this.http.callGetApi(
			`${path}?${id_key}=${scorecard_id}&metric=${metric}&month=${month}&year=${year}&scorecard_type=${type}`
		);
	}
	getCompetitorSegmentationData(profile_id) {
		return this.http.callGetApi(
			"dashboard/competitor/segmentation?profile_id=" + profile_id
		);
	}
	getCustomerScrappedProdCount(
		id?: number,
		categoryList?: any,
		storeList?: any,
		brandList?: any,
		rating?: any
	) {
		const filterApplied: any = `${categoryList}${storeList}${brandList}${
			rating ? `&filter[min_rating]=${rating}` : ""
		}`;
		return this.http.callGetApi(
			`scorecard/scrapped-products/count?profile_id=${id}`
		);
	}
	getCompetitorScrappedProdCount(
		id?: number,
		categoryList?: any,
		storeList?: any,
		brandList?: any,
		rating?: any
	) {
		const filterApplied: any = `${categoryList}${storeList}${brandList}${
			rating ? `&filter[min_rating]=${rating}` : ""
		}`;
		return this.http.callGetApi(
			`dashboard/competitor/scrapped-products/count?profile_id=${id}${filterApplied}`
		);
	}
	getAreaChartData(scorecard_id, metric, latest_date, type) {
		let path = "";
		let id_key = "";
		if (type === "store" || type === "product") {
			path = `scorecard/store-area-graph`;
			id_key = `scorecard_id`;
		} else {
			path = `dashboard/customer/area-graph`;
			id_key = `id`;
		}
		return this.http.callGetApi(
			`${path}?${id_key}=${scorecard_id}&metric=${metric}&latest_date=${latest_date}&scorecard_type=${type}`
		);
	}
	getMarketplaceProductInfo(productId: number) {
		return this.http.callGetApi(`product/mapped-product-info?id=${productId}`);
	}
	getMarketplaceProductDirectMapping(productId: number) {
		return this.http.callGetApi(`product/direct-matching?id=${productId}`);
	}
	getMarketplaceProductMappedKeyword(productId: number) {
		return this.http.callGetApi(`product/mapped-keyword?id=${productId}`);
	}
	getCompetitorWatchListCount(id?: number) {
		return this.http.callGetApi(
			"dashboard/competitor/competition-watchlist/count?profile_id=" + id
		);
	}
	getKeywordList(marketplaceId: number, pageNumber: number) {
		return this.http.callGetApi(
			`scorecard/keywords?page[size]=10&page[number]=${pageNumber}&marketplace_id=${marketplaceId}`
		);
	}
	createDirectMatching(body: any) {
		return this.http.callPostApi(`scorecard/direct-matching`, body);
	}
	createKeywordMapping(body: any) {
		return this.http.callPostApi(`scorecard/keywords-mapping`, body);
	}
	deletePoductMapping(customerProductId: number, competitorProductId: number) {
		return this.http.callDeleteApi(
			`scorecard/direct-matching/${customerProductId}/${competitorProductId}`
		);
	}
	deleteKeywordMapping(productId: number) {
		return this.http.callDeleteApi(`scorecard/keywords-mapping/${productId}`);
	}
	getVariantsList(productId: number) {
		return this.http.callGetApi(
			`product/marketplace-product-variants?id=${productId}`
		);
	}
	getStoreOverviewData(storeId: any, currencyId: number) {
		return this.http.callGetApi(
			`scorecard/store-overview/banner?store_id=${storeId}&filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`
		);
	}
	getScrapedProductFilterList(
		profileId: number,
		is_customer: any,
		filterType: any
	) {
		return this.http.callGetApi(
			`dashboard/${
				is_customer ? "customer" : "competitor"
			}/${filterType}?profile_id=${profileId}`
		);
	}
	getDashboardCurrencyData(profileId: any, is_customer: any) {
		return this.http.callGetApi(
			`dashboard/get-currency?profile_id=${profileId}&profile_type=${
				is_customer ? "customer" : "competitor"
			}`
		);
	}
	getMarketplaceList(pageNumber: any, currency_id: number) {
		return this.http.callGetApi(
			`scorecard/marketplaces?page[size]=10&page[number]=${pageNumber}&filter[${PAYLOAD.CURRENCY_ID}]=${currency_id}`
		);
	}
	getStoreList(
		marketplaceId: any,
		pageNumber: any,
		search: string,
		currency_id: number
	) {
		return this.http.callGetApi(
			`scorecard/stores?page[size]=10&page[number]=${pageNumber}&marketplace_id=${marketplaceId}${
				search && `&filter[search]=${search}`
			}`
		);
	}
	getProductList(
		pageNumber: any,
		search: string,
		currency_id: number,
		payload: any,
		directMappedId?:number
	) {
		let data = payload ? `&${this.commonFunctions.getPayloadFromObject(payload)}` : '';
		data = data[data.length - 1] === '&' ? data.substring(0, data.length - 1) : data
		return this.http.callGetApi(
			`common/products?page[size]=10&page[number]=${pageNumber}${
				search && `&filter[search]=${search}`
			}&filter[currency_id]=${currency_id}&base_type=id${data}${directMappedId ? `&direct_mapped_id=${directMappedId}` : ''}`
		);
	}
	getShareOfSearchData(
		profileData: any,
		shareType1: string,
		shareType2: string
	) {
		const firstShareDetails = this.http.callGetApi(
			`dashboard/share-of-search?profile_id=${profileData?.id}&profile_type=${shareType1}&is_customer=${profileData?.attributes?.is_customer}`
		);
		const secondShareDetails = this.http.callGetApi(
			`dashboard/share-of-search?profile_id=${profileData?.id}&profile_type=${shareType2}&is_customer=${profileData?.attributes?.is_customer}`
		);
		return forkJoin([firstShareDetails, secondShareDetails]);
	}
}
