import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-dashboard-headers",
	templateUrl: "./dashboard-headers.component.html",
	styleUrls: ["./dashboard-headers.component.scss"]
})
export class DashboardHeadersComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
