import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StoreScorecardService } from '@ds-private-layouts/scorecard/store-scorecard/store-scorecard.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';
import { CurrencyTemplate } from '@ds-shared/models/common.model';
import { finalize, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-info-store',
  templateUrl: './info-store.component.html',
  styleUrls: ['./info-store.component.scss']
})
export class InfoStoreComponent implements OnInit, OnDestroy {

  private _scorecardInfo: any;
  get scorecardInfo(): any {
    return this._scorecardInfo;
  }
  @Input() set scorecardInfo(value: any) {
    this._scorecardInfo = value;
  }
  private _dateFilter: any;
  get dateFilter(): any {
    return this._dateFilter;
  }
  @Input() set dateFilter(value: any) {
    this._dateFilter = value;
  }
  @Input() currencyDetails: CurrencyTemplate;
  public bannerData = {
    loading: false,
    data: null
  }
  private $destroy: Subject<boolean> = new Subject();
  public noteObj = {};
  public profileObj = {};
  constructor(public storeScorecardService: StoreScorecardService, public commonFunctions: CommonFunctionsService, private dataTransferService: DataTransferService) { 
  }

  ngOnInit(): void {
    this.getStoreOverviewData();
  }

  getStoreOverviewData() {
    this.bannerData.data = null;
    this.bannerData.loading = true;
    this.storeScorecardService.getStoreOverviewData(this.scorecardInfo.id, this.dateFilter)
      .pipe(
        finalize(() => {
          this.bannerData.loading = false;
      }), takeUntil(this.$destroy))
      .subscribe((res: any) => {
      if (res) {
        this.bannerData.data = res?.data;
        this.getActionObject();
        this.dataTransferService.sendScorecardBreadcrumb(this.bannerData.data?.name);
      }
    });
  }

  getActionObject() {
    this.noteObj = {
      ...{},
      marketplace: this.bannerData.data?.marketplace_name,
      name: this.bannerData.data?.name,
      id: this.bannerData.data?.id
    };
    this.profileObj = {
      ...{},
      url: this.bannerData.data?.url,
      name: this.bannerData.data?.name
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
