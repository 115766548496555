import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber'
})
export class NumberPipe implements PipeTransform {

  constructor(private decimalPipe:DecimalPipe) {}

  transform(value: any, ...args: any[]): any {
    let normal:String = this.decimalPipe.transform(value);
    normal = normal?.toString()
    normal = args[0] ? normal?.replace(/,/g, args[0]) : normal
    normal = args[1] ? normal?.replace(/\./g, args[1]) : normal
    return normal;
  }

}
