import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import {
	Discover,
	CategoryProductsService,
	DiscoverService,
	CategoryStoreExtraService
} from "../../../../layouts/private-layouts/discover/discover.service";
import { DocumentCollection } from "ngx-jsonapi";
import {
	ColumnModel,
	CommonSearchModel,
	ModuleType
} from "@ds-shared/models/common.model";
import { COL } from "@ds-shared/enums/common.enum";
import { UntypedFormControl } from "@angular/forms";
import { Subject, takeUntil, Subscription, finalize } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";

@Component({
	selector: "app-customer-products-grid",
	templateUrl: "./customer-products-grid.component.html",
	styleUrls: ["./customer-products-grid.component.scss"]
})
export class CustomerProductsGridComponent implements OnInit {
	@Input() public profileId: number;
	@Input() public gridType: string;
	@Input() scoreCardtype: any;
	public availableProducts: DocumentCollection<Discover>;
	private clearLastCall: Subscription;
	public cols: ColumnModel[];
	public NoData: boolean = false;
	public sort: string[] = [];
	public qs: any = {};
	public loadingData: boolean = false;
	public page: number = 1;
	public pageSize: number;
	public itemPerPage: number = 10;
	public searchObj: CommonSearchModel = {
		text: "",
		placeholder: "Search",
		style: { width: "11.68rem" }
	};
	public customerStoreFilter = {
		placeholder: "Store",
		list: [],
		meta: { key: "id" },
		multiple: true,
		selected: [],
		searchTerm: "",
		loading: false,
		query: [],
		clear: true,
		closeOnSelect: false
	};
	private $destroy: Subject<boolean> = new Subject();
	public searchedName: UntypedFormControl = new UntypedFormControl("");
	public moduleType: ModuleType = {
		name: "productGrid",
		style: {
			tableStyle: {
				height: "42rem"
			},
			tableWrapper: {
				border: "none",
				"border-radius": "0rem"
			},
			noDataStyle: {
				height: "36rem"
			},
			paginationStyle: {
				border: "none",
				padding: "1rem"
			}
		},
		pagination: {
			show: true
		}
	};
	public getTrackerPageDetaile: any;
	public paramName: string;
	constructor(
		private categoryProductsService: CategoryProductsService,
		private categoryStoreExtraService: CategoryStoreExtraService,
		private route: ActivatedRoute,
		public commonFunctions: CommonFunctionsService,
		private discoverService: DiscoverService,
		private globalService: GlobalService
	) {
		this.categoryProductsService.register();
		this.categoryProductsService.register();
		this.route.queryParams.subscribe((params) => {
			this.getTrackerPageDetaile = params["view"];
		});
	}

	ngOnInit(): void {
		this.setCols();
		if (this.gridType === "product") this.getStoreFilters();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.profileId = changes.profileId.currentValue;
		if (
			changes?.profileId?.previousValue !== changes?.profileId?.currentValue
		) {
			if (this.gridType === "product") {
				this.getStoreFilters();
				this.setExtraServices("product-matches-segregated-list");
				this.initData();
			} else if (this.gridType === "store") {
				this.setExtraServices("product-matches-segregated-list");
				this.initData();
			}
		}
	}
	public setCols(): void {
		const column = {
			product: [
				{
					id: 1,
					name: COL.PRODUCT_NAME,
					map: "attributes.name",
					sort: true,
					method: "productImage",
					component: "common-mapping",
					class: "",
					hasRedirect: true,
					isChecked: true,
					meta: {
						isRedirect: true,
						navigateTarget: "_blank",
						marketplaceLink: true
					},
					style: {
						th: {
							"min-width": "6.25rem",
							"max-width": "12.5rem"
						},
						td: {
							"min-width": "6.25rem",
							"max-width": "12.5rem"
						}
					}
				},
				{
					id: 2,
					name: `${COL.PRICE} & ${COL.DISCOUNT}`,
					map: "attributes.price",
					sort: true,
					method: "pricewithDiscountView",
					component: "common-mapping",
					class: "font-medium",
					hasRedirect: true,
					isChecked: true,
					style: {
						"th-class": "text-right",
						"td-class": "text-right"
					},
					meta: {
						isOwnCurrencyAvailable: true,
						currencyMap: "attributes.currency.symbol"
					}
				},
				{
					id: 3,
					name: `${COL.STORE} & ${COL.STORE_SEGMENT}`,
					map: "attributes.store",
					sort: true,
					method: "storewithSegmentView",
					component: "common-mapping",
					class: "ellipsis",
					hasRedirect: true,
					isChecked: true
				},
				{
					id: 4,
					name: "",
					map: "",
					sort: false,
					method: "goToDiscover",
					component: "common-mapping",
					class: "",
					hasRedirect: false,
					isChecked: true
				}
			],
			store: [
				{
					id: 4,
					name: COL.STORE,
					map: "attributes.name",
					sort: true,
					method: "storeView",
					component: "common-mapping",
					class: "ellipsis",
					hasRedirect: true,
					isChecked: true,
					meta: {
						type: "store",
						marketplaceLink: true,
						shouldRedirectToScorecard: true
					}
				},
				{
					id: 2,
					name: COL.STORE_SEGMENT,
					map: "attributes.segment",
					sort: true,
					method: "badgeView",
					component: "common-mapping",
					class: "",
					hasRedirect: true,
					isChecked: true
				},
				{
					id: 3,
					name: COL.AVERAGE_PRICE,
					map: "attributes.price",
					method: "priceView",
					component: "common-mapping",
					class: "",
					hasRedirect: true,
					isChecked: true,
					sort: true,
					style: {
						"th-class": "text-right",
						"td-class": "text-right"
					},
					meta: {
						isOwnCurrencyAvailable: true,
						currencyMap: "attributes.currency.symbol"
					}
				},
				{
					id: 4,
					name: "",
					map: "",
					sort: false,
					method: "goToDiscover",
					component: "common-mapping",
					class: "",
					hasRedirect: false,
					isChecked: true,
					meta: { isStore: true, profileId: this.profileId }
				}
			]
		};
		this.cols = JSON.parse(JSON.stringify(column[this.gridType]));
	}

	private initData(keepCurrentPage?: boolean): void {
		if (keepCurrentPage) this.getProfiles(this.page);
		else this.getProfiles(1);
	}

	public onPageChange(eve: number): void {
		this.page = eve;
		this.getProfiles(this.page);
	}
	public onPageSizeChange(eve: number): void {
		this.pageSize = eve;
		this.itemPerPage = eve;
		this.getProfiles(1);
	}

	private setExtraServices(type): void {
		this.categoryProductsService.setType(type);
		this.categoryProductsService.register();
		this.categoryStoreExtraService.register();
	}

	private getProfiles(page: number): void {
		this.clearLastCall?.unsubscribe();
		this.availableProducts = new DocumentCollection();
		const param: any = {};
		if (this.searchObj.text.length > 0) {
			param["search"] = this.searchObj.text?.trim();
		}
		// param["currency_id"] = this.commonFunctions.currencySelected?.id;
		param["type"] = "customer";
		// param["profile_id"] = this.profileId;
		this.paramName = this.getTrackerPageDetaile? `${this.getTrackerPageDetaile.toLowerCase()}_id` :'scorecard_id';
		param[this.paramName] = this.profileId;
		param["grid_type"] = this.gridType;
		param["scorecard_type"] = this.scoreCardtype;
		if (this.customerStoreFilter.selected.length) {
			this.customerStoreFilter.selected.map((e: any, i: number) => {
				param["store_ids"] = this.commonFunctions.getMappedData(
					this.customerStoreFilter.selected,
					"id"
				);
			});
		}
		this.clearLastCall = this.categoryProductsService
			.all({
				page: { number: page || 1, size: this.pageSize || 10 },
				remotefilter: param,
				sort: this.sort,
				beforepath: "discover"
			})
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(availableProducts) => {
					this.loadingData = true;
					if (
						availableProducts.source == "server" &&
						availableProducts.is_loading == false
					) {
						if (availableProducts.data.length == 0) {
							this.NoData = true;
							this.loadingData = true;
						} else {
							this.loadingData = false;
							this.NoData = false;
							this.availableProducts = JSON.parse(
								JSON.stringify(availableProducts)
							);
						}
					} else {
						this.loadingData = true;
						this.NoData = false;
					}
				},
				(error: any): void => {
					this.loadingData = true;
					this.NoData = true;
				}
			);
	}

	public onSearch(text: string): void {
		this.searchObj = { ...this.searchObj, text: text };
		this.initData();
	}

	public sortData(data: string[]): void {
		this.sort = data;
		this.initData(true);
	}

	private getStoreFilters() {
		if (this.paramName) {
			this.discoverService
				.getCategoryStoreList(
					this.paramName,
					this.profileId,
					"store",
					this.scoreCardtype,
					"customer"
				)
				.pipe(
					finalize(() => (this.customerStoreFilter.loading = false)),
					takeUntil(this.$destroy)
				)
				.subscribe((res: any) => {
					this.customerStoreFilter.list = res.data;
				});
		}
	}
	public onChange(e: any) {
		this.customerStoreFilter.selected = e;
		this.getProfiles(1);
	}

	public onStoreSearch(event) {
		this.customerStoreFilter.searchTerm = event.term;
	}

	public closeMultiDropdown() {
		this.customerStoreFilter.searchTerm = "";
	}

	public ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
