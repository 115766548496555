<div class="modal-header justify-content-end">
	<i
		class="icon icon-Close2 cursor-pointer"
		(click)="ngbActiveModalService.close()"
	>
	</i>
</div>
<div class="modal-body d-flex justify-content-center align-items-center">
	<div class="icon-container d-flex justify-content-center align-items-center">
		<i class="icon icon-Present"></i>
	</div>
</div>
<div class="text-center">
	<p class="bold-text" translate>This feature is currently not available</p>
	<p class="second-text" translate>
		Our team is currently working on this feature, It would be available in
		sometime.
	</p>
</div>
