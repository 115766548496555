import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { DocumentCollection } from "ngx-jsonapi";
import {
	ColumnModel,
	CommonSearchModel,
	ModuleType
} from "@ds-shared/models/common.model";
import { COL, SEGMENT, SEGMENTREVERSE } from "@ds-shared/enums/common.enum";
import { UntypedFormControl } from "@angular/forms";
import { Subject, takeUntil, Subscription, finalize } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import {
	Discover,
	CategoryProductsService,
	DiscoverService
} from "@ds-private-layouts/discover/discover.service";

@Component({
	selector: "app-competitors-products-grid",
	templateUrl: "./competitors-products-grid.component.html",
	styleUrls: ["./competitors-products-grid.component.scss"]
})
export class CompetitorsProductsGridComponent implements OnInit {
	@Input() public profileId: number;
	@Input() public gridType: string;
	@Input() scoreCardtype: any;
	public availableProducts: DocumentCollection<Discover>;
	private clearLastCall: Subscription;
	public cols: ColumnModel[];
	public NoData: boolean = false;
	public sort: string[] = [];
	public qs: any = {};
	public loadingData: boolean = false;
	public page: number = 1;
	public pageSize: number;
	public itemPerPage: number = 10;
	public searchObj: CommonSearchModel = {
		text: "",
		placeholder: "Search",
		style: { width: "11.68rem" }
	};
	public competitorStoreFilter = {
		placeholder: "Store",
		list: [],
		meta: { key: "id" },
		multiple: true,
		selected: [],
		searchTerm: "",
		loading: false,
		query: [],
		clear: true,
		closeOnSelect: false
	};
	private $destroy: Subject<boolean> = new Subject();
	public searchedName: UntypedFormControl = new UntypedFormControl("");
	public moduleType: ModuleType = {
		name: "productGrid",
		style: {
			tableStyle: {
				height: "42rem"
			},
			tableWrapper: {
				border: "none",
				"border-radius": "0rem"
			},
			noDataStyle: {
				height: "38.5rem"
			},
			paginationStyle: {
				border: "none",
				padding: "1rem"
			}
		},
		pagination: {
			show: true
		}
	};
	public getTrackerPageDetaile: any;
	public paramName: string;
	constructor(
		private categoryProductsService: CategoryProductsService,
		private route: ActivatedRoute,
		public commonFunctions: CommonFunctionsService,
		private discoverService: DiscoverService,
		private globalService: GlobalService
	) {
		this.categoryProductsService.register();
	}

	ngOnInit(): void {
		this.competitorStoreFilter.placeholder =
			this.gridType === "product" ? "Store" : "Segments";
		this.setCols();
	}
	public ngOnChanges(changes: SimpleChanges): void {
		this.route.queryParams.subscribe((params) => {
			this.getTrackerPageDetaile = params["view"];
		});
		if (
			changes?.profileId?.previousValue !== changes?.profileId?.currentValue
		) {
			this.initData();
			if (this.gridType === "product") this.getStoreFilters();
			else if (this.gridType === "store") this.getSegmentFilters();
		}
	}
	public setCols(): void {
		const column = {
			product: [
				{
					id: 1,
					name: COL.PRODUCT_NAME,
					map: "attributes.name",
					sort: true,
					method: "productImage",
					component: "common-mapping",
					class: "",
					hasRedirect: true,
					meta: {
						isRedirect: true,
						navigateTarget: "_blank",
						marketplaceLink: true
					},
					isChecked: true
				},
				{
					id: 2,
					name: `${COL.PRICE} & ${COL.DISCOUNT}`,
					map: "attributes.price",
					sort: true,
					method: "pricewithDiscountView",
					component: "common-mapping",
					class: "font-medium",
					hasRedirect: true,
					isChecked: true,
					style: {
						"th-class": "text-right",
						"td-class": "text-right"
					},
					meta: {
						isOwnCurrencyAvailable: true,
						currencyMap: "attributes.currency.symbol"
					}
				},
				{
					id: 3,
					name: `${COL.STORE} & ${COL.STORE_SEGMENT}`,
					map: "attributes.store",
					sort: true,
					method: "storewithSegmentView",
					component: "common-mapping",
					class: "ellipsis",
					hasRedirect: true,
					isChecked: true
				}
			],
			store: [
				{
					id: 4,
					name: COL.STORE,
					map: "attributes.name",
					sort: true,
					method: "storeView",
					component: "common-mapping",
					class: "ellipsis",
					hasRedirect: true,
					isChecked: true,
					meta: {
						type: "store",
						marketplaceLink: true,
						shouldRedirectToScorecard: true
					}
				},
				{
					id: 2,
					name: COL.STORE_SEGMENT,
					map: "attributes.segment",
					sort: true,
					method: "badgeView",
					component: "common-mapping",
					class: "",
					hasRedirect: true,
					isChecked: true
				},
				{
					id: 3,
					name: COL.AVERAGE_PRICE,
					map: "attributes.price",
					method: "priceView",
					component: "common-mapping",
					class: "",
					hasRedirect: true,
					isChecked: true,
					sort: true,
					style: {
						"th-class": "text-right",
						"td-class": "text-right"
					},
					meta: {
						isOwnCurrencyAvailable: true,
						currencyMap: "attributes.currency.symbol"
					}
				}
			]
		};
		this.cols = JSON.parse(JSON.stringify(column[this.gridType]));
	}

	private initData(keepCurrentPage?: boolean): void {
		if (keepCurrentPage) this.getProfiles(this.page);
		else this.getProfiles(1);
	}

	public onPageChange(eve: number): void {
		this.page = eve;
		this.getProfiles(this.page);
	}
	public onPageSizeChange(eve: number): void {
		this.pageSize = eve;
		this.itemPerPage = eve;
		this.getProfiles(1);
	}

	private getProfiles(page: number): void {
		this.clearLastCall?.unsubscribe();
		this.availableProducts = new DocumentCollection();
		let param: any = {};
		if (this.searchObj.text.length > 0) {
			param["search"] = this.commonFunctions.encodeURi(
				this.searchObj.text?.trim()
			);
		}
		param["type"] = "competitor";
		// param["profile_id"] = this.profileId;
		this.paramName = this.getTrackerPageDetaile? `${this.getTrackerPageDetaile.toLowerCase()}_id` :'scorecard_id';
		param[this.paramName] = this.profileId;
		param["grid_type"] = this.gridType;
		param["scorecard_type"] = this.scoreCardtype;
		if (this.competitorStoreFilter.selected.length) {
			this.competitorStoreFilter.selected.map((e: any, i: number) => {
				let selectedFilterObject;
				if (this.gridType !== "product") {
					selectedFilterObject = this.competitorStoreFilter.selected.map(
						(ele) => {
							return { name: SEGMENTREVERSE[ele.name] };
						}
					);
					//selectedFilterObject = this.competitorStoreFilter.selected.map((ele) => { return { id: ele.id, name: SEGMENTREVERSE[ele.name] } })
				} else {
					selectedFilterObject = this.competitorStoreFilter.selected;
				}
				// param = {};
				param[
					`${
						this.gridType === "product"
							? "competitor_store_ids"
							: "competitor_segments"
					}`
				] = this.commonFunctions.getMappedData(
					selectedFilterObject,
					`${this.gridType === "store" ? "name" : "id"}`
				);
			});
		}
		this.clearLastCall = this.categoryProductsService
			.all({
				page: { number: page || 1, size: this.pageSize || 10 },
				remotefilter: param,
				sort: this.sort,
				beforepath: "discover"
			})
			.pipe(takeUntil(this.$destroy))
			.subscribe(
				(availableProducts) => {
					this.loadingData = true;
					if (
						availableProducts.source == "server" &&
						availableProducts.is_loading == false
					) {
						if (availableProducts.data.length == 0) {
							this.NoData = true;
							this.loadingData = true;
						} else {
							this.loadingData = false;
							this.NoData = false;
							this.availableProducts = JSON.parse(
								JSON.stringify(availableProducts)
							);
						}
					} else {
						this.loadingData = true;
						this.NoData = false;
					}
				},
				(error: any): void => {
					this.loadingData = true;
					this.NoData = true;
				}
			);
	}

	public onSearch(text: string): void {
		this.searchObj = { ...this.searchObj, text: text };
		this.initData();
	}

	public sortData(data: string[]): void {
		this.sort = data;
		this.initData(true);
	}
	private getStoreFilters() {
		this.competitorStoreFilter.list = [];
		this.competitorStoreFilter.loading = true;
		if (this.paramName) {
			this.discoverService
				.getCategoryStoreList(
					this.paramName,
					this.profileId,
					"store",
					this.scoreCardtype,
					"competitor"
				)
				.pipe(
					finalize(() => (this.competitorStoreFilter.loading = false)),
					takeUntil(this.$destroy)
				)
				.subscribe((res: any) => {
					this.competitorStoreFilter.list = res.data;
				});
		}
	}
	segmentDropdown() {
		this.discoverService
			.getSegmentDropdownData(
				this.paramName,
				this.profileId,
				"segment",
				this.scoreCardtype,
				"competitor"
			)
			.pipe(
				finalize(() => (this.competitorStoreFilter.loading = false)),
				takeUntil(this.$destroy)
			)
			.subscribe((res: any) => {
				this.competitorStoreFilter.list = res.data.map((item, i) => {
					return {
						id: i + 1,
						name: SEGMENT[item.name]
					};
				});
			});
	}
	private getSegmentFilters() {
		this.competitorStoreFilter.list = [];
		this.competitorStoreFilter.loading = true;
		this.segmentDropdown();
		// this.globalService.getLocalFilters(false, 'segments', this.commonFunctions.currencySelected.id, false, null).pipe(finalize(() => (this.competitorStoreFilter.loading = false)), takeUntil(this.$destroy)).subscribe((res: any) => {
		// 	this.competitorStoreFilter.list = res.data.map((item, i) => {
		// 		return {
		// 			id: i + 1,
		// 			name: SEGMENT[item.name]
		// 		}
		// 	})
		// })
	}
	public onChange(e: any) {
		this.competitorStoreFilter.selected = e;
		this.getProfiles(1);
	}

	public onStoreSearch(event) {
		this.competitorStoreFilter.searchTerm = event.term;
	}

	public closeMultiDropdown() {
		this.competitorStoreFilter.searchTerm = "";
	}

	public ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
