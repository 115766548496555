/* eslint-disable */
import * as Highcharts from "highcharts";
import More from 'highcharts/highcharts-more';
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
import { filter, Subject, takeUntil } from "rxjs";
More(Highcharts);
let componentContext;
@Component({
	selector: "app-bubble-chart",
	templateUrl: "./bubble-chart.component.html",
	styleUrls: ["./bubble-chart.component.scss"]
})
export class BubbleChartComponent implements OnInit, AfterViewInit {
	@Input() public data;
	@Input() public showLegends=false;
	@Input() public caller = "default";
	@Input() public labelColor: string;
	@Input() public yAxisOffsetVal: number;
	@Input() public xAxisOffsetVal: number;
	@Input() public currencyDetails:any;
	public Highcharts: typeof Highcharts = Highcharts; // required
	public highchartsData: typeof Highcharts;
	public chartRef;
	public chartOptions;
	private $destroy: Subject<boolean> = new Subject();

	constructor(private dataTransferService: DataTransferService, private commonFunctions: CommonFunctionsService) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.$destroy)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				// thousandsSep: ','
			}
		});
	}

	ngAfterViewInit(): void {
		this.renderBubbleChartData();
	}

	renderBubbleChartData() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				type: "bubble",
				events: {
					load: function (event) {
						event.target.reflow();
					}
				}
			},
			title: {
				text: ""
			},
			legend: {
				enabled: this.showLegends
			},
			xAxis: {
				gridLineWidth: 1,
				gridLineDashStyle: 'dash',
				lineWidth: 1,
				tickWidth: 1,
				min: 0,
				title: {
					text: `${componentContext.commonFunctions.getSpecificTranslation("Min Price")}(${componentContext.commonFunctions.currencySelected.attributes.symbol})`,
					offset: 40,
					style:{
						fontFamily: "Poppins Regular",
						fontWeight: "400",
						color: componentContext.labelColor,
						fontSize: "0.825rem"
					}
				},
				labels: {
					rotation: 270,
					formatter: (ev)=>{
						return this.commonFunctions.getSuffixedNumberFormat(ev.value)
					},
					style: {
						fontFamily: "Poppins Medium",
						fontWeight: "400",
						color: "#8D9199",
						fontSize: "0.625rem"
					},
					padding: 20
				}
			},

			yAxis: {
				startOnTick: false,
				endOnTick: false,
				gridLineWidth: 1,
				gridLineDashStyle: 'dash',
				lineWidth: 1,
				min: 0,
				title: {
					text: `${this.commonFunctions.getSpecificTranslation("Max Price")}(${componentContext.commonFunctions.currencySelected.attributes.symbol})`,
					offset: 40,
					style:{
						fontFamily: "Poppins Regular",
						fontWeight: "400",
						color: componentContext.labelColor,
						fontSize: "0.825rem"
					},
					padding: 20
				},
				labels: {
					formatter: (ev)=>{
						return this.commonFunctions.getSuffixedNumberFormat(ev.value)
					},
					style: {
						fontFamily: "Poppins Medium",
						fontWeight: "400",
						color: "#8D9199",
						fontSize: "0.625rem"
					  }
				},
				maxPadding: 0.2,
			},
			credits: false,
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: false,
				hideDelay: 100,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true
			},

			plotOptions: {
				bubble: {
					cursor: "pointer",
					minSize: 40,
					maxSize: 130
				},
				series: {
					dataLabels: {
						enabled: false,
						format: "{point.name}"
					},
					marker: {
						lineWidth: 0,
						fillOpacity: 1,
						lineColor: null // inherit from series
					}
				}
			},

			series: [
				{
					data: this.data,
					marker: {
						fillOpacity: 0.5
					},
				}
			]
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (dataLabel["point"]["caller"]) {
			case "selloutPriceRange":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
			 				<div style="font-size:0.625rem;">${dataLabel["point"]['name']}</div>
							<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between;margin-top:0.5rem">
							<div style="display:flex;flex-direction: column;">
            				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.currencySelected.attributes.symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['x'])} - ${componentContext.commonFunctions.currencySelected.attributes.symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['y'])}</span>
            				<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">Price Range</span>
        </div>
        <div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.currencySelected.attributes.symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['point']['gmv'])}</span>
            <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">GMV</span>
        </div>
        <div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['point']['quantity_sold'])}</span>
                    <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Quantity Sold')}</span>
        </div>
    </div>
						</div>`
			case "discover_pricing_trend":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
						<div style="font-size:0.625rem;">${dataLabel["point"]['name']}</div>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between;margin-top:0.5rem">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.currencyDetails.symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['x'])} - ${componentContext.currencyDetails.symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['y'])}</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">Price Range</span>
					</div>
					<div style="display:flex;flex-direction: column;">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.currencyDetails.symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['point']['z'])}</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Average Price')}</span>
					</div>
				</div>
		   </div>`
			default:
				return ``;
		}
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};
	ngOnDestroy() {
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
