import { Component, Input, OnInit } from '@angular/core';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';

@Component({
  selector: 'app-available-store-grid',
  templateUrl: './available-store-grid.component.html',
  styleUrls: ['./available-store-grid.component.scss']
})
export class AvailableStoreGridComponent implements OnInit {
  @Input() ngxItems: any;
  constructor(
    public commonFunctions: CommonFunctionsService
  ) { }

  ngOnInit(): void {
  }

}
