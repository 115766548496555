<div class="ds-box align-items-center justify-content-between kpi">
	<div class="title mb-2" translate>{{cardData?.title}}</div>
	<ng-container *ngIf="cardData.data;else noQuantityData">
		<div class="GMVContainer d-flex justify-content-between align-items-center">
			<div class="gmvValue gap-8 d-flex align-items-center">
				<div class="value d-flex align-items-center">
					<span
						>{{ cardData.data?.value? (cardData.data?.value | numberSuffix : 2)
						: (cardData.data?.value | noValueChecker)
						}}{{cardData.data?.valueInPercentage?"%":""}}</span
					>
					<i
						class="icon"
						*ngIf="cardData.data?.wow_value || cardData.data?.mom_value"
						[ngClass]="{'icon-Arrow-up-2':cardData.data?.dir==='up','icon-Arrow-down-2':cardData.data?.dir==='down'}"
					></i>
				</div>
			</div>
			<div class="deltaValues d-flex align-items-center">
				<div class="delta d-flex align-items-center gap-6">
					<span class="title" translate>WoW</span>
					<span class="value" [ngClass]="{'positive':cardData.data?.wow_value}"
						>{{cardData.data?.wow_value ? (cardData.data?.wow_value + "%") :
						'-'}}</span
					>
				</div>
				<div class="seperator"></div>
				<div class="delta d-flex align-items-center gap-6">
					<span class="title" translate>MoM</span>
					<span
						class="value"
						[ngClass]="{'positive':cardData.data?.mom_value>0,'negative':cardData.data?.mom_value<0}"
						>{{cardData.data?.mom_value ? (cardData.data?.mom_value + "%") :
						'-'}}</span
					>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-template #noQuantityData>
		<div class="no-data-common w-100">
			<div *ngIf="!cardData.isLoading" class="no-data-found" translate>
				<div class="no-data-text" translate>No data present</div>
			</div>
			<div *ngIf="cardData.isLoading" class="loading-panel">
				<img
					ngSrc="/assets/images/spinner.svg"
					alt=""
					height="200"
					width="200"
				/>
			</div>
		</div>
	</ng-template>
</div>
