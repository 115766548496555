import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessMatrixService } from '@ds-shared/common-services/utility-services/access-matrix.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { AccessMatrix } from '@ds-shared/enums/access-matrix.enum';
import { RolePermissions } from '@ds-shared/models/access-matrix.model';

@Component({
  selector: 'app-scorecard-actions',
  templateUrl: './scorecard-actions.component.html',
  styleUrls: ['./scorecard-actions.component.scss']
})
export class ScorecardActionsComponent implements OnInit {

  @Input() bannerData;
  @Input() profileObj;
  @Input() noteObj;
  @Input() scorecardType;

  public permissions: RolePermissions = new RolePermissions();
  constructor(public commonFunctions: CommonFunctionsService, private route: ActivatedRoute, private accessMatrixService: AccessMatrixService) { 
    this.permissions = this.accessMatrixService.getPermissionByModule(AccessMatrix.Notifications)
  }

  ngOnInit(): void {
  }

  openNotification() {
    this.commonFunctions.redirectToNotification(`${this.scorecardType}s`, this.commonFunctions.getEncodedData(JSON.stringify(this.noteObj)), this.route);
  }
  openProfile() {
    this.commonFunctions.redirectToProfile(this.scorecardType, this.commonFunctions.getEncodedData(JSON.stringify(this.profileObj)), this.route);
  }

}
