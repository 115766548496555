<div class="main-container">
	<div class="item">
		<div class="prod-box text-align-right-rtl">
			<div class="header">
				<div class="d-flex align-items-center gap-6">
					<h5 class="d-inline-block mb-0" translate>Active Products</h5>
					<span class="count-badge" *ngIf="count?.active"
						>{{ count?.active }}</span
					>
				</div>
				<div class="search-container d-inline-block">
					<input
						type="text"
						placeholder="{{'Search Active Product' | translate}}"
						[formControl]="searchedActiveProd"
					/>
					<i class="icon icon-Search"></i>
				</div>
			</div>
			<div class="manage-product-grid-container">
				<div
					class="checkbox-container"
					infiniteScroll
					[infiniteScrollDistance]="2"
					[infiniteScrollThrottle]="50"
					[scrollWindow]="false"
					(scrolled)="onActiveScroll()"
				>
					<div
						*ngIf="activeProd?.length == 0 || activeProdLoading"
						class="no-data-common"
					>
						<div
							*ngIf="activeProd?.length == 0 && !activeProdLoading"
							class="no-data-found"
							translate
						>
							<div class="img"><i class="icon icon-Search"></i></div>
							<div class="no-data-text" translate>No data present</div>
						</div>
						<div *ngIf="activeProdLoading" class="loading-panel">
							<img src="/assets/images/spinner.svg" alt="" />
						</div>
					</div>
					<div
						class="info-container manage-product-grid"
						*ngIf="activeProd?.length > 0"
					>
						<table>
							<thead>
								<tr>
									<th></th>
									<th
										(click)="sortInGrid('name-active')"
										(mouseover)="mouseAction('name-active')"
										(mouseout)="mouseAction('')"
										translate
									>
										Name
										<span class="table-header-text">
											<span
												class="icon"
												[ngClass]="mouseActionType=='name-active' && sortDirection"
											></span>
										</span>
									</th>
									<th
										(click)="sortInGrid('price-active')"
										(mouseover)="mouseAction('price-active')"
										(mouseout)="mouseAction('')"
										translate
									>
										Price
										<span class="table-header-text">
											<span
												class="icon"
												[ngClass]="mouseActionType=='price-active' && sortDirection"
											></span>
										</span>
									</th>
									<th
										(click)="sortInGrid('discount-active')"
										(mouseover)="mouseAction('discount-active')"
										(mouseout)="mouseAction('')"
										translate
									>
										Discount
										<span class="table-header-text">
											<span
												class="icon"
												[ngClass]="mouseActionType=='discount-active' && sortDirection"
											></span>
										</span>
									</th>
									<th></th>
								</tr>
							</thead>

							<tbody>
								<tr *ngFor="let prod of activeProd; let i = index">
									<td>
										<label class="checkbox" for="{{ prod.id }}">
											<input
												type="checkbox"
												name="checkbox"
												id="{{ prod.id }}"
												(change)="checkActiveProd($event, i, prod)"
											/>
											<div class="checkbox-section">
												<i class="icon icon-Check"></i>
											</div>
										</label>
									</td>
									<td>
										<div class="image-name-content">
											<div class="img-container">
												<img
													*ngIf="prod?.images && prod?.images?.length > 0"
													[src]="prod?.images[0]"
													alt="Product Image"
												/>
												<img
													*ngIf="!prod?.images?.length"
													src="/assets/images/no-image.svg"
													alt="Product Image"
												/>
											</div>
											<div class="name-container">
												<div
													class="mb-0 available-heading cursor-pointer on-hover-action"
													showMultiTooltipOnTextOverflow
													[data]="prod?.name"
													(click)="openProductSummaryOverlay(prod.id)"
												></div>
												<div class="showVariants" *ngIf="prod?.variant_count">
													<div
														ngbDropdown
														class="d-inline-block"
														placement="bottom-left"
														class="variantDropdown"
													>
														<span id="dropdownBasic1" ngbDropdownToggle
															>{{'In' | translate}} {{(prod?.variant_count)}}
															{{'Variants' | translate}}</span
														>
														<span
															id="dropdownBasic1"
															ngbDropdownToggle
															class="cursor-pointer"
															>&nbsp;</span
														>
													</div>
												</div>
											</div>
										</div>
									</td>
									<td *ngIf="prod?.price">
										{{prod?.currency?.symbol}}{{ prod?.price | numberSuffix: 2
										}}
									</td>
									<td *ngIf="!prod?.price">-</td>
									<td *ngIf="prod?.discount">
										<span class="voucher-badge">
											{{ prod?.discount }}% {{'OFF' | translate}}</span
										>
									</td>
									<td *ngIf="!prod?.discount">
										<span>-</span>
									</td>
									<td>
										<div
											ngbDropdown
											class="d-inline-block"
											placement="bottom-right"
										>
											<span
												id="dropdownBasic1"
												ngbDropdownToggle
												class="cursor-pointer"
												><span
													><i
														class="icon icon-Options"
														placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
														ngbTooltip="{{'Actions' | translate}}"
														tooltipClass="info-custom-tooltip"
													></i
												></span>
											</span>
											<div
												ngbDropdownMenu
												aria-labelledby="dropdownBasic1"
												class="action-dropdown-rtl"
											>
												<button
													class="dropdown-item"
													(click)="openDirectMapping(prod?.id)"
												>
													<i class="icon icon-Edit-alt"></i>
													<span translate>Manage Direct Mapping</span>
												</button>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="item d-flex flex-column align-items-center justify-content-center gap-8"
	>
		<button
			class="btn icon-only-btn d-block"
			(click)="!permissions.write_access?false:onMoveProduct('active')"
			[disabled]="!permissions.write_access || tempMovedActiveProduct.length == 0"
			placement="{{commonFunctions.rtlStatus ? 'left' : 'right'}}"
			ngbTooltip="{{'Move active to discarded' | translate}}"
			tooltipClass="info-custom-tooltip"
		>
			<i
				[ngClass]="{'pdisabled':!permissions.write_access}"
				class="icon"
				[ngClass]="{'icon-Arrow-left':commonFunctions.rtlStatus,'icon-Arrow-right':!commonFunctions.rtlStatus}"
			></i>
		</button>
		<button
			class="btn icon-only-btn d-block"
			(click)="!permissions.write_access?false:onMoveProduct('discarded')"
			[disabled]="!permissions.write_access || tempMovedDiscardedProduct.length == 0"
			placement="{{commonFunctions.rtlStatus ? 'left' : 'right'}}"
			ngbTooltip="{{'Move all discarded to active' | translate}}"
			tooltipClass="info-custom-tooltip"
		>
			<i
				[ngClass]="{'pdisabled':!permissions.write_access}"
				class="icon"
				[ngClass]="{'icon-Arrow-right':commonFunctions.rtlStatus,'icon-Arrow-left':!commonFunctions.rtlStatus}"
			></i>
		</button>
	</div>
	<div class="item">
		<div class="prod-box text-align-right-rtl">
			<div class="header">
				<div class="d-flex align-items-center gap-6">
					<h5 class="d-inline-block mb-0" translate>Discarded Products</h5>
					<span class="count-badge" *ngIf="count?.discarded"
						>{{ count?.discarded }}</span
					>
				</div>
				<div class="search-container d-inline-block">
					<input
						type="text"
						placeholder="{{'Search Discarded Product' | translate}}"
						[formControl]="searchedDiscardedProd"
					/>
					<i class="icon icon-Search"></i>
				</div>
			</div>
			<div class="manage-product-grid-container">
				<div
					class="checkbox-container"
					infiniteScroll
					[infiniteScrollDistance]="2"
					[infiniteScrollThrottle]="50"
					[scrollWindow]="false"
					(scrolled)="onDiscardedScroll()"
				>
					<div
						*ngIf="discardedProd?.length == 0 || discardedProdLoading"
						class="no-data-common"
					>
						<div
							*ngIf="discardedProd?.length == 0 && !discardedProdLoading"
							class="no-data-found"
							translate
						>
							<div class="img"><i class="icon icon-Search"></i></div>
							<div class="no-data-text" translate>No data present</div>
						</div>
						<div *ngIf="discardedProdLoading" class="loading-panel">
							<img src="/assets/images/spinner.svg" alt="" />
						</div>
					</div>
					<div
						class="info-container manage-product-grid"
						*ngIf="discardedProd?.length > 0"
					>
						<table>
							<thead>
								<tr>
									<th></th>
									<th
										(click)="sortInGrid('name-deactive')"
										(mouseover)="mouseAction('name-deactive')"
										(mouseout)="mouseAction('')"
										translate
									>
										Name
										<span class="table-header-text">
											<span
												class="icon"
												[ngClass]="mouseActionType=='name-deactive' && sortDirection"
											></span>
										</span>
									</th>
									<th
										(click)="sortInGrid('price-deactive')"
										(mouseover)="mouseAction('price-deactive')"
										(mouseout)="mouseAction('')"
										translate
									>
										Price
										<span class="table-header-text">
											<span
												class="icon"
												[ngClass]="mouseActionType=='price-deactive' && sortDirection"
											></span>
										</span>
									</th>
									<th
										(click)="sortInGrid('discount-deactive')"
										(mouseover)="mouseAction('discount-deactive')"
										(mouseout)="mouseAction('')"
										translate
									>
										Discount
										<span class="table-header-text">
											<span
												class="icon"
												[ngClass]="mouseActionType=='discount-deactive' && sortDirection"
											></span>
										</span>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let prod of discardedProd; let j = index">
									<td>
										<label class="checkbox" for="{{ prod.id }}">
											<input
												type="checkbox"
												name="checkbox"
												id="{{ prod.id }}"
												(change)="checkDiscardedProd($event, j, prod)"
											/>
											<div class="checkbox-section">
												<i class="icon icon-Check"></i>
											</div>
										</label>
									</td>
									<td>
										<div class="image-name-content">
											<div class="img-container">
												<img
													*ngIf="prod?.images && prod?.images?.length > 0"
													[src]="prod?.images[0]"
													alt="Product Image"
												/>
												<img
													*ngIf="!prod?.images?.length"
													src="/assets/images/no-image.svg"
													alt="Product Image"
												/>
											</div>
											<div class="name-container">
												<div
													class="mb-0 available-heading cursor-pointer on-hover-action"
													showMultiTooltipOnTextOverflow
													[data]="prod?.name"
													(click)="openProductSummaryOverlay(prod.id)"
												></div>
												<div class="showVariants" *ngIf="prod?.variant_count">
													<div
														ngbDropdown
														class="d-inline-block"
														placement="bottom-left"
														class="variantDropdown"
													>
														<span id="dropdownBasic1" ngbDropdownToggle
															>{{'In' | translate}} {{(prod?.variant_count)}}
															{{'Variants' | translate}}</span
														>
														<span
															id="dropdownBasic1"
															ngbDropdownToggle
															class="cursor-pointer"
															>&nbsp;</span
														>
													</div>
												</div>
											</div>
										</div>
									</td>
									<td *ngIf="prod?.price">
										{{prod?.currency?.symbol}}{{ prod?.price | numberSuffix: 2
										}}
									</td>
									<td *ngIf="!prod?.price">-</td>
									<td *ngIf="prod?.discount">
										<span class="voucher-badge">
											{{ prod?.discount }}% {{'OFF' | translate}}</span
										>
									</td>
									<td *ngIf="!prod?.discount">
										<span>-</span>
									</td>
									<td>
										<i
											class="icon icon-Info-circle cursor-pointer reason-info pe-1"
											*ngIf="prod?.reason && prod?.reason?.length>0"
											placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
											ngbTooltip="{{prod?.reason}}"
											tooltipClass="info-custom-tooltip"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
