<div class="catalog-body">
	<table>
		<tr
			class="catalog-list"
			[ngClass]="{'competitor': info_type==='competitor' || info_type==='keyword'}"
			*ngFor="let product of productDetail"
		>
			<td *ngIf="info_type==='competitor' || info_type==='customer'">
				<div class="product-info-div d-flex flex-column">
					<div class="product-type">
						<span
							class="badge bg-store cursor-pointer"
							[ngClass]="info_type==='competitor' && 'bg-dark'"
							(click)="commonFunctions?.redirectToScorecardInfo(product?.store_id,'store')"
							>{{product?.store}}</span
						>
					</div>
					<div class="image-name-content">
						<div class="img-container">
							<img
								*ngIf="product?.images && product?.images?.length > 0"
								[src]="product?.images[0]"
								alt="Product Image"
							/>
							<img
								*ngIf="product?.images && product?.images.length === 0"
								src="../../../../assets/images/no-image.svg"
								alt="Product Image"
							/>
						</div>
						<div class="name-container">
							<div class="d-flex">
								<a
									class="mb-0 available-heading position-relative cursor-pointer"
									[routerLink]="[commonFunctions?.redirectToScorecardInfo(product?.id,'product',true)?.params]"
									[target]="route.snapshot['_routerState'].url==='/scorecard/product/list'?'_self':'_blank'"
									[ngClass]="info_type!=='keyword' && 'on-hover-action'"
									showMultiTooltipOnTextOverflow
									[data]="product?.name"
								>
								</a>
								<a
									href="{{product?.url}}"
									target="_blank"
									class="navigate-link"
									placement="bottom"
									ngbTooltip="{{'Go to Product Channel' | translate}}"
									tooltipClass="info-custom-tooltip"
									><i class="icon icon-Open-link"></i
								></a>
							</div>
							<div class="showVariants" *ngIf="product?.variant_count">
								<div
									ngbDropdown
									class="d-inline-block"
									placement="bottom-left"
									class="variantDropdown"
								>
									<span
										id="dropdownBasic1"
										ngbDropdownToggle
										class="cursor-pointer on-hover-action"
										(click)="getVariantsList(product)"
										>{{'In' | translate}} {{(product?.variant_count)}}
										{{'Variants' | translate}}</span
									>
									<span
										id="dropdownBasic1"
										ngbDropdownToggle
										class="cursor-pointer"
										>&nbsp;</span
									>
									<div
										ngbDropdownMenu
										aria-labelledby="dropdownBasic1"
										class="variantDropdownList infoVariantDropdown"
										*ngIf="variantsList && variantsList?.length>0"
									>
										<button
											class="dropdown-item"
											*ngFor="let variant of variantsList"
										>
											<span>{{variant?.variant_name}}</span>
										</button>
									</div>
								</div>
							</div>
							<div
								class="showVariants emptyVariant"
								*ngIf="!product?.variant_count"
							></div>
						</div>
					</div>
				</div>
			</td>

			<td
				*ngIf="info_type==='competitor' || info_type==='customer'"
				class="category-container"
			>
				<span class="labelName" translate>Category</span>
				<div
					class="category"
					*ngIf="product?.category"
					showTooltipOnTextOverflow
					[data]="product?.category"
				></div>
				<div class="labelValue" *ngIf="!product?.category">-</div>
			</td>
			<td
				*ngIf="info_type==='competitor' || info_type==='customer'"
				class="rating-container"
			>
				<span class="labelName" ngbAutofocus translate>Ratings</span>
				<ng-template #starLabel>
					<span class="star">&#x02605;</span>
				</ng-template>
				<div
					class="ratingLabel"
					[ngClass]="commonFunctions.getStarStatus(product?.rating)"
					*ngIf="product?.rating"
				>
					{{product?.rating}}<ngb-rating
						[max]="1"
						[starTemplate]="starLabel"
						[rate]="1"
					>
					</ngb-rating>
				</div>
				<span class="labelValue" *ngIf="!product?.rating">-</span>
			</td>
			<td
				*ngIf="info_type==='competitor' || info_type==='customer'"
				class="discount-container"
			>
				<span class="labelName" translate>Discount</span>
				<span class="voucher-badge" *ngIf="product?.discount"
					>{{product?.discount}}% {{'OFF' | translate}}</span
				>
				<span class="labelValue" *ngIf="!product?.discount">-</span>
			</td>
			<td
				*ngIf="info_type==='competitor' || info_type==='customer'"
				class="price-container"
			>
				<span class="labelName" translate>Price</span>
				<span class="price-value" *ngIf="product?.price">
					<span class="value-content"
						>{{product?.currency?.symbol}}{{(product?.price | numberSuffix:
						2)}}</span
					>
					<span
						class="delta-content"
						*ngIf="product?.price_change"
						[ngClass]="{'negative':product?.price_change>0}"
						><i class="icon icon-Triangle-Down1"></i
						>{{product?.currency?.symbol}}{{(commonFunctions.getAbsoluteValue(product?.price_change)
						| numberSuffix: 2)}} {{product?.price_change>0?("Higher" |
						translate):("Lower" | translate)}}</span
					>
				</span>
				<span class="labelValue" *ngIf="!product?.price">-</span>
			</td>
			<td *ngIf="info_type!=='keyword'" class="product-marketplace">
				<span class="labelName" translate>Channel</span>
				<div
					class="shpeeName d-flex align-items-center gap-6"
					*ngIf="product?.marketplace"
				>
					<app-marketplace-store-logo
						[storeName]="product?.marketplace"
					></app-marketplace-store-logo>
					<span class="labelValue marketplace">{{product?.marketplace}}</span>
				</div>
				<span class="labelValue" *ngIf="!product?.marketplace">-</span>
			</td>
			<td *ngIf="info_type==='keyword'" class="keyword">
				<span class="labelName" translate>Keyword</span>
				<div
					class="labelValue keyword"
					*ngIf="product?.keyword"
					showTooltipOnTextOverflow
					[data]="product?.keyword"
				></div>
				<div class="labelValue" *ngIf="!product?.keyword">-</div>
			</td>
			<td *ngIf="info_type==='keyword'" class="keyword">
				<span class="labelName" translate>Channel</span>
				<div
					class="shpeeName d-flex align-items-center gap-6"
					*ngIf="product?.marketplace"
				>
					<app-marketplace-store-logo
						[storeName]="product?.marketplace"
					></app-marketplace-store-logo>
					<span class="labelValue marketplace">{{product?.marketplace}}</span>
				</div>
				<span class="labelValue" *ngIf="!product?.marketplace">-</span>
			</td>
			<td *ngIf="info_type==='keyword'" class="keyword">
				<span class="labelName" translate>Products</span>
				<span class="labelValue" *ngIf="product?.product_count"
					>{{(product?.product_count | numberSuffix : 2 )}}
					<span class="products">Products</span></span
				>
				<span class="labelValue" *ngIf="!product?.product_count">-</span>
			</td>
			<td *ngIf="info_type==='keyword'" class="keyword">
				<span class="labelName" translate>Last Rank</span>
				<span class="labelValue">{{product?.rank?product?.rank:'-'}}</span>
			</td>
			<td *ngIf="info_type=='keyword'" class="keyword">
				<app-keyword-trends
					[actionData]="product?.rank_change"
					*ngIf="product?.rank_change && product?.rank_change?.length>0"
				></app-keyword-trends>
				<span
					*ngIf="!product?.rank_change || product?.rank_change?.length===0"
					class="noGraphData"
					translate
					>No Data</span
				>
			</td>
			<td *ngIf="info_type==='keyword'" class="keyword">
				<span class="labelName" translate>Last Updated at</span>
				<span class="labelValue dateValue"
					>{{product?.updated_at?commonFunctions.formatSimpleDate(product?.updated_at):'-'}}</span
				>
			</td>
			<td>
				<div class="h-100 button-container d-flex justify-content-end">
					<button
						class="btn icon-only-btn notification position-relative"
						[disabled]="!permissionForNotification.write_access"
						placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
						ngbTooltip="{{'Create Notification' | translate}}"
						tooltipClass="info-custom-tooltip"
						(click)="openNotification(product)"
					>
						<i class="icon icon-Notification-on"></i>
					</button>
					<app-mapped-action
						[ngClass]="{'hide-visibility':info_type==='customer'}"
						[info_type]="info_type"
						[productDetail]="product"
						(onDeleteAction)="onDeleteAction($event)"
					></app-mapped-action>
				</div>
			</td>
		</tr>
	</table>
</div>
