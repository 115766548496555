import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserManagementService } from "@ds-private-layouts/user-management/user-management.service";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { ColumnModel, CommonModel } from "@ds-shared/models/common.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

@Component({
	selector: "app-bulk-activity-actions",
	templateUrl: "./bulk-activity-actions.component.html",
	styleUrls: ["./bulk-activity-actions.component.scss"]
})
export class BulkActivityActionsComponent implements OnInit {
	@Input() public actionData: any;
	@Input() public componentDetail: any;
	@Input() public col: ColumnModel;
	@Output() refreshGrid = new EventEmitter();
	public bulkActivitySubscription: Subscription;

	constructor(
		public userManagementService: UserManagementService,
		public notificationService: NotificationService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {}

	public deleteBulkActivity(id: number): void {
		this.bulkActivitySubscription?.unsubscribe();
		this.bulkActivitySubscription = this.userManagementService
			.deleteBulkActivity(id)
			.subscribe(
				(res: CommonModel) => {
					if (res) {
						this.refreshGrid.emit(true);
						this.notificationService.setMessage(
							res["context_code"],
							res["summary"]
						);
					}
				},
				(err: any) => {
					this.notificationService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);
	}

	public openModal(content, view): void {
		if (view === "deleteView") {
			this.modalService.open(content, {
				centered: true,
				windowClass: "customModal"
			});
		}
	}
}
