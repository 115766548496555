<div class="action-container d-inline-flex align-items-center gap-8">
    <div ngbDropdown class="d-inline-block position-static" display="dynamic">
        <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span><i
                    class="icon icon-Options"></i></span>
        </span>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button [ngClass]="{'cursor-pointer':permissions.write_access}"
                [disabled]="!permissions.write_access || bannerData?.edit_details?.is_editable" class="dropdown-item"
                (click)="openProfile()">
                <i class="icon icon-Bookmark"></i>
                <span translate>Create Tracker</span>
            </button>
            <button [ngClass]="{'cursor-pointer':permissions.write_access}" [disabled]="!permissions.write_access"
                class="dropdown-item" (click)="openNotification()">
                <i class="icon icon-Notification-on"></i>
                <span translate>Create Notification</span>
            </button>
        </div>
    </div>
</div>