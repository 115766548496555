<div class="pie-chart-dropdown">
	<div
		ngbDropdown
		class="d-inline-block position-static"
		display="dynamic"
		#myDrop="ngbDropdown"
	>
		<span
			*ngIf="data.length"
			id="dropdownBasic1"
			ngbDropdownToggle
			class="dropdown-toggle manage-field-box mt-0 cursor-pointer"
		>
			<i class="icon icon-Settings-alt"></i>
		</span>
		<div
			ngbDropdownMenu
			aria-labelledby="dropdownBasic1"
			class="commonDropdown"
		>
			<div class="pieNames">
				<div *ngFor="let item of data; let i = index">
					<label class="checkbox d-flex" [for]="chartName+item.name+item.id">
						<input
							type="checkbox"
							name="checkbox"
							[checked]="selectedData.includes(item.id)"
							[id]="chartName+item.name+item.id"
							(change)="onPieSpliceSelect($event, item.id)"
						/>
						<div class="checkbox-section"><i class="icon icon-Check"></i></div>
						<span class="text" showTooltipOnTextOverflow [data]="item?.name"
							>{{item?.name | translate}}</span
						>
					</label>
				</div>
			</div>
			<div class="button-container">
				<button
					class="btn btn-primary btn-lg"
					(click)="onApply();$event.stopPropagation(); myDrop.close()"
					[disabled]="!isBtnDisabled"
				>
					Apply
				</button>
				<button
					class="btn btn-primary btn-lg"
					(click)="onReset();$event.stopPropagation(); myDrop.close()"
				>
					<i class="icon icon-Refresh"></i>
				</button>
			</div>
		</div>
	</div>
</div>
