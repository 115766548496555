import { Component, Input, OnInit } from '@angular/core';
import { HarmonisationService } from '@ds-private-layouts/configuration/harmonisation/harmonisation.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';
import { NotificationService } from '@ds-shared/common-services/utility-services/notification.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-harmonisation-unmap-action',
  templateUrl: './harmonisation-unmap-action.component.html',
  styleUrls: ['./harmonisation-unmap-action.component.scss']
})
export class HarmonisationUnmapActionComponent implements OnInit {

  @Input() public popupInfo: any;
  private destroy$: Subject<boolean> = new Subject();
  constructor(public modalService: NgbModal, public activeModal: NgbActiveModal, public commonFunctions: CommonFunctionsService, private notificationService: NotificationService, public harmonisationService: HarmonisationService, public dataTransferService: DataTransferService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
