import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-comming-soon',
  templateUrl: './comming-soon.component.html',
  styleUrls: ['./comming-soon.component.scss']
})
export class CommingSoonComponent implements OnInit {
  @Input() heightValue; 
  constructor() { }

  ngOnInit(): void { }

}
