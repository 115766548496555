<div class="d-flex align-items-center transmission-action">
    <!-- <button class="btn icon-only-btn" [disabled]="isProfileUploading || !permissions.write_access"
        (click)="openImportModal()" placement="bottom" ngbTooltip="{{ 'Upload Profiles' | translate }}"
        tooltipClass="info-custom-tooltip">
        <i class="icon icon-Upload"></i>
    </button> -->
    <button class="btn btn-secondary btn-lg" [ngStyle]="buttonStyle" [disabled]="isProfileUploading || !permissions.write_access"
        (click)="openImportModal()"><i class="icon icon-Upload"></i>{{'Import' | translate}}</button>
    <!-- <button [disabled]="profileDownloadProgress.disabled || !permissions.export_access" placement="bottom"
        ngbTooltip="{{ 'Download Profiles' | translate }}" tooltipClass="info-custom-tooltip" class="btn icon-only-btn"
        (click)="onProfilesDownload()">
        <i class="icon icon-Download" [ngClass]="{'grn':profileDownloadProgress.downloaded}"></i>
    </button> -->
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3 class="modal-title" translate>Bulk Import</h3>
        <i class="icon icon-Close2 cursor-pointer" (click)="closeModal()">
        </i>
    </div>
    <div class="modal-body">
        <div class="d-flex gap-16">
            <label translate class="mt-2" translate>Choose File<sup class="super-star">*</sup></label>
            <div class="browser-error-content">
                <div class="browse-cont cursor-pointer" [ngClass]="{'fileError' : errorMessage}">
                    <label for="file-upload1" class="cursor-pointer" (click)="fileInput.click()">
                        <span class="browse-label" *ngIf="!fileToUpload" translate>Browse</span>
                        <span class="browse-label" ttPosition="'top'" [ngbTooltip]="fileToUpload?.name" *ngIf="fileToUpload"
                            showTooltipOnTextOverflow [data]="fileToUpload?.name | translate">{{fileToUpload?.name}}</span>
                    </label>
                    <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="buildImport" id="buildImport" type="file" #fileInput (change)="checkFileSizeExceed($event)">
                </div>
                <div class="errorLable" *ngIf="errorMessage">
                    <i class="icon  icon-Error"></i>
                    <span translate>{{errorMessage}}</span>
                </div>
            </div>
            <ng-template #fileName><span class="osr" translate>{{fileToUpload?.name}}</span></ng-template>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between align-items-center">
        <div class="sample-file-action d-flex align-items-center" [ngClass]="{'disabled':profileDownloadProgress.disabled}">
            <i class="icon icon-Download"></i>
           <a class="cursor-pointer"  (click)="downloadSampleTemplate()" translate>{{listType === 'product_collection' ? 'Download Template' : 'Downlaod Tracker Template'}}</a> 
        </div>
        <div class="actions d-flex justify-content-between">
            <button type="button" class="btn btn-secondary btn-lg" (click)="closeModal()" translate>Cancel</button>
            <button type="button" class="btn btn-primary btn-lg" [disabled]="!fileToUpload || isProfileUploading"
                (click)="onProfilesUpload()" translate>Upload</button>
        </div>
       
    </div>
</ng-template>