import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@ds-common-services/utility-services/notification.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserManagementService } from '@ds-private-layouts/user-management/user-management.service';
import { RolePermissions } from '@ds-models/access-matrix.model';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  @Input() public moduleType: any;
  @Input() public actionData: any;
  @Input() public componentDetail: any;
  @Input() public selectedPage_size:any;
  @Input() public col:any;
  public enableDeactivation;
  public actionType:string;
  public profileList;
  public profileListLoading:boolean = true;
  public deactivateSub: Subscription;
  public deleteSub: Subscription;
  public moveUserSub: Subscription;
  public getProfileSub: Subscription;
  public permissions: RolePermissions = new RolePermissions();
  public selectedDeleteOption:string = 'MoveDelete';
  constructor(private router: Router,
    public userManagementService: UserManagementService, private modalService: NgbModal, private notificationService: NotificationService, private accessMatrixService: AccessMatrixService, private mixpanelService: MixpanelService) {
      this.permissions = this.accessMatrixService.getPermissionByRoute();
     }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
  }

  check(){
    if(this.actionData){
      if(this.actionData.attributes.is_active == true){
        this.enableDeactivation = true;
      }else{
        this.enableDeactivation = false;
      }
    }
  }

  editUser(action) {
    this.actionType = action;
    this.router.navigate([`/user-management/team-members/edit/${this.actionData.id}`]);
  }

  deactivateUser() {
    let userActivationData = {
      id: this.actionData.id,
      email:this.actionData.attributes.email,
      is_active:this.actionData.attributes.is_active == true ? false :true
    }
    this.deactivateSub?.unsubscribe();
    this.deactivateSub = this.userManagementService.activateDeactivateTeamMember(userActivationData).subscribe((res:any) => {
      if (res) {
        this.mixpanelService.track('User Deactivated',{});
        this.notificationService.setMessage(res['context_code'], res['summary']);
        this.refreshGridAction();
      }
    }, (err: any) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    }
    );
  }
  
  onConfirmDeleteUser(){
    if(this.selectedDeleteOption === 'MoveDelete'){
      this.moveUserSub?.unsubscribe();
      const obj = { id : this.actionData.id}
      this.moveUserSub = this.userManagementService.moveProfile(obj).subscribe((res:any) => {
        if (res) {
          this.notificationService.setMessage(res['context_code'], res['summary']);
          this.deleteUser();
        }
      }, (err: any) => {
        this.notificationService.setMessage(
          err?.error?.context_code,
          err?.error?.summary
        );
      }
      );
    }
    else if(this.selectedDeleteOption === 'Delete'){
      this.deleteUser();
    }
  }

  deleteUser(){
    this.deleteSub?.unsubscribe();
    this.deleteSub = this.userManagementService.deleteTeamMember(this.actionData.id).subscribe((res:any) => {
      if (res) {
        this.mixpanelService.track('User deleted',{});
        this.notificationService.setMessage(res['context_code'], res['summary']);
        this.refreshGridAction();
      }
    }, (err: any) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    }
    );
  }

  openModal(content) {
    this.modalService.open(content, { centered: true, windowClass: "customModal" });
  }

  getProfiles(){
    this.profileListLoading = true;
    this.getProfileSub?.unsubscribe();
    this.getProfileSub = this.userManagementService.getUserProfileList(this.actionData.id).subscribe((res:any) => {
      if (res) {
        this.profileList = res['data'];
        if(!this.profileList) this.selectedDeleteOption = 'Delete'
        this.profileListLoading = false;
      }
    }, (err: any) => {
      this.profileList = null;
      this.profileListLoading = false;
    }
    );
  }

  refreshGridAction() {
    this.componentDetail.refreshData();
  }

  getPermissionStatus(permission) {
    return (permission.full_access || permission.write_access);
  }

  onDeleteOptionSelect(option){
    this.selectedDeleteOption = option;
  }

  ngOnDestroy(): void {
    if (this.deactivateSub) {
      this.deactivateSub.unsubscribe();
    }
    if (this.deleteSub)
    this.deleteSub?.unsubscribe();

    if (this.moveUserSub)
    this.moveUserSub?.unsubscribe();

    if (this.getProfileSub)
    this.getProfileSub?.unsubscribe();
  }

}

