import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DocumentCollection } from "ngx-jsonapi";
import { Subject, filter } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import {
  ProfileScorecard, ProfileInformationService, CustomerScrappedProducts
} from "../profile-information.service";
import { takeUntil,Subscription } from 'rxjs';
import { ColumnModel, CommonSearchModel, CurrencyTemplate, ModuleType } from "@ds-shared/models/common.model";
import { COL, PAYLOAD } from "@ds-shared/enums/common.enum";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import moment from 'moment';

@Component({
  selector: "app-scraped-products",
  templateUrl: "./scraped-products.component.html",
  styleUrls: ["./scraped-products.component.scss"],
})
export class ScrapedProductsComponent implements OnInit, OnChanges {
  public gridTitle: string = "";
  private _scorecardInfo: any;
  get scorecardInfo(): any {
    return this._scorecardInfo;
  }
  @Input() set scorecardInfo(value: any) {
    this._scorecardInfo = value;
    if (this.scorecardInfo) {
      if (this.scorecardInfo.view === "profile") this.gridTitle = `Products tracked under selected ${this.scorecardInfo.type}`;
      else if (this.scorecardInfo.view === "harmonised") this.gridTitle = `Products mapped under selected harmonised ${this.scorecardInfo.type}`;
      else this.gridTitle = `Products under selected ${this.scorecardInfo.type}`;
      this.resetData();
      this.initData();
    }
  }
  @Input() currencyDetails: CurrencyTemplate;
  public scrappedProducts: DocumentCollection<ProfileScorecard>;
  public cols: ColumnModel[];
  public NoData: boolean = false;
  public sort: string[] = [];
  public loadingData: boolean = false;
  public page: number = 1;
  public pageSize: number;
  public customerProdCount: any;
  public itemPerPage: number = 10;
  public moduleType: ModuleType = {
    name: "scrapped-product",
    style: {
      tableStyle: {
        height: '21.5rem'
      },
      tableWrapper: {
        'border-radius': '0rem'
      },
      noDataStyle: {
        height: '18.375rem',
      },
      paginationStyle: {
        'border': 'none',
        'padding': '1rem 0rem 0rem 0rem'
      }
    },
    pagination: {
      show: true
    }
  };
  private $destroy: Subject<boolean> = new Subject();
  public searchObj: CommonSearchModel = {
    text: '',
    placeholder: 'Search Product',
    style: { width: '19rem' }
  }
	public clearLastCall: Subscription;
  public exportDetails = {
    fileName:'',
    path:'scorecard/export-scrapped-products',
    params:{}
  }
  public scorecardName = '';
  public maxLengthToshow = 8;
  public productFilter: { name: string, code: string }[] = [];
  public selectedFilter: { name: string, code: string };
  @Input() permittedLocalFilter;

  // @Input() optionalParams;
  private _localFilters;
   get localFilters() {
     return this._localFilters;
   }
   @Input() set optionalFilters(value) {
     this._localFilters = value;
     if(this.permittedLocalFilter){if (this.permittedLocalFilter.some(item => { return this._localFilters[item] })) {
       this.getScrappedProducts(1);
     }
    }
   }
   private _dateFilter: any;
   get dateFilter(): any {
     return this._dateFilter;
   }
   @Input() set dateFilter(value: any) {
     this._dateFilter = value;
     if (this.scorecardInfo && this.dateFilter) {
      this.getScrappedProducts(1);
     }
   }
  
  private _miscParams: any = {};
  get miscParams(): any {
    return this._miscParams;
  }
  @Input() set miscParams(value: any) {
    this._miscParams = value;
    if (this.scorecardInfo && Object.keys(this.miscParams).length) {
      this.getScrappedProducts(1);
    }
  }
  constructor(
    private dashboardService: ProfileInformationService,
    private scrappedProductService: CustomerScrappedProducts,
    private route: ActivatedRoute,
    public commonFunctions: CommonFunctionsService,
    private dataTransferService: DataTransferService
  ) {
    dashboardService.register();
    scrappedProductService.register();
      // TO get the name of particular scorecard tracker, so we can bind it with share file name
      this.dataTransferService.scorecardBreadcrumb$.pipe(filter(res=>res), takeUntil(this.$destroy)).subscribe((res)=>{
        this.scorecardName = res;
        this.makeExportFileName();
      })
    this.getProductFilters();
    this.dataTransferService.variantMappingUpdated$.pipe(filter(res => res), takeUntil(this.$destroy)).subscribe((res) => {
      this.page = 1;
      this.initData();
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.currencyDetails?.currentValue?.id) {
      this.setCols();
    }
  }

  ngOnInit(): void {
    this.setCols();
    this.getScrappedProducts(1);

  }

  private makeExportFileName(): void{
    const dConfig = this.getDateConfig();
    const exportName = {
      "all": 'all',
      "dropped_price" : "dropped_price",
      "overpriced" : "overpriced",
      "underpriced" : "underpriced",
      "poor_ratings" : "poor_ratings",
      "less_engagement" : "less_engagement",
      "high_engagement" : "high_engagement",
      "more_discount" : "increased_discount",
      "increased_ratings" : 'increased_ratings',
      "new_vouchers" : 'new_vouchers',
      "low_content" : 'low_content_quality',
      "neg_sentiment": 'negative_sentiment',
      "new_launches" : "newly_tracked"
    }
    const mapTypeName = exportName[this.selectedFilter.code];
    this.exportDetails.fileName = 
    this.commonFunctions.getDynamicExportFileName(
      this.commonFunctions.getExportDateFormat(this.dateFilter),
      `${this.scorecardName}_${this.commonFunctions.makeTitlecase(mapTypeName.split('_').join(' '))}_${this.gridTitle.split(' ').join('_')}`
    );

		// if(this.isCustomDate){
		// 	this.exportDetails.fileName =
		// 	this.commonFunctions.getDynamicExportFileName(
		// 		this.commonFunctions.getExportDateFormat(this.dateFilter),
		// 		`${this.scorecardName}_${this.commonFunctions.makeTitlecase(mapTypeName.split('_').join(' '))}_Products_Tracked_under_Selected_${this.commonFunctions.makeTitlecase(this.scorecardInfo?.type)}`
		// 	);
		// }
		// else 
		// this.exportDetails.fileName =
		// 	this.commonFunctions.getDynamicExportFileName(
		// 		this.getExportDateFormat(dConfig),
		// 		`${this.scorecardName}_${this.commonFunctions.makeTitlecase(mapTypeName.split('_').join(' '))}_Products_Tracked_under_Selected_${this.commonFunctions.makeTitlecase(this.scorecardInfo?.type)}`
		// 	);
  }

  getExportDateFormat(date) {
		if (!date.startDate && !date.endDate) return;
		if (date.startDate === date.endDate)
			return date.startDate.split("-").join("");
		return `${date.startDate.split("-").join("")}_${date.endDate
			.split("-")
			.join("")}`;
	}
  
  public onPageChange(eve: number): void {
    this.page = eve;
    this.getScrappedProducts(this.page);
  }
  public onPageSizeChange(eve: number): void {
    this.pageSize = eve;
    this.itemPerPage = eve;
    this.getScrappedProducts(1);
  }

  private initData(keepCurrentPage?: boolean): void {
    if (keepCurrentPage) this.getScrappedProducts(this.page)
    else
      this.getScrappedProducts(1);
  }

  public setCols(): void {
    this.cols = [
      {
        id: 1,
        name: COL.PRODUCT_NAME,
        map: "attributes.name",
        sort: true,
        method: "productImage",
        component: "common-mapping",
        class: "",
        hasRedirect: true,
        isChecked: true,
        meta: { "marketplaceLink": true },
        style: {
          th: {
            'min-width': '9.375rem',
            'max-width': '15.625rem',
          },
          td: {
            'min-width': '9.375rem',
            'max-width': '15.625rem',
          }
        }
      },
      {
        id: 2,
        name: COL.PRICE,
        map: "attributes.price",
        sort: true,
        method: "pricewithDiscountNewView",
        component: "common-mapping",
        class: "font-medium",
        hasRedirect: true,
        isChecked: true,
        style: {
          th: {
            'min-width': '6rem',
          },
          td: {
            'min-width': '6rem',
            'font-size': '0.8125rem',
          },
          'th-class': 'text-right',
          'td-class': 'text-right'
        },
        meta:{
					isOwnCurrencyAvailable: true,
					currencyMap: 'attributes.currency.symbol'
				}
      },
      {
        id: 3,
        name: "Tags",
        map: "attributes.tags",
        sort: false,
        method: "filterCriteriaView",
        component: "common-mapping",
        class: "",
        hasRedirect: false,
        isChecked: false,
        style: {
          th: {
            'min-width': '13.5rem'
          },
          td: {
            'min-width': '13.5rem'
          }
        }
      },
      {
				id: 5,
				name: COL.QUANTITY_SOLD,
				map: "attributes.qsold",
				sort: true,
				method: "countView",
				component: "common-mapping",
				class: "font-medium",
				hasRedirect: true,
				isChecked: false,
				style: {
					'th-class': 'text-right',
					'td-class': 'text-right'
				}
			},
      {
        id: 6,
        name: COL.GMV,
        map: "attributes.gmv",
        sort: true,
        method: "priceView",
        component: "common-mapping",
        class: "font-medium",
        hasRedirect: true,
        isChecked: false,
        style: {
          'th-class': 'text-right',
          'td-class': 'text-right'
        }
      },
      {
        id: 7,
        name: COL.STORE,
        map: "attributes.store",
        sort: true,
        method: "storewithSegmentView",
        component: "common-mapping",
        class: "ellipsis",
        hasRedirect: true,
        isChecked: true,
				meta: {"marketplaceLink": true},
        style: {
          th: {
            'min-width': '6.5rem',
            'max-width': '8.625rem'
          },
          td: {
            'min-width': '6.5rem',
            'max-width': '8.625rem'
          }
        }
      },
      {
        id: 8,
        name: COL.CATEGORY,
        map: "attributes.category",
        sort: true,
        method: "defaultView",
        component: "common-mapping",
        class: "ellipsis",
        hasRedirect: true,
        isChecked: true,
        style: {
          th: {
            'min-width': '7.5rem',
            'max-width': '10.5rem',
          },
          td: {
            'min-width': '7.5rem',
            'max-width': '10.5rem',
          }
        }
      },
      {
        id: 9,
        name: COL.BRAND,
        map: "attributes.brand",
        sort: true,
        method: "defaultView",
        component: "common-mapping",
        class: "ellipsis",
        hasRedirect: true,
        isChecked: false,
        style: {
          th: {
            'min-width': '6.5rem',
            'max-width': '8.625rem'
          },
          td: {
            'min-width': '6.5rem',
            'max-width': '8.625rem'
          }
        }
      },
      {
        id: 10,
        name: COL.RETAIL_CHANNEL,
        map: "attributes.marketplace",
        sort: true,
        method: "logoFormat",
        component: "common-mapping",
        class: "",
        hasRedirect: false,
        isChecked: true,
      },
      {
        id: 11,
        name: COL.RATINGS,
        map: "attributes.rating",
        sort: true,
        method: "ratingView",
        component: "common-mapping",
        class: "",
        hasRedirect: false,
        isChecked: false,
        style: {
          th: {
            'min-width': '7rem',
          },
          td: {
            'min-width': '7rem',
          }
        }
      },
      {
        id: 12,
        name: COL.LIKES,
        map: "attributes.likes",
        meta: { includeZero: true },
        sort: true,
        method: "countView",
        component: "common-mapping",
        class: "font-medium",
        hasRedirect: true,
        isChecked: false,
        style: {
          th: {
            'min-width': '5.5rem'
          },
          td: {
            'min-width': '5.5rem'
          },
          'th-class': 'text-right',
          'td-class': 'text-right'
        }
      },
      {
        id: 14,
        name: COL.LAST_UPDATED,
        map: "attributes.updated_at",
        sort: true,
        method: "dateFormat",
        component: "common-mapping",
        class: "",
        hasRedirect: false,
        isChecked: true,
        style: {
          th: {
            'min-width': '11.25rem'
          },
          td: {
            'min-width': '11.25rem'
          }
        }
      },
      {
        id: 15,
        name: "",
        map: "",
        meta: { type: 'products' },
        sort: false,
        method: "",
        component: "common-grid-action",
        class: "",
        hasRedirect: false,
        isChecked: true,
        style: {
          th: {
            'min-width': '3.125rem'
          },
          td: {
            'min-width': '3.125rem'
          },
          'th-class': "text-right",
          'td-class': "text-right",
        }
      }
    ]
    if(this.selectedFilter.code === "dropped_price") {
        this.cols.splice(1,1, {
            id: 0,
            name: COL.LAST_PRICE,
            map: "attributes.last_different_price",
            sort: true,
            method: "priceView",
            component: "common-mapping",
            class: "font-medium",
            hasRedirect: true,
            isChecked: true,
            style: {
              'th-class': 'text-right',
              'td-class': 'text-right'
            },
            meta:{
              isOwnCurrencyAvailable: true,
              currencyMap: 'attributes.currency.symbol',
              noOfDays: "attributes.days_since_last_price"
            },
        },
        {
          id: 2,
          name: COL.PRICE,
          map: "attributes.price",
          sort: true,
          method: "priceView",
          component: "common-mapping",
          class: "font-medium",
          hasRedirect: true,
          isChecked: true,
          style: {
            th: {
              'min-width': '6rem',
            },
            td: {
              'min-width': '6rem',
              'font-size': '0.8125rem',
            },
            'th-class': 'text-right',
            'td-class': 'text-right'
          },
          meta:{
            isOwnCurrencyAvailable: true,
            currencyMap: 'attributes.currency.symbol'
          }
        },
        {
          id: 2,
          name: COL.DISCOUNT,
          map: "attributes.discount_new",
          sort: true,
          method: "discountView",
          component: "common-mapping",
          class: "",
          hasRedirect: false,
          isChecked: true,
          style: {
            th: {
              'min-width': '8rem',
            },
            td: {
              'min-width': '8rem',
            }
          }
        },)
      }
      else if (this.selectedFilter.code === "more_discount") {
        this.cols.splice(1,1, 
          {
            id: 0,
            name: COL.PRICE,
            map: "attributes.price",
            sort: true,
            method: "priceView",
            component: "common-mapping",
            class: "font-medium",
            hasRedirect: true,
            isChecked: true,
            style: {
              th: {
                'min-width': '6rem',
              },
              td: {
                'min-width': '6rem',
                'font-size': '0.8125rem',
              },
              'th-class': 'text-right',
              'td-class': 'text-right'
            },
            meta:{
              isOwnCurrencyAvailable: true,
              currencyMap: 'attributes.currency.symbol'
            }
          },
          {
          id: 1,
          name: COL.LAST_DISCOUNT,
          map: "attributes.last_different_discount",
          sort: true,
          method: "discountView",
          component: "common-mapping",
          class: "font-medium",
          hasRedirect: true,
          isChecked: true,
          style: {
            'th-class': 'text-right',
            'td-class': 'text-right'
          },
          meta:{
            noOfDays: "attributes.days_since_last_discount"
          },
      },
      {
        id: 3,
        name: COL.DISCOUNT,
        map: "attributes.discount_new",
        sort: true,
        method: "discountView",
        component: "common-mapping",
        class: "",
        hasRedirect: false,
        isChecked: true,
        style: {
          th: {
            'min-width': '8rem',
          },
          td: {
            'min-width': '8rem',
          }
        }
      }
      )
      }
    if (this.scorecardInfo.view === "harmonised" && this.scorecardInfo.type === "product") {
      const pos = this.cols.map(e => e.name).indexOf('Last Updated');
      this.cols.splice(pos, 0, {
        id: 0,
        name: "Variants",
        map: "attributes.variant_count",
        sort: true,
        method: "variant-data",
        component: "common-mapping",
        class: "variant-data",
        hasRedirect: true,
        isChecked: true,
      })
    }
    if (this.scorecardInfo.view === "profile") {
      const newFields: ColumnModel[] = [
        {
          id: 5,
          name: "Rank",
          map: "attributes.rank",
          sort: true,
          method: "countView",
          component: "common-mapping",
          class: "font-medium",
          hasRedirect: true,
          isChecked: true,
          style: {
            th: {
              'min-width': '5.5rem'
            },
            td: {
              'min-width': '5.5rem'
            },
            'th-class': 'text-right',
            'td-class': 'text-right'
          }
        }
      ]
      if (this.scorecardInfo.type === "keyword") {
        newFields.push(
          {
            id: 4,
            name: "Is Sponsored",
            map: "attributes.is_sponsored",
            sort: true,
            method: "booleanYesNoBadge",
            component: "common-mapping",
            class: "",
            hasRedirect: false,
            isChecked: true,
            meta: {
              badgeType: {
                true: 'badge-active-light',
                false: 'badge-red',
                null: 'badge-red'
              },
              booleanMapping: {
                true: "Yes",
                false: "No",
                null: "No"
              }
            }
          }
        )
        if (this.currencyDetails?.iso_code === "KRW") {
          newFields.push(
            {
              id: 5,
              name: "Sponsored Type",
              map: "attributes.sponsor_type",
              sort: true,
              method: "defaultView",
              component: "common-mapping",
              class: "font-medium",
              hasRedirect: true,
              isChecked: true,
              style: {
                th: {
                  'min-width': '5.5rem'
                },
                td: {
                  'min-width': '5.5rem'
                },
              }
            },
            {
              id: 5,
              name: "Sponsored Text",
              map: "attributes.sponsor_text",
              sort: true,
              method: "defaultView",
              component: "common-mapping",
              class: "font-medium",
              hasRedirect: true,
              isChecked: true,
              style: {
                th: {
                  'min-width': '5.5rem'
                },
                td: {
                  'min-width': '5.5rem'
                },
              }
            }
          )
        }
      }
      const pos = this.cols.map(e => e.name).indexOf('Tags');
      this.cols.splice(pos + 1, 0, ...newFields)
    }
    this.cols = this.cols.map((item, idx) => {
      return { ...item, id: idx + 1 };
    })
  }

  private resetData(): void {
    this.moduleType = { ...this.moduleType };
    this.searchObj = { ...this.searchObj, text: '' };
    this.sort = [];
    this.setCols();
  }

  private getScrappedProducts(page: number): void {
    this.scrappedProducts = new DocumentCollection();
    let param: any = {};
    param[`${PAYLOAD.SEARCH}`] = this.searchObj.text?.trim();
    param['scorecard_type'] = this.scorecardInfo.type;
    param[`${this.scorecardInfo.view}_id`] = this.scorecardInfo.id;
    param['tag'] = this.selectedFilter.code;
    param['start_date'] = this.dateFilter?.startDate;
    param['end_date'] = this.dateFilter?.endDate;
    param = { ...param, ...this.miscParams };
    if(this.permittedLocalFilter){this.permittedLocalFilter.forEach(item => {
			param[this.localFilters?.[item]?.payloadString] = this.commonFunctions.getMappedData(this.localFilters?.[item]?.selected, this.localFilters?.[item]?.meta?.key);
		})}
    this.makeExportFileName();
    param = this.commonFunctions.filterPayload(param);
    this.exportDetails.params = {
      ...param,
      sort: this.sort,
      currency_id: this.currencyDetails.id
    };
    this.exportDetails.params = this.commonFunctions.filterPayload(this.exportDetails.params)
    this.clearLastCall?.unsubscribe();
		this.clearLastCall = this.scrappedProductService
      .all({
        page: { number: page || 1, size: this.pageSize || 10 },
        remotefilter: param,
        sort: this.sort,
        beforepath: "scorecard",
      }).pipe(takeUntil(this.$destroy))
      .subscribe(
        (scrappedProducts) => {
          this.loadingData = true;
          if (
            scrappedProducts.source == "server" &&
            scrappedProducts.is_loading == false
          ) {
            if (scrappedProducts.data.length == 0) {
              this.NoData = true;
              this.loadingData = true;
            } else {
              this.loadingData = false;
              this.NoData = false;
              this.scrappedProducts = scrappedProducts;
              this.exportDetails.params = this.commonFunctions.filterPayload({...this.exportDetails.params, currency_id: this.currencyDetails?.id})
            }
          } else {
            this.loadingData = true;
            this.NoData = false;
          }
        },
        (error): void => {
          this.loadingData = true;
          this.NoData = true;
        }
      );
  }

  changeFilter(filter: any) {
    this.page = 1;
    this.selectedFilter = filter;
   this.resetData();
    this.setCols();
    this.initData();
  }

  public sortData(data: string[]): void {
    this.sort = data;
    this.initData(true);
  }

  public onSearch(text: string): void {
    this.searchObj = { ...this.searchObj, text: text };
    this.initData();
  }

  getProductFilters() {
    this.productFilter = [
      {
        name: "All",
        code: "all"
      },
      {
        name: "Dropped Price",
        code: "dropped_price"
      },
      {
        name: "Overpriced",
        code: "overpriced"
      },
      {
        name: "Underpriced",
        code: "underpriced"
      },
      {
        name: "Negative Sentiment",
        code: "neg_sentiment"
      },
      {
        name: "Poor Ratings",
        code: "poor_ratings"
      },
      {
        name: "Low Content Quality",
        code: "low_content"
      },
      {
        name: "Less Engagement",
        code: "less_engagement"
      },
      {
        name: "High Engagement",
        code: "high_engagement"
      },
      {
        name: "Increased Discount",
        code: "more_discount"
      },
      {
        name: "Increased Ratings",
        code: "increased_ratings"
      },
      {
        name: "New Vouchers Introduced",
        code: "new_vouchers"
      },
      {
        name: "Newly Tracked",
        code: "new_launches"
      },
    ]
    this.selectedFilter = { ...this.productFilter[0] };
  }
  public getDisplayDate(date){
    return this.getDisplayDateString(moment(date))
  }
  private getDisplayDateString(momentDate) {
    if (momentDate) return momentDate.format('MMM Do YYYY');
  }

  private getDateConfig(): { startDate; endDate } {
		const dateConfig = {
			startDate:this.dateFilter?.startDate,
			endDate:this.dateFilter?.endDate
		};
		return dateConfig;
	}

  public ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }
}
