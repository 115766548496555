/*
 Highcharts JS v10.3.3 (2023-01-20)

 (c) 2014-2021 Highsoft AS
 Authors: Jon Arild Nygard / Oystein Moseng

 License: www.highcharts.com/license
*/
(function (a) {
  "object" === typeof module && module.exports ? (a["default"] = a, module.exports = a) : "function" === typeof define && define.amd ? define("highcharts/modules/treemap", ["highcharts"], function (u) {
    a(u);
    a.Highcharts = u;
    return a;
  }) : a("undefined" !== typeof Highcharts ? Highcharts : void 0);
})(function (a) {
  function u(a, e, b, m) {
    a.hasOwnProperty(e) || (a[e] = m.apply(null, b), "function" === typeof CustomEvent && window.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: e,
        module: a[e]
      }
    })));
  }
  a = a ? a._modules : {};
  u(a, "Series/ColorMapComposition.js", [a["Core/Series/SeriesRegistry.js"], a["Core/Utilities.js"]], function (a, e) {
    var b = a.seriesTypes.column.prototype,
      m = e.addEvent,
      l = e.defined,
      n;
    (function (a) {
      function e(c) {
        this.moveToTopOnHover && this.graphic && this.graphic.attr({
          zIndex: c && "hover" === c.state ? 1 : 0
        });
      }
      var f = [];
      a.pointMembers = {
        dataLabelOnNull: !0,
        moveToTopOnHover: !0,
        isValid: function () {
          return null !== this.value && Infinity !== this.value && -Infinity !== this.value && (void 0 === this.value || !isNaN(this.value));
        }
      };
      a.seriesMembers = {
        colorKey: "value",
        axisTypes: ["xAxis", "yAxis", "colorAxis"],
        parallelArrays: ["x", "y", "value"],
        pointArrayMap: ["value"],
        trackerGroups: ["group", "markerGroup", "dataLabelsGroup"],
        colorAttribs: function (c) {
          var h = {};
          !l(c.color) || c.state && "normal" !== c.state || (h[this.colorProp || "fill"] = c.color);
          return h;
        },
        pointAttribs: b.pointAttribs
      };
      a.compose = function (c) {
        var h = c.prototype.pointClass;
        -1 === f.indexOf(h) && (f.push(h), m(h, "afterSetState", e));
        return c;
      };
    })(n || (n = {}));
    return n;
  });
  u(a, "Series/Treemap/TreemapAlgorithmGroup.js", [], function () {
    return function () {
      function a(a, b, m, l) {
        this.height = a;
        this.width = b;
        this.plot = l;
        this.startDirection = this.direction = m;
        this.lH = this.nH = this.lW = this.nW = this.total = 0;
        this.elArr = [];
        this.lP = {
          total: 0,
          lH: 0,
          nH: 0,
          lW: 0,
          nW: 0,
          nR: 0,
          lR: 0,
          aspectRatio: function (a, e) {
            return Math.max(a / e, e / a);
          }
        };
      }
      a.prototype.addElement = function (a) {
        this.lP.total = this.elArr[this.elArr.length - 1];
        this.total += a;
        0 === this.direction ? (this.lW = this.nW, this.lP.lH = this.lP.total / this.lW, this.lP.lR = this.lP.aspectRatio(this.lW, this.lP.lH), this.nW = this.total / this.height, this.lP.nH = this.lP.total / this.nW, this.lP.nR = this.lP.aspectRatio(this.nW, this.lP.nH)) : (this.lH = this.nH, this.lP.lW = this.lP.total / this.lH, this.lP.lR = this.lP.aspectRatio(this.lP.lW, this.lH), this.nH = this.total / this.width, this.lP.nW = this.lP.total / this.nH, this.lP.nR = this.lP.aspectRatio(this.lP.nW, this.nH));
        this.elArr.push(a);
      };
      a.prototype.reset = function () {
        this.lW = this.nW = 0;
        this.elArr = [];
        this.total = 0;
      };
      return a;
    }();
  });
  u(a, "Series/DrawPointUtilities.js", [a["Core/Utilities.js"]], function (a) {
    return {
      draw: function (a, b) {
        var e = b.animatableAttribs,
          l = b.onComplete,
          n = b.css,
          q = b.renderer,
          k = a.series && a.series.chart.hasRendered ? void 0 : a.series && a.series.options.animation,
          f = a.graphic;
        b.attribs = b.attribs || {};
        b.attribs["class"] = a.getClassName();
        if (a.shouldDraw()) f || (a.graphic = f = "text" === b.shapeType ? q.text() : q[b.shapeType](b.shapeArgs || {}), f.add(b.group)), n && f.css(n), f.attr(b.attribs).animate(e, b.isNew ? !1 : k, l);else if (f) {
          var c = function () {
            a.graphic = f = f && f.destroy();
            "function" === typeof l && l();
          };
          Object.keys(e).length ? f.animate(e, void 0, function () {
            return c();
          }) : c();
        }
      }
    };
  });
  u(a, "Series/Treemap/TreemapPoint.js", [a["Series/DrawPointUtilities.js"], a["Core/Series/SeriesRegistry.js"], a["Core/Utilities.js"]], function (a, e, b) {
    var m = this && this.__extends || function () {
        var a = function (c, b) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, c) {
            a.__proto__ = c;
          } || function (a, c) {
            for (var g in c) c.hasOwnProperty(g) && (a[g] = c[g]);
          };
          return a(c, b);
        };
        return function (c, b) {
          function h() {
            this.constructor = c;
          }
          a(c, b);
          c.prototype = null === b ? Object.create(b) : (h.prototype = b.prototype, new h());
        };
      }(),
      l = e.series.prototype.pointClass,
      n = e.seriesTypes;
    e = n.pie.prototype.pointClass;
    var q = b.extend,
      k = b.isNumber,
      f = b.pick;
    b = function (c) {
      function h() {
        var a = null !== c && c.apply(this, arguments) || this;
        a.name = void 0;
        a.node = void 0;
        a.options = void 0;
        a.series = void 0;
        a.shapeType = "rect";
        a.value = void 0;
        return a;
      }
      m(h, c);
      h.prototype.draw = function (c) {
        a.draw(this, c);
      };
      h.prototype.getClassName = function () {
        var a = l.prototype.getClassName.call(this),
          c = this.series,
          h = c.options;
        this.node.level <= c.nodeMap[c.rootNode].level ? a += " highcharts-above-level" : this.node.isLeaf || f(h.interactByLeaf, !h.allowTraversingTree) ? this.node.isLeaf || (a += " highcharts-internal-node") : a += " highcharts-internal-node-interactive";
        return a;
      };
      h.prototype.isValid = function () {
        return !(!this.id && !k(this.value));
      };
      h.prototype.setState = function (a) {
        l.prototype.setState.call(this, a);
        this.graphic && this.graphic.attr({
          zIndex: "hover" === a ? 1 : 0
        });
      };
      h.prototype.shouldDraw = function () {
        return k(this.plotY) && null !== this.y;
      };
      return h;
    }(n.scatter.prototype.pointClass);
    q(b.prototype, {
      setVisible: e.prototype.setVisible
    });
    return b;
  });
  u(a, "Series/Treemap/TreemapUtilities.js", [a["Core/Utilities.js"]], function (a) {
    var e = a.objectEach,
      b;
    (function (a) {
      function b(a, e, k) {
        void 0 === k && (k = this);
        a = e.call(k, a);
        !1 !== a && b(a, e, k);
      }
      a.AXIS_MAX = 100;
      a.isBoolean = function (a) {
        return "boolean" === typeof a;
      };
      a.eachObject = function (a, b, k) {
        k = k || this;
        e(a, function (e, c) {
          b.call(k, e, c, a);
        });
      };
      a.recursive = b;
    })(b || (b = {}));
    return b;
  });
  u(a, "Series/TreeUtilities.js", [a["Core/Color/Color.js"], a["Core/Utilities.js"]], function (a, e) {
    function b(a, h) {
      var c = h.before,
        e = h.idRoot,
        v = h.mapIdToNode[e],
        g = h.points[a.i],
        r = g && g.options || {},
        w = [],
        x = 0;
      a.levelDynamic = a.level - (!1 !== h.levelIsConstant ? 0 : v.level);
      a.name = f(g && g.name, "");
      a.visible = e === a.id || !0 === h.visible;
      "function" === typeof c && (a = c(a, h));
      a.children.forEach(function (g, c) {
        var r = m({}, h);
        m(r, {
          index: c,
          siblings: a.children.length,
          visible: a.visible
        });
        g = b(g, r);
        w.push(g);
        g.visible && (x += g.val);
      });
      c = f(r.value, x);
      a.visible = 0 <= c && (0 < x || a.visible);
      a.children = w;
      a.childrenTotal = x;
      a.isLeaf = a.visible && !x;
      a.val = c;
      return a;
    }
    var m = e.extend,
      l = e.isArray,
      n = e.isNumber,
      q = e.isObject,
      k = e.merge,
      f = e.pick;
    return {
      getColor: function (c, h) {
        var b = h.index,
          e = h.mapOptionsToLevel,
          v = h.parentColor,
          g = h.parentColorIndex,
          r = h.series,
          w = h.colors,
          x = h.siblings,
          k = r.points,
          l = r.chart.options.chart,
          m;
        if (c) {
          k = k[c.i];
          c = e[c.level] || {};
          if (e = k && c.colorByPoint) {
            var q = k.index % (w ? w.length : l.colorCount);
            var n = w && w[q];
          }
          if (!r.chart.styledMode) {
            w = k && k.options.color;
            l = c && c.color;
            if (m = v) m = (m = c && c.colorVariation) && "brightness" === m.key && b && x ? a.parse(v).brighten(b / x * m.to).get() : v;
            m = f(w, l, n, m, r.color);
          }
          var A = f(k && k.options.colorIndex, c && c.colorIndex, q, g, h.colorIndex);
        }
        return {
          color: m,
          colorIndex: A
        };
      },
      getLevelOptions: function (a) {
        var c = {};
        if (q(a)) {
          var b = n(a.from) ? a.from : 1;
          var e = a.levels;
          var v = {};
          var g = q(a.defaults) ? a.defaults : {};
          l(e) && (v = e.reduce(function (a, c) {
            if (q(c) && n(c.level)) {
              var r = k({}, c);
              var h = f(r.levelIsConstant, g.levelIsConstant);
              delete r.levelIsConstant;
              delete r.level;
              c = c.level + (h ? 0 : b - 1);
              q(a[c]) ? k(!0, a[c], r) : a[c] = r;
            }
            return a;
          }, {}));
          e = n(a.to) ? a.to : 1;
          for (a = 0; a <= e; a++) c[a] = k({}, g, q(v[a]) ? v[a] : {});
        }
        return c;
      },
      setTreeValues: b,
      updateRootId: function (a) {
        if (q(a)) {
          var c = q(a.options) ? a.options : {};
          c = f(a.rootNode, c.rootId, "");
          q(a.userOptions) && (a.userOptions.rootId = c);
          a.rootNode = c;
        }
        return c;
      }
    };
  });
  u(a, "Extensions/Breadcrumbs.js", [a["Core/Chart/Chart.js"], a["Core/Defaults.js"], a["Core/Globals.js"], a["Core/Utilities.js"], a["Core/FormatUtilities.js"]], function (a, e, b, m, l) {
    var n = l.format;
    l = m.addEvent;
    var q = m.objectEach,
      k = m.extend,
      f = m.fireEvent,
      c = m.merge,
      h = m.pick,
      u = m.defined,
      E = m.isString;
    k(e.defaultOptions.lang, {
      mainBreadcrumb: "Main"
    });
    e = function () {
      function a(g, r) {
        this.group = void 0;
        this.list = [];
        this.elementList = {};
        this.isDirty = !0;
        this.level = 0;
        this.options = void 0;
        r = c(g.options.drilldown && g.options.drilldown.drillUpButton, a.defaultBreadcrumbsOptions, g.options.navigation && g.options.navigation.breadcrumbs, r);
        this.chart = g;
        this.options = r || {};
      }
      a.prototype.updateProperties = function (a) {
        this.setList(a);
        this.setLevel();
        this.isDirty = !0;
      };
      a.prototype.setList = function (a) {
        this.list = a;
      };
      a.prototype.setLevel = function () {
        this.level = this.list.length && this.list.length - 1;
      };
      a.prototype.getLevel = function () {
        return this.level;
      };
      a.prototype.getButtonText = function (a) {
        var c = this.chart,
          g = this.options,
          b = c.options.lang,
          e = h(g.format, g.showFullPath ? "{level.name}" : "\u2190 {level.name}");
        b = b && h(b.drillUpText, b.mainBreadcrumb);
        a = g.formatter && g.formatter(a) || n(e, {
          level: a.levelOptions
        }, c) || "";
        (E(a) && !a.length || "\u2190 " === a) && u(b) && (a = g.showFullPath ? b : "\u2190 " + b);
        return a;
      };
      a.prototype.redraw = function () {
        this.isDirty && this.render();
        this.group && this.group.align();
        this.isDirty = !1;
      };
      a.prototype.render = function () {
        var a = this.chart,
          c = this.options;
        !this.group && c && (this.group = a.renderer.g("breadcrumbs-group").addClass("highcharts-no-tooltip highcharts-breadcrumbs").attr({
          zIndex: c.zIndex
        }).add());
        c.showFullPath ? this.renderFullPathButtons() : this.renderSingleButton();
        this.alignBreadcrumbsGroup();
      };
      a.prototype.renderFullPathButtons = function () {
        this.destroySingleButton();
        this.resetElementListState();
        this.updateListElements();
        this.destroyListElements();
      };
      a.prototype.renderSingleButton = function () {
        var a = this.chart,
          c = this.list,
          b = this.options.buttonSpacing;
        this.destroyListElements();
        var e = this.group ? this.group.getBBox().width : b;
        c = c[c.length - 2];
        !a.drillUpButton && 0 < this.level ? a.drillUpButton = this.renderButton(c, e, b) : a.drillUpButton && (0 < this.level ? this.updateSingleButton() : this.destroySingleButton());
      };
      a.prototype.alignBreadcrumbsGroup = function (a) {
        if (this.group) {
          var g = this.options,
            b = g.buttonTheme,
            e = g.position,
            k = "chart" === g.relativeTo || "spacingBox" === g.relativeTo ? void 0 : "scrollablePlotBox",
            f = this.group.getBBox();
          g = 2 * (b.padding || 0) + g.buttonSpacing;
          e.width = f.width + g;
          e.height = f.height + g;
          f = c(e);
          a && (f.x += a);
          this.options.rtl && (f.x += e.width);
          f.y = h(f.y, this.yOffset, 0);
          this.group.align(f, !0, k);
        }
      };
      a.prototype.renderButton = function (a, b, e) {
        var g = this,
          h = this.chart,
          k = g.options,
          r = c(k.buttonTheme);
        b = h.renderer.button(g.getButtonText(a), b, e, function (c) {
          var b = k.events && k.events.click,
            e;
          b && (e = b.call(g, c, a));
          !1 !== e && (c.newLevel = k.showFullPath ? a.level : g.level - 1, f(g, "up", c));
        }, r).addClass("highcharts-breadcrumbs-button").add(g.group);
        h.styledMode || b.attr(k.style);
        return b;
      };
      a.prototype.renderSeparator = function (a, c) {
        var b = this.chart,
          g = this.options.separator;
        a = b.renderer.label(g.text, a, c, void 0, void 0, void 0, !1).addClass("highcharts-breadcrumbs-separator").add(this.group);
        b.styledMode || a.css(g.style);
        return a;
      };
      a.prototype.update = function (a) {
        c(!0, this.options, a);
        this.destroy();
        this.isDirty = !0;
      };
      a.prototype.updateSingleButton = function () {
        var a = this.chart,
          c = this.list[this.level - 1];
        a.drillUpButton && a.drillUpButton.attr({
          text: this.getButtonText(c)
        });
      };
      a.prototype.destroy = function () {
        this.destroySingleButton();
        this.destroyListElements(!0);
        this.group && this.group.destroy();
        this.group = void 0;
      };
      a.prototype.destroyListElements = function (a) {
        var c = this.elementList;
        q(c, function (b, g) {
          if (a || !c[g].updated) b = c[g], b.button && b.button.destroy(), b.separator && b.separator.destroy(), delete b.button, delete b.separator, delete c[g];
        });
        a && (this.elementList = {});
      };
      a.prototype.destroySingleButton = function () {
        this.chart.drillUpButton && (this.chart.drillUpButton.destroy(), this.chart.drillUpButton = void 0);
      };
      a.prototype.resetElementListState = function () {
        q(this.elementList, function (a) {
          a.updated = !1;
        });
      };
      a.prototype.updateListElements = function () {
        var a = this,
          c = a.elementList,
          b = a.options.buttonSpacing,
          e = a.list,
          h = a.options.rtl,
          k = h ? -1 : 1,
          f = function (a, c) {
            return k * a.getBBox().width + k * c;
          },
          m = a.group ? f(a.group, b) : b,
          l;
        e.forEach(function (g, r) {
          r = r === e.length - 1;
          if (c[g.level]) {
            l = c[g.level];
            var q = l.button;
            if (l.separator || r) l.separator && r && (l.separator.destroy(), delete l.separator);else {
              m += k * b;
              l.separator = a.renderSeparator(m, b);
              if (h) {
                var n = l.separator;
                n.translate(m - n.getBBox().width, b);
              }
              m += f(l.separator, b);
            }
            c[g.level].updated = !0;
          } else q = a.renderButton(g, m, b), h && q.translate(m - q.getBBox().width, b), m += f(q, b), r || (n = a.renderSeparator(m, b), h && n.translate(m - n.getBBox().width, b), m += f(n, b)), c[g.level] = {
            button: q,
            separator: n,
            updated: !0
          };
          q && q.setState(r ? 2 : 0);
        });
      };
      a.defaultBreadcrumbsOptions = {
        buttonTheme: {
          fill: "none",
          height: 18,
          padding: 2,
          "stroke-width": 0,
          zIndex: 7,
          states: {
            select: {
              fill: "none"
            }
          },
          style: {
            color: "#335cad"
          }
        },
        buttonSpacing: 5,
        floating: !1,
        format: void 0,
        relativeTo: "plotBox",
        rtl: !1,
        position: {
          align: "left",
          verticalAlign: "top",
          x: 0,
          y: void 0
        },
        separator: {
          text: "/",
          style: {
            color: "#666666"
          }
        },
        showFullPath: !0,
        style: {},
        useHTML: !1,
        zIndex: 7
      };
      return a;
    }();
    b.Breadcrumbs || (b.Breadcrumbs = e, l(a, "getMargins", function () {
      var a = this.breadcrumbs;
      if (a && !a.options.floating && a.level) {
        var c = a.options,
          b = c.buttonTheme;
        b = (b.height || 0) + 2 * (b.padding || 0) + c.buttonSpacing;
        c = c.position.verticalAlign;
        "bottom" === c ? (this.marginBottom = (this.marginBottom || 0) + b, a.yOffset = b) : "middle" !== c ? (this.plotTop += b, a.yOffset = -b) : a.yOffset = void 0;
      }
    }), l(a, "redraw", function () {
      this.breadcrumbs && this.breadcrumbs.redraw();
    }), l(a, "destroy", function () {
      this.breadcrumbs && (this.breadcrumbs.destroy(), this.breadcrumbs = void 0);
    }), l(a, "afterShowResetZoom", function () {
      if (this.breadcrumbs) {
        var a = this.resetZoomButton && this.resetZoomButton.getBBox(),
          c = this.breadcrumbs.options;
        a && "right" === c.position.align && "plotBox" === c.relativeTo && this.breadcrumbs.alignBreadcrumbsGroup(-a.width - c.buttonSpacing);
      }
    }), l(a, "selection", function (a) {
      !0 === a.resetSelection && this.breadcrumbs && this.breadcrumbs.alignBreadcrumbsGroup();
    }));
    "";
    return e;
  });
  u(a, "Series/Treemap/TreemapComposition.js", [a["Core/Series/SeriesRegistry.js"], a["Series/Treemap/TreemapUtilities.js"], a["Core/Utilities.js"]], function (a, e, b) {
    var m = b.addEvent,
      l = b.extend,
      n = !1;
    m(a.series, "afterBindAxes", function () {
      var a = this.xAxis,
        b = this.yAxis;
      if (a && b) if (this.is("treemap")) {
        var f = {
          endOnTick: !1,
          gridLineWidth: 0,
          lineWidth: 0,
          min: 0,
          minPadding: 0,
          max: e.AXIS_MAX,
          maxPadding: 0,
          startOnTick: !1,
          title: void 0,
          tickPositions: []
        };
        l(b.options, f);
        l(a.options, f);
        n = !0;
      } else n && (b.setOptions(b.userOptions), a.setOptions(a.userOptions), n = !1);
    });
  });
  u(a, "Series/Treemap/TreemapNode.js", [], function () {
    return function () {
      function a() {
        this.childrenTotal = 0;
        this.visible = !1;
      }
      a.prototype.init = function (a, b, m, l, n, q, k) {
        this.id = a;
        this.i = b;
        this.children = m;
        this.height = l;
        this.level = n;
        this.series = q;
        this.parent = k;
        return this;
      };
      return a;
    }();
  });
  u(a, "Series/Treemap/TreemapSeries.js", [a["Core/Color/Color.js"], a["Series/ColorMapComposition.js"], a["Core/Globals.js"], a["Core/Legend/LegendSymbol.js"], a["Core/Series/SeriesRegistry.js"], a["Series/Treemap/TreemapAlgorithmGroup.js"], a["Series/Treemap/TreemapPoint.js"], a["Series/Treemap/TreemapUtilities.js"], a["Series/TreeUtilities.js"], a["Extensions/Breadcrumbs.js"], a["Core/Utilities.js"], a["Series/Treemap/TreemapNode.js"]], function (a, e, b, m, l, n, q, k, f, c, h, u) {
    var E = this && this.__extends || function () {
        var a = function (c, d) {
          a = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, d) {
            a.__proto__ = d;
          } || function (a, d) {
            for (var p in d) d.hasOwnProperty(p) && (a[p] = d[p]);
          };
          return a(c, d);
        };
        return function (c, d) {
          function p() {
            this.constructor = c;
          }
          a(c, d);
          c.prototype = null === d ? Object.create(d) : (p.prototype = d.prototype, new p());
        };
      }(),
      v = a.parse;
    a = b.noop;
    var g = l.series;
    b = l.seriesTypes;
    var r = b.column,
      w = b.heatmap,
      x = b.scatter,
      J = f.getColor,
      K = f.getLevelOptions,
      L = f.updateRootId,
      B = h.addEvent,
      M = h.correctFloat,
      A = h.defined,
      N = h.error,
      D = h.extend,
      O = h.fireEvent,
      I = h.isArray,
      P = h.isObject,
      G = h.isString,
      z = h.merge,
      y = h.pick,
      Q = h.stableSort;
    f = function (a) {
      function b() {
        var d = null !== a && a.apply(this, arguments) || this;
        d.axisRatio = void 0;
        d.data = void 0;
        d.mapOptionsToLevel = void 0;
        d.nodeMap = void 0;
        d.nodeList = void 0;
        d.options = void 0;
        d.points = void 0;
        d.rootNode = void 0;
        d.tree = void 0;
        d.level = void 0;
        return d;
      }
      E(b, a);
      b.prototype.algorithmCalcPoints = function (a, b, c, e) {
        var d,
          p,
          t,
          g,
          h = c.lW,
          F = c.lH,
          f = c.plot,
          k = 0,
          m = c.elArr.length - 1;
        if (b) h = c.nW, F = c.nH;else var l = c.elArr[c.elArr.length - 1];
        c.elArr.forEach(function (a) {
          if (b || k < m) 0 === c.direction ? (d = f.x, p = f.y, t = h, g = a / t) : (d = f.x, p = f.y, g = F, t = a / g), e.push({
            x: d,
            y: p,
            width: t,
            height: M(g)
          }), 0 === c.direction ? f.y += g : f.x += t;
          k += 1;
        });
        c.reset();
        0 === c.direction ? c.width -= h : c.height -= F;
        f.y = f.parent.y + (f.parent.height - c.height);
        f.x = f.parent.x + (f.parent.width - c.width);
        a && (c.direction = 1 - c.direction);
        b || c.addElement(l);
      };
      b.prototype.algorithmFill = function (a, b, c) {
        var d = [],
          p,
          t = b.direction,
          e = b.x,
          g = b.y,
          h = b.width,
          f = b.height,
          k,
          m,
          l,
          n;
        c.forEach(function (c) {
          p = c.val / b.val * b.height * b.width;
          k = e;
          m = g;
          0 === t ? (n = f, l = p / n, h -= l, e += l) : (l = h, n = p / l, f -= n, g += n);
          d.push({
            x: k,
            y: m,
            width: l,
            height: n
          });
          a && (t = 1 - t);
        });
        return d;
      };
      b.prototype.algorithmLowAspectRatio = function (a, b, c) {
        var d = [],
          p = this,
          t,
          e = {
            x: b.x,
            y: b.y,
            parent: b
          },
          g = 0,
          h = c.length - 1,
          f = new n(b.height, b.width, b.direction, e);
        c.forEach(function (c) {
          t = c.val / b.val * b.height * b.width;
          f.addElement(t);
          f.lP.nR > f.lP.lR && p.algorithmCalcPoints(a, !1, f, d, e);
          g === h && p.algorithmCalcPoints(a, !0, f, d, e);
          g += 1;
        });
        return d;
      };
      b.prototype.alignDataLabel = function (a, b, c) {
        var d = c.style;
        d && !A(d.textOverflow) && b.text && b.getBBox().width > b.text.textWidth && b.css({
          textOverflow: "ellipsis",
          width: d.width += "px"
        });
        r.prototype.alignDataLabel.apply(this, arguments);
        a.dataLabel && a.dataLabel.attr({
          zIndex: (a.node.zIndex || 0) + 1
        });
      };
      b.prototype.calculateChildrenAreas = function (a, b) {
        var d = this,
          c = d.options,
          p = d.mapOptionsToLevel[a.level + 1],
          e = y(d[p && p.layoutAlgorithm] && p.layoutAlgorithm, c.layoutAlgorithm),
          g = c.alternateStartingDirection,
          f = [];
        a = a.children.filter(function (a) {
          return !a.ignore;
        });
        p && p.layoutStartingDirection && (b.direction = "vertical" === p.layoutStartingDirection ? 0 : 1);
        f = d[e](b, a);
        a.forEach(function (a, c) {
          c = f[c];
          a.values = z(c, {
            val: a.childrenTotal,
            direction: g ? 1 - b.direction : b.direction
          });
          a.pointValues = z(c, {
            x: c.x / d.axisRatio,
            y: k.AXIS_MAX - c.y - c.height,
            width: c.width / d.axisRatio
          });
          a.children.length && d.calculateChildrenAreas(a, a.values);
        });
      };
      b.prototype.createList = function (a) {
        var d = this.chart,
          b = [];
        if (d.breadcrumbs) {
          var c = 0;
          b.push({
            level: c,
            levelOptions: d.series[0]
          });
          d = a.target.nodeMap[a.newRootId];
          for (var e = []; d.parent || "" === d.parent;) e.push(d), d = a.target.nodeMap[d.parent];
          e.reverse().forEach(function (a) {
            b.push({
              level: ++c,
              levelOptions: a
            });
          });
          1 >= b.length && (b.length = 0);
        }
        return b;
      };
      b.prototype.drawDataLabels = function () {
        var a = this,
          b = a.mapOptionsToLevel,
          c,
          e;
        a.points.filter(function (a) {
          return a.node.visible;
        }).forEach(function (d) {
          e = b[d.node.level];
          c = {
            style: {}
          };
          d.node.isLeaf || (c.enabled = !1);
          e && e.dataLabels && (c = z(c, e.dataLabels), a._hasPointLabels = !0);
          d.shapeArgs && (c.style.width = d.shapeArgs.width, d.dataLabel && d.dataLabel.css({
            width: d.shapeArgs.width + "px"
          }));
          d.dlOptions = z(c, d.options.dataLabels);
        });
        g.prototype.drawDataLabels.call(this);
      };
      b.prototype.drawPoints = function (a) {
        void 0 === a && (a = this.points);
        var d = this,
          b = d.chart,
          c = b.renderer,
          e = b.styledMode,
          g = d.options,
          f = e ? {} : g.shadow,
          h = g.borderRadius,
          k = b.pointCount < g.animationLimit,
          l = g.allowTraversingTree;
        a.forEach(function (a) {
          var b = a.node.levelDynamic,
            p = {},
            H = {},
            t = {},
            C = "level-group-" + a.node.level,
            m = !!a.graphic,
            R = k && m,
            n = a.shapeArgs;
          a.shouldDraw() && (a.isInside = !0, h && (H.r = h), z(!0, R ? p : H, m ? n : {}, e ? {} : d.pointAttribs(a, a.selected ? "select" : void 0)), d.colorAttribs && e && D(t, d.colorAttribs(a)), d[C] || (d[C] = c.g(C).attr({
            zIndex: 1E3 - (b || 0)
          }).add(d.group), d[C].survive = !0));
          a.draw({
            animatableAttribs: p,
            attribs: H,
            css: t,
            group: d[C],
            renderer: c,
            shadow: f,
            shapeArgs: n,
            shapeType: a.shapeType
          });
          l && a.graphic && (a.drillId = g.interactByLeaf ? d.drillToByLeaf(a) : d.drillToByGroup(a));
        });
      };
      b.prototype.drillToByGroup = function (a) {
        var d = !1;
        1 !== a.node.level - this.nodeMap[this.rootNode].level || a.node.isLeaf || (d = a.id);
        return d;
      };
      b.prototype.drillToByLeaf = function (a) {
        var d = !1;
        if (a.node.parent !== this.rootNode && a.node.isLeaf) for (a = a.node; !d;) a = this.nodeMap[a.parent], a.parent === this.rootNode && (d = a.id);
        return d;
      };
      b.prototype.drillToNode = function (a, b) {
        N(32, !1, void 0, {
          "treemap.drillToNode": "use treemap.setRootNode"
        });
        this.setRootNode(a, b);
      };
      b.prototype.drillUp = function () {
        var a = this.nodeMap[this.rootNode];
        a && G(a.parent) && this.setRootNode(a.parent, !0, {
          trigger: "traverseUpButton"
        });
      };
      b.prototype.getExtremes = function () {
        var a = g.prototype.getExtremes.call(this, this.colorValueData),
          b = a.dataMax;
        this.valueMin = a.dataMin;
        this.valueMax = b;
        return g.prototype.getExtremes.call(this);
      };
      b.prototype.getListOfParents = function (a, b) {
        a = I(a) ? a : [];
        var d = I(b) ? b : [];
        b = a.reduce(function (a, b, d) {
          b = y(b.parent, "");
          "undefined" === typeof a[b] && (a[b] = []);
          a[b].push(d);
          return a;
        }, {
          "": []
        });
        k.eachObject(b, function (a, b, c) {
          "" !== b && -1 === d.indexOf(b) && (a.forEach(function (a) {
            c[""].push(a);
          }), delete c[b]);
        });
        return b;
      };
      b.prototype.getTree = function () {
        var a = this.data.map(function (a) {
          return a.id;
        });
        a = this.getListOfParents(this.data, a);
        this.nodeMap = {};
        this.nodeList = [];
        return this.buildTree("", -1, 0, a);
      };
      b.prototype.buildTree = function (a, b, c, e, g) {
        var d = this,
          f = [],
          h = d.points[b],
          p = 0,
          t;
        (e[a] || []).forEach(function (b) {
          t = d.buildTree(d.points[b].id, b, c + 1, e, a);
          p = Math.max(t.height + 1, p);
          f.push(t);
        });
        var k = new d.NodeClass().init(a, b, f, p, c, d, g);
        f.forEach(function (a) {
          a.parentNode = k;
        });
        d.nodeMap[k.id] = k;
        d.nodeList.push(k);
        h && (h.node = k, k.point = h);
        return k;
      };
      b.prototype.hasData = function () {
        return !!this.processedXData.length;
      };
      b.prototype.init = function (a, b) {
        var d = this,
          e = z(b.drillUpButton, b.breadcrumbs);
        var f = B(d, "setOptions", function (a) {
          a = a.userOptions;
          A(a.allowDrillToNode) && !A(a.allowTraversingTree) && (a.allowTraversingTree = a.allowDrillToNode, delete a.allowDrillToNode);
          A(a.drillUpButton) && !A(a.traverseUpButton) && (a.traverseUpButton = a.drillUpButton, delete a.drillUpButton);
        });
        g.prototype.init.call(d, a, b);
        delete d.opacity;
        d.eventsToUnbind.push(f);
        d.options.allowTraversingTree && (d.eventsToUnbind.push(B(d, "click", d.onClickDrillToNode)), d.eventsToUnbind.push(B(d, "setRootNode", function (a) {
          var b = d.chart;
          b.breadcrumbs && b.breadcrumbs.updateProperties(d.createList(a));
        })), d.eventsToUnbind.push(B(d, "update", function (a, b) {
          (b = this.chart.breadcrumbs) && a.options.breadcrumbs && b.update(a.options.breadcrumbs);
        })), d.eventsToUnbind.push(B(d, "destroy", function (a) {
          var b = this.chart;
          b.breadcrumbs && (b.breadcrumbs.destroy(), a.keepEventsForUpdate || (b.breadcrumbs = void 0));
        })));
        a.breadcrumbs || (a.breadcrumbs = new c(a, e));
        d.eventsToUnbind.push(B(a.breadcrumbs, "up", function (a) {
          a = this.level - a.newLevel;
          for (var b = 0; b < a; b++) d.drillUp();
        }));
      };
      b.prototype.onClickDrillToNode = function (a) {
        var b = (a = a.point) && a.drillId;
        G(b) && (a.setState(""), this.setRootNode(b, !0, {
          trigger: "click"
        }));
      };
      b.prototype.pointAttribs = function (a, b) {
        var c = P(this.mapOptionsToLevel) ? this.mapOptionsToLevel : {},
          d = a && c[a.node.level] || {};
        c = this.options;
        var e = b && c.states && c.states[b] || {},
          g = a && a.getClassName() || "";
        a = {
          stroke: a && a.borderColor || d.borderColor || e.borderColor || c.borderColor,
          "stroke-width": y(a && a.borderWidth, d.borderWidth, e.borderWidth, c.borderWidth),
          dashstyle: a && a.borderDashStyle || d.borderDashStyle || e.borderDashStyle || c.borderDashStyle,
          fill: a && a.color || this.color
        };
        -1 !== g.indexOf("highcharts-above-level") ? (a.fill = "none", a["stroke-width"] = 0) : -1 !== g.indexOf("highcharts-internal-node-interactive") ? (b = y(e.opacity, c.opacity), a.fill = v(a.fill).setOpacity(b).get(), a.cursor = "pointer") : -1 !== g.indexOf("highcharts-internal-node") ? a.fill = "none" : b && (a.fill = v(a.fill).brighten(e.brightness).get());
        return a;
      };
      b.prototype.setColorRecursive = function (a, b, c, e, g) {
        var d = this,
          f = d && d.chart;
        f = f && f.options && f.options.colors;
        if (a) {
          var h = J(a, {
            colors: f,
            index: e,
            mapOptionsToLevel: d.mapOptionsToLevel,
            parentColor: b,
            parentColorIndex: c,
            series: d,
            siblings: g
          });
          if (b = d.points[a.i]) b.color = h.color, b.colorIndex = h.colorIndex;
          (a.children || []).forEach(function (b, c) {
            d.setColorRecursive(b, h.color, h.colorIndex, c, a.children.length);
          });
        }
      };
      b.prototype.setPointValues = function () {
        var a = this,
          b = a.xAxis,
          c = a.yAxis,
          e = a.chart.styledMode;
        a.points.forEach(function (d) {
          var g = d.node,
            f = g.pointValues;
          g = g.visible;
          if (f && g) {
            g = f.height;
            var h = f.width,
              k = f.x,
              p = f.y,
              l = e ? 0 : (a.pointAttribs(d)["stroke-width"] || 0) % 2 / 2;
            f = Math.round(b.toPixels(k, !0)) - l;
            h = Math.round(b.toPixels(k + h, !0)) - l;
            k = Math.round(c.toPixels(p, !0)) - l;
            g = Math.round(c.toPixels(p + g, !0)) - l;
            g = {
              x: Math.min(f, h),
              y: Math.min(k, g),
              width: Math.abs(h - f),
              height: Math.abs(g - k)
            };
            d.plotX = g.x + g.width / 2;
            d.plotY = g.y + g.height / 2;
            d.shapeArgs = g;
          } else delete d.plotX, delete d.plotY;
        });
      };
      b.prototype.setRootNode = function (a, b, c) {
        a = D({
          newRootId: a,
          previousRootId: this.rootNode,
          redraw: y(b, !0),
          series: this
        }, c);
        O(this, "setRootNode", a, function (a) {
          var b = a.series;
          b.idPreviousRoot = a.previousRootId;
          b.rootNode = a.newRootId;
          b.isDirty = !0;
          a.redraw && b.chart.redraw();
        });
      };
      b.prototype.setState = function (a) {
        this.options.inactiveOtherPoints = !0;
        g.prototype.setState.call(this, a, !1);
        this.options.inactiveOtherPoints = !1;
      };
      b.prototype.setTreeValues = function (a) {
        var b = this,
          c = b.options,
          d = b.nodeMap[b.rootNode];
        c = k.isBoolean(c.levelIsConstant) ? c.levelIsConstant : !0;
        var e = 0,
          g = [],
          f = b.points[a.i];
        a.children.forEach(function (a) {
          a = b.setTreeValues(a);
          g.push(a);
          a.ignore || (e += a.val);
        });
        Q(g, function (a, b) {
          return (a.sortIndex || 0) - (b.sortIndex || 0);
        });
        var h = y(f && f.options.value, e);
        f && (f.value = h);
        D(a, {
          children: g,
          childrenTotal: e,
          ignore: !(y(f && f.visible, !0) && 0 < h),
          isLeaf: a.visible && !e,
          levelDynamic: a.level - (c ? 0 : d.level),
          name: y(f && f.name, ""),
          sortIndex: y(f && f.sortIndex, -h),
          val: h
        });
        return a;
      };
      b.prototype.sliceAndDice = function (a, b) {
        return this.algorithmFill(!0, a, b);
      };
      b.prototype.squarified = function (a, b) {
        return this.algorithmLowAspectRatio(!0, a, b);
      };
      b.prototype.strip = function (a, b) {
        return this.algorithmLowAspectRatio(!1, a, b);
      };
      b.prototype.stripes = function (a, b) {
        return this.algorithmFill(!1, a, b);
      };
      b.prototype.translate = function () {
        var a = this,
          b = a.options,
          c = L(a);
        g.prototype.translate.call(a);
        var e = a.tree = a.getTree();
        var f = a.nodeMap[c];
        "" === c || f && f.children.length || (a.setRootNode("", !1), c = a.rootNode, f = a.nodeMap[c]);
        a.mapOptionsToLevel = K({
          from: f.level + 1,
          levels: b.levels,
          to: e.height,
          defaults: {
            levelIsConstant: a.options.levelIsConstant,
            colorByPoint: b.colorByPoint
          }
        });
        k.recursive(a.nodeMap[a.rootNode], function (b) {
          var c = !1,
            d = b.parent;
          b.visible = !0;
          if (d || "" === d) c = a.nodeMap[d];
          return c;
        });
        k.recursive(a.nodeMap[a.rootNode].children, function (a) {
          var b = !1;
          a.forEach(function (a) {
            a.visible = !0;
            a.children.length && (b = (b || []).concat(a.children));
          });
          return b;
        });
        a.setTreeValues(e);
        a.axisRatio = a.xAxis.len / a.yAxis.len;
        a.nodeMap[""].pointValues = c = {
          x: 0,
          y: 0,
          width: k.AXIS_MAX,
          height: k.AXIS_MAX
        };
        a.nodeMap[""].values = c = z(c, {
          width: c.width * a.axisRatio,
          direction: "vertical" === b.layoutStartingDirection ? 0 : 1,
          val: e.val
        });
        a.calculateChildrenAreas(e, c);
        a.colorAxis || b.colorByPoint || a.setColorRecursive(a.tree);
        b.allowTraversingTree && (b = f.pointValues, a.xAxis.setExtremes(b.x, b.x + b.width, !1), a.yAxis.setExtremes(b.y, b.y + b.height, !1), a.xAxis.setScale(), a.yAxis.setScale());
        a.setPointValues();
      };
      b.defaultOptions = z(x.defaultOptions, {
        allowTraversingTree: !1,
        animationLimit: 250,
        borderRadius: 0,
        showInLegend: !1,
        marker: void 0,
        colorByPoint: !1,
        dataLabels: {
          defer: !1,
          enabled: !0,
          formatter: function () {
            var a = this && this.point ? this.point : {};
            return G(a.name) ? a.name : "";
          },
          inside: !0,
          verticalAlign: "middle"
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "<b>{point.name}</b>: {point.value}<br/>"
        },
        ignoreHiddenPoint: !0,
        layoutAlgorithm: "sliceAndDice",
        layoutStartingDirection: "vertical",
        alternateStartingDirection: !1,
        levelIsConstant: !0,
        traverseUpButton: {
          position: {
            align: "right",
            x: -10,
            y: 10
          }
        },
        borderColor: "#e6e6e6",
        borderWidth: 1,
        colorKey: "colorValue",
        opacity: .15,
        states: {
          hover: {
            borderColor: "#999999",
            brightness: w ? 0 : .1,
            halo: !1,
            opacity: .75,
            shadow: !1
          }
        }
      });
      return b;
    }(x);
    D(f.prototype, {
      buildKDTree: a,
      colorAttribs: e.seriesMembers.colorAttribs,
      colorKey: "colorValue",
      directTouch: !0,
      drawLegendSymbol: m.drawRectangle,
      getExtremesFromAll: !0,
      getSymbol: a,
      optionalAxis: "colorAxis",
      parallelArrays: ["x", "y", "value", "colorValue"],
      pointArrayMap: ["value"],
      pointClass: q,
      NodeClass: u,
      trackerGroups: ["group", "dataLabelsGroup"],
      utils: {
        recursive: k.recursive
      }
    });
    e.compose(f);
    l.registerSeriesType("treemap", f);
    "";
    return f;
  });
  u(a, "masters/modules/treemap.src.js", [a["Core/Globals.js"], a["Extensions/Breadcrumbs.js"]], function (a, e) {
    a.Breadcrumbs = e;
  });
});
