import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { UserManagementService } from "@ds-private-layouts/user-management/user-management.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";

@Component({
	selector: "app-edit-user-details",
	templateUrl: "./edit-user-details.component.html",
	styleUrls: ["./edit-user-details.component.scss"]
})
export class EditUserDetailsComponent implements OnInit {
	@Input() actionData: any;
	@Input() userData;
	public actionType;
	public companyList;
	public submitted: boolean = false;
	public response: any;
	public email;
	public umUserInfoSub: Subscription;
	public notificationInfo: string;
	public id: any;
	public hasErrors: boolean = false;
	public userForm: UntypedFormGroup;
	@Output() onCancelAction: EventEmitter<any> = new EventEmitter();
	@Output() onSuccessAction: EventEmitter<any> = new EventEmitter();
	userLocalSData: any;

	constructor(
		private userManagementService: UserManagementService,
		private dataTransferService: DataTransferService,
		private notificationService: NotificationService,
		private localStorageService: LocalStorageService,
		private commonFunctions: CommonFunctionsService
	) {
		this.actionType = "add";
	}

	ngOnInit(): void {
		this.userForm = new UntypedFormGroup({
			first_name: new UntypedFormControl(null, [
				Validators.required,
				this.validateName.bind(this)
			]),
			last_name: new UntypedFormControl(null, [
				Validators.required,
				this.validateName.bind(this)
			]),
			email: new UntypedFormControl(null, [
				Validators.required,
				Validators.email
			]),
			contact: new UntypedFormControl(null, [
				Validators.required,
				Validators.maxLength(15)
			]),
			countryCode: new UntypedFormControl(null, [
				Validators.required,
				Validators.maxLength(3),
				Validators.minLength(2)
			])
			// 'address': new UntypedFormControl(null),
		});
		this.userLocalSData = JSON.parse(
			this.commonFunctions.getDecodedData(this.localStorageService.get("user"))
		);
		this.userData = this.userLocalSData["data"];
		if (!this.userData) {
			this.getUserInfo();
		} else {
			this.setFormData();
		}
	}

	getUserInfo() {
		this.umUserInfoSub = this.userManagementService
			.getUserInfo()
			.subscribe((response: any) => {
				if (response) {
					this.response = response;
					this.userData = this.response.data;
					if (this.userData != undefined) {
						this.setFormData();
					}
				}
			});
	}

	setFormData() {
		const { first_name, last_name, email, contact } = this.userData;
		const [countryCode, phoneNumber] = contact.includes("-")
			? contact.split("-")
			: [null, contact];

		this.userForm.setValue({
			first_name,
			last_name,
			email,
			countryCode,
			contact: phoneNumber
			// address: this.userData.company.address,
		});
	}

	noSpace(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode === 32) {
			return false;
		}
		return true;
	}

	numberOnly(event) {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode >= 48 && charCode <= 57) {
			return true;
		}
		return false;
	}
	onPasswordPaste() {
		this.notificationService.setMessage(1100, "Copy/Paste is not allowed");
		return false;
	}

	onSubmit() {
		this.submitted = true;
		if (
			this.userForm.status == "INVALID" &&
			this.userForm.controls["contact"].status != "PENDING"
		) {
			this.notificationService.setMessage(
				1100,
				"Please fill all mandatory fields"
			);
		} else {
			this.callSubmitApi();
		}
	}

	callSubmitApi() {
		this.submitted = true;
		const data = {
			id: this.userData.id,
			first_name: this.userForm.controls["first_name"].value,
			last_name: this.userForm.controls["last_name"].value,
			email: this.userForm.controls["email"].value,
			contact:
				this.userForm.controls["countryCode"].value +
				"-" +
				this.userForm.controls["contact"].value
		};

		let call;
		if (this.userForm.dirty == true) {
			call = true;
		} else {
			call = false;
		}
		if (call == true) {
			this.userManagementService
				.editUserInfo(data)
				.subscribe((response: any) => {
					if (response) {
						this.response = response;
						this.updateLocalData();
						this.onCloseAction();
						this.onSuccessAction.emit();
						this.notificationService.setMessage(
							this.response["context_code"],
							this.response["summary"]
						);
					}
				});
		} else {
			this.onCloseAction();
		}
	}

	updateLocalData() {
		this.userLocalSData.data.first_name =
			this.userForm.controls["first_name"].value;
		this.userLocalSData.data.last_name =
			this.userForm.controls["last_name"].value;
		this.userLocalSData.data.contact =
			this.userForm.controls["countryCode"].value +
			this.userForm.controls["contact"].value;
		this.localStorageService.set(
			"user",
			this.commonFunctions.getEncodedData(JSON.stringify(this.userLocalSData))
		);
	}

	onCloseAction() {
		this.resetNoteData();
		this.onCancelAction.emit();
	}
	resetNoteData() {
		this.dataTransferService.sendnoteDetails(null);
	}
	checkUpdate() {
		return (
			this.userForm?.status == "INVALID" ||
			!(
				this.userData?.["first_name"] !=
					this.userForm.get("first_name").value ||
				this.userData?.["last_name"] != this.userForm.get("last_name").value ||
				this.userData?.["contact"] !=
					this.userForm.get("countryCode").value +
						this.userForm.get("contact").value
			)
		);
	}
	validateName(c: UntypedFormControl): { [s: string]: string } {
		const val = c.value;
		if (!new RegExp("^[a-zA-Z]*$").test(val)) {
			return { fieldMessage: "Only alphabets are allowed" };
		} else return null;
	}

	ngOnDestroy() {
		if (this.umUserInfoSub) {
			this.umUserInfoSub.unsubscribe();
		}
	}
}
