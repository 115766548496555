<div class="d-flex justify-content-between align-items-center gap-16">
	<!-- Gmv and Quantity Sold toggle -->
	<div class="d-flex align-items-center justify-content-end w-100 gap-8">
		<span class="view-by">View by</span>
		<div class="ds-switch checked-only-switch">
			<div class="switch-name" translate>GMV</div>
			<label class="switch-container d-flex" for="switch-view">
				<input
					id="switch-view"
					name="metric-view"
					type="checkbox"
					(change)="onMetricViewChange(defaultMetricView==='quantity_sold'?'gmv':'quantity_sold')"
				/>
				<div class="toggle-content">
					<div class="toggle-bubble"></div>
				</div>
			</label>
			<div class="switch-name" translate>Quantity Sold</div>
		</div>
	</div>
	<!-- code ends here -->

	<!-- Market Place dropdown -->
	<div class="marketplace-container">
		<ng-select
			(close)="onMarketplaceClose()"
			class="global-brands select-text-wrap"
			[(ngModel)]="marketplace.selectedMarketplace"
			[notFoundText]="'No Data Found' | translate"
			[loadingText]="'Loading...' | translate"
			[items]="marketplace.list"
			bindLabel="attributes.name"
			placeholder="{{marketplace.placeholder | translate}}"
			(scrollToEnd)="getMarketPlaceList()"
			[searchable]="true"
			(change)="onMarketPlaceSelection($event)"
			(search)="searchData($event)"
			[loading]="marketplace.isLoading"
			[clearable]="true"
			[multiple]="true"
			[closeOnSelect]="false"
			[ngbTooltip]="'Channels' | translate"
			tooltipClass="info-custom-tooltip"
		>
			<ng-template ng-multi-label-tmp>
				<span class="placeholder-temp">
					{{marketplace.searchMarketplace?.length ? '' : (('Channel' |
					translate) + " (" + marketplace.selectedMarketplace?.length + ")")}}
				</span>
			</ng-template>
			<ng-template ng-option-tmp let-item="item" let-index="index">
				<div class="multi-select-option-container multi-text-wrap">
					<span class="item-name" translate>{{item.attributes.name}}</span>
					<i class="icon icon-Check" *ngIf="checkSelectedOption(item)"></i>
				</div>
			</ng-template>
		</ng-select>
	</div>
	<!-- code ends here -->

	<!-- Brand Specific filter -->
	<div
		class="marketplace-container"
		*ngIf="marketShareType=== 'brand-market-share'"
	>
		<ng-select
			[items]="brandFilter"
			bindLabel="label"
			(change)="onChange($event)"
			[clearable]="false"
			[(ngModel)]="selectedBrandType"
		>
		</ng-select>
	</div>
	<!-- Code ends here -->
	<!-- Category Specific filter -->
	<div
		class="marketplace-container"
		*ngIf="marketShareType=== 'category-market-share'"
	>
		<ng-select
			[multiple]="true"
			[items]="categoryList.list"
			bindLabel="attributes.name"
			(change)="onCategorySelect($event)"
			[clearable]="true"
			[closeOnSelect]="false"
			(close)="closeMultiDropdown()"
			placeholder="{{categoryList.placeholder}}"
			[(ngModel)]="categoryList.selectedCategory"
			(search)="onCategorySearch($event)"
			[ngbTooltip]="'Category' | translate"
			tooltipClass="info-custom-tooltip"
			[loadingText]="'Loading...' | translate"
		>
			<ng-template ng-multi-label-tmp
				><span class="placeholder-temp"
					>{{categoryList.searchCategory?.length ? '' :
					((categoryList.placeholder | translate) + " (" +
					categoryList.selectedCategory.length + ")")}}</span
				></ng-template
			>
			<ng-template ng-option-tmp let-item="item" let-index="index">
				<div class="multi-select-option-container multi-text-wrap gap-16">
					<span class="item-name">{{item.attributes.name}}</span>
					<i class="icon icon-Check" *ngIf="addTick(item)"></i>
				</div>
			</ng-template>
		</ng-select>
	</div>
	<!-- Code ends here -->
</div>
