import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-sliced-legends',
  templateUrl: './common-sliced-legends.component.html',
  styleUrls: ['./common-sliced-legends.component.scss']
})
export class CommonSlicedLegendsComponent implements OnInit {
  @Input() public data: any;
  @Input() public noOfSlice: number;
  @Input() public showPercentageValue: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
