export const SAVE_WIDGET = "SaveWidget"

export enum CHART_TYPE{
    LINE_CHART = 'line-chart',
    BAR_CHART = 'bar-chart',
    PIE_CHART = 'pie-chart',
    TABLE = 'table',
}

export enum TIMELINE{
    DAILY = "daily",
    WEEKLY = "weekly",
    MONTHLY = "monthly",
    TILL_DATE = "till_date"
}