import { Component, Input, OnInit } from '@angular/core';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';

@Component({
  selector: 'app-common-tags-dropdown',
  templateUrl: './common-tags-dropdown.component.html',
  styleUrls: ['./common-tags-dropdown.component.scss']
})
export class CommonTagsDropdownComponent implements OnInit {

  @Input() item: any;
  @Input() map: string;
  constructor(public commonFunctions: CommonFunctionsService) { }

  ngOnInit(): void {
  }

}
