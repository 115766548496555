/* eslint-disable */
import {
	Component,
	Input,
	OnInit,
	Renderer2,
	SimpleChanges
} from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import HC_more from "highcharts/highcharts-more";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { Router } from "@angular/router";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
import { NumberSuffixPipe } from "@ds-shared/pipes/number-suffix.pipe";

HC_more(Highcharts);

let componentContext;

@Component({
	selector: "app-column-range-chart",
	templateUrl: "./column-range-chart.component.html",
	styleUrls: ["./column-range-chart.component.scss"]
})
export class ColumnRangeChartComponent implements OnInit {
	public Highcharts: typeof Highcharts = Highcharts;
	public highchartsData: typeof Highcharts;
	public chartOptions: any;
	@Input() public data: any;
	@Input() public categories;
	@Input() public enableScrollBar: boolean = false;
	@Input() public scrollbarThreshold = 15;
	@Input() barColor: string = "#FF9800";
	@Input() barHoverColor: string = "#EE6002";
	@Input() caller: string = "default";
	public maxValue: number;
	public bgData = [];
	public profileType = {
		keyword: "K",
		category: "C",
		brand: "B",
		product: "P"
	};
	public barDiffValue = 0.5;
	public storeClickedElement;
	listenerFn = () => {};
	constructor(
		private commonFunctions: CommonFunctionsService,
		private dataTransferService: DataTransferService,
		private router: Router,
		private renderer: Renderer2
	) {
		this.listenerFn = this.renderer.listen("document", "click", (e: any) => {
			componentContext.storeClickedElement =
				componentContext.commonFunctions.closeCustomDropdown(
					e,
					componentContext.storeClickedElement
				);
		});
	}

	ngOnInit(): void {
		componentContext = this;

		Highcharts.setOptions({
			lang: {
				// thousandsSep: ","
			}
		});
		this.maxValue = Math.max(...this.data.map((o) => o.high));
		this.data.forEach((item) => {
			let newObj = {
				low: 0,
				high: this.maxValue + 150,
				dataLabels: { enabled: false },
				type: item.type,
				id: item.id
			};
			if ("harmonised_view" in item)
				newObj = {
					...newObj,
					...{
						harmonised_view: item?.harmonised_view,
						raw_data: item?.raw_data
					}
				};
			this.bgData.push(newObj);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		componentContext = this;
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.renderBarChart();
		}, 0);
	}

	onStoreNameClick(e) {
		const OuterDiv = document.querySelectorAll(".highcharts-container");
		const axis = e.xAxis[0];
		const ticks = axis.ticks;
		const points = e.series[0].points;
		const processedXData = e.series[0].processedXData;
		points.forEach(function (point, i) {
			if (point?.type !== "store") return;
			if (ticks[processedXData[i]]) {
				const label = ticks[processedXData[i]].label.element;
				label.onmouseover = function () {
					label.childNodes.forEach((item) => {
						item.style.setProperty("color", "#ff5e00", "important");
						item.style.setProperty("cursor", "pointer");
						item.childNodes[1].style.setProperty("visibility", "visible");
						OuterDiv.forEach((div: HTMLElement) =>
							div.style.setProperty("overflow", "unset")
						);
					});
				};
				label.onmouseout = function () {
					label.childNodes.forEach((item) => {
						item.style.setProperty("color", "#727487", "important");
						item.childNodes[1].style.setProperty("visibility", "hidden");
						OuterDiv.forEach((div: HTMLElement) =>
							div.style.setProperty("overflow", "hidden")
						);
					});
				};
				label.onclick = function (event) {
					if (point?.harmonised_view) {
						componentContext.storeClickedElement =
							componentContext.commonFunctions.setPositionOfCustomDropdown(
								event,
								label,
								componentContext.storeClickedElement,
								point["raw_data"],
								20
							);
					} else {
						componentContext.storeClickedElement =
							componentContext.commonFunctions.closeCustomDropdown(
								null,
								componentContext.storeClickedElement
							);
						window.open(
							componentContext.commonFunctions?.redirectToScorecardInfo(
								point.id,
								"store",
								true
							)?.params,
							"_blank"
						);
					}
				};
			}
		});
	}
	renderBarChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				type: "columnrange",
				inverted: true,
				spacing: [0, 0, 0, 0],
				events: {
					load: function () {
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
						const chart = this,
							yAxis = chart.yAxis[0];
						yAxis.setExtremes(yAxis.min, componentContext.maxValue + 100);
					},
					render: function () {
						componentContext.onStoreNameClick(this);
					}
				}
			},
			title: {
				text: ""
			},
			subtitle: {
				text: ""
			},
			scrollbar: {
				enabled: this.enableScrollBar,
				barBackgroundColor: "#aaaaaae5",
				barBorderRadius: 7,
				barBorderWidth: 0,
				buttonBackgroundColor: "#B3B3B3",
				buttonBorderWidth: 0,
				buttonBorderRadius: 7,
				trackBackgroundColor: "none",
				trackBorderWidth: 0,
				trackBorderRadius: 8,
				trackBorderColor: "#CCC",
				rifleColor: "transparent"
			},
			navigator: {
				enabled: false,
				height: 0
			},
			xAxis: {
				type: "category",
				//alternateGridColor: '#F0F3F8',
				categories: this.categories,
				labels: {
					useHTML: true, //Set to true
					align: "left",
					reserveSpace: true,
					verticalAlign: "bottom",
					formatter: function () {
						if (
							this.axis.series[0]?.userOptions?.data[this.pos].type ===
								"store" &&
							componentContext.caller === "pricing_store"
						) {
							return `<div style="display:flex;font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
							<span style=" visibility: hidden;border: 0.0625rem solid black; padding: 0.375rem ;left: -0.25rem ;bottom: 100%;position: absolute;background-color:${TOOLTIPCOLOR["BG"]};color:${TOOLTIPCOLOR["VALUE"]};border-radius:0.375rem">${this.value}</span>
							<div style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${this.value}</div>
							</div>`;
						} else {
							return `<span style="font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
							<span style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${this.value}</span>
							</span>`;
						}
					}
				},
				lineColor: "transparent",
				gridLineColor: "transparent",
				title: {
					text: null
				},
				max:
					this.data?.length > this.scrollbarThreshold
						? this.scrollbarThreshold - 1
						: this.data?.length - 1,
				tickLength: 0
			},
			yAxis: {
				labels: {
					enabled: true,
					formatter: function () {
						return (
							componentContext.commonFunctions.currencySelected?.attributes
								?.symbol +
							componentContext.commonFunctions.getSuffixedNumberFormat(
								this.value,
								false
							)
						);
					}
				},
				lineColor: "transparent",
				gridLineColor: "transparent",
				title: {
					text: "",
					align: "high"
				}
			},
			tooltip: {
				enabled: true,
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: true,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true,
				outside: false
			},
			plotOptions: {
				backgroundColor: "green",
				columnrange: {
					groupPadding: 0,
					pointPadding: 0.1,
					borderWidth: 0,
					cursor: "pointer",
					stickyTracking: false,
					dataLabels: {
						enabled: true,
						style: {
							textOutline: 0
						}
					},
					pointWidth: 20,
					states: {
						hover: {
							color: ""
						}
					},
					events: {
						click: (event) => {
							this.dataTransferService.sendGraphSliceData({
								...event.point.options
							});
						}
					}
				},
				bar: {
					cursor: "pointer"
				},
				series: {
					cursor: "pointer",
					cropThreshold: 600,
					turboThreshold: 0,
					pointWidth: 20
				}
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			colors: [this.barColor],
			series: [
				{
					enableMouseTracking: false,
					showInLegend: false,
					grouping: false,
					color: "#F0F3F8",
					stacking: false,
					data: componentContext.bgData
				},
				{
					name: "Price Range",
					dataLabels: {
						inside: false,
						align: "left",
						allowOverlap: false,
						formatter: function (e) {
							const color = this.y === this.point.high ? "#9BA1AC" : "#FFFFFF";
							return `<span style="color:${color};z-index:0;position:relative;">${
								componentContext.commonFunctions.currencySelected?.attributes
									?.symbol
							}${componentContext.commonFunctions.getSuffixedNumberFormat(
								this.y === this.point.high
									? this.y - componentContext.barDiffValue
									: this.y
							)}</span>`;
						}
					},

					point: {
						events: {
							mouseOver() {
								this.dataLabelUpper.css({
									color: "#1C2331"
								});
							},
							mouseOut() {
								this.dataLabelUpper.css({
									color: "#D2D3D6"
								});
							}
						}
					},
					colorByPoint: false,
					data: this.data.map((ele) => {
						return { ...ele, high: ele.high + componentContext.barDiffValue };
					}),
					states: {
						hover: {
							color: this.barHoverColor
						}
					}
				}
			]
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (dataLabel["point"]["options"]["caller"]) {
			case "pricing":
				const avgDiscount = dataLabel["point"]["options"]["avg_discount"];
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:14rem;border:0.0625rem solid ${
					TOOLTIPCOLOR["BG"]
				};border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${
				TOOLTIPCOLOR["VALUE"]
			};font-size:0.625rem;margin:0; z-index:1">${
					dataLabel["point"]["options"]["key"]
				}</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: row; gap:0.25rem;">
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2);padding-right:0.5rem;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Min. Price"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["low"]
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2)
			;padding-right:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Max. Price"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["high"] - componentContext.barDiffValue
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2);padding-right:0.5rem;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Avg. Price"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["avg_price"]
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Avg. Discount"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					avgDiscount === null
						? "-"
						: avgDiscount === 0
						? "0%"
						: `Upto
				${componentContext.commonFunctions.getSuffixedNumberFormat(avgDiscount)}%`
				}</span>
			</div>
			</div>
			</div>`;
			default:
				return "";
		}
	}
	ngOnDestroy() {
		this.listenerFn();
	}
}
