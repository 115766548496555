<div ngbDropdown class="d-inline-block position-static" display="dynamic" *ngIf="col?.method==='actions'">
    <span id="dropdownBasic1" ngbDropdownToggle
        class="cursor-pointer"><span class="roles-option"><i class="icon icon-Options"></i></span>
    </span>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="grid-action-dropdown">
        <button class="dropdown-item" (click)="editProfileRightPanel()" [disabled]="!getPermissionStatus(permissions)">
            <i class="icon icon-Edit"></i>
            <span translate>Edit Role</span>
        </button>
        <button [disabled]="userData.data.role.name === actionData.attributes.name || !getPermissionStatus(permissions)" class="dropdown-item" (click)="openModal(content)"><i
                class="icon  icon-Trash"></i><span>Delete
                Role</span></button>
    </div>
</div>
<div class="module-count on-hover-action" *ngIf="col?.method==='accessible_modules' && actionData?.attributes?.module_count" (click)="openAccessModal(contentAccess)">{{actionData?.attributes?.module_count===(totalModule) ? (('All' | translate)+' ('+actionData?.attributes?.module_count+')') : actionData?.attributes?.module_count}}</div>
<div class="module-count" *ngIf="col?.method==='accessible_modules' && !actionData?.attributes?.module_count">{{actionData?.attributes?.module_count}}</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>Delete Role</h4>
    </div>
    <div class="modal-body">
        <i class="icon  icon-Trash" ngbAutofocus></i>
        <p translate>Are you sure you want to delete this role?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate>Cancel</button>
        <button type="button" class="btn btn-primary btn-lg" (click)="[deleteRole(),modal.close('Close click')]" translate>Yes,
            Delete</button>
    </div>
</ng-template>
<ng-template #contentAccess let-modal>
    <div class="modal-access-body">
        <div class="role-header d-flex justify-content-between">
            <div class="role-name d-flex align-items-center">
                <span class="head" translate>Role</span>
                <div class="name">{{actionData?.attributes?.name}}</div>
            </div>
            <i class="icon icon-Close2 cursor-pointer" (click)="modal.close('Close click')">
            </i>
        </div>
        <div class="modules-access">
            <ng-template #htmlContent>
                <div class="access-info d-flex flex-column justify-content-start">
                    <div class="d-flex justify-content-start align-items-center" *ngFor="let items of accessParams">
                        <div class="access-code d-flex justify-content-center me-2 align-items-center" [ngClass]="items.code">{{items.code}} </div>
                        <div class="right-name" translate>{{items.name}}</div>
                    </div>
                </div>
            </ng-template>
            <div class="module-header d-flex align-items-center" translate>Modules Access <i class="icon icon-Info-circle" ngbAutofocus
                    placement="{{commonFunctions.rtlStatus ? 'left' : 'right'}}" [ngbTooltip]="htmlContent" tooltipClass="info-custom-tooltip role-info-tooltip"></i>
            </div>
            <div class="modules-access-content d-flex flex-wrap">
                <div class="modules d-flex align-items-center" *ngFor="let module of allModuleRoles">
                    <div class="module-name" translate>{{moduleNameMapping[module.module_code]}}</div>
                    <div class="d-flex align-items-center rights-gap">
                        <div class="access-rights d-flex align-items-center justify-content-center"
                            *ngFor="let item of getAccessMatrix(module)" [ngClass]="item">{{item}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-access-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate>Cancel</button>
    </div>
</ng-template>
