<div showTooltipOnTextOverflow [data]="commonFunctions.formatSimpleDate(data?.value)"
    *ngIf="data.key === 'created_at__date' && timeline !== 'daily'">
</div>
<div showTooltipOnTextOverflow [data]="getHour(data?.value)"
    *ngIf="data.key === 'created_at__date' && timeline === 'daily'">
</div>
<div *ngIf="data?.key === 'price_val' || data?.key === 'gmv_val'" class="align-right">
    <ng-container *ngIf="data.value > 0">
        {{currency?.symbol}}{{data.value | numberSuffix: 2 }}
    </ng-container>
    <ng-container *ngIf="!(data.value > 0)">
       {{data.value === 0 ? 0 : '-'}}
    </ng-container>
</div>
<div *ngIf="data?.key === 'discount_val'">
    <div class="badge-light-yellow badgeView d-inline-block discountView " *ngIf="data?.value > 0">{{data?.value}}% OFF </div>
    <div *ngIf="!(data?.value > 0)"> {{data.value === 0 ? 0 : '-'}}</div>
</div>
<!-- Rating Value -->
<div *ngIf="data?.key === 'ratings_val' && data.value >= 0">
    <ng-template #starLabel>
        <span class="star">&#x02605;</span>
    </ng-template>
    <div class="ratingLabel" [ngClass]="commonFunctions.getStarStatus(data.value)">
        {{data.value | numberSuffix: 2 }}<ngb-rating [max]="1" [starTemplate]="starLabel" [rate]="1">
        </ngb-rating>
    </div>
</div>


<div *ngIf="data?.key === 'views_val' || data?.key === 'likes_val'">
    <ng-container *ngIf="data.value > 0; else nData">
        {{data.value | numberSuffix: 2 }}
    </ng-container>
    <ng-template #nData>
        {{data.value === 0 ? 0 : '-'}}
    </ng-template>
</div>
<div *ngIf="data?.key !== 'discount_val' && data.key !== 'created_at__date' && data?.key !== 'price_val' && data?.key !== 'ratings_val' && data?.key !== 'gmv_val' && data?.key !== 'views_val' && data?.key !== 'likes_val'"
    showTooltipOnTextOverflow [data]="data?.value"
    [ngClass]="{'align-right': (data.key === 'quantity_sold_val' || data.key=== 'stock_available_val' || data.key=== 'out_of_stock_val')}">
</div>
