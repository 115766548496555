<!-- <div class="d-inline-block icon-cont" (click)="onGlobalConfigOpen(content)" ngbTooltip="{{'Preferences' | translate}}">
    <i class="icon icon-Eye"></i>
</div> -->
<div class="d-flex align-items-center justify-content-center icon-container" (click)="onGlobalConfigOpen(content)"
    ngbTooltip="{{'Preferences' | translate}}">
    <i class="icon icon-Binocular"></i><span class="text" translate>View As</span>
</div>
<ng-template #content let-modal>
    <div class="modal-header mb-3 p-0 d-flex justify-content-between align-items-center">
        <h4 class="modal-title" translate ngbAutoFocus>Preferences</h4>
        <i class="icon icon-Close2 cursor-pointer" placement="left" ngbTooltip="Close"
            tooltipClass="info-custom-tooltip" (click)="modal.close('Close click')"></i>
    </div>
    <div class="modal-body p-0">
        <!-- View As -->
        <div class="text-align-right-rtl input-container" *ngIf="userData?.data?.enabler_id">
            <label for="" translate>{{'View As' | translate}}</label>
            <ng-select class="global-brands select-text-wrap" [(ngModel)]="filters['enablersList'].selectedEnabler"
                [notFoundText]="'No Data Found' | translate" [loadingText]="'Loading...' | translate"
                [items]="filters['enablersList'].list" bindLabel="attributes.name"
                placeholder="{{filters['enablersList'].placeholder | translate}}" (change)="onChangeFilter($event, 'enabler')"
                [loading]="filters['enablersList'].isLoading" [clearable]="false">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="multi-select-option-container multi-text-wrap">
                        <span class="item-name" translate>{{item.attributes.name}}</span>
                    </div>
                </ng-template>
            </ng-select>
        </div>
        <!-- Currency -->
        <div class="text-align-right-rtl input-container" *ngIf="!showCurrencyFilter">
            <label for="" translate>{{'Country' | translate}}</label>
            <ng-select [disabled]="filters['currency'].disabled" #select appendTo="body"
                [items]="filters['currency'].list" [loading]="filters['currency'].loading" [notFoundText]="'No Data Found' | translate"
                [(ngModel)]="filters['currency'].selected" bindLabel="attributes.name" [loadingText]="'Loading...' | translate"
                [placeholder]="('Country' | translate)" [clearable]="false" (change)="onChangeFilter($event, 'currency')"
                (open)="onOpenCloseDropdown('currency')" (close)="onOpenCloseDropdown('currency')"
                [ngbTooltip]="!dropdownOpenStatus.currency?'Country':''" tooltipClass="info-custom-tooltip">
                <ng-template ng-label-tmp let-item="item">
                    <span class="currency-container"> {{item?.attributes?.symbol}} | </span>
                    {{item?.attributes?.country}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <span class="d-inline-block float-start curency-name">{{item?.attributes?.country}}</span>
                    <span class="float-end currency-symbol">{{item?.attributes?.name}}({{item?.attributes?.symbol}})</span>
                </ng-template>
            </ng-select>
            <!-- <p class="note mb-0" translate>
                Changing the currency filter will show product enlisted in respective retail channels with that currency. It doesnt convert currencies of products.
            </p> -->
        </div>
        <!-- Harmonised -->
        <div class="text-align-right-rtl input-container" *ngIf="showHarmonisation">
            <label for="" translate>{{'Show Harmonised' | translate}}</label>
            <ng-select [items]="filters['harmonise'].list" [(ngModel)]="filters['harmonise'].selected"
            bindLabel="name" [clearable]="false"  [loadingText]="'Loading...' | translate" (open)="onOpenCloseDropdown('viewBy')"
            (change)="onChangeFilter($event, 'harmonise')">
            <ng-template ng-label-tmp let-item="item">
                {{item.name | translate}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <span class="float-start">{{item.name | translate}}</span>
            </ng-template>
        </ng-select>
        </div>
         <!-- Language -->
         <div class="d-flex gap-14">
            <div class="text-align-right-rtl input-container">
                <label for="" translate>{{'Language' | translate}}</label>
                <ng-select #select [items]="filters['language'].list" [notFoundText]="'No Data Found' | translate"
                    [loadingText]="'Loading...' | translate" [clearable]="false" appendTo="body" bindLabel="name"
                    placeholder="{{'Select Language' | translate}}" (change)="onChangeFilter($event, 'language')"
                    [(ngModel)]="filters['language'].selected">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="modal-footer p-0">
        <button type="button" class="btn btn-primary btn-lg" (click)="onApply()" translate> Apply
        </button>
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate> Cancel
        </button>
    </div>
</ng-template>