import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-shared/common-services/http-services/http-request.service';
import { PAYLOAD } from '@ds-shared/enums/common.enum';
import { Resource, Service } from 'ngx-jsonapi';

export class SKU extends Resource {
	public attributes = {
		type: "",
		id: ""
	};
}

@Injectable({
	providedIn: "root"
})
export class SKUListService extends Service<SKU> {
	public resource = SKU;
  public type = 'sku-analysis';
	public ttl = 1;
}

@Injectable({
	providedIn: "root"
})
export class ProductListService extends Service<SKU> {
	public resource = SKU;
  public type = 'sku-analysis-product-grid';
	public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class SkuAnalysisService extends Service<SKU> {

  constructor(public http: HttpRequestService) {
		super();
	}

	private appendMarketplace(payload){
		if(payload.marketplace?.length){
		  return '&' + payload.marketplace.map((item,i)=>`filter[${PAYLOAD.MARKETPLACE_ID}][${i}]=${item.id}`).join('&');
		}
		return '';
	  }

	callSKUProductsTrend(skuID, payload, optionalParams) {
		return this.http.callGetApi(`reporting/sku-analysis-product-price-trend?filter[skus][0]=${skuID}&filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.START_DATE}]=${payload.timeline.startDate}&filter[${PAYLOAD.END_DATE}]=${payload.timeline.endDate}${this.appendMarketplace(payload)}${optionalParams?optionalParams:''}`)
	  }
}
