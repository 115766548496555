import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ProfileInformationService } from "@ds-private-layouts/scorecard/profile-information/profile-information.service";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import { RolePermissions } from "@ds-models/access-matrix.model";

@Component({
	selector: "app-product-info",
	templateUrl: "./product-info.component.html",
	styleUrls: ["./product-info.component.scss"]
})
export class ProductInfoComponent implements OnInit, OnDestroy {
	@Input() info_type: any;
	@Input() productDetail: any;
	@Input() customerProductInfo: any;
	@Output() onRefreshAction: EventEmitter<any> = new EventEmitter();
	public variantSubscription: Subscription;
	public variantsList: any[] = [];
	public dashboardCurrencySubscription: Subscription;
	public dashboardCurrencyData: any;
	public permissionForNotification: RolePermissions = new RolePermissions();
	public currencyDetails;
	constructor(
		private profileInformationService: ProfileInformationService,
		private dataTransferService: DataTransferService,
		public commonFunctions: CommonFunctionsService,
		public route: ActivatedRoute,
		private accessMatrixService: AccessMatrixService
	) {
		this.permissionForNotification =
			this.accessMatrixService.getPermissionByModule(
				AccessMatrix.Notifications
			);
	}

	ngOnInit(): void {
		this.dashboardCurrencySubscription?.unsubscribe();
		this.dashboardCurrencySubscription =
			this.dataTransferService.dashboardCurrencyData$.subscribe((data: any) => {
				this.dashboardCurrencyData = data;
			});
	}

	getVariantsList(product: any) {
		this.variantsList = [];
		this.variantSubscription?.unsubscribe();
		this.variantSubscription = this.profileInformationService
			.getVariantsList(product?.id)
			.subscribe(
				(res: any) => {
					if (res) this.variantsList = res?.data;
				},
				(err: any) => {
					this.variantsList = [];
				}
			);
	}
	onDeleteAction(event: any) {
		this.onRefreshAction.emit(event);
	}
	openNotification(product: any) {
		let currentProductId: any = null;
		this.route?.queryParams
			.subscribe((data: any) => {
				currentProductId = data?.productId;
			})
			?.unsubscribe();
		const parentPath = this.getCurrentParentRoute();
		let noteObj: any;
		if (this.info_type === "keyword") {
			noteObj = {
				marketplace: product?.marketplace,
				product: this.customerProductInfo[0].name,
				keyword: product?.keyword,
				id: product?.id
			};
		} else {
			noteObj = {
				marketplace: product?.marketplace,
				name: product?.name,
				id: product?.id
			};
		}
		this.commonFunctions.redirectToNotification(
			`${this.info_type === "keyword" ? "keywords" : "products"}`,
			this.commonFunctions.getEncodedData(JSON.stringify(noteObj)),
			null,
			parentPath,
			{ productId: currentProductId, customerProductId: null }
		);
	}

	getCurrentParentRoute() {
		const path = this.commonFunctions.getURLSegments();
		if (path[0] === 'build') return `${path[0]}/${path[1]}/${path[2]}/`;
		else if (path[0] === 'scorecard') return `${path[0]}/${path[1]}/`;
		else if (path[0] === 'insights') return `${path[0]}/`;
		return "";
	}

	ngOnDestroy() {
		this.variantSubscription?.unsubscribe();
		this.dashboardCurrencySubscription?.unsubscribe();
	}
}
