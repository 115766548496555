<div class="chart-content" [ngClass]="{'d-flex': chartData.meta.chartType.value === 'pie_chart'}">
    <highcharts-chart *ngIf="chartData.data && chartOptions" [ngClass]="{'pie': chartData.meta.chartType.value === 'pie_chart'}" [Highcharts]="highchartsData" [options]="chartOptions">
    </highcharts-chart>
    <div class="legends" *ngIf="chartData.meta.legends" [ngClass]="{'pie': chartData.meta.chartType.value === 'pie_chart'}">
        <div *ngIf="chartData.meta.chartType.value === 'pie_chart'">{{chartData.meta?.axis?.x}}</div>
        <div class="legends-container">
            <div class="info-text" *ngFor="let legendItem of this.chartData.data.series; let i = index">
                <span class="info-color" [ngStyle]="{'background-color':legendItem.color}"></span>
                <span class="text" showTooltipOnTextOverflow [data]="legendItem.name | translate"></span>
            </div>
        </div>
    </div>
</div>