import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-common-services/http-services/http-request.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { PAYLOAD } from '@ds-shared/enums/common.enum';
import { Service, Resource } from 'ngx-jsonapi';
import { forkJoin, Observable } from 'rxjs';

export class Discover extends Resource {
  public attributes = {
    type: '',
    id: '',
    store: ''
  };
}

@Injectable({
  providedIn: 'root'
})
export class AvailableProductsService extends Service<Discover>{
  public resource = Discover;
  public type = 'available-products';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class AvailableProductService extends Service<Discover>{
  public resource = Discover;
  public type = 'product-info';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class CategoryProductsService extends Service<Discover>{
  public resource = Discover;
  public type = '';
  public ttl = 1;
  public setType(val: any) {
    this.type = val;
  }
}



@Injectable({
  providedIn: 'root'
})
export class CategoryStoreExtraService extends Service<Discover>{
  public resource = Discover;
  public type = 'category-store-list';
  public ttl = 1;
}


@Injectable({
  providedIn: 'root'
})
export class ProductsWithMatches extends Service<Discover>{
  public resource = Discover;
  public type = 'discover/product-list';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class AvailableStoreService extends Service<Discover>{
  public resource = Discover;
  public type = 'product-matching-available';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class DiscoverService extends Service<Discover>  {

  constructor(public http: HttpRequestService, public commonFunctions: CommonFunctionsService) {
    super();
  }

  getCompetitorProductList(customerProductid, storeId, pageNumber) {
    return this.http.callGetApi('discover/deep-comparison/competitor-products?filter[customer_product_id]=' + customerProductid + "&filter[store]=" + storeId + "&page[size]=10&page[number]=" + pageNumber);
  }
  searchCompetitorProductList(customerProductid, storeId, text) {
    return this.http.callGetApi('discover/deep-comparison/competitor-products?filter[customer_product_id]=' + customerProductid + "&filter[store]=" + storeId + '&filter[search_pattern]=' + text);
  }
  getBrandFilters(customerProductid: number, scorecardType: string, type: string, filterType: string, currencyId: number) {
    return this.http.callGetApi(`discover/product-matching-filters?filter[scorecard_id]=${customerProductid}&filter[scorecard_type]=${scorecardType}&filter[type]=${type}&filter[filter_type]=${filterType}`);
  }
  getMarketplaceFilters(customerProductid: number, scorecardType: string, type: string, filterType: string, currencyId: number) {
    return this.http.callGetApi(`discover/product-matching-filters?filter[scorecard_id]=${customerProductid}&filter[scorecard_type]=${scorecardType}&filter[type]=${type}&filter[filter_type]=${filterType}`);
  }
  getCategoryFilters(customerProductid: number, scorecardType: string, type: string, filterType: string, currencyId: number) {
    return this.http.callGetApi(`discover/product-matching-filters?filter[scorecard_id]=${customerProductid}&filter[scorecard_type]=${scorecardType}&filter[type]=${type}&filter[filter_type]=${filterType}`);
  }
  getKeywordFilters(customerProductid) {
    return this.http.callGetApi('discover/keyword-filter?marketplace_product_id=' + customerProductid);
  }
  getProductList(storeId: number, pageNumber: number) {
    return this.http.callGetApi('discover/search-customer-product?filter[store]=' + storeId + "&page[size]=10&page[number]=" + pageNumber);
  }
  searchProductList(storeId: number, text) {
    return this.http.callGetApi('discover/search-customer-product?filter[store]=' + storeId + '&search_keyword=' + text);
  }
  getSortOptions() {
    return this.http.callGetApi('discover/sortlist');
  }
  getDeepCompareExport(id): any {
    let path = 'discover/deep-comparison/export?matching_id=' + id
    return this.http.getFileAsBlob(path);
  }
  //Discover New API's
  getRecentlyComparedData(currencyId: number) {
    return this.http.callGetApi(`discover/recently-compared?filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getRecentlyAddedData(currencyId: number) {
    return this.http.callGetApi(`discover/recently-added?filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getSignificantChangeData(currencyId: number) {
    return this.http.callGetApi(`discover/significant-kpi-change?filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  searchProduct(searchProduct: any, pageNumber: any, currencyId: number) {
    return this.http.callGetApi(`discover/search-product?page[size]=10&page[number]=${pageNumber}${searchProduct && `&searched_product=${searchProduct}`}&filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getCategoryPerformanceData(distributionType: any, currencyId: number) {
    return this.http.callGetApi(`discover/scraped-product-count-basis-on-distribution?distribution_type=${distributionType}&filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getScrappingReport(currencyId: number) {
    return this.http.callGetApi(`discover/scrapping-analysis?filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getProductInfo(productId: number, currencyId: number) {
    return this.http.callGetApi(`scorecard/banner?marketplace_product_id=${productId}`);
  }
  getCompetitorProductInfo(customerProductid, type: string, competitorProductId, currencyId: number) {
    return this.http.callGetApi(`discover/available-products?filter[scorecard_id]=${customerProductid}&filter[scorecard_type]=${type}&filter[competitor_product_id]=${competitorProductId}&page[size]=25`);
  }
  getDeepComparison(customerProductId: number, competitorProductId: number, currencyId: number) {
    const customerData = this.http.callGetApi(`discover/deep-compare?marketplace_product_id=${customerProductId}`);
    const competitorData = this.http.callGetApi(`discover/deep-compare?marketplace_product_id=${competitorProductId}`);
    return forkJoin([customerData, competitorData]);
  }
  getVariantsList(productId: number) {
    return this.http.callGetApi(`product/marketplace-product-variants?id=${productId}`);
  }
  getMarketplaceList(pageNumber: any, search: string, currencyId: number) {
    return this.http.callGetApi(`scorecard/marketplaces?page[size]=10&page[number]=${pageNumber}${search && `&filter[search]=${search}`}`);
  }
  getStoreList(marketplaceId: any, pageNumber: any, search: string, currencyId: number) {
    return this.http.callGetApi(`scorecard/stores?page[size]=10&page[number]=${pageNumber}&marketplace_id=${marketplaceId}${search && `&filter[search]=${search}`}&filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getProductsList(marketplaceId: any, storeId: any, pageNumber: any, search: string, currencyId: number) {
    return this.http.callGetApi(`scorecard/products?page[size]=10&page[number]=${pageNumber}&marketplace_id=${marketplaceId}&store_id=${storeId}${search && `&filter[search]=${search}`}&filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getRangeFilters(customerProductid, scorCardtype: string, type: string, currencyId: number) {
    return this.http.callGetApi(`discover/range-filter?filter[scorecard_id]=${customerProductid}&filter[scorecard_type]=${scorCardtype}&filter[type]=${type}`);
  }
  getMissingBuzzwords(customerProductid: number | string, profileID?: number, type?: string) {
    return this.http.callGetApi(`discover/missing-buzzwords?${type === 'store' ? `store_id=${customerProductid}${profileID ? `&profile_id=${profileID}` : ''}` : `marketplace_product_id=${customerProductid}`}`)
  }
  getMissingKeywordProdList(keyword: string, customerProductid: number | string, profile_id?: number, type?: string) {
    return this.http.callGetApi(`scorecard/keywords-products?phrase=${keyword}&${type === 'store' ? `store_id=${customerProductid}${profile_id ? `&profile_id=${profile_id}` : ''}` : `marketplace_product_id=${customerProductid}`}`)
  }
  getCategoryList(currencyId: number) {
    return this.http.callGetApi(`discover/category-banner?filter[${PAYLOAD.CURRENCY_ID}]=${currencyId}`);
  }
  getCategoryStoreList(trakerId: string, profileId: number, filterType: string, scoreCardtype: string, type: string) {
    return this.http.callGetApi(`discover/product-matching-filters?filter[${trakerId}]=${profileId}&filter[filter_type]=${filterType}&filter[scorecard_type]=${scoreCardtype}&filter[type]=${type}`)
  }
  getSegmentDropdownData(trakerId: string, profileId: number, segment: string, scoreCardtype: string, type: string) {
    return this.http.callGetApi(`discover/product-matching-filters?filter[${trakerId}]=${profileId}&filter[filter_type]=${segment}&filter[scorecard_type]=${scoreCardtype}&filter[type]=${type}`)
  }
  getStoreSegmentDropdownData(trakerId: string, profileId: number, segment: string, scoreCardtype: string, type: string) {
    return this.http.callGetApi(`discover/product-matching-filters?filter[${trakerId}]=${profileId}&filter[filter_type]=${segment}&filter[scorecard_type]=${scoreCardtype}&filter[type]=${type}`)
  }
  getPricingPosition(trakerId: string, profileId: number, scoreCardtype: string) {
    return this.http.callGetApi(`discover/pricing-position?filter[${trakerId}]=${profileId}&filter[scorecard_type]=${scoreCardtype}`)
  }
  getPricingTrend(trakerId: string, profileId: any, scoreCardtype: string, limit: number = 20) {
    return this.http.callGetApi(`discover/pricing-trend?filter[${trakerId}]=${profileId}&limit=${limit}&filter[scorecard_type]=${scoreCardtype}`)
  }
  getPricingHistory(currencyId: number, productId: number) {
    return this.http.callGetApi(`discover/pricing-history?filter[currency_id]=${currencyId}&filter[product_id]=${productId}`)
  }
  getHeatmapData(trakerId: string, profileId: number, scoreCardtype: string, metric: string, year: number, month: number) {
    return this.http.callGetApi(`discover/calendar-heatmap?filter[${trakerId}]=${profileId}&filter[scorecard_type]=${scoreCardtype}&metric=${metric}&year=${year}&month=${month}`);
  }
  getCompareData(trakerId: string, profileId: number, scoreCardtype: string, metric: string, latest_date) {
    return this.http.callGetApi(`discover/heatmap-area-graph?filter[${trakerId}]=${profileId}&filter[scorecard_type]=${scoreCardtype}&metric=${metric}&latest_date=${latest_date}`);
  }
  getProductSentimentAnalysisWithDetails(productId: number) {
    const pathForSentimentReview = `discover/review?marketplace_product_id=${productId}`;
    const pathForSentimentDetails = `discover/sentiment-analysis?marketplace_product_id=${productId}`;
    const reviewDetails = this.http.callGetApi(pathForSentimentReview);
    const sentimentDetails = this.http.callGetApi(pathForSentimentDetails);
    return forkJoin([reviewDetails, sentimentDetails]);
  }
  getProductInsights(currencyId: number, productId: number) {
    return this.http.callGetApi(`discover/products-insights?filter[currency_id]=${currencyId}&marketplace_product_id=${productId}`)
  }
  getCategoryDetails(currencyId: number, profileId: number) {
    return this.http.callGetApi(`discover/category-banner?filter[currency_id]=${currencyId}&filter[profiles][0]=${profileId}`)
  }
  getReviewsList(marketplace_product_id: number, sentiment_type: string, sentiment_phrase: string) {
    return this.http.callGetApi(`discover/product-reviews-list?marketplace_product_id=${marketplace_product_id}${sentiment_type ? `&sentiment_type=${sentiment_type}` : ''}${sentiment_phrase ? `&sentiment_phrase=${sentiment_phrase}` : ''}`);
  }
  getStoreBannerData(store_id: number, profile_id: number) {
    return this.http.callGetApi(`discover/customer-product-store/store?filter[store_id]=${store_id}&filter[profile_id]=${profile_id}`);
  }
  getStoreMatchingInsightsData(currency_id: number | string, store_id: number, profile_id: number) {
    return this.http.callGetApi(`discover/products-insights?filter[currency_id]=${currency_id}&store_id=${store_id}${profile_id ? `&profile_id=${profile_id}` : ''}`);
  }
  getDeepCompareData(compareType: string, compareId: number) {
    return this.http.callGetApi(`discover/all-type-deep-compare?compare_type=${compareType}&compare_id=${compareId}`);
  }
  getPerformaceData(compareType: string, compareId: number, currencyId: number, performaceType: string) {
    return this.http.callGetApi(`discover/extreme-selling/${performaceType}?filter[currency_id]=${currencyId}&filter[timeline]=last_90_days&filter[${compareType}_ids][0]=${compareId}`);
  }
  getCompareList(path: string, payload: any) {
    const data = this.commonFunctions.getPayloadFromObject(payload)
    return this.http.callGetApi(`${path}?${data}`);
  }
}