<div class="share-container ds-box pt-2">
    <div class="header-container d-flex align-items-center justify-content-between">
            <div class=" d-flex gap-8">
                <span class="ds-box-heading" translate>{{shareType | titlecase}} Share</span>
                <ng-template [ngIf]="dateFilter">
                    <span class="d-inline info-text" *ngIf="dateFilter?.startDate == dateFilter?.endDate">{{'Showing data for' | translate}} {{getDisplayDate(dateFilter?.startDate)}}</span>
                    <span class="d-inline info-text" *ngIf="dateFilter?.startDate != dateFilter?.endDate">{{'Showing data from' | translate}} {{getDisplayDate(dateFilter?.startDate)}} {{'to'
                        | translate}} {{getDisplayDate(dateFilter?.endDate)}}</span>
                </ng-template>
            </div>
            <div class="d-flex align-items-center">
                <app-common-piechart-slicing [data]="scorecardShareData.sliceData" [type]="shareType"
                    (spliceFilterParamEvent)="onPieSplice($event,'shareType')" [chartName]="shareType" [localFilter]="optionalParams"></app-common-piechart-slicing>
                <app-common-action-dropdown *ngIf="scorecardShareData.graph?.length" [filename]="exportDetails.fileName"
                    [pieData]="scorecardShareData.graph" [params]="exportDetails.params" [path]="exportDetails.path" [spliceFilter]="spliceFilter" [imageDownloadObj]="imageDownloadObj" [disableIcon]="!scorecardShareData.graph?.length"></app-common-action-dropdown>
            </div>
    </div>
    <div class="graph-container" *ngIf="scorecardShareData.graph?.length;else noShareData">
        <section id="{{imageDownloadObj.mainDiv}}">
            <app-pie-chart [data]="scorecardShareData.graph"
                [disableHalo]="true" [caller]="'scorecard_sos'" [showDataLabel]="true" [borderWidth]="0"></app-pie-chart>
        </section>
        <div class="legends">
            <div class="legends-container d-flex">
                <div class="info-text" *ngFor="let legendItem of scorecardShareData.graph.slice(0,3); let i = index">
                    <div class="info-color" [ngStyle]="{'background-color':legendItem?.color}"></div>
                    <span class="text" showTooltipOnTextOverflow [data]="legendItem?.name">{{legendItem?.name |
                        translate}}</span>
                </div>
        
                <div ngbDropdown class="d-inline-block position-static" display="dynamic" *ngIf="scorecardShareData.graph?.length > 3">
                    <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span
                            class="legends-list">{{scorecardShareData.graph?.length > 3 ? ('+'+
                            (scorecardShareData.graph?.length-3)) : ""}}</span>
                    </span>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown">
                        <div class="info-text"
                            *ngFor="let legendItem of scorecardShareData.graph.slice(3,scorecardShareData.graph.length); let i = index">
                            <div class="info-color" [ngStyle]="{'background-color':legendItem.color}"></div>
                            <span class="text" showTooltipOnTextOverflow [data]="legendItem?.name">{{legendItem?.name |
                                translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- This is dummy code for download Image -->
        <div class="legends d-none" id="{{imageDownloadObj.legendDiv}}">
            <div class="legends-container d-flex flex-wrap">
                <div class="info-text" *ngFor="let legendItem of scorecardShareData.graph; let i = index">
                    <div class="info-color" [ngStyle]="{'background-color':legendItem?.color}"></div>
                    <span class="text" showTooltipOnTextOverflow [data]="legendItem?.name">{{legendItem?.name |
                        translate}}</span>
                </div>
            </div>
        </div>
        <!-- code ends here -->
    </div>
</div>
<ng-template #noShareData>
    <div *ngIf='!scorecardShareData.graph?.length' class="no-data-common">
        <div *ngIf="!scorecardShareData.isLoading" class="no-data-found" translate>
            <div class="img"><i class="icon icon-Search"></i></div>
            <div class="no-data-text" translate>No data present</div>
        </div>
        <div *ngIf="scorecardShareData.isLoading" class="loading-panel">
            <img ngSrc="/assets/images/spinner.svg" alt="" height="200" width="200"/>
        </div>
    </div>
</ng-template>
<app-common-grid-pop-up></app-common-grid-pop-up>