/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { Subject, filter, takeUntil } from "rxjs";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
let componentContext;
import Wordcloud from "highcharts/modules/wordcloud";
Wordcloud(Highcharts);

@Component({
	selector: "app-word-cloud",
	templateUrl: "./word-cloud.component.html",
	styleUrls: ["./word-cloud.component.scss"]
})
export class WordCloudComponent implements OnInit, AfterViewInit {
	@Input() public data;
	@Input() public caller = "default";
	private destroy$: Subject<boolean> = new Subject();
	public Highcharts: typeof Highcharts = Highcharts; // required
	public highchartsData: typeof Highcharts;
	public chartRef;
	public chartOptions;

	constructor(
		private dataTransferService: DataTransferService,
		private commonFunctions: CommonFunctionsService
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				thousandsSep: ","
			}
		});
	}

	ngAfterViewInit(): void {
		this.renderWordCloud();
	}

	renderWordCloud() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				type: "wordcloud",
				verticalAlign: "middle",
				floating: true,
				reflow: true,
				move: false,
				marginRight: 0,
				marginLeft: 0,
				marginBottom: 8
			},
			credits: {
				enabled: false
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: false,
				hideDelay: 100,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true
			},
			plotOptions: {
				series: {
					pointPadding: 0,
					cursor: "pointer",
					lineWidth: 1.8,
					states: {
						hover: {
							enabled: true,
							color: "#ff5e00"
						}
					},
					events: {
						click: (ev) => {
							this.dataTransferService.sendGraphSliceData(ev.point.options);
						}
					}
				}
			},
			title: {
				text: ""
			},

			series: [
				{
					type: "wordcloud",
					data: this.data,
					name: "Occurrences",
					minFontSize: 10
				}
			]
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		return `<div
    style="background-color:${
			TOOLTIPCOLOR["BG"]
		};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${
			TOOLTIPCOLOR["TEXT"]
		};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
			dataLabel["point"]["options"]["name"]
		)}</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
        <div style="display:flex;flex-direction: column;">
            <span style="color:${
							TOOLTIPCOLOR["VALUE"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
			dataLabel["point"]["options"]["weight"]
		)}</span>
            <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
			"Products"
		)}</span>
        </div>
    </div>
</div>`;
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
