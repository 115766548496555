<div
	class="manage-column-container d-flex"
	[ngClass]="{'justify-content-end':moduleType?.name==='scraed-product'}"
>
	<div
		ngbDropdown
		class="d-inline-block more-drop"
		[placement]="commonFunctions.rtlStatus?'bottom-left':'bottom-right'"
	>
		<div
			id="dropdownForm1"
			ngbDropdownToggle
			class="manage-field-box"
			placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
			ngbTooltip="{{ 'Manage Columns' | translate }}"
			tooltipClass="info-custom-tooltip"
		>
			<i class="icon icon-Settings-alt"></i>
		</div>
		<div
			ngbDropdownMenu
			aria-labelledby="dropdownForm1"
			class="manage-columns-container"
		>
			<div class="info-text" translate>Manage Columns</div>
			<ng-scrollbar>
				<div class="more-container">
					<div class="checkbox-container more-item mb-3">
						<label class="checkbox" [for]="'select_all'+moduleType.name">
							<input
								type="checkbox"
								name="checkbox"
								[id]="'select_all'+moduleType.name"
								(change)="onSelectAllColumn($event)"
								[checked]="isEveryColumnChecked()"
							/>
							<div class="checkbox-section">
								<i class="icon icon-Check"></i>
							</div>
							<span class="fil-text user-select-none"
								>{{'Select All' | translate}}</span
							>
						</label>
					</div>
					<ng-container *ngFor="let col of columns; let i = index">
						<div
							class="checkbox-container more-item"
							*ngIf="col.name && !hideColumn.includes(col.name)"
						>
							<label
								class="checkbox"
								[for]="(col?.name)?col.name+moduleType.name+i:'actions'"
							>
								<input
									type="checkbox"
									name="checkbox"
									[id]="(col?.name)?col.name+moduleType.name+i:'actions'"
									[(ngModel)]="col.isChecked"
									[disabled]="i===0 || (moduleType?.bulkAction && i===1) || col?.disableColoumn"
									(change)="onSingleCheckChange($event,i)"
								/>
								<div class="checkbox-section">
									<i class="icon icon-Check"></i>
								</div>
								<span class="fil-text user-select-none"
									>{{(col?.name)?(col.name | translate):('Actions' |
									translate)}}</span
								>
							</label>
						</div>
					</ng-container>
				</div>
			</ng-scrollbar>
		</div>
	</div>
</div>
