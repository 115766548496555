import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from "highcharts/highstock";
let componentContext;

@Component({
  selector: 'app-all-charts',
  templateUrl: './all-charts.component.html',
  styleUrls: ['./all-charts.component.scss']
})
export class AllChartsComponent implements OnInit {

  public Highcharts: typeof Highcharts = Highcharts;
  public highchartsData: typeof Highcharts;
  public chartOptions: any;
  @Input() chartData: any;
  constructor() { }

  ngOnInit(): void {
    componentContext = this;
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    componentContext = this;
    // this.highchartsData = Highcharts
    this.chartOptions = null;
    setTimeout(() => {
      if (changes?.chartData?.currentValue) {
        if (this.chartData.meta.chartType.value === 'bar_chart') {
          this.getBarChart(this.chartData.data.categories,this.chartData.data.series);
        }
        else if (this.chartData.meta.chartType.value === 'line_chart' || this.chartData.meta.chartType.value === 'step_line_chart') {
          this.getLineChart(this.chartData.data.categories, this.chartData.data.series);
        }
        else if (this.chartData.meta.chartType.value === 'column_chart') {
          this.getColumnChart(this.chartData.data.categories, this.chartData.data.series);
        }
        else if (this.chartData.meta.chartType.value === 'pie_chart') {
          this.getPieChart(this.chartData.data.series);
        }
        else if (this.chartData.meta.chartType.value === 'stacked_bar_chart') {
          this.getStackedBarChart(this.chartData.data.categories, this.chartData.data.series);
        }
      }
    },0)
  }
  getBarChart(categories: string[] ,series: any) {
    this.highchartsData = Highcharts;
    this.chartOptions = {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      scrollbar: {
        enabled: categories?.length > 8,
        barBackgroundColor: '#B3B3B3',
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: '#B3B3B3',
        buttonBorderWidth: 0,
        buttonBorderRadius: 7,
        trackBackgroundColor: 'none',
        trackBorderWidth: 1,
        trackBorderRadius: 8,
        trackBorderColor: '#CCC',
        rifleColor: 'transparent'
      },
      xAxis: {
        type: 'category',
        lineColor: "transparent",
        gridLineColor: "transparent",
        categories: categories,
        title: {
          text: this.chartData.meta?.axis?.x,
          style: {
            color: '#1C2331'
          }
        },
        max: categories?.length > 8 ? 7 : categories?.length - 1,
        tickLength: 0,
        labels: {
          style: {
            color: '#A4A7AD'
          }
        }
      },
      yAxis: {
        title: {
          text: this.chartData.meta?.axis?.y,
          style: {
            color: '#1C2331'
          }
        },
        labels: {
          formatter: function () {
            return `${this.value ? (Highcharts.numberFormat(this.value, 2) + '') : 0}`;
          },
          style: {
            color: '#A4A7AD'
          }
        },
        tickInterval: 1
      },
      tooltip: {
        split: false,
        valueSuffix: '',
        backgroundColor: '#FFFFFF',
        borderColor: 'rgba(214,223,237,0.39)',
        borderRadius: 2,
        borderWidth: 0,
        shadow: true,
        formatter: function () {
          return `<div style="display:flex;flex-direction:column;">
          <div style="display:flex;align-items:center;"><span style="width:0.625rem;height:0.625rem;background-color:${this.color};border-radius:0.125rem;"></span><span style="margin-left:0.375rem;;color:#777B83"><span style="color:#1C2331">${this.x}: </span><span style="color: #1C2331;font-size: 0.875rem;font-weight:900">${Highcharts.numberFormat(this.y, 2)}</span></span></div>
        </div>`
        },
        shared: false,
        useHTML: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            formatter: this.formatterFunction
          }
        },
        series: {
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 30
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: series
    }
  }
  getStackedBarChart(categories: string[], series: any) {
    this.highchartsData = Highcharts;
    this.chartOptions = {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      scrollbar: {
        enabled: categories?.length > 8,
        barBackgroundColor: '#B3B3B3',
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: '#B3B3B3',
        buttonBorderWidth: 0,
        buttonBorderRadius: 7,
        trackBackgroundColor: 'none',
        trackBorderWidth: 1,
        trackBorderRadius: 8,
        trackBorderColor: '#CCC',
        rifleColor: 'transparent'
      },
      xAxis: {
        type: 'category',
        lineColor: "transparent",
        gridLineColor: "transparent",
        categories: categories,
        title: {
          text: this.chartData.meta?.axis?.x,
          style: {
            color: '#1C2331'
          }
        },
        max: categories?.length > 8 ? 7 : categories?.length - 1,
        tickLength: 0,
        labels: {
          style: {
            color: '#A4A7AD'
          }
        }
      },
      yAxis: {
        // lineColor: "transparent",
        // gridLineColor: "transparent",
        title: {
          text: this.chartData.meta?.axis?.y,
          style: {
            color: '#1C2331'
          }
        },
        labels: {
          formatter: function () {
            return `${this.value ? (Highcharts.numberFormat(this.value, 2) + '') : 0}`;
          },
          style: {
            color: '#A4A7AD'
          }
        },
        tickInterval: 1
      },
      tooltip: {
        split: false,
        valueSuffix: '',
        backgroundColor: '#FFFFFF',
        borderColor: 'rgba(214,223,237,0.39)',
        borderRadius: 2,
        borderWidth: 0,
        shadow: true,
        formatter: function () {
          return `<div style="display:flex;flex-direction:column;">
          <div style="display:flex;align-items:center;"><span style="width:0.625rem;height:0.625rem;background-color:${this.color};border-radius:0.125rem;"></span><span style="margin-left:0.375rem;;color:#777B83"><span style="color:#1C2331">${this.x}: </span><span style="color: #1C2331;font-size: 0.875rem;font-weight:900">${Highcharts.numberFormat(this.y, 2)}</span></span></div>
        </div>`
        },
        shared: false,
        useHTML: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            formatter: this.formatterFunction
          }
        },
        series: {
          stacking: 'normal',
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 30
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: series
    }
  }
  public getColumnChart(categories: string[], series: any) {
    this.highchartsData = Highcharts;
    this.chartOptions = {
      chart: {
        type: "column"
      },
      title: {
        verticalAlign: "middle",
        floating: true,
        text: "",
        style: false
      },
      tooltip: {
        split: false,
        valueSuffix: '',
        backgroundColor: '#FFFFFF',
        borderColor: 'rgba(214,223,237,0.39)',
        borderRadius: 2,
        borderWidth: 0,
        shadow: true,
        formatter: function () {
          return `<div style="display:flex;flex-direction:column;">
          <div style="display:flex;align-items:center;"><span style="width:0.625rem;height:0.625rem;background-color:${this.color};border-radius:0.125rem;"></span><span style="margin-left:0.375rem;;color:#777B83"><span style="color:#1C2331">${this.x}: </span><span style="color: #1C2331;font-size: 0.875rem;font-weight:900">${Highcharts.numberFormat(this.y, 2)}</span></span></div>
        </div>`
        },
        shared: false,
        useHTML: true
      },
      scrollbar: {
        enabled: categories?.length > 8,
        barBackgroundColor: '#B3B3B3',
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: '#B3B3B3',
        buttonBorderWidth: 0,
        buttonBorderRadius: 7,
        trackBackgroundColor: 'none',
        trackBorderWidth: 1,
        trackBorderRadius: 8,
        trackBorderColor: '#CCC',
        rifleColor: 'transparent'
      },
      xAxis: {
        categories: categories,
        max: categories?.length > 8 ? 7 : categories?.length - 1,
        title: {
          text: this.chartData.meta?.axis?.x,
          style: {
            color: '#1C2331'
          }
        },
        labels: {
          style: {
            color: '#A4A7AD'
          }
        }
      },

      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: this.formatterFunction
          }
        },
        series: {
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 30
        }
      },
      yAxis: {
        title: {
          text: this.chartData.meta?.axis?.y,
          style: {
            color: '#1C2331'
          }
        },
        labels: {
          formatter: function () {
            return `${this.value ? (Highcharts.numberFormat(this.value, 2) + '') : 0}`;
          },
          style: {
            color: '#A4A7AD'
          }
        },
        tickInterval: 1
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      series: series
    };
  }
  getLineChart(categories: string[], series: any) {
    this.highchartsData = Highcharts;
    this.chartOptions = {
      xAxis: {
        categories: categories,
        scrollbar: {
          enabled: categories?.length > 10,
          barBackgroundColor: "#B3B3B3",
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: "#B3B3B3",
          buttonBorderWidth: 0,
          buttonBorderRadius: 7,
          trackBackgroundColor: "none",
          trackBorderWidth: 1,
          trackBorderRadius: 8,
          trackBorderColor: "#CCC",
          rifleColor: "transparent"
        },
        max: categories?.length > 10 ? 9 : categories?.length - 1,
        title: {
          text: this.chartData.meta?.axis?.x,
          style: {
            color: '#1C2331'
          }
        },
        labels: {
          style: {
            color: '#A4A7AD'
          }
        }
      },
      yAxis: {
        labels: {
          formatter: function () {
            return `${this.value ? (Highcharts.numberFormat(this.value, 2) + '') : 0}`;
          },
          style: {
            color: '#A4A7AD'
          }
        },
        title: {
          text: this.chartData.meta?.axis?.y,
          style: {
            color: '#1C2331'
          }
        },
        tickInterval: 1
      },
      title: {
        text: ''
      },
      tooltip: {
        split: false,
        valueSuffix: '',
        backgroundColor: '#FFFFFF',
        borderColor: 'rgba(214,223,237,0.39)',
        borderRadius: 2,
        borderWidth: 0,
        shadow: true,
        formatter: function () {
          return `<div style="display:flex;flex-direction:column;">
          <div style="display:flex;align-items:center;"><span style="width:0.625rem;height:0.625rem;background-color:${this.color};border-radius:0.125rem;"></span><span style="margin-left:0.375rem;;color:#777B83"><span style="color:#1C2331">${this.x}: </span><span style="color: #1C2331;font-size: 0.875rem;font-weight:900">${Highcharts.numberFormat(this.y, 2)}</span></span></div>
        </div>`
        },
        shared: false,
        useHTML: true
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: this.formatterFunction
          }
        }
      },
      series: series,
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
    }
  }

  public getPieChart(shareData: any) {
    this.highchartsData = Highcharts;
    this.chartOptions = {
      chart: {
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        type: "pie",
        plotShadow: false,
        verticalAlign: "middle",
        floating: true,
        move: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          size: "80%",
          borderWidth: 2,
          borderColor: "#ffffff",
          slicedOffset: 0,
          cursor: "pointer",
          move: false,
          dataLabels: {
            connectorWidth: 0,
            enabled: false
          }
        }
      },
      tooltip: {
        split: false,
        valueSuffix: '',
        backgroundColor: '#FFFFFF',
        borderColor: 'rgba(214,223,237,0.39)',
        borderRadius: 2,
        borderWidth: 0,
        shadow: true,
        formatter: function () {
          return `<div style="display:flex;flex-direction:column;">
          <div style="display:flex;align-items:center;"><span style="width:0.625rem;height:0.625rem;background-color:${this.color};border-radius:0.125rem;"></span><span style="margin-left:0.375rem;;color:#777B83"><span style="color:#1C2331">${this.point.name}: </span><span style="color: #1C2331;font-size: 0.875rem;font-weight:900">${this.y}</span></span></div>
        </div>`
        },
        shared: false,
        useHTML: true
      },
      title: {
        verticalAlign: "middle",
        floating: true,
        text: "",
        style: false
      },
      legend: {
        enabled: false
      },

      series: [
        {
          allowPointSelect: true,
          type: "pie",
          name: "Value",
          data: shareData
        }
      ]
    };
  }

  formatterFunction(): string {
    const dataLabel = this as Highcharts.DataLabelsOptions
    return componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y);
  }
  

}