import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-common-services/http-services/http-request.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { PAYLOAD } from '@ds-shared/enums/common.enum';
import { Service, Resource } from 'ngx-jsonapi';

// Common Resource
export class Notification extends Resource {
  public attributes = {
    type: '',
    id: '',
  };
}

@Injectable({
  providedIn: 'root'
})

export class StoreNotificationService extends Service<Notification>{
  public resource = Notification;
  public type = 'store-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class ProductNotificationService extends Service<Notification>{
  public resource = Notification;
  public type = 'product-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class KeywordNotificationService extends Service<Notification>{
  public resource = Notification;
  public type = 'keyword-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class CategoryNotificationService extends Service<Notification>{
  public resource = Notification;
  public type = 'category-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class BrandNotificationService extends Service<Notification>{
  public resource = Notification;
  public type = 'brand-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class ProfileNotificationService extends Service<Notification>{
  public resource = Notification;
  public type = 'profile-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class StoreNotificationExtraService extends Service<Notification>{
  public resource = Notification;
  public type = 'notification-store-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class ProductNotificationExtraService extends Service<Notification>{
  public resource = Notification;
  public type = 'notification-product-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class KeywordNotificationExtraService extends Service<Notification>{
  public resource = Notification;
  public type = 'notification-keyword-config';
  public ttl = 1;
}
@Injectable({
  providedIn: 'root'
})

export class ProfileNotificationExtraService extends Service<Notification>{
  public resource = Notification;
  public type = 'notification-profile-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class CategoryNotificationExtraService extends Service<Notification>{
  public resource = Notification;
  public type = 'notification-category-config';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})

export class BrandNotificationExtraService extends Service<Notification>{
  public resource = Notification;
  public type = 'notification-brand-config';
  public ttl = 1;
}
// common ends

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends Service<Notification>{

  constructor(public http: HttpRequestService, public commonFunctionServices: CommonFunctionsService) { 
    super();
  }

  getNotifications(isCompetitor:boolean,type:string,pageNumber:number){
    return this.http.callGetApi('notification/recent?filter[competitor]='+isCompetitor+'&filter[type]='+type+"&page[size]=10"+"&page[number]="+pageNumber);
  }
  getHistoricNotifications(isCompetitor:boolean,type:string,pageNumber:number){
    return this.http.callGetApi('notification/historical-recent?filter[competitor]='+isCompetitor+'&filter[type]='+type+"&page[size]=10"+"&page[number]="+pageNumber);
  }
  getNotificationCount(isCompetitor:boolean,type:string){
    return this.http.callGetApi('notification/notification-count?competitor='+isCompetitor+'&type='+type);
  }
  getMetricFilterList(type,id){
    if(type=='store')return this.http.callGetApi('notifications/config-filters?filter[competitor]=false&store_id='+id+"&notification_type="+type);
    else return this.http.callGetApi('notifications/config-filters?filter[competitor]=false&marketplace_product_id='+id+"&notification_type="+type);
  }
  addNotification(notificationType,data){
    return this.http.callPostApi('notifications/'+notificationType, data);
  }
  searchMarketplace(text:string){
    return this.http.callGetApi('marketplaces?status[active]=true&filter[search_pattern]='+text)
  }
  searchStore(marketplaceId:number,text:string){
    return this.http.callGetApi('stores?marketplace_id='+marketplaceId+'&search_pattern='+text);
  }
  searchProduct(marketplaceId:number,text:string){
    return this.http.callGetApi('marketplace-products?marketplace_id='+marketplaceId+'&search_pattern='+text);
  }
  getCurrentKPIValue(notificationType,id,kpiId){
    if(notificationType=='store')return this.http.callGetApi('notifications/get-current-data-kpi?store_id='+id+"&kpi_id="+kpiId);
    else if(notificationType=='product')return this.http.callGetApi('notifications/get-current-data-kpi?marketplace_product_id='+id+"&kpi_id="+kpiId);
    else if(notificationType=='category')return this.http.callGetApi('notifications/get-current-data-kpi?category_id='+id+"&kpi_id="+kpiId);
    else if(notificationType=='brand')return this.http.callGetApi('notifications/get-current-data-kpi?brand_id='+id+"&kpi_id="+kpiId);
    return null;
  }
  getNotificationsFilter(type, isMetric){
    return this.http.callGetApi('notifications/config-filters?notification_type='+type+'&filter[competitor]='+isMetric);
  }
  getProductListInKeyword(marketplaceId,pageNumber){
    return this.http.callGetApi('notifications/get-product-dropdown?marketplace_id='+marketplaceId+"&page[size]=10&page[number]="+pageNumber);
  }
  getFilterListOfKeyword(){
    return this.http.callGetApi('notifications/get-notification-keywords');
  }
  markNotificationUnread(id){
    let path = 'notifications/edit-notification-read/'+id
    return this.http.callPostApi(path,'');
  }
  deleteRecentNotification(id){
    let path = 'notifications/delete-notification/'+id
    return this.http.callDeleteApi(path);
  }
  getMarketplaceList(pageNumber: number, payload: any = {}) {
    let path = `common/marketplaces`;
    if (Object.keys(payload)?.length) {
      path = `${path}?${this.commonFunctionServices.getPayloadFromObject(payload)}`
    }
    return this.http.callGetApi(path);
  }
  getStoreList(marketplaceId: number, pageNumber: number, search: string) {
    return this.http.callGetApi(`common/stores?page[size]=10&page[number]=${pageNumber}&filter[marketplace_ids][0]=${marketplaceId}${search ? `&filter[search]=${search}` : ''}&base_type=id`);
  }
  getMetricList(type: any, competitor: any) {
    return this.http.callGetApi(`notification/metric-filter?type=${type}&competitor=${competitor}`);
  }
  getKeywordList(marketplaceId: any) {
    return this.http.callGetApi(`common/profiles?filter[profile_type]=keyword&filter[marketplace_ids][0]=${marketplaceId}`);
  }
  getProfileList(marketplaceId: any, isCustomer:any, pageNumber: Number, search:any) {
    return this.http.callGetApi(`notification/select-profiles?filter[customer]=${isCustomer}&filter[marketplace]=${marketplaceId}&page[size]=10&page[number]=${pageNumber}${search ? `&filter[search]=${search}` : ''}`);
  }
  getCurrentKPIsValue(type: any, id: any, isCompetitor: any, kpiId: any, keywordId?: number) {
    if(type == 'store')
      return this.http.callGetApi(`notification/current-kpi-value?store_id=${id}&is_competitor=${isCompetitor}&kpi_id=${kpiId}`);
    else if (type == 'product')
      return this.http.callGetApi(`notification/current-kpi-value?product_id=${id}&is_competitor=${isCompetitor}&kpi_id=${kpiId}`);
    else if (type == 'profile')
      return this.http.callGetApi(`notification/current-kpi-value?profile_id=${id}&is_competitor=${isCompetitor}&kpi_id=${kpiId}`);
    else if (type == 'category')
      return this.http.callGetApi(`notification/current-kpi-value?category_id=${id}&is_competitor=${isCompetitor}&kpi_id=${kpiId}`);
    else if (type == 'brand')
      return this.http.callGetApi(`notification/current-kpi-value?brand_id=${id}&is_competitor=${isCompetitor}&kpi_id=${kpiId}`);
    else if (type == 'keyword')
      return this.http.callGetApi(`notification/current-kpi-value?product_id=${id}&is_competitor=${isCompetitor}&keyword=${keywordId}`);
    return null;
  }
  createNotification(notificationType: any, data: any) {
    return this.http.callPostApi(`notification/${notificationType}`,data);
  }
  updateNotification(notificationType: any, data: any, notificationId: any) {
    return this.http.callPutApi(`notification/${notificationType}/${notificationId}`, data);
  }
  deleteNotification(notificationType: any, notificationId:any) {
    return this.http.callDeleteApi(`notification/${notificationType}-config/${notificationId}`);
  }
  getNotificationData(notificationType: string, notificationId: number) {
    return this.http.callGetApi(`notification/${notificationType}-config/${notificationId}`);
  }
  clearRecentNotification(isCompetitor:boolean,type:string, id){
    if(id){
      let path = `notification/recent?filter[competitor]=${isCompetitor}&filter[type]=${type}&id=${id}`
      return this.http.callPutApi(path,{});
    }
    else{
      let path = `notification/recent?filter[competitor]=${isCompetitor}&filter[type]=${type}`
      return this.http.callPutApi(path,{});
    }
  }
  getCategoriesList(marketplaceId: number, pageNumber: number, search: string) {
    return this.http.callGetApi(`common/categories?page[size]=10&page[number]=${pageNumber}&filter[marketplace_ids][0]=${marketplaceId}${search ? `&filter[search]=${search}` : ''}&base_type=id`);
  }
  getBrandsList(marketplaceId: number, pageNumber: number, search: string) {
    return this.http.callGetApi(`common/brands?page[size]=10&page[number]=${pageNumber}&filter[marketplace_ids][0]=${marketplaceId}${search ? `&filter[search]=${search}` : ''}&base_type=id`);
  }
  getProductList(marketplaceId: number, pageNumber: number, search: string, profileId?: number) {
    return this.http.callGetApi(`common/products?page[size]=10&page[number]=${pageNumber}&filter[marketplace_ids][0]=${marketplaceId}${search ? `&filter[search]=${search}` : ''}${profileId ? `&filter[profile_ids][0]=${profileId}` : ''}&base_type=id`);
  }
}
