<!-- Customer Segmentation -->

<div class="ds-box">
  <div class="d-flex mb-3 justify-content-between align-items-center">
    <div class="d-flex gap-8 align-items-center">
      <div class="toggle_radio fs_lg">
        <ng-container *ngFor="let item of segmentDetail.list">
          <input type="radio" id="{{item}}" name="Segmentation" [ngClass]="{ active: mappedType == item }">
          <label for="{{item}}" (click)="onMappedTypeUpdate(item)">
            <p translate>{{item | titlecase}}</p>
          </label>
        </ng-container>
      </div>
      <ng-template [ngIf]="dateFilter">
        <span class="d-inline info-text" *ngIf="dateFilter?.startDate == dateFilter?.endDate">{{'Showing data for' | translate}} {{getDisplayDate(dateFilter?.startDate)}}</span>
        <span class="d-inline info-text" *ngIf="dateFilter?.startDate != dateFilter?.endDate">{{'Showing data from' | translate}} {{getDisplayDate(dateFilter?.startDate)}} {{'to'
            | translate}} {{getDisplayDate(dateFilter?.endDate)}}</span>
      </ng-template>
    </div>
    <app-search-common [searchObj]="searchObj" (onSearch)="onSearch($event)"></app-search-common>
  </div>
  <app-ngx-grid-revamp [ngxCols]="cols" [ngxItems]="segmentationDataLists" [moduleType]="moduleType" [column]="cols"
    [collection]="segmentationDataLists" [loadingData]="loadingData" [noData]="NoData" (sortData)="sortData($event)"
    [itemPerPage]='itemPerPage' [isPaginationWithoutRoute]=true (paginationNo)="onPageChange($event)"
    (paginationSize)="onPageSizeChange($event)" [isTable]="false"></app-ngx-grid-revamp>
</div>