<div
	class="available-products-filter"
	#leftFilterHeight
	(resized)="getFilterHeight()"
>
	<div class="row">
		<div class="col w-50">
			<h3 translate>Filters</h3>
		</div>
		<div class="col w-50 text-end text-align-left-rtl">
			<button
				[attr.data-test]="'clear-all'"
				class="btn btn-secondary btn-sm"
				(click)="onClearAllFilters()"
				translate
			>
				Clear All
			</button>
		</div>
	</div>
	<span
		class="info-text d-inline-block"
		*ngIf="brandFilters && brandFilters.length > 0"
		translate
	>
		Brand
		<span *ngIf="selectedBrandFilters && selectedBrandFilters.length">
			({{selectedBrandFilters.length}})
		</span>
	</span>
	<div *ngIf="brandFilters" [attr.data-test]="'brand-checkboxes'">
		<div
			class="checkbox-container d-flex justify-content-between align-items-center"
			*ngFor="let brandFil of brandFilters; let i = index"
		>
			<label class="checkbox" for="{{brandFil?.name}}">
				<input
					type="checkbox"
					name="checkbox"
					id="{{brandFil?.name}}"
					[checked]="brandFil?.isChecked"
					(click)="onSelectBrand(i, false)"
				/>
				<div class="checkbox-section"><i class="icon icon-Check"></i></div>
				<span translate>{{brandFil?.name}}</span>
			</label>
			<span class="badge count-badge">{{brandFil?.count}}</span>
		</div>

		<div
			ngbDropdown
			class="d-inline-block more-drop"
			*ngIf="brandFiltersMore.length > 0"
			placement="bottom-left"
		>
			<a id="dropdownForm1" ngbDropdownToggle
				>+ {{ brandFiltersMore.length }} {{'more' | translate}}</a
			>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownForm1"
				class="available-type-dropdown"
			>
				<div class="info-text" translate>Brands</div>
				<div class="more-container">
					<div
						class="checkbox-container more-item"
						*ngFor="let brandFilMore of brandFiltersMore; let ii = index"
					>
						<label class="checkbox" for="{{brandFilMore?.name}}">
							<input
								type="checkbox"
								name="checkbox"
								id="{{brandFilMore?.name}}"
								[checked]="brandFilMore?.isChecked"
								(click)="onSelectBrand(ii, true)"
							/>
							<div class="checkbox-section">
								<i class="icon icon-Check"></i>
							</div>
							<span class="fil-text">{{brandFilMore?.name}}</span>
						</label>
						<span class="badge count-badge">{{brandFilMore?.count}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<span
		class="info-text d-inline-block"
		*ngIf="marketplaceFilters && marketplaceFilters.length > 0"
		translate
	>
		Channel
		<span
			*ngIf="selectedMarketPlaceFilters && selectedMarketPlaceFilters.length"
		>
			({{selectedMarketPlaceFilters.length}})
		</span>
	</span>
	<div *ngIf="marketplaceFilters">
		<div
			class="checkbox-container"
			*ngFor="let marketplaceFil of marketplaceFilters; let k = index"
		>
			<label class="checkbox" for="{{marketplaceFil?.name}}">
				<input
					type="checkbox"
					name="checkbox"
					[checked]="marketplaceFil?.isChecked"
					(click)="onSelectMarketPlace(k, false)"
					id="{{marketplaceFil?.name}}"
				/>
				<div class="checkbox-section"><i class="icon icon-Check"></i></div>
				<span>{{marketplaceFil?.name}}</span>
			</label>
		</div>
		<div
			ngbDropdown
			placement="bottom-left"
			class="d-inline-block more-drop"
			*ngIf="marketplaceFiltersMore.length > 0"
		>
			<a id="dropdownForm1" ngbDropdownToggle
				>+ {{ marketplaceFiltersMore.length }} {{'more' | translate}}</a
			>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownForm1"
				class="available-type-dropdown"
			>
				<div class="info-text" translate>Channel</div>
				<div class="more-container">
					<div
						class="checkbox-container more-item"
						*ngFor="let marketplaceFilMore of marketplaceFiltersMore; let kk = index"
					>
						<label class="checkbox" for="{{marketplaceFilMore?.name}}">
							<input
								type="checkbox"
								name="checkbox"
								[checked]="marketplaceFilMore?.isChecked"
								(click)="onSelectMarketPlace(kk, true)"
								id="{{marketplaceFilMore?.name}}"
							/>
							<div class="checkbox-section">
								<i class="icon icon-Check"></i>
							</div>
							<span class="fil-text">{{marketplaceFilMore?.name}}</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>

	<span
		class="info-text d-inline-block"
		*ngIf="categoryFilters && categoryFilters.length > 0"
		translate
	>
		Category
		<span
			*ngIf="selectedCategoryFiltersInfo && selectedCategoryFiltersInfo.length"
		>
			({{selectedCategoryFiltersInfo.length}})
		</span>
	</span>
	<div *ngIf="categoryFilters">
		<div
			class="checkbox-container"
			*ngFor="let categoryFil of categoryFilters; let j = index"
		>
			<label class="checkbox" for="{{categoryFil?.name}}">
				<input
					type="checkbox"
					name="checkbox"
					id="{{categoryFil?.name}}"
					[checked]="categoryFil?.isChecked"
					(click)="onSelectCategory(j, false)"
				/>
				<div class="checkbox-section"><i class="icon icon-Check"></i></div>
				<span>{{categoryFil?.name}}</span>
			</label>
		</div>
		<div
			ngbDropdown
			placement="bottom-left"
			class="d-inline-block more-drop"
			*ngIf="categoryFiltersMore.length > 0"
		>
			<a id="dropdownForm1" ngbDropdownToggle
				>+ {{ categoryFiltersMore.length }} {{'more' | translate}}</a
			>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownForm1"
				class="available-type-dropdown"
			>
				<div class="info-text" translate>Categories</div>
				<div class="more-container">
					<div
						class="checkbox-container more-item"
						*ngFor="let categoryFilMore of categoryFiltersMore; let jj = index"
					>
						<label class="checkbox" for="{{categoryFilMore?.name}}">
							<input
								type="checkbox"
								name="checkbox"
								id="{{categoryFilMore?.name}}"
								[checked]="categoryFilMore?.isChecked"
								(click)="onSelectCategory(jj, true)"
							/>
							<div class="checkbox-section">
								<i class="icon icon-Check"></i>
							</div>
							<span class="fil-text">{{categoryFilMore?.name}}</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="keywordFilters">
		<div
			class="checkbox-container"
			*ngFor="let keywordFil of keywordFilters; let l = index"
		>
			<label class="checkbox" for="{{keywordFil?.keyword}}">
				<input
					type="checkbox"
					name="checkbox"
					[checked]="keywordFil?.isChecked"
					(click)="onSelectKeyword(l, false)"
					id="{{keywordFil?.keyword}}"
				/>
				<div class="checkbox-section"><i class="icon icon-Check"></i></div>
				<span>{{keywordFil?.keyword}}</span>
			</label>
		</div>
		<div
			ngbDropdown
			placement="bottom-left"
			class="d-inline-block more-drop more-drop"
			*ngIf="keywordFiltersMore.length > 0"
		>
			<a id="dropdownForm1" ngbDropdownToggle
				>+ {{ keywordFiltersMore.length }} {{'more' | translate}}</a
			>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownForm1"
				class="available-type-dropdown"
			>
				<div class="info-text" translate>Keywords</div>
				<div class="more-container">
					<div
						class="checkbox-container more-item"
						*ngFor="let keywordFilMore of keywordFiltersMore; let ll = index"
					>
						<label class="checkbox" for="{{keywordFilMore?.keyword}}">
							<input
								type="checkbox"
								name="checkbox"
								[checked]="keywordFilMore?.isChecked"
								(click)="onSelectKeyword(ll, true)"
								id="{{keywordFilMore?.keyword}}"
							/>
							<div class="checkbox-section">
								<i class="icon icon-Check"></i>
							</div>
							<span class="fil-text">{{keywordFilMore?.keyword}}</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Product type filter -->
	<span class="info-text d-inline-block" translate> Matches Type </span>
	<div>
		<div
			class="checkbox-container"
			*ngFor="let prodType of productType; let ab = index"
		>
			<label class="checkbox" for="{{prodType?.value}}">
				<input
					type="checkbox"
					name="checkbox"
					[checked]="prodType?.isChecked"
					(click)="onSelectProductType(prodType,ab)"
					id="{{prodType?.value}}"
				/>
				<div class="checkbox-section"><i class="icon icon-Check"></i></div>
				<span translate>{{prodType?.name}}</span>
			</label>
		</div>
	</div>
	<!-- Product type filter ends-->

	<div
		class="rangeFilters"
		*ngIf="rangeFiltersSelected.data && rangeFiltersSelected.data[rangeFiltersSelected.selectedOption.key]"
	>
		<div class="rangeHeader d-flex align-items-center justify-content-between">
			<span class="info-text d-inline-block" translate> Range </span>
			<div class="change-container d-inline-flex align-items-center gap-8">
				<div
					ngbDropdown
					class="d-inline-block position-static"
					display="dynamic"
					#rangeChange="ngbDropdown"
				>
					<span
						id="dropdownBasic1"
						ngbDropdownToggle
						class="cursor-pointer d-flex align-items-center"
						>{{(rangeFiltersSelected.selectedOption.name) | translate}}
						<i
							class="icon"
							[ngClass]="{'icon-Caret-up':rangeChange.isOpen(),'icon-Caret-down':!rangeChange.isOpen()}"
						></i>
					</span>
					<div
						ngbDropdownMenu
						aria-labelledby="dropdownBasic1"
						class="dropdown-menu"
					>
						<button
							class="dropdown-item"
							*ngFor="let item of rangeFilterOptions"
							(click)="onRangeFilterSelect(item)"
							[ngClass]="{'activeChange':item.key===rangeFiltersSelected.selectedOption.key}"
						>
							<span translate>{{item.name}}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="rangeContainer">
			<ngx-slider
				[(value)]="rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].min_value"
				[(highValue)]="rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].max_value"
				[options]="options"
				(userChangeEnd)="onPriceChange('range', $event)"
			></ngx-slider>
			<div class="rangeDisplay">
				<div class="rangeContainer d-flex gap-10">
					<div
						class="valContainer"
						[ngClass]="{'cursor-not-allowed':!rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].min_value}"
					>
						{{commonFunctions.getSpecificTranslation('MIN') +
						(rangeFiltersSelected.selectedOption.units)}}
						<input
							type="number"
							[attr.data-test]="'min-range-test'"
							[value]="rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].min_value"
							[min]="rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value"
							[max]="rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value"
							(input)="onPriceChange('min',$event)"
							onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
							[(ngModel)]="rangeFiltersSelected.model_min_value"
						/>
						<div
							*ngIf="!rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].min_value"
						>
							--
						</div>
					</div>
					<div
						class="valContainer"
						[ngClass]="{'cursor-not-allowed':!rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].max_value}"
					>
						{{commonFunctions.getSpecificTranslation('MAX') +
						(rangeFiltersSelected.selectedOption.units)}}
						<input
							type="number"
							[attr.data-test]="'max-range-test'"
							[value]="rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].max_value"
							[min]="rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].min_value"
							[max]="rangeFiltersSelected.data[this.rangeFiltersSelected.selectedOption.key].max_value"
							(input)="onPriceChange('max')"
							onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
							[(ngModel)]="rangeFiltersSelected.model_max_value"
						/>
						<div
							*ngIf="!rangeFiltersSelected.rangeFilterValue[rangeFiltersSelected.selectedOption.key].max_value"
						>
							--
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<span class="info-text d-inline-block" translate> Availability </span>
	<div class="availability-container d-flex flex-column">
		<ng-container *ngFor="let kpi of productAvailability">
			<label class="radio" [for]="kpi.value">
				<input
					type="radio"
					name="kpis"
					[id]="kpi.value"
					[value]="kpi"
					(change)="[availabilitySelected=kpi,emitFilters()]"
					[checked]="kpi.value===availabilitySelected.value"
				/>
				<div class="circle"></div>
				<span translate>{{kpi.name}} </span>
			</label>
		</ng-container>
	</div>

	<span class="info-text d-inline-block" translate> Ratings </span>
	<div [attr.data-test]="'ratings-container'">
		<ng-template #starsOnly let-fill="fill">
			<span class="stars" [class.full]="fill === 100">
				<span class="half" [style.width.%]="fill">&#x02605;</span>&#x02605;
			</span>
		</ng-template>
		<div
			class="checkbox-container"
			*ngFor="let rat of ratingsOption; let z = index"
		>
			<label class="checkbox" for="rat-{{rat.value}}">
				<input
					type="checkbox"
					name="checkbox"
					id="rat-{{rat.value}}"
					(change)="onRatingSelect('5', z)"
					[checked]="rat.isChecked"
				/>
				<div class="checkbox-section"><i class="icon icon-Check"></i></div>
				<span class="rating">
					<ngb-rating
						[rate]="rat.value"
						[starTemplate]="starsOnly"
						[readonly]="true"
						[max]="rat.value"
					></ngb-rating>
					<span class="associateText" *ngIf="rat.value != 5"></span>
				</span>
			</label>
		</div>
	</div>
</div>
