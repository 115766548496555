import { Component, Input, OnInit } from "@angular/core";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { LocalStorageService } from "@ds-shared/common-services/storage-services/local-storage.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { LOCALFILTER } from "@ds-shared/enums/common.enum";
import { Subject, Subscription, takeUntil } from "rxjs";

@Component({
	selector: "app-common-in-app-messages",
	templateUrl: "./common-in-app-messages.component.html",
	styleUrls: ["./common-in-app-messages.component.scss"]
})
export class CommonInAppMessagesComponent implements OnInit {
	@Input() public messageKey: string;
	@Input() public messageType: string;
	@Input() public messageStyle = {
		width: "20rem"
	};
	public showMessage = {
		warning: true,
		"light-warning": true,
		info: true
	};
	public allMessageLists = {
		sellout_campaign_mode:
			"To see data, track stores/keywords/products in Campaign Mode. Sales Trend is based on estimated inventory change. Data is available upto Today - 7 days only.",
		sellout_campaign_mode_with_brand:
			"To see data, track stores/keywords/products in Campaign Mode and add both Brand and SKU harmonised labels. Sales Trend is based on estimated inventory change. Data is available upto Today - 1 day only.",
		"data-management-info": "This is sample data.",
		wow_mom_info:
			"WoW/MoM: Refers to % change in values when comparing selected timeline filter against similar date range 7 days ago or 30 days ago",
		last_updated_info:
			"Last Value (Price, Discount, or Sales) refers to the last updated value (Price, Discount, or Sales) before the start of the selected timeline filter.",
		last_discount_info:
			"Last discount refers to the last updated discount before the start of the selected timeline filter.",
		last_price_info:
			"Last price refers to the last updated price before the start of the selected timeline filter.",
		last_sales_info:
			"Last sales refers to the last updated sales before the start of the selected timeline filter.",
		pm_based_on_category: "Based on product matches from category trackers",
		scorecard_product_info:
			"Enable Content Quality on upto 100 Product Trackers and Sentiment Analysis on upto 100 Product Trackers each to collect product content and review details.",
		scorecard_store_info:
			"Enable and view all Stores and tag as Official, Reseller, Greyseller and Competitor store segments.",
		scorecard_brand_info: "Enable and view all Brand Trackers.",
		scorecard_keyword_info:
			"Enable and view all Keyword Trackers to monitor Share of Search, Ranking of products and identify third party sellers and competition based on trending marketplace results.",
		scorecard_category_info:
			"Enable and view Product Matching on upto 10 Category Trackers for monthly run to Discover Top Matches for Official products only and to find Overpriced and Underpriced performance.",
		sellout_timeline_info: "Data is available upto Today - 7 days only.",
		sellout_market_share_sales:
			"Share of Sales is based on Historical Sold figures as available on channel.",
		marketShare_custom_msg:
			"Please note that access to clickable popups and product tabs are unavailable for custom date range selection.  Select pre-defined date range in timeline to see the complete data.",
		latest_data_message: ""
	};

	public message = "";
	private destroy$: Subject<boolean> = new Subject();
	public latestDataSubscription: Subscription;

	/* Filter Start */
	private _filterParams;
	get filterParams(): any {
		return this._filterParams;
	}
	@Input() set filterParams(value) {
		this._filterParams = value;
		if (value) this.latestProductData();
	}
	/***** Local Filters *****/
	public isOptionalFilterApplied: boolean = false;
	@Input() public permittedLocalFilter = [
		LOCALFILTER.STORE,
		LOCALFILTER.BRAND,
		LOCALFILTER.CATEGORY,
		LOCALFILTER.CATEGORY_L1,
		LOCALFILTER.CATEGORY_L2,
		LOCALFILTER.SEGMENT,
		LOCALFILTER.SKU
	];
	private _localFilters;
	get localFilters() {
		return this._localFilters;
	}
	@Input() set optionalFilters(value) {
		if (value) {
			this._localFilters = value;
			if (
				this.permittedLocalFilter.some((item) => {
					return this._localFilters[item];
				})
			) {
				if (
					this.permittedLocalFilter.some((item) => {
						return this._localFilters[item]?.selected?.length > 0;
					})
				) {
					this.isOptionalFilterApplied = true;
					this.latestProductData();
				} else if (this.isOptionalFilterApplied) {
					this.isOptionalFilterApplied = false;
					this.latestProductData();
				}
			}
		}
	}

	/* Filter End */

	constructor(
		private commonFunctions: CommonFunctionsService,
		public dataTransferService: DataTransferService,
		private localStorageService: LocalStorageService,
		private globalService: GlobalService
	) {}

	ngOnInit(): void {
		this.updateAIServiceDynamicLimit();
		this.message = this.allMessageLists[this.messageKey];
	}

	private updateAIServiceDynamicLimit(): void {
		const mlServiceList = this.commonFunctions.getMlServiceList();
		const limits = mlServiceList.reduce(
			(result, item) => {
				if (item.code in result) {
					result[item.code] = item.limit;
				}
				return result;
			},
			{
				cq: 100,
				sa: 100,
				pm: 10
			}
		);
		this.allMessageLists[
			"scorecard_product_info"
		] = `Enable Content Quality on upto ${limits.cq} Product Trackers and Sentiment Analysis on upto ${limits.sa} Product Trackers each to collect product content and review details.`;
		this.allMessageLists[
			"scorecard_category_info"
		] = `Enable and view Product Matching on upto ${limits.pm} Category Trackers for monthly run to Discover Top Matches for Official products only and to find Overpriced and Underpriced performance.`;
	}

	public onClose(id: string) {
		this.showMessage[id] = false;
	}

	private latestProductData(): void {
		this.latestDataSubscription?.unsubscribe();
		this.showMessage[this.messageType] = true;
		this.allMessageLists["latest_data_message"] = "Loading...";
		this.message = this.allMessageLists[this.messageKey];
		const optionalParams = this.commonFunctions.reduceOptionalParams(
			this.permittedLocalFilter,
			this.localFilters
		);
		this.latestDataSubscription = this.globalService
			.latestProductData(this.filterParams, optionalParams)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					this.allMessageLists["latest_data_message"] = res.data;
					this.message = this.allMessageLists[this.messageKey];
				},
				(err: any) => {
					this.allMessageLists["latest_data_message"] = "";
					this.message = this.allMessageLists[this.messageKey];
				}
			);
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
