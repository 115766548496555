/* eslint-disable */
import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
import Tree from "highcharts/modules/treemap";
import Heatmap from "highcharts/modules/heatmap";
import { Subject, filter, takeUntil } from "rxjs";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
import { PRODUCTTYPE } from "../../../enums/common.enum";

More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);

let componentContext;
@Component({
	selector: "app-treemap",
	templateUrl: "./treemap.component.html",
	styleUrls: ["./treemap.component.scss"]
})
export class TreemapComponent implements OnInit {
	@Input() chartsData;
	public Highcharts: typeof Highcharts = Highcharts;
	public highchartsData: typeof Highcharts;
	public chartOptions: any;
	private destroy$: Subject<boolean> = new Subject();
	private chartRef;
	public optionalFilters: any = {
		store: null,
		brand: null,
		category_l1: null,
		category_l2: null,
		category: null,
		segment: null
	};
	constructor(
		private dataTransferService: DataTransferService,
		private commonFunctions: CommonFunctionsService
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				// thousandsSep: ","
			}
		});
	}

	ngAfterViewInit() {
		this.getPerformanceChart();
	}

	getPerformanceChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				spacing: [0, 0, 0, 0]
			},
			colorAxis: {
				minColor: "#ffbc66",
				maxColor: "#fee9cb"
			},
			credits: {
				enabled: false
			},
			legend: {
				enabled: false
			},
			title: {
				text: ""
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: true,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true
			},
			plotOptions: {
				treemap: {
					events: {
						click: (ev) => {
							this.dataTransferService.sendGraphSliceData(ev.point.options);
						}
					}
				}
			},
			series: [
				{
					type: "treemap",
					cursor: "pointer",
					layoutAlgorithm: "sliceAndDice",
					color: "#ffbc66",
					borderRadius: 2,
					borderColor: "#ffffff",
					borderWidth: 3,
					states: {
						hover: {
							enabled: true,
							borderColor: "#ffffff"
						}
					},
					levels: [
						{
							level: 1,
							layoutAlgorithm: "squarified",
							dataLabels: {
								enabled: true,
								align: "left",
								verticalAlign: "top",
								overflow: "none",
								useHTML: true,
								crop: false,
								formatter: function () {
									if (this.point.shapeArgs) {
										const boxWidth = this.point.shapeArgs.width;
										const lineLength = boxWidth / 12;
										const nameParts = this.point.name.split(" ");
										const name = [];
										let line = "";
										for (let i in nameParts) {
											line = line.concat(nameParts[i]).concat(" ");
											if (line.length > lineLength) {
												name.push(line);
												line = "";
											}
										}
										name.push(line);
										return name.join("<br/>");
									}
									return this.point.name;
								},
								style: {
									fontSize: "0.625rem",
									fontFamily: "Poppins Regular",
									color: "#121737",
									fontWeight: "400"
									// textOverflow: "allow"
								},
								x: 2,
								y: 4
							}
						},
						{
							level: 2,
							layoutAlgorithm: "squarified",
							dataLabels: {
								enabled: true,
								align: "right",
								verticalAlign: "bottom",
								overflow: "none",
								useHTML: true,
								formatter: function () {
									if (this.point.options.caller === "category_availability") {
										const dataList = this.key.split("_");
										let display = "";
										let gap = "";
										if (this.point.shapeArgs.height <= 50) {
											display = "flex";
											gap = "0.1875rem";
										}
										return `<div style="display: ${display};gap: ${gap};">
												<div style="display: flex;gap: 0.25rem;align-items: center;">
													<div style="border: 0.0625rem solid ${
														PRODUCTTYPE["new launches"]
													};background-color:${
											PRODUCTTYPE["new launches"]
										};width:0.625rem;height:0.625rem;"></div>
													<span style="color:#1C2331;font-weight:400;">${
														dataList[2] ? dataList[2] : 0
													}</span>
												</div>
												<div style="display: flex;gap: 0.25rem;align-items: center;">
													<div style="border: 0.0625rem solid ${
														PRODUCTTYPE["available to purchase"]
													};background-color:${
											PRODUCTTYPE["available to purchase"]
										};width:0.625rem;height:0.625rem;"></div>
													<span style="color:#1C2331;font-weight:400;">${
														dataList[0] ? dataList[0] : 0
													}</span>
												</div>
												<div style="display: flex;gap: 0.25rem;align-items: center;">
													<div style="border: 0.0625rem solid ${
														PRODUCTTYPE["out of stock"]
													};background-color:${
											PRODUCTTYPE["out of stock"]
										};width:0.625rem;height:0.625rem;"></div>
													<span style="color:#1C2331;font-weight:400;">${
														dataList[1] ? dataList[1] : 0
													}</span>
												</div>
												</div>`;
									}
									if (this.point.options.caller === "category") {
										return `
										<div style="fontSize:0.75rem;font-family: Poppins Regular;color:#1C2331;font-weight:400;padding:0.3125;text-align:right">${
											this.point.options["currency"]
										} ${componentContext.commonFunctions.getSuffixedNumberFormat(
											this.key
										)}</div>
										<div style="fontSize:0.75rem;font-family: Poppins Regular;color:#1C2331;font-weight:400;padding:0.3125;text-align:right">
										${componentContext.commonFunctions.getSuffixedNumberFormat(
											this.point.options.quantity
										)} ${componentContext.commonFunctions.getSpecificTranslation(
											"units"
										)}
										</div>
										`;
									} else {
										return `
										<div style="fontSize:0.75rem;font-family: Poppins Regular;color:#1C2331;font-weight:400;padding:0.3125;text-align:right">${
											this.point.options["currency"]
										} ${componentContext.commonFunctions.getSuffixedNumberFormat(
											this.key
										)}</div>
										`;
									}
								},
								x: -4,
								y: -4
							}
						}
					],
					data: this.chartsData
				}
			]
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		if (!dataLabel["point"]["parent"]) return undefined;
		switch (dataLabel["point"]["options"]["caller"]) {
			case "category":
				return `<div
    style="background-color:${
			TOOLTIPCOLOR["BG"]
		};min-width:12rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.625rem;margin:0">${
					dataLabel["point"]["title"]
				}</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
    <div style="display:flex;flex-direction: column;">
    <span style="color:${
			TOOLTIPCOLOR["VALUE"]
		};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["quantity"]
				)}</span>
            <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Quantity Sold"
				)}</span>
</div>
        <div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["key"]
				)}</span>
                    <span style="color:${
											TOOLTIPCOLOR["TEXT"]
										};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"GMV"
				)}</span>
        </div>
    </div>
</div>`;
				break;
			case "category_pricing":
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:14rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border: none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.625rem;margin:0">${
					dataLabel["point"]["title"]
				}</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: row; gap:0.25rem;">
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2);padding-right:0.5rem;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Min. Price"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["minPrice"]
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2)
			;padding-right:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Max. Price"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["maxPrice"]
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2);padding-right:0.5rem;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;" translate>${componentContext.commonFunctions.getSpecificTranslation(
					"Avg. Price"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["currency"]
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["avgPrice"]
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;" translate>${componentContext.commonFunctions.getSpecificTranslation(
					"Avg. Discount"
				)}</span>
				<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">
				${
					dataLabel["point"]["avgDiscount"] === null
						? "-"
						: `Upto
				${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["avgDiscount"]
				)}%`
				}</span>
			</div>
			</div>
			</div>`;
			case "category_availability":
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:14rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.625rem;margin:0">${
					dataLabel["point"]["title"]
				}</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: row; gap:0.25rem;">
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2)
			;padding-right:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Newly Tracked"
				)}</span>
				<span style="color:${
					TOOLTIPCOLOR["VALUE"]
				};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["newLaunchesCount"]
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column; border-right: 0.0625rem solid rgb(255,255,255,0.2);padding-right:0.5rem;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"ATP"
				)}</span>
				<span style="color:${
					TOOLTIPCOLOR["VALUE"]
				};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["atpCount"]
				)}</span>
			</div>
			<div style="display:flex;flex-direction: column;padding-right:0.5rem;padding-left:0.5rem;">
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.775rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"OOS"
				)}</span>
				<span style="color:${
					TOOLTIPCOLOR["VALUE"]
				};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["oosCount"]
				)}</span>
			</div>
			</div>
			</div>`;
				break;
			default:
				return "";
		}
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
