<div 
class="pagination-container d-flex justify-content-between gap-8">
		<div class="align-items-center justify-content-start" >
			<div class="page-align" *ngIf="!hidePageSize">
				<ng-select #select [(ngModel)]="selectedPage_size"
					[items]="pageSizePerRow"
					bindLabel="value"
					(change)="changePageSize($event)"
					[clearable]="false"
					[searchable]="false" [clearable]="false" [ngModelOptions]="{standalone: true}"
				>
				<ng-option *ngFor="let size of pageSizePerRow" [value]="size.value" translate>{{size.value}}
				</ng-option>
				</ng-select>
				<span class="goToPage" *ngIf="gridGoToPage">
					<div class="input-container">
						<input
							type="number"
							id="page_limit"
							min="1" (keydown.enter)="goToPageFunc()"
							placeholder="{{'Go to page' | translate}}"
							onkeypress="return event.charCode >= 48 && event.charCode <= 57"
							autocomplete="off"
							(keydown.enter)="goToPageFunc()"
							[(ngModel)]="goToPage"
						/>
						<i
							class="icon icon-Arrow-right enter-state icon-show"
							*ngIf="goToPage"
							(click)="goToPageFunc()"
						></i>
					</div>
				</span>
			</div>
			<div  class="ms-auto">
				<ngb-pagination 
					(pageChange)="pageChange($event)"
					class="pagination-block"
					[collectionSize]="meta.total_count"
					[(page)]="currentPage"
					[maxSize]="maxSize"
					[pageSize]="selectedPage_size"
					[boundaryLinks]="true"
				>
				</ngb-pagination>
			</div>
		</div>
</div>