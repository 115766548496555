import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonNgSelectModel } from '@ds-shared/models/common.model';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-common-select-filter',
  templateUrl: './common-select-filter.component.html',
  styleUrls: ['./common-select-filter.component.scss']
})
export class CommonSelectFilterComponent implements OnInit {

  @Input() data: CommonNgSelectModel;
  @Input() searchOnline: boolean = false;
  @Input() isPaginated: boolean = false;
  @Output() dataChange = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() onSearchChange = new EventEmitter();
  public searchSubject$ = new Subject<string>();
  public $destroy: Subject<boolean> = new Subject()
  constructor() { }

  ngOnInit(): void {
    this.searchInit();
  }
  onSelect() {
    this.dataChange.emit(this.data);
  }

  searchInit() {
    this.searchSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.$destroy)
      )
      .subscribe(
        (term) => {
          this.data.search = term;
          if (this.searchOnline) this.data.list = [];
          if (this.isPaginated) this.data.meta.pageNumber = 1;
          this.onSearchChange.emit();
        }
      );
  }

  onMultiSearch(event: any) {
    if (this.searchOnline) {
      this.data.search = event.term;
      this.searchSubject$.next(event.term)
    }
    else this.data.search = event.term;
  }
  closeMultiDropdown() {
    this.data.search = null;
    if (this.searchOnline) this.data.list = this.data.meta.initialList;
    if (this.isPaginated) this.data.meta.pageNumber = this.data.meta.initialPageNumber;
  }
  customSearchFn(term: string, item: any) {
    return item;
  }
  checkSelectedOption(item) {
    if (this.data.selected) {
      return this.data.selected.map(val => val.id).includes(item.id);
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
