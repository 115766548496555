import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[widthInPercentage]'
})
export class WidthChangerDirective {
  @Input() widthInPercentage: number=100;

  constructor(private el: ElementRef) { }

  ngOnInit(){
    this.el.nativeElement.style.width=`${this.widthInPercentage}%`;
  }

}
