<div class="ds-box">
    <div class="d-flex justify-content-between align-items-center  gap-8 mb-3">
        <div class="d-flex justify-content-between gap-8">
            <span class="ds-box-heading infoHeader text-capitalize" translate>Competitor {{gridType}}s
            </span>
            <span class="grid-count m-auto" *ngIf="availableProducts?.meta?.total_count">{{(availableProducts?.meta?.total_count | numberSuffix : 2)}}</span>
        </div>
        <div class="d-flex gap-8">
            <app-search-common [searchObj]="searchObj" (onSearch)="onSearch($event)"></app-search-common>
            <div class="store-filter-container">
                <!-- bindLabel="attributes.name" -->
                <ng-select 
                    [items]="competitorStoreFilter.list" 
                    [notFoundText]="'No Data Found' | translate"
                    [loadingText]="'Loading...' | translate" 
                    [multiple]="true"
                    [closeOnSelect]="competitorStoreFilter.closeOnSelect" 
                    [clearable]="competitorStoreFilter.clear" 
                    bindLabel="name"
                    placeholder="{{('Select Seller ' + competitorStoreFilter.placeholder) | translate}}" 
                    (change)="onChange($event)"
                    (search)="onStoreSearch($event)"
                    (close)="closeMultiDropdown()"
                    [loading]="competitorStoreFilter.loading" 
                >
                <ng-template ng-multi-label-tmp><span class="placeholder-temp">{{competitorStoreFilter.searchTerm.length ? ''
                        :
                        ((competitorStoreFilter.placeholder | translate) + " (" +
                        competitorStoreFilter.selected.length + ")")}}</span></ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div class="multi-select-option-container multi-text-wrap gap-16">
                            <!-- <span class="item-name">{{item.attributes.name}}</span> -->
                            <span class="item-name">{{(item.attributes && item.attributes.name) ? item.attributes.name : item.name}}</span>
                            <i class="icon icon-Check" *ngIf="competitorStoreFilter.selected.includes(item)"></i>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    
    <app-ngx-grid-revamp [ngxCols]="cols" [ngxItems]="availableProducts" [moduleType]="moduleType" [column]="cols"
        [collection]="availableProducts" [loadingData]="loadingData" [noData]="NoData" (sortData)="sortData($event)"
        [itemPerPage]='itemPerPage' [isPaginationWithoutRoute]=true (paginationNo)="onPageChange($event)"
        (paginationSize)="onPageSizeChange($event)" [maxSize]="1" [showThreeOptionsForPageSize]="true"
        [goToPlaceholder]="'Pg.'" [pgType]="'sm'"></app-ngx-grid-revamp>
</div>