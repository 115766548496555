<div class="tag-value">
    <ng-container *ngIf="item?.length">
        <span class="tag-0" [ngClass]="{'dashboard-tag-0':dropdownType=='dashboard-header'}">{{item[0]}}</span>
        <div ngbDropdown class="d-inline-block position-static" display="dynamic" *ngIf="item.length > 1">
            <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer">
                <span class="note-text tagDropdown">+{{item.length - 1}}</span>
            </span>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="tagsContent">
                <div class="d-flex flex-column">
                    <ng-template ngFor let-tag [ngForOf]="item" let-i="index">
                        <span class="tags" *ngIf="i > 0">{{tag}}</span>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
    <span *ngIf="!item?.length">-</span>
</div>