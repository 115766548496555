import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ComponentList } from "@ds-shared/models/dashboard.model";
@Component({
	selector: "app-common-custom-dashboard",
	templateUrl: "./common-custom-dashboard.component.html",
	styleUrls: ["./common-custom-dashboard.component.scss"]
})
export class CommonCustomDashboardComponent implements OnInit {
	@Input() public componentList;
	@Output() hideShowComponent = new EventEmitter();
	public isOpen: boolean = false;
	public checkCounter: number = 0;
	public noOfHiddenComp: ComponentList[];
	constructor() {}

	ngOnInit(): void {
		this.noOfHiddenComp = this.componentList.filter((ele: any) => ele.hide);
		this.checkCounter = this.checkCounter - this.noOfHiddenComp.length;
		this.componentList?.forEach((ele: ComponentList) => {
			if (!ele.isChecked) {
				this.checkCounter++;
			}
		});
	}
	toggleConfig() {
		this.isOpen = !this.isOpen;
	}

	onClickCheckbox(id: number) {
		this.componentList[id].isChecked = !this.componentList[id].isChecked;
		if (this.componentList[id].isChecked) this.checkCounter--;
		else this.checkCounter++;
	}

	onApply() {
		this.toggleConfig();
		this.hideShowComponent.emit(this.componentList);
	}

	public isEveryColumnChecked(): boolean {
		return this.componentList.every((item) => item?.isChecked);
	}

	public checkDisable() {
		return (
			this.checkCounter ===
			this.componentList.length - this.noOfHiddenComp.length
		);
	}

	public onSelectAllColumn(event) {
		this.componentList.forEach((ele: ComponentList) => {
			if (event.target.checked) {
				if (!ele.isChecked) this.checkCounter--;
				ele.isChecked = true;
			} else {
				if (ele.isChecked) this.checkCounter++;
				ele.isChecked = false;
			}
		});
	}
}
