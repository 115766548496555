<div class="scraped-products-container">
  <!-- START: To Remove autofocus on searchbox when the component used as modal -->
  <input type="text" autofocus="autofocus" style="display:none" />
  <!-- END: To Remove autofocus on searchbox when the component used as modal -->

  <div class="products-container">
    <div class="heading-container d-flex align-items-center gap-8 position-relative">
      <span class="head" translate>{{gridTitle}}</span>
      <span class="grid-count" *ngIf="scrappedProducts?.meta?.total_count">{{(scrappedProducts?.meta?.total_count | numberSuffix : 2 )}}</span>
      <ng-template [ngIf]="dateFilter">
        <span class="d-inline info-text" *ngIf="dateFilter?.startDate == dateFilter?.endDate">{{'Showing data for' | translate}} {{getDisplayDate(dateFilter?.startDate)}}</span>
        <span class="d-inline info-text" *ngIf="dateFilter?.startDate != dateFilter?.endDate">{{'Showing data from' | translate}} {{getDisplayDate(dateFilter?.startDate)}} {{'to'
            | translate}} {{getDisplayDate(dateFilter?.endDate)}}</span>
    </ng-template>
    </div>
    <div class="heading1-container">
      <app-search-common [searchObj]="searchObj" (onSearch)="onSearch($event)"></app-search-common>
      <app-manage-columns [moduleType]="moduleType" [columns]="cols"></app-manage-columns>
      <app-common-action-dropdown [filename]="exportDetails.fileName" [params]="exportDetails.params" [path]="exportDetails.path" [columns]="cols" [disableIcon]="NoData"></app-common-action-dropdown>
    </div>
  </div>

  <div class="metricFilterContainer position-relative d-flex align-items-center">
    <div class="filters d-flex flex-wrap">
      <div class="filterItem" *ngFor="let filter of productFilter.slice(0, maxLengthToshow); index as i">
        <span class="value cursor-pointer" [ngClass]="{'activeFilter':selectedFilter.code==filter.code}" (click)="changeFilter(filter)"
          translate>{{filter.name}}</span>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block position-static" display="dynamic"
      *ngIf="productFilter.length > maxLengthToshow">
      <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span class="listNumber">{{'+'+
          (productFilter.length-maxLengthToshow)}}</span>
      </span>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown">
        <div class="dropDownfilterItem d-block">
          <span class="value d-flex justify-content-between align-items-center p-1 cursor-pointer"
            *ngFor="let filter of productFilter.slice(maxLengthToshow,productFilter.length); index as i"
            [ngClass]="{'activeFilter':selectedFilter.code==filter.code}" (click)="changeFilter(filter)" translate>
            {{ filter.name }}
          </span>
          <span *ngIf="productFilter.length === 0" class="filter-capsule" translate>Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <app-ngx-grid-revamp [ngxCols]="cols" [ngxItems]="scrappedProducts" [moduleType]="moduleType" [column]="cols"
      [collection]="scrappedProducts" [loadingData]="loadingData" [noData]="NoData" (sortData)="sortData($event)"
      [itemPerPage]='itemPerPage' [isPaginationWithoutRoute]=true (paginationNo)="onPageChange($event)"
      (paginationSize)="onPageSizeChange($event)" [className]="'scrapped-product-grid'"></app-ngx-grid-revamp>
  </div>
</div>


 