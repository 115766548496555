<section class="product-details-container d-flex flex-column gap-12">
    <div class="image-section d-flex flex-column gap-8">
        <div class="main-image-container position-relative">
            <div class="ratings-box">
                <ng-template #starLabel>
                    <span class="star">&#x02605;</span>
                </ng-template>
                <div *ngIf="product.attributes?.rating" class="ratingLabel"
                    [ngClass]="commonFunctions.getStarStatus(product.attributes.rating)">
                    {{product.attributes.rating}}<ngb-rating [max]="1" [starTemplate]="starLabel" [rate]="1"></ngb-rating>
                </div>
            </div>
            <ng-container *ngIf="product.attributes?.images;else noImage">
                <img [src]="product.attributes.images[0]" alt="" srcset="">
            </ng-container>
            <ng-template #noImage>
                <img
                src="assets/images/no-image.svg" alt="">
            </ng-template>
        </div>
        <div class="other-images d-flex gap-8" *ngIf="product.attributes?.images">
            <ng-container *ngFor="let image of product.attributes.images | slice:0:4;let isFirst = first">
                <div class="small-img-wrapper position-relative">
                    <img [src]="image" alt="">
                    <div *ngIf="!isFirst" class="overlay"></div>
                </div>
            </ng-container>
            <span class="d-flex justify-content-center align-items-center"
                *ngIf="product.attributes.images.length > 4 && product.attributes.images.length - 4">+{{product.attributes.images.length
                - 4}}</span>
        </div>
    </div>
    <div class="p-name mb-0">
        {{product.attributes?.name}}
    </div>
    <p class="mb-0">
        <span class="gmv me-2">Price</span> <span class="price" *ngIf="(product.attributes?.price || product.attributes?.price_new || product.attributes.current_price || product.attributes.min_price);else showNoData">
            {{currencyDetails.symbol}}{{(product.attributes.price || product.attributes.price_new || product.attributes.current_price || product.attributes.min_price) | numberSuffix: 2}}
        </span>
        <ng-template #showNoData>
            -
        </ng-template>
    </p>
</section>