/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit, Renderer2 } from "@angular/core";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
import * as Highcharts from "highcharts/highstock";
import { Subject, filter, takeUntil } from "rxjs";

let componentContext;
@Component({
	selector: "app-stacked-bar-chart",
	templateUrl: "./stacked-bar-chart.component.html",
	styleUrls: ["./stacked-bar-chart.component.scss"]
})
export class StackedBarChartComponent implements OnInit, AfterViewInit {
	@Input() public data;
	@Input() public categories;
	@Input() public showYAxisLabels;
	@Input() public showXAxisLabels;
	@Input() public showLegends;
	@Input() public pointWidth = 120;
	@Input() public showTooltip = true;
	@Input() public dataLabelPosition = { x: -4, y: -120 };
	@Input() public scrollbarThreshold = 12;
	@Input() public caller = "";
	@Input() public chartObj: { spacing?:number[],margin?:number[]} = {
		spacing: [0, 0, 0, 0],
		margin: [0, 0, 0, 0]
	}
	@Input() public meta={};
	@Input() public type;
	@Input() public allowOverlap: boolean = false;
	@Input() public overflow : string = 'justify'
	@Input() public showTooltipOutside: boolean = true;
	public profileType = {
		"keyword" : "K",
		"category" : "C",
		"brand" : "B",
		"product": "P"
	}
	public Highcharts: typeof Highcharts = Highcharts; // required
	public highchartsData: typeof Highcharts;
	chartOptions;
	chartRef;
	private destroy$: Subject<boolean> = new Subject();
	public storeClickedElement;
	listenerFn = () => { };
constructor(private dataTransferService: DataTransferService,
	private commonFunctions: CommonFunctionsService,
	private renderer: Renderer2
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
		.pipe(
			filter((res) => res),
			takeUntil(this.destroy$)
		)
		.subscribe((res) => {
			setTimeout(() => {
				this.chartRef.reflow();
			}, 0);
		});
	
	this.listenerFn=this.renderer.listen('document', 'click', (e: any) => {
		componentContext.storeClickedElement = componentContext.commonFunctions.closeCustomDropdown(e, componentContext.storeClickedElement);
	});
	}

	ngOnInit(): void {
		componentContext = this;
	}

	ngAfterViewInit(): void {
		this.renderStackedBarChart();
	}
	onStoreNameClick(e){
		const OuterDiv  = document.querySelectorAll('.highcharts-container');
		const axis = e.xAxis[0] 
		const ticks = axis.ticks
		const processedXData = e.series[0].processedXData;
		const points = e.series[0].points
		points.forEach(function(point, i) {
		if(point.series?.userOptions["availabilityType"] !== 'store') return;
		if (ticks[processedXData[i]]) {
			const label = ticks[processedXData[i]].label.element
			label.onmouseover = function(){
				label.childNodes.forEach((item)=>{
				item.style.setProperty('color', '#ff5e00', 'important');
				item.style.setProperty('cursor', 'pointer');
				item.childNodes[1].style.setProperty('visibility', 'visible');
				OuterDiv.forEach((div: HTMLElement) => div.style.setProperty('overflow', 'unset'));
			})
			}
			label.onmouseout = function(){
				label.childNodes.forEach((item)=>{
					item.style.setProperty('color', '#727487', 'important');
					item.childNodes[1].style.setProperty('visibility', 'hidden');
					OuterDiv.forEach((div: HTMLElement) => div.style.setProperty('overflow', 'hidden'));
				})
			}
			label.onclick = function(event) {
				if (point["series"]["userOptions"]?.harmonised_view?.[i]) {
					componentContext.storeClickedElement=componentContext.commonFunctions.setPositionOfCustomDropdown(event, label, componentContext.storeClickedElement, point["series"]["userOptions"]["raw_data"][i], 20);
				}
				else {
					componentContext.storeClickedElement = componentContext.commonFunctions.closeCustomDropdown(null, componentContext.storeClickedElement);
					window.open(componentContext.commonFunctions?.redirectToScorecardInfo(point.series.userOptions.Id[point.index], 'store', true)?.params, '_blank');
				}
			}
		}					  
	})
	}
	renderStackedBarChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				type: "bar",
				events: {
					load: function (event) {
						event.target.reflow();
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
					},
					render:function(){
						componentContext.onStoreNameClick(this)
					}
				},
				backgroundColor: 'transparent',
				...this.chartObj
			},
			title: {
				text: ""
			},
			scrollbar: {
				enabled: this.categories?.length > this.scrollbarThreshold,
				barBackgroundColor: "#aaaaaae5",
				barBorderRadius: 7,
				barBorderWidth: 0,
				buttonBackgroundColor: "#B3B3B3",
				buttonBorderWidth: 0,
				buttonBorderRadius: 7,
				trackBackgroundColor: "none",
				trackBorderWidth: 0,
				trackBorderRadius: 8,
				trackBorderColor: "#CCC",
				rifleColor: "transparent"
			},
			xAxis: {
				categories:this.categories,
				gridLineWidth: 0,
				lineWidth: 0,
				tickWidth: 0,
				labels: {
					enabled: this.showXAxisLabels,
					align: "left",
					reserveSpace: true,
					verticalAlign: "bottom",
					useHTML: true,
					formatter: function () {
						if (componentContext.data[0].caller === "availability") {
							if (this.axis.series[0]?.userOptions?.availabilityType=== "store") {
								if (componentContext.data[0].profileType[this.pos] === "store") {
								return `<div style="display:flex;font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
								<span style=" visibility: hidden;border: 0.0625rem solid ${TOOLTIPCOLOR["BG"]}; padding: 0.375rem ;z-index: 1; left: -0.25rem;bottom: 100%;position: absolute;background-color:${TOOLTIPCOLOR["BG"]};color:${TOOLTIPCOLOR["VALUE"]};border-radius:0.375rem">${componentContext.categories[this.pos]}</span>
								<div style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${this.value}</div>
								</div>`;
							}
								return `<div style="display:flex;font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
								<span style=" visibility: hidden;border: 0.0625rem solid ${TOOLTIPCOLOR["BG"]}; padding: 0.375rem ;z-index: 1;left: -0.25rem;bottom: 100%;position: absolute;background-color:${TOOLTIPCOLOR["BG"]};color:${TOOLTIPCOLOR["VALUE"]};border-radius:0.375rem">${componentContext.categories[this.pos]}</span>
								<div style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${this.value}</div>
								</div>`;
							}
							else {
								return `<span style="font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
								<span style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${this.value}</span>
								</span>`;
							}
						}
						return `<span style="font-size:0.688rem;font-family:Poppins Regular;font-weight:400;color:#727487;gap:0.3125rem">
						<span style="width:5.25rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">${this.value}</span>
						</span>`
				
					},
					style: this.data[0].caller === "availability"?{}:{
						lineHeight: 'normal',
						overflow: 'hidden',
						whiteSpace: "nowrap",
						textOverflow: 'ellipsis',
						width: 100
					}
				}, 
				tickLength: 0,
				lineColor: "transparent",
				gridLineColor: "transparent",
				max: this.categories?((this.categories?.length > this.scrollbarThreshold) ? (this.scrollbarThreshold - 1) : (this.categories?.length - 1)):null,
			},
			yAxis: {
				minPadding: 10,
				maxPadding: 0,
				reversedStacks: false,
				min: 0,
				max: 100,
				lineColor: "transparent",
				gridLineColor: "transparent",
				title: "",
				opposite:true,
				labels: {
					enabled: this.showYAxisLabels,
					align:'left',
					style:{
						color:'red'
					},
					padding: 0,
					minPadding: 0,
					maxPadding: 0,
					y:200
				}
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: false,
				hideDelay: 100,
				formatter:this.toolTipFormatter,
				shared: false,
				useHTML: true,
				outside: this.showTooltipOutside
			},
			legend: {
				enabled: this.showLegends,
				reversed: false
			},
			plotOptions: {
				bar: {
					cursor: "pointer",
					events: {
						click: (ev) => {
								this.dataTransferService.sendGraphSliceData(ev.point);
							}
							
						}
				},
				series: {
					pointWidth:this.pointWidth,
					borderWidth: 2,
					stacking: "normal",
					size: "100%",
					boostThreshold:1,
					cropThreshold: 600,
					dataLabels: {
						enabled: true,
						inside: true,
						allowOverlap: componentContext.allowOverlap,
   						overflow: componentContext.overflow,
						align:'left',
						y: this.dataLabelPosition.y,
						x: this.dataLabelPosition.x,
						useHTML: true,
						formatter: this.dataLabelFormatter,
					}
				}
			},
			series: this.data,
			credits: false
		};
	}
 
	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (dataLabel["series"]["userOptions"]["caller"]) {
			case "inventory":
				return `<div
    style="background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["name"])} (${dataLabel.y}%)</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
        <div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel["series"]["userOptions"]["quantity"]
						)}</span>
            <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Quantity')}</span>
        </div>
    </div>
</div>`
			case "channelShareOfBestSelling":
				return `<div
style="background-color:${TOOLTIPCOLOR["BG"]};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;gap:0.5rem">
<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]['name'])}</p>
<div style="display:flex;flex-direction: column;">
	<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.75rem;">${dataLabel.y}%</span>
</div>
</div>`;
			case "overviewOnlineChannel":
				return `<div
style="background-color:${TOOLTIPCOLOR["BG"]};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${
	componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]["name"]) 
				} (${
					dataLabel.y}%)</p>
<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
<div style="display:flex;flex-direction: column;">
	<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${dataLabel["series"]["userOptions"]["criteria"] === 'sales' ? componentContext.commonFunctions.currencySelected.attributes.symbol : ''}${componentContext.commonFunctions.getSuffixedNumberFormat(
		dataLabel["series"]["userOptions"]["value"]
	)}</span>
	<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${dataLabel["series"]["userOptions"]["criteria"] === 'sales' ? componentContext.commonFunctions.getSpecificTranslation('GMV') : componentContext.commonFunctions.getSpecificTranslation('Quantity')}</span>
</div>
</div>
</div>`
			case "overviewDealerShare":	
				return `<div
style="background-color:${TOOLTIPCOLOR["BG"]};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;gap:0.5rem">
<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]['name'])} (${dataLabel.y}%)</p>
<div style="display:flex;flex-direction: column;">
	<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.meta?.criteria === 'sales'?componentContext.commonFunctions.currencySelected.attributes.symbol:''}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel['point'].options.total)}</span>
	<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.meta?.criteria === 'sales'?componentContext.commonFunctions.getSpecificTranslation('GMV'):componentContext.commonFunctions.getSpecificTranslation('Quantity Sold')}</span>
</div>
</div>`;
			case "availability":
				return `<div
			style="background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(dataLabel["key"])} : ${componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]["name"])} (${dataLabel.y}%)</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: column;">
			<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
							dataLabel["series"]["userOptions"]["count"][`${dataLabel["point"]["index"]}`]
						)}</span>
			<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation('Quantity')}</span>
			</div>
			</div>
			</div>`
			case "marketShare":
				const calculationType = dataLabel["series"]["userOptions"]["calculationType"]
				return `<div
							style="background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
							<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["name"])} (${dataLabel.y}%)</p>
							<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
								<div style="display:flex;flex-direction: column;">
									<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${calculationType==='gmv'?componentContext.commonFunctions.currencySelected.attributes.symbol:''}${componentContext.commonFunctions.getSuffixedNumberFormat(
													dataLabel["series"]["userOptions"]["value"]
												)}</span>
									<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(calculationType==='gmv'?'Estimated Sales':'Quantity Sold')}</span>
								</div>
							</div>
							</div>
						</div>`
			default:
				return `
				<div style="display:flex;flex-direction:column;padding:0.313rem">
	  <div style="display:flex;justify-content:space-between;align-items:baseline;gap:0.5rem;margin-top:0.25rem">
		<div style="display:flex;align-items:baseline">
		  <div style="width:0.5rem;height:0.5rem;background-color:${dataLabel['color']};border-radius:0.125rem;margin-right:0.25rem"></div>
		  <div style="color: #777B83;font-size: 0.75rem;">${componentContext.commonFunctions.getSpecificTranslation(dataLabel['name'])}</div>
		</div>
		<div style="color: #1C2331;font-size: 0.875rem;font-weight:900">${dataLabel.y}%</div>
	  </div>
	</div>
				`;
		}
	}

	dataLabelFormatter(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (dataLabel["series"]["userOptions"]["caller"]) {
			case "inventory":
				return `<span style="color:#1C2331;font-family:'Poppins Regular';font-weight:400;font-size:0.875rem">${dataLabel.y}%</span></br><span style="color:#1C2331;font-family:'Poppins Regular';font-size:0.75rem;font-weight:400"> ${dataLabel["series"]["userOptions"]['name'] === 'Available to Purchase' && dataLabel.y < 12 ? componentContext.commonFunctions.getSpecificTranslation('ATP') : dataLabel["series"]["userOptions"]['name'] === 'Out Of Stock' && dataLabel.y < 12 ? componentContext.commonFunctions.getSpecificTranslation('OOS') : dataLabel["series"]["userOptions"]['name'] === 'Newly Tracked' && dataLabel.y < 10?'NL':componentContext.commonFunctions.getSpecificTranslation(dataLabel["series"]["userOptions"]['name'])}</span>`;
			case "channelShareOfBestSelling":
				return `${dataLabel.y > 9 ? `<div style="color:#FFFFFF;font-family:'Poppins Regular';font-weight:500;font-size:0.75rem"><div>${dataLabel.y}%</div><div>${dataLabel["series"]["userOptions"]['name']}</div></div>` : ''}`;
			case "overviewOnlineChannel":
				return `${dataLabel.y > 9 ? `<div style="color:#FFFFFF;font-family:'Poppins Regular';font-weight:500;font-size:0.75rem"><div>${dataLabel.y}%</div><div>${dataLabel["series"]["userOptions"]['name']}</div></div>` : ''}`
			case "availability":
				return `${dataLabel.y > 9 ? `<div style="color:#FFFFFF;font-family:'Poppins Regular';font-weight:500;font-size:0.75rem;"><div>${dataLabel.y}%</div></div>` : ''}`
			case "overviewDealerShare" :
				return `${dataLabel.y > 15 ? `<div style="display:flex;gap:0.5rem;color:#FFFFFF;font-family:'Poppins Regular';font-weight:500;font-size:0.75rem"><div>${dataLabel.y}%</div><div>${dataLabel["series"]["userOptions"]['name']}</div></div>` : ''}`;
			case "marketShare" :
				return `${dataLabel.y > 9 ? `<div style="display:flex;flex-direction:column;gap:0.25rem;color:#1C2331;font-family:'Poppins Regular';font-weight:500;font-size:0.75rem"><div>${dataLabel.y}%</div><div>${dataLabel["series"]["userOptions"]['name']}</div></div>` : ''}`;
			default:
				return `${dataLabel.y > 9 ? `<div style="color:#FFFFFF;font-family:'Poppins Regular';font-weight:500;font-size:0.75rem"><div>${dataLabel.y}%</div><div>${dataLabel["series"]["userOptions"]['name']}</div></div>` : ''}`;
		}
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		this.listenerFn();
	}
}
