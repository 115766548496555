<div ngbDropdown class="d-inline-block position-static" display="dynamic">
    <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span><i class="icon icon-Options"></i></span></span>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="grid-action-dropdown">
       <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="editProfileRightPanel()"><i class="icon  icon-Edit-alt"></i><span translate>Update Notification</span></button>
       <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="openModal(content)"><i class="icon  icon-Trash"></i><span translate>Delete Notification</span></button>
    </div>
 </div>
 <ng-template #content let-modal>
    <div class="modal-header">
       <h4 class="modal-title" translate>Delete Notification</h4>
    </div>
    <div class="modal-body">
       <i class="icon  icon-Trash" ngbAutofocus></i>
       <p translate>Are you sure you want to delete this notification?</p>
    </div>
    <div class="modal-footer">
       <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate>Cancel</button>
       <button type="button" class="btn btn-primary btn-lg" (click)="[deleteProfile(),modal.close('Close click')]" translate>Yes,
          Delete</button>
    </div>
 </ng-template>