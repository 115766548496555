<div
	[ngClass]="{'d-flex justify-content-between align-items-center':moduleType?.name==='build'}"
	class="filter-top-container"
>
	<div
		*ngIf="moduleType?.name!=='scrapped-product' && moduleType?.name!=='sellout-gmv-aggregate-product' && moduleType?.name!=='sellout-product' && moduleType?.name!=='trending-product'"
		class="filter-container d-flex"
		#filterIdentifier
		(resized)="getFilterHeight()"
	>
		<span
			class="filter-capsule"
			*ngFor="let filter of filters.slice(0,maxLengthToshow); index as i"
			[ngClass]="{ active: (filter.id === activeFilters[i].value || filter.name === activeFilters[i].value) && activeFilters[i].isActive }"
			(click)="onSelectFilter(filterById ? filter.id : filter.name, i)"
			translate
		>
			{{ filter.name }}
		</span>
		<span *ngIf="filters?.length === 0" class="filter-capsule" translate
			>Loading...</span
		>

		<div ngbDropdown class="d-inline-block position-static" display="dynamic">
			<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
				><span class="list"
					>{{filters.length > maxLengthToshow ? ('+'+ (filters.length-maxLengthToshow)) : ""}}</span
				>
			</span>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownBasic1"
				class="commonDropdown"
			>
				<div>
					<span
						class="filter-capsule m-2"
						*ngFor="let filter of filters.slice(maxLengthToshow,filters.length); index as i"
						[ngClass]="{ active: (filter.id === activeFilters[i+maxLengthToshow].value || filter.name === activeFilters[i+maxLengthToshow].value) && activeFilters[i+maxLengthToshow].isActive }"
						(click)="onSelectFilter(filterById ? filter.id : filter.name, i+maxLengthToshow)"
						translate
					>
						{{ filter.name }}
					</span>
					<span *ngIf="filters?.length === 0" class="filter-capsule" translate
						>Loading...</span
					>
				</div>
			</div>
		</div>
	</div>
</div>
