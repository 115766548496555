import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DiscoverService } from '@ds-private-layouts/discover/discover.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
@Component({
  selector: 'app-quick-compare',
  templateUrl: './quick-compare.component.html',
  styleUrls: ['./quick-compare.component.scss']
})
export class QuickCompareComponent implements OnInit, OnDestroy {

  public quickInfo: String = '';
  public customerProductSubscription: Subscription;
  public customerProductResponse: any;
  public competitorProductSubscription: Subscription;
  public competitorProductResponse: any;
  public customerProductId: any;
  public activeIndex: number = 0;
  public customerDataPresent: boolean = false;
  public competitorDataPresent: boolean = false;
  @ViewChild('content')
  content: ElementRef;
  constructor(public modalService: NgbModal, private discoverService: DiscoverService, public router: Router, public commonFunctions: CommonFunctionsService, public route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.quickInfo = 'Quick Compare';
  }

  quickComparisonModal(customerId, competitorId) {
    if (!String(this.route["_routerState"]["snapshot"].url).includes("add-notification")) {
      this.customerProductId = customerId;
      this.customerProductResponse = null;
      this.competitorProductResponse = null;
      this.activeIndex = 0;
      this.getCustomerProduct(customerId);
      this.getcompetitorProduct(customerId, competitorId);
      this.modalService.open(this.content, { centered: true, windowClass: "quickCompareModal" });
    }
  }

  getCustomerProduct(id) {
    this.customerProductSubscription?.unsubscribe();
    this.customerDataPresent = false;
    this.customerProductSubscription = this.discoverService.getProductInfo(id, this.commonFunctions.currencySelected?.id).subscribe((res: any) => {
      if (res) {
        this.customerProductResponse = res?.data;
        this.customerDataPresent = true;
      }
    }, (err: any) => {
      this.customerProductResponse = null;
      this.customerDataPresent = true;
    });
  }
  getcompetitorProduct(cusId, compId) {
    this.competitorProductSubscription?.unsubscribe();
    this.competitorDataPresent = false;
    this.competitorProductSubscription = this.discoverService.getCompetitorProductInfo(cusId, 'product', compId, this.commonFunctions.currencySelected?.id).subscribe((res: any) => {
      if (res) {
        this.competitorProductResponse = res?.data;
        this.competitorDataPresent = true;
      }
    }, (err: any) => {
      this.competitorProductResponse = null;
      this.competitorDataPresent = true;
    });
  }
  deepComparison() {
    this.router.navigate(['/scorecard/product/deep-compare'], {
      queryParams: {
        customerProductId: this.customerProductId,
        competitorProductId: this.competitorProductResponse[this.activeIndex]?.id
      },
      queryParamsHandling: "merge",
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  ngOnDestroy(): void {
    if (this.customerProductSubscription) this.customerProductSubscription.unsubscribe();
    if (this.competitorProductSubscription) this.competitorProductSubscription.unsubscribe();
  }

}
