import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-common-services/http-services/http-request.service';
import { ShareOfSearchType } from '@ds-shared/enums/dashboard.enum';
import { AvailableToPurchase, DashboardFilterParams } from '@ds-shared/models/dashboard.model';
import { Service, Resource } from 'ngx-jsonapi';
import { PAYLOAD } from '@ds-shared/enums/common.enum';
import { forkJoin } from 'rxjs';


export class Dashboard extends Resource {
  public attributes = {
    type: '',
    id: '',
  };
}
@Injectable({
  providedIn: "root",
})
export class ProfileHealthMetric extends Service<Dashboard> {
  public resource = Dashboard;
  public type = "profiles-aggregation";
  public ttl = 1;
}
@Injectable({
  providedIn: 'root'
})
export class ProfileHealthMetricExtra extends Service<Dashboard>{
  public resource = Dashboard;
  public type = 'insights/health-grid';
  public ttl = 1;
}


@Injectable({
  providedIn: 'root'
})
export class DashboardService extends Service<Dashboard> {

  constructor(public http: HttpRequestService) {
    super();
  }

  private appendMarketplace(payload){
    if(payload.marketplace?.length){
      return '&' + payload.marketplace.map((item,i)=>`filter[${PAYLOAD.MARKETPLACE_ID}][${i}]=${item.id}`).join('&');
    }
    return '';
  }

  getMarketplaceList(pageNumber: number,search:string,currency_id:number | string,currentModule:string,marketplaceId?,timeline?:string) {
    if(marketplaceId) return this.http.callGetApi(`common/marketplaces?${currency_id ? `filter[${PAYLOAD.CURRENCY_ID}]=${currency_id}&` : ''}filter[marketplace_id][0]=${marketplaceId}${timeline ? timeline : ""}&filter[module]=${currentModule}`);
    else return this.http.callGetApi(`common/marketplaces?${currency_id ? `filter[${PAYLOAD.CURRENCY_ID}]=${currency_id}` : ''}${search && `&filter[search]=${search}`}${timeline ? timeline : ""}&filter[module]=${currentModule}&page[size]=10&page[number]=${pageNumber}`);
  }
  
  getProfileList(marketplace_id: number) {
    return this.http.callGetApi(`insights/profiles?filter[${PAYLOAD.MARKETPLACE_ID}]=[${marketplace_id}]`);
  }
  getCurrency() {
    const PATH = "common/currencies";
    return this.http.callGetApi(PATH);
  }

  getKeywordList(payload: DashboardFilterParams){
    return this.http.callGetApi(`insights/top-keywords?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}`);
  }

  getShareOfSearch(payload: DashboardFilterParams,type:ShareOfSearchType, keyword: number,spliceFilter?) {
    return this.http.callGetApi(`insights/share-of-search?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}&type=${type}${this.appendMarketplace(payload)}${keyword?`&filter[keyword]=${keyword}`:''}${spliceFilter ? spliceFilter : ''}`);
  }
  getAvailableToPurchase(payload: DashboardFilterParams) {
    return this.http.callGetApi(`insights/available-to-purchase?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}`);
  }
  getGMVandQuantitySold(payload: DashboardFilterParams, ToggleType:string,spliceFilter?) {
    return this.http.callGetApi(`insights/gmv-quantity-sold?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}&filter[${PAYLOAD.VIEW}]=quantity&filter[calc_type]=${ToggleType}${spliceFilter ? spliceFilter : ''}`);
  }

  getSellerManagementData(payload: DashboardFilterParams) {
    return this.http.callGetApi(`insights/seller-info?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}`);
  }

  getContentQuality(path: string, payload: DashboardFilterParams) {
    return this.http.callGetApi(`insights/${path}?filter[ai_service]=${true}&filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}`);
  }
  
  getSegmentCountData(payload: DashboardFilterParams) {
    return this.http.callGetApi(`insights/seller-count?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}`);
  }

  getKpiList(payload: DashboardFilterParams) {
    const filter = `filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}`;
    const getContentQuality = this.http.callGetApi(`insights/kpi-panel?kpi=cq&${filter}`);
    const getRatings = this.http.callGetApi(`insights/kpi-panel?kpi=rating&${filter}`);
    const getPositiveReviews = this.http.callGetApi(`insights/kpi-panel?kpi=review&${filter}`);
    const getATP = this.http.callGetApi(`insights/kpi-panel?kpi=atp&${filter}`);
    const getOOS = this.http.callGetApi(`insights/kpi-panel?kpi=oos&${filter}`);
    const getNewLaunches = this.http.callGetApi(`insights/kpi-panel?kpi=new&${filter}`);
    const getLikes = this.http.callGetApi(`insights/kpi-panel?kpi=like&${filter}`);
    const getFollowers = this.http.callGetApi(`insights/kpi-panel?kpi=follower&${filter}`);
    // const getGMV = this.http.callGetApi(`insights/kpi-panel?kpi=gmv&${filter}`);
    // const getQuantitySold = this.http.callGetApi(`insights/kpi-panel?kpi=qsold&${filter}`);
    return forkJoin([getContentQuality, getPositiveReviews, getRatings,getNewLaunches,getATP,getOOS,getLikes,getFollowers]);
  }
  getCurrencyList() {
    const PATH = "common/currencies";
    return this.http.callGetApi(PATH);
  }
  getEnablerList() {
    const PATH = "enabler-list";
    return this.http.callGetApi(PATH);
  }
  editUserInfo(data) {
    return this.http.callPutApi('user', data)
  }
  getUserInfo(id?) {
    if (id != undefined) {
        return this.http.callGetApi('user?user_id=' + id);
    } else {
        return this.http.callGetApi('user');
    }
  }
  getKPIPanelPopUpData(payload: DashboardFilterParams, kpi:string){
    return this.http.callGetApi(`insights/kpi-panel-popup?filter[${PAYLOAD.CURRENCY_ID}]=${payload.currency_id}&filter[${PAYLOAD.TIMELINE}]=${payload.timeline}${this.appendMarketplace(payload)}&kpi=${kpi}`);
  }

  callInsightsAPI(currency_id: number | string) {
    return this.http.callGetApi(`common/insights?filter[currency_id]=${currency_id}`);
  }
}