import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SKU, ProductListService } from '@ds-private-layouts/sellout/sku-analysis/sku-analysis.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { PAYLOAD, TIMELINE } from '@ds-shared/enums/common.enum';
import { ColumnModel, CommonSearchModel, InnerGridDetailFormat, ModuleType } from '@ds-shared/models/common.model';
import { DocumentCollection } from 'ngx-jsonapi';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-recursive-inner-grid',
  templateUrl: './recursive-inner-grid.component.html',
  styleUrls: ['./recursive-inner-grid.component.scss']
})
export class RecursiveInnerGridComponent implements OnInit {
  @Input() item;
  @Input() gridDetails:InnerGridDetailFormat;
  public NoData: boolean = false;
	public sort: string[] = [];
	public loadingData: boolean = false;
	public page: number = 1;
	public pageSize: number = 5;
	public itemPerPage: number = 5;
	public noDataText = 'No data present';
  	public clearLastCall: Subscription;
	public moduleType: ModuleType = {
		name: "skuGrid",
		style: {
			tableStyle: {
				height: "22rem"
			},
			tableWrapper: {
				border: "none",
				"border-radius": "0rem"
			},
			noDataStyle: {
				height: "18rem"
			},
			paginationStyle: {
				border: "none",
				padding: '1rem'
			}
		},
		pagination: {
			show: true
		}
	};
	private destroy$: Subject<boolean> = new Subject();
	public searchedName: UntypedFormControl = new UntypedFormControl("");
	public searchObj: CommonSearchModel = {
		text: "",
		placeholder: "Search Product",
		style: { width: "19rem" }
	};
  public productList: DocumentCollection<SKU>;

  constructor(private productListService: ProductListService, private commonFunctionService: CommonFunctionsService) { 
    productListService.register();
  }

  ngOnInit(): void {
		this.initData()
  }

  private initData(keepPage?:boolean): void {
	if(keepPage) this.getProductList(this.page)
    else this.getProductList(1);
  }

  public sortData(data: string[]): void {
		this.sort = data;
		this.initData(true);
	}

  public onPageChange(eve: number): void {
		this.page = eve;
		this.initData(true)
	}
	public onPageSizeChange(eve: number): void {
		this.pageSize = eve;
		this.itemPerPage = eve;
		this.initData()
	}
  public onSearch(text: string): void {
		this.searchObj = {...this.searchObj,text:text};
		this.initData()
	}

  private getProductList(page?){
    this.clearLastCall?.unsubscribe();
		this.productList = new DocumentCollection();
		let param: any = this.gridDetails.params;
		param[`${PAYLOAD.SEARCH}`] = this.searchObj.text?.trim();
		param[`${PAYLOAD.CURRENCY_ID}`] = this.commonFunctionService.currencySelected?.id;
    	param[PAYLOAD.SKU] = [this.item.id]
		Object.keys(param).forEach(item => {
			if ((Array.isArray(param[item]) && !param[item]?.length) || (!Array.isArray(param[item]) && !param[item])) delete param[item];
		})
		this.gridDetails.exportDetails.fileName = this.commonFunctionService.getDynamicExportFileName(TIMELINE.LAST_90_DAYS,`${this.item.attributes.sku_name}-SKU Products`);
    	this.gridDetails.exportDetails.params = {...this.commonFunctionService.filterPayload({...param,sort: this.sort})}
    // this.exportDetails.params = {
		// 	...param,
		// 	sort: this.sort
		// };
		// this.exportDetails.params = this.commonFunctionService.filterPayload(this.exportDetails.params);
		// this.exportDetails.fileName = this.commonFunctionService.getDynamicExportFileName(TIMELINE.LAST_90_DAYS,'Competitive SKU Pricing');
		this.clearLastCall = this.productListService
			.all({
				page: { number: page || 1, size: this.pageSize || 10 },
				remotefilter: param,
				sort: this.sort,
				beforepath: "reporting"
			})
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(productList) => {
					this.loadingData = true;
					if (
						productList.source == "server" &&
						productList.is_loading == false
					) {
						if (productList.data.length == 0) {
							this.NoData = true;
							this.loadingData = true;
						} else {
							this.loadingData = false;
							this.NoData = false;
							this.productList = productList;
						}
					} else {
						this.loadingData = true;
						this.NoData = false;
					}
				},
				(error: any): void => {
					this.loadingData = true;
					this.NoData = true;
				}
			);
  }



}
