import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@ds-common-services/utility-services/translation.service';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service';
import { RolePermissions } from '@ds-models/access-matrix.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit,OnDestroy {

  public permissions: RolePermissions = new RolePermissions();
  private $destroy: Subject<boolean> = new Subject();
  constructor(private dataTransferService: DataTransferService, private translate: TranslateService, public translationService: TranslationService, private accessMatrixService: AccessMatrixService) { 
    this.setUpdatedLang();
  }

  ngOnInit(): void { 
    this.permissions = this.accessMatrixService.getPermissionByRoute();
		this.dataTransferService.sendfireMenuCount('discover');
  }
  setUpdatedLang() {
    let lang = this.translationService.getBrowsersLocals();
    this.translate.use(`${lang.code}/locale`);
  }

  ngOnDestroy(): void {
    
  }
}
