import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationTypes } from "@ds-shared/enums/notification.enum";

@Component({
	selector: "app-notification-headers",
	templateUrl: "./notification-headers.component.html",
	styleUrls: ["./notification-headers.component.scss"]
})
export class NotificationHeadersComponent implements OnInit {
	NotificationTypes = NotificationTypes;
	@Input() public subType: string;
	@Input() public actionType: string;
	@Output() public selected = new EventEmitter();
	public itemSelected: string = "";
	public notificationInfo: any;
	constructor(private router: Router, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.itemSelected = this.subType;
		this.selected.emit(this.itemSelected);
		this.route.queryParams.subscribe((params: any) => {
			this.notificationInfo = params?.notificationInfo
				? params?.notificationInfo
				: null;
		});
	}
}
