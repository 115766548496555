/* eslint-disable */
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { Subject, filter, takeUntil } from "rxjs";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
let componentContext;

@Component({
	selector: "app-area-chart",
	templateUrl: "./area.component.html",
	styleUrls: ["./area.component.scss"]
})
export class AreaComponent implements OnInit {
	public Highcharts: typeof Highcharts = Highcharts;
	public highchartsData: typeof Highcharts;
	public chartOptions: any;
	private chartRef;
	@Input() data: any;
	@Input() enableBottomLabel: boolean = true;
	@Input() chartTitle: string = "";
	@Input() categories = [];
	@Input() public scrollbarThreshold = 12;
	@Input() public meta = {};
	@Input() public xAxisRotationAngle: number = 0;
	readonly MONTHS = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];
	private destroy$: Subject<boolean> = new Subject();
	constructor(
		private dataTransferService: DataTransferService,
		private commonFunctions: CommonFunctionsService
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				thousandsSep: ","
			}
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		componentContext = this;
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.renderAreaChart();
		}, 0);
	}

	renderAreaChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				type: "areaspline",
				// margin: [0,0,0,0],
				marginTop: 60,
				spacing: [16, 16, 5, 16],
				backgroundColor: "#F7F8FA",
				borderRadius: 2,
				events: {
					load: () => {
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
					}
				}
			},
			title: {
				align: "left",
				text: componentContext.commonFunctions.getSpecificTranslation(
					this.chartTitle
				),
				style: {
					fontSize: "0.813rem",
					fontFamily: "Poppins Regular",
					fontWeight: "400",
					color: "#1C2331"
				}
			},
			scrollbar: {
				enabled: this.categories?.length > this.scrollbarThreshold,
				barBackgroundColor: "#aaaaaae5",
				barBorderRadius: 7,
				barBorderWidth: 0,
				buttonBackgroundColor: "#B3B3B3",
				buttonBorderWidth: 0,
				buttonBorderRadius: 7,
				trackBackgroundColor: "none",
				trackBorderWidth: 0,
				trackBorderRadius: 8,
				trackBorderColor: "#CCC",
				rifleColor: "transparent"
			},
			xAxis: {
				categories: this.categories,
				labels: {
					enabled: true,
					formatter: (ev) => {
						if (ev.value?.includes("-")) {
							return `${
								this.MONTHS[new Date(ev.value).getMonth()] +
								" " +
								new Date(ev.value).getDate()
							}`;
						}
						return ev.value; // clean, unformatted number for year
					},
					step: 1,
					style: {
						color: "#898D96",
						fontFamily: "Poppins Regular",
						fontWeight: "400",
						fontSize: "0.625rem"
					},
					rotation: this.xAxisRotationAngle
				}
				// max: (this.categories?.length > this.scrollbarThreshold) ? (this.scrollbarThreshold - 1) : (this.categories?.length - 1)
			},
			yAxis: {
				labels: {
					enabled: true,
					formatter: function () {
						if (typeof this.value === "number" && this.value < 1000)
							return this.value;
						return this.value / 1000 + "k";
					},
					style: {
						color: "#898D96",
						fontFamily: "Poppins Regular",
						fontWeight: "400",
						fontSize: "0.625rem"
					}
				},
				// left:20,
				lineColor: "#eaebed",
				gridLineColor: "#eaebed",
				title: {
					enabled: false
				}
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: true,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true
			},
			plotOptions: {
				areaspline: {
					lineColor: "#FF5E00",
					cursor: "pointer",
					marker: {
						enabled: this.data?.length === 1,
						symbol: "circle",
						radius: 4,
						fillColor: "#FF5E00",
						states: {
							hover: {
								enabled: true
							}
						}
					},
					events: {
						click: (event) => {
							this.dataTransferService.sendGraphSliceData({
								...event.point.options,
								category: event.point.category
							});
						}
					}
				}
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			series: [
				{
					data: this.data,
					color: "#f8e8e1"
				}
			]
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (dataLabel["point"]["options"]["caller"]) {
			case "trend":
				return `<div
    style="background-color:${
			TOOLTIPCOLOR["BG"]
		};min-width:8.75rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${
			TOOLTIPCOLOR["TEXT"]
		};font-size:0.625rem;margin:0;font-weight:400">${
					dataLabel["point"]["options"]["time"] === "day"
						? "at " + componentContext.tConvert(dataLabel["key"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["key"]).getMonth()] +
						  " " +
						  new Date(dataLabel["key"]).getDate()
				}</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
        <div style="display:flex;flex-direction: column;">
            <div style="color:${
							TOOLTIPCOLOR["VALUE"]
						};font-size:1rem;font-weight:500">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)} <span style="font-size:0.688rem">${
					dataLabel.y === 1
						? componentContext.commonFunctions.getSpecificTranslation("Product")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Products"
						  )
				}</span></div>
            <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.688rem;font-weight:400">${
					dataLabel["series"]["chart"]["title"]["textStr"]
				}</span>
        </div>
    </div>
</div>`;
				break;
			case "skuTrend":
				return `<div
    style="background-color:${
			TOOLTIPCOLOR["BG"]
		};min-width:8.75rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${
			TOOLTIPCOLOR["TEXT"]
		};font-size:0.625rem;margin:0;font-weight:400">${
					dataLabel["point"]["options"]["time"] === "day"
						? "at " + componentContext.tConvert(dataLabel["key"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["key"]).getMonth()] +
						  " " +
						  new Date(dataLabel["key"]).getDate()
				}</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
        <div style="display:flex;flex-direction: column;">
            <div style="display:flex;flex-direction:column;color:${
							TOOLTIPCOLOR["VALUE"]
						};font-size:1rem;font-weight:500">${
					dataLabel["point"]["options"]["criteria"] === "sales"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol
						: ""
				}${
					dataLabel["point"]["options"]["criteria"] === "sales"
						? componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel.y
						  )
						: componentContext.commonFunctions.numberFormat(dataLabel.y)
				} <span style="font-size:0.688rem">${
					dataLabel["point"]["options"]["criteria"] === "sales"
						? componentContext.commonFunctions.getSpecificTranslation("GMV")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity Sold"
						  )
				}</span>
			</div>
            <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.688rem;font-weight:400">${
					dataLabel["series"]["chart"]["title"]["textStr"]
				}</span>
        </div>
    </div>
</div>`;
			default:
				return "";
		}
	}

	tConvert(time) {
		// Check correct time format and split into components
		time = time
			.toString()
			.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) {
			// If time format correct
			time = time.slice(1); // Remove full string match value
			time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(""); // return adjusted time or original string
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
