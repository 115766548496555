<div class="secondary-filter-content d-flex align-items-center justify-content-end">
    <p class="d-inline info-text" *ngIf="startDate == endDate && !hideSelectedDateText">{{'Showing data for' | translate}} {{startDate}}</p>
    <p class="d-inline info-text" *ngIf="startDate != endDate && !hideSelectedDateText">{{'Showing data from' | translate}} {{startDate}} {{'to' | translate}} {{endDate}}</p>
    <div class="toggle_radio fs_sm">
        <input type="radio" checked id="one_month" name="toggle_option1" [ngClass]="{'active' : rangeString == 'month'}"
            (click)="setRange('month')">
        <label for="one_month">
            <p translate>Monthly Trend</p>
        </label>
        <input type="radio" id="three_month" name="toggle_option1" [ngClass]="{'active' : rangeString == '3 month'}"
            (click)="setRange('3 month')">
        <label for="three_month">
            <p translate>Quarterly Trend</p>
        </label>
    </div>
</div>
