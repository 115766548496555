export enum MATCHTYPE {
	"category" = "Category Matches",
	"brand" = "Brand Matches",
    "keyword" = "Keyword Matches",
	"RS" = "Reseller Matches",
	"CP" = "Competitor Matches",
	"GS" = "Greyseller Matches",
	"direct" = "Direct Matches",
	"OT"="Other Matches"
}

export enum MATCHTYPEBADGECLASS {
    "category" = "badge-category",
	"brand" = "badge-brand",
    "keyword" = "badge-keyword",
	"RS" = "badge-purple",
	"CP" = "badge-blue",
	"GS" = "badge-grey",
	"direct" = "badge-sunshine",
	"OT"="badge-others"
}