import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
  pure: false
})
export class PluralPipe implements PipeTransform {

  transform(word: string, count: number = 2, pluralForm: string = 's'): string {
    let transformed;
    // 'ies' vs singular 'y' (city / cities)
    if (pluralForm === 'ies') {
      // replace y with ies
      transformed = (count === 1 || !count) ? word : word.replace(/.$/, pluralForm);
    } else {
      // default to plural 's' (applicant / applicants)
      transformed = (count === 1 || !count) ? word : word + pluralForm;
    }
    return transformed;
  }

}
