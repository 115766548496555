import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-kpi-filter',
  templateUrl: './radio-kpi-filter.component.html',
  styleUrls: ['./radio-kpi-filter.component.scss']
})
export class RadioKpiFilterComponent implements OnInit {
  @Input() public radioKpiList = [];
  @Output() kpiEmit: EventEmitter<string | number | {}> = new EventEmitter();
  selectedRadioKpi={};
  constructor() { }

  ngOnInit(): void {
    this.selectedRadioKpi = this.radioKpiList[0]
  }

  onRadioChange(){
    this.kpiEmit.emit(this.selectedRadioKpi)
  }

}
