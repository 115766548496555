export enum FilterLables {
    "profiles" = "profiles",
    "brands" = "brands",
    "marketplaces" = "marketplaces",
    "categories" = "categories",
    "stores" = "stores"
}

export enum ViewOnSellout{
    'PRODUCT'='PRODUCT',
    'BRAND'='BRAND',
    'CATEGORY'='CATEGORY',
    'STORE'='STORE',
}

export enum ShareOfSearchType{
    'BRAND'='brand',
    'STORE'='store',
    'SEGMENT'='segment',
    'CATEGORY'='category',
    'MARKETPLACE'='marketplace'
}


export enum ContentQualityMetrics{
    'IMAGE'='IMAGE',
    'CONTENT'='CONTENT',
    'REVIEW'='REVIEW',
    'RATING'='RATING'
  }

export enum BySellerGraph{
    SALES_BY_SELLER = 'sales_by_seller',
    HISTORICAL_SOLD = 'historical_sold'
}