import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';

@Component({
  selector: 'app-compare-info',
  templateUrl: './compare-info.component.html',
  styleUrls: ['./compare-info.component.scss']
})
export class CompareInfoComponent implements OnInit {

  @Input() public productDetail: any;
  @Input() public type: any;
  @Input() public productId: any;
  @Output("onCloseAction") onCloseAction: EventEmitter<any> =
    new EventEmitter();
  constructor(public commonFunctions: CommonFunctionsService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.onCloseAction.emit();
  }

}
