import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-coming-soon-pop-up",
	templateUrl: "./coming-soon-pop-up.component.html",
	styleUrls: ["./coming-soon-pop-up.component.scss"]
})
export class ComingSoonPopUpComponent implements OnInit {
	constructor(public ngbActiveModalService: NgbActiveModal) {}

	ngOnInit(): void {}
}
