import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import { Subscription, finalize } from "rxjs";
import { DashboardService } from "@ds-private-layouts/dashboard/dashboard.service";
import { CategoryMarketShareService } from "@ds-private-layouts/sellout/category-market-share/category-market-share.service";
import { LocalStorageService } from "@ds-shared/common-services/storage-services/local-storage.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { COMMONTEXT } from "@ds-shared/enums/common.enum";
import {
	SingleItemModel,
	MarketplaceModel
} from "@ds-shared/models/dashboard.model";
import { CategoryListResponse } from "@ds-shared/models/sellout.model";

@Component({
	selector: "app-global-filters",
	templateUrl: "./global-filters.component.html",
	styleUrls: ["./global-filters.component.scss"]
})
export class GlobalFiltersComponent implements OnInit {
	@Input() public marketShareType: string;
	public defaultMetricView = "gmv";
	public marketplace: {
		list: SingleItemModel[];
		selectedMarketplace: SingleItemModel[];
		placeholder: string;
		searchMarketplace: string;
		isLoading: boolean;
		clearGetMarketplaceListCall: Subscription;
	} = {
		list: [],
		selectedMarketplace: [] as SingleItemModel[],
		placeholder: `${COMMONTEXT.RETAIL_CHANNEL}s`,
		searchMarketplace: "",
		isLoading: true,
		clearGetMarketplaceListCall: null
	};

	public categoryList: {
		list: CategoryListResponse[];
		selectedCategory: CategoryListResponse[];
		placeholder: string;
		searchCategory: string;
		isLoading: boolean;
		clearLastCall: Subscription;
	} = {
		list: [],
		selectedCategory: [],
		placeholder: "Category",
		searchCategory: "",
		isLoading: true,
		clearLastCall: null
	};

	public brandFilter: { id: number; label: string; code: number }[] = [
		{
			id: 0,
			label: "All Brands",
			code: 0
		},
		{
			id: 1,
			label: "Top 5 Brands",
			code: 5
		}
	];

	public selectedBrandType = this.brandFilter[0];

	constructor(
		private dashboardService: DashboardService,
		private commonFunctions: CommonFunctionsService,
		private localStorageService: LocalStorageService,
		public router: Router,
		private dataTransferService: DataTransferService,
		private categoryMarketShareService: CategoryMarketShareService
	) {}

	ngOnInit(): void {
		this.marketplace.selectedMarketplace = this.localStorageService.get(
			this.localStorageService.globalFilterStorageKey
		)?.marketplace;
		this.getMarketPlaceList();
		this.marketShareType === "category-market-share" &&
			this.getCategoryFilter();
		this.sendSelectedData();
	}

	private getCategoryFilter(): void {
		this.categoryList.isLoading = true;
		this.categoryList.list = [];
		this.categoryList.clearLastCall?.unsubscribe();
		this.categoryList.clearLastCall = this.categoryMarketShareService
			.callCategoryFilter()
			.pipe(finalize(() => (this.categoryList.isLoading = false)))
			.subscribe(
				(res: {
					context_code: number;
					data: CategoryListResponse[];
					summary: string;
				}) => {
					this.categoryList.list = res.data;
				}
			);
	}

	public getMarketPlaceList(): void {
		this.marketplace.isLoading = true;
		this.marketplace.clearGetMarketplaceListCall?.unsubscribe();
		this.marketplace.clearGetMarketplaceListCall = this.dashboardService
			.getMarketplaceList(
				1,
				this.marketplace.searchMarketplace,
				this.commonFunctions.currencySelected?.id,
				this.router.url.split("/").slice(1).join("-"),
				null,
				"&filter[timeline]=last_90_days"
			)
			.pipe(finalize(() => (this.marketplace.isLoading = false)))
			.subscribe(
				(res: MarketplaceModel) => {
					if (!res) return;
					this.marketplace.list = [...res?.data];
				},
				() => {
					this.marketplace.list = [];
				}
			);
	}
	public checkSelectedOption(item) {
		if (this.marketplace?.selectedMarketplace?.length) {
			return this.marketplace?.selectedMarketplace
				?.map((val) => val.id)
				.includes(item.id);
		} else return false;
	}

	public searchData(event: { term: string; items: SingleItemModel[] }): void {
		this.marketplace.searchMarketplace = event.term;
	}

	public onMarketPlaceSelection(event) {
		this.localStorageService.set(
			this.localStorageService.globalFilterStorageKey,
			{
				...this.localStorageService.get(
					this.localStorageService.globalFilterStorageKey
				)?.marketplace,
				marketPlace: this.marketplace.selectedMarketplace
			}
		);
		this.sendSelectedData();
	}

	public onMetricViewChange(selectedMetric) {
		this.defaultMetricView = selectedMetric;
		this.sendSelectedData();
	}

	public onChange(e) {
		this.selectedBrandType = { ...e };
		this.sendSelectedData();
	}

	public onCategorySearch(event) {
		this.categoryList.searchCategory = event.term;
	}

	public onCategorySelect(event: CategoryListResponse[]) {
		this.categoryList.selectedCategory = [...event];
		this.sendSelectedData();
	}

	closeMultiDropdown() {
		this.categoryList.searchCategory = "";
	}

	public addTick(item) {
		if (this.categoryList.selectedCategory.some((e) => e.id === item.id)) {
			return true;
		} else return false;
	}

	public onMarketplaceClose(): void {
		this.marketplace.searchMarketplace = "";
	}

	private sendSelectedData() {
		this.dataTransferService.sendTimeLineFilterFilter({
			viewBy: this.defaultMetricView,
			marketPlace: this.marketplace.selectedMarketplace,
			currency_id: this.commonFunctions.currencySelected?.id,
			timeline: {
				startDate: moment().subtract(90, "d").format("YYYY-MM-DD"),
				endDate: moment().subtract(1, "d").format("YYYY-MM-DD")
			},
			timelineText: `${moment()
				.subtract(90, "d")
				.format("MMM Do YYYY")} to ${moment()
				.subtract(1, "d")
				.format("MMM Do YYYY")}`,
			brandFilter: this.selectedBrandType,
			selectedCategory: this.categoryList.selectedCategory
		});
	}
}
