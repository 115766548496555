/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit, Output } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { Subject, filter, takeUntil } from "rxjs";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
let componentContext;

@Component({
	selector: "app-pie-chart",
	templateUrl: "./pie-chart.component.html",
	styleUrls: ["./pie-chart.component.scss"]
})
export class PieChartComponent implements OnInit, AfterViewInit {
	@Input() public data;
	@Input() public categories;
	@Input() public graphCenterTitle = "";
	@Input() public graphCenterTitleStyle: {} = {
		fontSize: "1rem",
		fontFamily: "Poppins Medium",
		fontWeight: "500"
	};
	@Input() public height = 240;
	@Input() public width = 240;
	@Input() public showLegend: boolean = false;
	@Input() public innerSize: number | string = "0%";
	@Input() public disableHalo: boolean = false;
	@Input() public borderWidth: number = 2;
	@Input() public showDataLabel: boolean = false;
	@Input() public caller = "default";
	@Input() public meta = {};
	@Input() public showTooltipOutside: boolean = true;
	@Input() isDate = false;
	@Input() distanceDatalabel = {
		textOutline: "none",
		textOverflow: "none",
		distance: "-30",
		margin: [0, 0, 0, 0],
		spacing: [0, 0, 0, 0]
	};
	private destroy$: Subject<boolean> = new Subject();
	public Highcharts: typeof Highcharts = Highcharts; // required
	public highchartsData: typeof Highcharts;
	public chartRef;
	public chartOptions;
	readonly MONTHS = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];
	constructor(
		private dataTransferService: DataTransferService,
		private commonFunctions: CommonFunctionsService
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				thousandsSep: ","
			}
		});
	}

	ngAfterViewInit(): void {
		this.renderPieChart();
	}

	formatNumberWithOneDecimalPlace(number) {
		return Math.floor(number * 10) / 10;
	}

	renderPieChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				margin: this.distanceDatalabel.margin,
				spacing: this.distanceDatalabel.spacing,
				type: "pie",
				verticalAlign: "middle",
				floating: true,
				reflow: true,
				move: false,
				// height:this.height,
				backgroundColor: "rgba(0,0,0,0)",
				width: this.width,
				overflow: "visible"
			},
			credits: {
				enabled: false
			},

			plotOptions: {
				series: {
					states: {
						hover: {
							enabled: true,
							halo: {
								size: this.disableHalo ? 0 : 9
							}
						}
					}
				},
				overflow: "visible",
				pie: {
					innerSize: this.innerSize,
					size: "100%",
					// borderWidth: this.borderWidth,
					// borderColor: "#ffffff",
					slicedOffset: 0,
					cursor: "pointer",
					// move: true,
					center: ["50%", "50%"],
					shadow: false,
					showInLegend: this.showLegend,
					dataLabels: {
						// padding: 0,
						connectorWidth: 3,
						enabled: this.showDataLabel,
						distance: this.distanceDatalabel.distance,
						useHTML: true,
						formatter: function () {
							if (
								componentContext.caller === "brandMarketShare" &&
								this.y > 20
							) {
								return `<div style="display:flex;flex-direction:column;color:#FFFFFF">
											<span style="color:#FFFFFF;font-family:Poppins Regular;font-weight:400;font-size: 0.75rem">${this.y}%</span>
										</div>
								`;
							}
							return `${
								Number.isInteger(this.y) && this.y > 2
									? `<span style="color:#FFFFFF;font-family:Poppins Regular;font-weight:400;font-size: 0.75rem">${this.y}%</span>`
									: this.y > 4.5
									? `<span style="color:#FFFFFF;font-family:Poppins Regular;font-weight:400;font-size: 0.75rem">${componentContext.formatNumberWithOneDecimalPlace(
											this.y
									  )}%</span>`
									: ""
							}`;
						},
						style: {
							textOutline: this.distanceDatalabel.textOutline,
							textOverflow: this.distanceDatalabel.textOverflow,
							fontSize: "8px",
							marginRight: "20px",
							color: "#000000"
						},
						crop: false,
						overflow: "allow"
					}
				}
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: false,
				hideDelay: 100,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true,
				outside: this.showTooltipOutside
			},
			title: {
				verticalAlign: "middle",
				floating: true,
				text: componentContext.commonFunctions.getSpecificTranslation(
					this.graphCenterTitle
				),
				style: this.graphCenterTitleStyle
			},
			legend: {
				enabled: this.showLegend,
				floating: true,
				padding: 0,
				margin: 0,
				maxHeight: 100
			},

			series: [
				{
					data: this.data,
					point: {
						events: {
							click: (ev) => {
								this.dataTransferService.sendGraphSliceData(ev.point.options);
							}
						}
					}
				}
			]
		};
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		const symbol = dataLabel["series"]["userOptions"]["data"][0]["currency"];
		const isStoreBrandCategory =
			dataLabel["point"]["options"]["graphType"] === "brand" ||
			dataLabel["point"]["options"]["graphType"] === "store" ||
			dataLabel["point"]["options"]["graphType"] === "category" ||
			dataLabel["point"]["options"]["type"] === "brand" ||
			dataLabel["point"]["options"]["type"] === "store" ||
			dataLabel["point"]["options"]["type"] === "category" ||
			dataLabel["point"]["options"]["shareType"] === "brand" ||
			dataLabel["point"]["options"]["shareType"] === "store" ||
			dataLabel["point"]["options"]["shareType"] === "category";
		switch (dataLabel["point"]["options"]["caller"]) {
			case "gmv":
				const isQuantitySold =
					dataLabel["series"]["userOptions"]["data"][0]["tooltipName"] ===
					"Quantity Sold";
				return `<div
    style="background-color:${TOOLTIPCOLOR["BG"]};min-width: ${
					isQuantitySold ? "12rem" : "8rem"
				};border-radius:0.375rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${
			TOOLTIPCOLOR["TEXT"]
		};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
        <div style="display:flex;flex-direction: column;">
            <span style="color:${
							TOOLTIPCOLOR["VALUE"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["quantity"]
				)}</span>
            <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["userOptions"]["data"][0]["tooltipName"]
				)}</span>
        </div>
        ${
					isQuantitySold
						? `<div style="display:flex;flex-direction: column;">
            <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
								dataLabel["point"]["options"]["gmv"]
									? dataLabel["point"]["options"]["currency"]
									: ""
						  }${componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["options"]["gmv"]
						  )}</span>
                    <span style="color:${
											TOOLTIPCOLOR["TEXT"]
										};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
								"GMV"
						  )}</span>`
						: ""
				}
        </div>
    </div>
</div>`;
				break;
			case "sos":
			case "scorecard_sos":
				return `<div
style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem 0 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;">
<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)}</p>
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">
				${
					isStoreBrandCategory && dataLabel["point"]["options"]["marketplace"]
						? "(" + dataLabel["point"]["options"]["marketplace"] + ")"
						: ""
				}
				</span>
<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
<div style="display:flex;flex-direction: column; margin:0.5rem 0">
	<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel.y
				}%</span>
</div>
</div>
</div>`;
			case "overviewBrandShare":
				return `<div
style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)</p>
				<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)}</p>
<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
<div style="display:flex;flex-direction: column;">
	<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					componentContext.meta?.criterion === "sales"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["value"]
				)}</span>
	<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					componentContext.meta?.criterion === "sales"
						? componentContext.commonFunctions.getSpecificTranslation("GMV")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity Sold"
						  )
				}</span>
</div>
</div>
</div>`;
				break;
			case "segments":
				return `<div
				style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
				<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)</p>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column;">
						<span style="color:${
							TOOLTIPCOLOR["VALUE"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["quantity"]
				)} </span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					dataLabel["series"]["chart"]["title"]["textStr"]
				}</span>
					</div>
				</div>
			</div>`;
				break;
			case "variants":
				return `<div
    style="background-color:${
			TOOLTIPCOLOR["BG"]
		};min-width:12rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
    <p style="color:${
			TOOLTIPCOLOR["TEXT"]
		};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)</p>
    <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
        <div style="display:flex;flex-direction: column;">
            <span style="color:${
							TOOLTIPCOLOR["VALUE"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["quantity"]
				)}</span>
            <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Products"
				)}</span>
        </div>
		<div style="display:flex;flex-direction: column;">
		<span style="color:${
			TOOLTIPCOLOR["VALUE"]
		};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["variants"]
				)}</span>
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Variants"
				)}</span>
	</div>
    </div>
</div>`;
			case "xCurrency":
				return `<div
			style="background-color:${
				TOOLTIPCOLOR["BG"]
			};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${
				TOOLTIPCOLOR["TEXT"]
			};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">
			${
				componentContext.isDate ||
				dataLabel["series"]["userOptions"]["data"][0]["isDate"]
					? dataLabel["key"].toString().includes(":")
						? "at " + componentContext.tConvert(dataLabel["key"])
						: "on " +
						  componentContext.MONTHS[new Date(dataLabel["key"]).getMonth()] +
						  " " +
						  new Date(dataLabel["key"]).getDate()
					: componentContext.commonFunctions.getSpecificTranslation(
							dataLabel["key"]
					  )
			}
				</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: column;">
				<span style="color:${
					TOOLTIPCOLOR["VALUE"]
				};font-size:0.875rem;">${symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["series"]["userOptions"]["data"][
						`${dataLabel["point"]["index"]}`
					]["x"]
				)}</span>
			</div>
			</div>
			</div>`;
			case "xUnit":
				return `<div
			style="background-color:${
				TOOLTIPCOLOR["BG"]
			};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${
				TOOLTIPCOLOR["TEXT"]
			};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["data"][0]["isDate"]
						? dataLabel["key"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["key"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["key"]).getMonth()] +
							  " " +
							  new Date(dataLabel["key"]).getDate()
						: componentContext.commonFunctions.getSpecificTranslation(
								dataLabel["key"]
						  )
				}</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: column;">
				<span style="color:${
					TOOLTIPCOLOR["VALUE"]
				};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["series"]["userOptions"]["data"][
						`${dataLabel["point"]["index"]}`
					]["x"]
				)} units</span>
			</div>
			</div>
			</div>`;
			case "xSimple":
				return `<div
			style="background-color:${
				TOOLTIPCOLOR["BG"]
			};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
			<p style="color:${
				TOOLTIPCOLOR["TEXT"]
			};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">${componentContext.commonFunctions.getSpecificTranslation(
					componentContext.isDate ||
						dataLabel["series"]["userOptions"]["data"][0]["isDate"]
						? dataLabel["key"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["key"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["key"]).getMonth()] +
							  " " +
							  new Date(dataLabel["key"]).getDate()
						: dataLabel["key"]
				)}</p>
			<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
			<div style="display:flex;flex-direction: column;">
				<span style="color:${
					TOOLTIPCOLOR["VALUE"]
				};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["series"]["userOptions"]["data"][
						`${dataLabel["point"]["index"]}`
					]["x"]
				)}</span>
			</div>
			</div>
			</div>`;
			case "pricing_position":
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
							<p style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0;text-transform:capitalize">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)</p>
							<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
								<div style="display:flex;flex-direction: column;">
									<span style="color:${
										TOOLTIPCOLOR["VALUE"]
									};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["x"]
				)}</span>
									<span style="color:${
										TOOLTIPCOLOR["TEXT"]
									};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Products"
				)}</span>
								</div>
							</div>
						</div>`;
			case "marketShare":
				const calculationType =
					dataLabel["series"]["userOptions"]["data"][0]["calculationType"];
				return `<div
				style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:12rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;">
				<p style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)</p>
				<span style="color:${
					TOOLTIPCOLOR["TEXT"]
				};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">
				${
					isStoreBrandCategory && dataLabel["point"]["options"]["marketplace"]
						? "(" + dataLabel["point"]["options"]["marketplace"] + ")"
						: ""
				}
				</span>
				<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column; margin : 0.5rem 0">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					calculationType === "gmv"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["options"]["value"]
				)}</span>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">
								${
									dataLabel["point"]["options"]["marketShareType"] === "sales"
										? componentContext.commonFunctions.getSpecificTranslation(
												calculationType === "gmv"
													? "Estimated Sales"
													: "Quantity Sold"
										  )
										: componentContext.commonFunctions.getSpecificTranslation(
												"Number of Assortments"
										  )
								}
							</span>
					</div>
					${
						dataLabel["point"]["options"]["marketShareType"] === "sales"
							? `<div style="display:flex;flex-direction: column;; margin : 0.5rem 0"">
				</div>`
							: ""
					}
				</div>
			</div>`;
				break;
			case "brandMarketShare":
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
							<p style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0;text-transform:capitalize">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)
							<span style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0;text-transform:capitalize;display:inline-block">${
					dataLabel["point"]["options"]["segment"]
						? `${dataLabel["point"]["options"]["segment"]}`
						: ""
				}</span>
							<span style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0;text-transform:capitalize;display:inline-block">${
					dataLabel["point"]["options"]["marketplace"]
						? `(${dataLabel["point"]["options"]["marketplace"]})`
						: ""
				}</span></p>
							<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
								<div style="display:flex;flex-direction: column;">
									<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["share_on"] === "gmv"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol +
						  componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["options"]["value"]
						  )
						: componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["options"]["value"]
						  )
				}</span>
									<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["share_on"] === "gmv"
						? componentContext.commonFunctions.getSpecificTranslation("GMV")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity Sold"
						  )
				}
									</span>
								</div>
							</div>
						</div>`;
			case "segmentsPromotion":
				return `<div
					style="background-color:${
						TOOLTIPCOLOR["BG"]
					};min-width:8rem;border-radius:0.375rem;display:flex;flex-direction: column;padding:0.5rem 0.625rem 0 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;">
					<p style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)}</p>
									<span style="color:${
										TOOLTIPCOLOR["TEXT"]
									};font-size:0.625rem;margin:0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;">
									${
										isStoreBrandCategory &&
										dataLabel["point"]["options"]["marketplace"]
											? "(" + dataLabel["point"]["options"]["marketplace"] + ")"
											: ""
									}
									</span>
					<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
					<div style="display:flex;flex-direction: column; margin:0.5rem 0">
						<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel.y
				}%</span>
					</div>
					</div>
					</div>`;
			case "categoryMarketShare":
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
							<p style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0;text-transform:capitalize">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)
							<span style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0;text-transform:capitalize;display:inline-block">${
					dataLabel["point"]["options"]["segment"]
						? `${dataLabel["point"]["options"]["segment"]}`
						: ""
				}</span>
							<span style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.625rem;margin:0;text-transform:capitalize;display:inline-block">${
					dataLabel["point"]["options"]["marketplace"]
						? `(${dataLabel["point"]["options"]["marketplace"]})`
						: ""
				}</span></p>
							<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
								<div style="display:flex;flex-direction: column;">
									<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["share_on"] === "gmv"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol +
						  componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["options"]["value"]
						  )
						: componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["options"]["value"]
						  )
				}</span>
									<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					dataLabel["point"]["options"]["share_on"] === "gmv"
						? componentContext.commonFunctions.getSpecificTranslation("GMV")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity Sold"
						  )
				}
									</span>
								</div>
							</div>
						</div>`;
			default:
				return "";
		}
	}
	formatterFunction(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		return componentContext.commonFunctions.getSuffixedNumberFormat(
			dataLabel.y
		);
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
