import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-common-card",
	templateUrl: "./common-card.component.html",
	styleUrls: ["./common-card.component.scss"]
})
export class CommonCardComponent implements OnInit {
	private _cardData: any;
	get cardData(): any {
		return this._cardData;
	}
	@Input() set cardData(value: any) {
		this._cardData = value;
	}
	constructor() {}

	ngOnInit(): void {}
}
