import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import { PluralPipe } from "@ds-pipes/plural.pipe";
import { Subject, Subscription, takeUntil } from "rxjs";
import { DocumentCollection } from "ngx-jsonapi";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validators
} from "@angular/forms";
import {
	ColumnModel,
	CommonSearchModel,
	ModuleType
} from "@ds-shared/models/common.model";

import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { COL, LOCALFILTER, PAYLOAD } from "@ds-shared/enums/common.enum";
import { RolePermissions } from "@ds-shared/models/access-matrix.model";
import { AccessMatrixService } from "@ds-shared/common-services/utility-services/access-matrix.service";
import {
	HarmonisationService,
	Harmonised,
	HarmonisedListService
} from "@ds-private-layouts/configuration/harmonisation/harmonisation.service";
import { CustomValidationsService } from "@ds-shared/common-services/utility-services/custom-validations.service";
import {
	OptionalFilters,
	AddHarmResponse,
	FILTERKEY,
	RefreshGridEvent,
	RefreshGridAfterDelete,
	GridParams
} from "@ds-shared/models/harmonisation.model";

@Component({
	selector: "app-harmonisation-by-type",
	templateUrl: "./harmonisation-by-type.component.html",
	styleUrls: ["./harmonisation-by-type.component.scss"]
})
export class HarmonisationByTypeComponent implements OnInit, OnDestroy {
	@Input() public harmonisationType: string;
	public activeTabIndex = 0;
	public tabList: { name: string; code: string }[] = [];
	public cols: ColumnModel[] = [];
	public harmonisedList: DocumentCollection<Harmonised>;
	public NoData = false;
	public sort: string[] = [];
	public loadingData = false;
	public page = 1;
	public pageSize: number;
	public itemPerPage = 10;
	public noDataText = "No data present";
	public defaultMetricView = "all";
	public moduleType: ModuleType = {
		name: "store",
		style: {
			tableStyle: {
				height: "calc(100vh - 20.5rem)"
			},
			tableWrapper: {
				border: "none",
				"border-radius": "0rem"
			},
			noDataStyle: {
				height: "27rem"
			},
			paginationStyle: {
				border: "none",
				padding: "1rem"
			}
		},
		pagination: {
			show: true
		}
	};
	private destroy$: Subject<boolean> = new Subject();
	public searchedName: UntypedFormControl = new UntypedFormControl("");
	public searchObj: CommonSearchModel = {
		text: "",
		placeholder: "Search",
		style: { width: "15rem" }
	};
	private clearLastCall: Subscription = new Subscription();
	public exportPath = "harmonisation/export-harmonisation-by-type";
	public exportParams = {};
	public exportedFileName = "";
	public pluralGridName = "";
	private titleCasePipe = new TitleCasePipe();
	public isMappingPanelOpen = false;
	public showOnlyUnmapped = false;
	public permissions: RolePermissions = new RolePermissions();
	public productCollection: UntypedFormGroup;
	// ProductFilters
	public newProductSegment: string;
	public productFilters: OptionalFilters = {
		[LOCALFILTER.PRODUCT_SEGMENT]: {
			label: "Product Segment",
			placeholder: "Product Segment",
			list: [],
			meta: { key: "attributes.name" },
			selected: [],
			searchTerm: "",
			loading: false,
			path: "brands",
			query: [],
			clear: true,
			closeOnSelect: false,
			showFilter: true,
			payloadString: "segment",
			needAPIcall: true
		},
		[LOCALFILTER.BRAND]: {
			label: "Brand Collection",
			placeholder: "Brand Collection",
			list: [],
			meta: { key: "id" },
			selected: [],
			searchTerm: "",
			loading: false,
			path: "brands",
			query: [],
			clear: true,
			closeOnSelect: false,
			showFilter: true,
			payloadString: "harmonised_brand_id",
			needAPIcall: true
		},
		[LOCALFILTER.NEW_PRODUCT_SEGMENT]: {
			label: "Product Segment",
			placeholder: "Add new product segment",
			list: [],
			meta: { key: "" },
			selected: [],
			searchTerm: "",
			loading: false,
			path: "brands",
			query: [],
			clear: true,
			closeOnSelect: false,
			showFilter: false,
			payloadString: "segment",
			needAPIcall: false
		}
	};
	public filters: FILTERKEY[] = Object.keys(this.productFilters) as FILTERKEY[];

	constructor(
		public commonFunctions: CommonFunctionsService,
		private harmonisedListService: HarmonisedListService,
		private harmonisationService: HarmonisationService,
		private modalService: NgbModal,
		private dataTransferService: DataTransferService,
		private customValidations: CustomValidationsService,
		private notificationMessageService: NotificationService,
		private accessMatrixService: AccessMatrixService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
		this.harmonisationService.register();
		this.dataTransferService.harmonisationMappingDelete$
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				this.deleteMapping(res.harmonisedId, res.mappings);
			});
	}

	ngOnInit(): void {
		this.productCollection = new UntypedFormGroup({
			brand: new UntypedFormControl(null),
			product_segment: new UntypedFormControl(null),
			new_product_segment: new UntypedFormControl("", [
				this.validateString.bind(this)
			]),
			harmonisedName: new UntypedFormControl("", [
				Validators.required,
				this.validateString.bind(this)
			])
		});
		const plural = new PluralPipe();
		this.pluralGridName = plural.transform(
			this.titleCasePipe.transform(this.harmonisationType),
			2,
			this.harmonisationType.includes("category") ? "ies" : "s"
		);
		this.moduleType.name = this.harmonisationType;
		this.searchObj = {
			...this.searchObj,
			placeholder: ["store", "product"].includes(this.harmonisationType)
				? `Search name or ${this.harmonisationType === "product" ? "sku no." : "reg no."
				}`
				: "Search"
		};
		this.initializeGrid();
		this.columnConfig();
	}

	private validateString(control: UntypedFormControl): ValidationErrors | null {
		return this.customValidations.validateInputString(control);
	}

	private getFilters() {
		this.filters.forEach((item: FILTERKEY) => {
			if (this.productFilters[item].needAPIcall) {
				this.harmonisationService
					.filterharmonisedList(item, this.commonFunctions.currencySelected.id)
					.pipe(takeUntil(this.destroy$))
					.subscribe((res: any) => {
						this.productFilters[item].list = res.data;
					});
			}
		});
	}

	private columnConfig() {
		this.cols = [
			{
				id: 1,
				name: `Harmonised ${this.titleCasePipe.transform(
					this.harmonisationType
				)} Name`,
				map: "attributes.name",
				sort: true,
				method: "harmonisationNavigation",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				meta: {
					navigateTarget: "_self",
					view: "harmonised",
					type: this.harmonisationType
				},
				isChecked: true
			},
			{
				id: 3,
				name: "Mapped To",
				map: "attributes.mapped_details",
				sort: true,
				method: "clickableUnitWithString",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				isChecked: true,
				meta: {
					name: `Raw ${this.pluralGridName}`
				}
			},
			{
				id: 4,
				name: COL.COUNTRY,
				map: "attributes.country",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				isChecked: true
			},
			{
				id: 5,
				name: COL.LAST_UPDATED,
				map: "attributes.updated_at",
				sort: true,
				method: "dateFormat",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				isChecked: true
			},
			{
				id: 6,
				name: "",
				map: "",
				sort: false,
				method: "",
				component: "harmonisation-action",
				class: "",
				hasRedirect: false,
				isChecked: true
			}
		];
		if (this.harmonisationType === "product") {
			this.cols.splice(1,0,			{
				id: 2,
				name: COL.PRODUCT_SEGMENT,
				map: "attributes.product_segment",
				sort: true,
				method: "defaultView",
				component: "common-mapping",
				class: "ellipsis",
				hasRedirect: true,
				isChecked: true
			}, )
		}
	}

	public initializeGrid() {
		this.moduleType = { ...this.moduleType };
		this.searchObj = { ...this.searchObj, text: "" };
		this.sort = [];
		this.page = 1;
		this.harmonisedListService.setType("harmonisation-by-type");
		this.getGridData(1);
	}

	public sortData(data: string[]): void {
		this.sort = data;
		this.getGridData(this.page);
	}

	public onSearch(text: string): void {
		this.searchObj = { ...this.searchObj, text: text };
		this.getGridData(1);
	}

	public onPageChange(eve: number): void {
		this.page = eve;
		this.getGridData(this.page);
	}

	public onPageSizeChange(eve: number): void {
		this.pageSize = eve;
		this.itemPerPage = eve;
		this.getGridData(1);
	}

	public getGridData(page = 1, eve?: RefreshGridEvent): void {
		if ((eve as RefreshGridAfterDelete)?.pageNo)
			page = (eve as RefreshGridAfterDelete)?.pageNo;
		this.clearLastCall?.unsubscribe();
		this.harmonisedList = new DocumentCollection();
		const param: GridParams = {
			harmonisation_type: "",
			currency_id: 0
		};
		param[`${PAYLOAD.HARMONISED_TYPE}`] = this.harmonisationType;
		param["search"] = this.searchObj.text?.trim()
		param["status"] = this.showOnlyUnmapped ? "Unmapped" : "";
		if (this.defaultMetricView === "currency") {
			param[PAYLOAD.CURRENCY_ID] = this.commonFunctions.currencySelected?.id;
		}
		this.exportParams = {
			...param,
			sort: this.sort
		};
		this.exportParams = this.commonFunctions.filterPayload(this.exportParams);
		this.exportedFileName = `Uniform Harmonised ${this.pluralGridName}`;
		Object.keys(param).forEach((item) => {
			if (
				(Array.isArray(param[item]) && !param[item]?.length) ||
				(!Array.isArray(param[item]) && !param[item])
			)
				delete param[item];
		});
		this.clearLastCall = this.harmonisedListService
			.all({
				page: { number: page || 1, size: this.pageSize || 10 },
				remotefilter: param,
				sort: this.sort,
				beforepath: "harmonisation"
			})
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(list) => {
					this.loadingData = true;
					if (list.source == "server" && list.is_loading == false) {
						if (list.data.length == 0) {
							this.NoData = true;
							this.loadingData = true;
						} else {
							this.loadingData = false;
							this.NoData = false;
							this.harmonisedList = list;
						}
					} else {
						this.loadingData = true;
						this.NoData = false;
					}
				},
				(error: any): void => {
					this.loadingData = true;
					this.NoData = true;
				}
			);
	}

	public openAddModal(modalContent) {
		this.resetFilter();
		this.getFilters();
		this.modalService.open(modalContent, {
			centered: true,
			windowClass: "harmonisedModal"
		});
	}

	private getPayload(selectedField, key?: string) {
		if (!key) return selectedField.trim() as string;
		else {
			const temp = key?.split(".");
			return temp?.reduce((res, item) => {
				return res?.[item];
			}, selectedField);
		}
	}

	public addHarmonisationData() {
		const param = {};
		param["name"] = this.productCollection.get("harmonisedName").value?.trim();
		param[`${PAYLOAD.CURRENCY_ID}`] = this.commonFunctions.currencySelected?.id;
		this.filters.forEach((item: string) => {
			if (this.productCollection.get(item)?.value) {
				this.productFilters[item].selected =
					this.productCollection.get(item).value;
				param[this.productFilters[item].payloadString as string] =
					this.getPayload(
						this.productFilters[item].selected,
						this.productFilters[item].meta?.key
					);
			}
		});
		this.harmonisationService
			.addHarmonisation(this.harmonisationType, param)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: {
					context_code: number;
					data: AddHarmResponse;
					summary: string;
				}) => {
					if (res) {
						this.resetFilter();
						this.dataTransferService.sendSignalOnHarmonisationCrud(true);
						this.initializeGrid();
						this.notificationMessageService.setMessage(
							res["context_code"],
							res["summary"]
						);
					}
				},
				(err) => {
					this.notificationMessageService.setMessage(
						err.error["context_code"],
						err.error["summary"]
					);
				}
			);
	}

	private resetFilter() {
		this.productCollection.get("harmonisedName").reset();
		this.productCollection.get(LOCALFILTER.BRAND).reset();
		this.productCollection.get(LOCALFILTER.PRODUCT_SEGMENT).reset();
		this.productCollection.get(LOCALFILTER.NEW_PRODUCT_SEGMENT).reset();
	}

	private deleteMapping(harmonisedId, mappings) {
		const payload = {
			harmonisation_type: this.harmonisationType,
			unmap_ids: mappings,
			harmonised_id: harmonisedId
		};
		this.harmonisationService
			.udpateMapping(payload)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: { context_code: number; data: null; summary: string }) => {
					this.getGridData(this.page);
					this.notificationMessageService.setMessage(
						res["context_code"],
						res["summary"]
					);
				},
				(err) => {
					this.notificationMessageService.setMessage(
						err.error["context_code"],
						err.error["summary"]
					);
				}
			);
	}

	public onRightPanelEmit(event: {
		closePanel: boolean;
		refreshGrid: boolean;
	}) {
		if (event.closePanel) {
			this.isMappingPanelOpen = !this.isMappingPanelOpen;
		}
		if (event.refreshGrid) {
			this.initializeGrid();
		}
	}

	public onChange(type?: string) {
		if (type === "new")
			this.productCollection.get(LOCALFILTER.PRODUCT_SEGMENT).reset();
		else if (type === "old")
			this.productCollection.get(LOCALFILTER.NEW_PRODUCT_SEGMENT).reset();
	}

	onKeyEnter() {
		this.productCollection.get(LOCALFILTER.PRODUCT_SEGMENT).reset();
	}

	public onMetricViewChange(selectedMetric: string) {
		this.defaultMetricView = selectedMetric;
		this.initializeGrid();
	}

	public ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
