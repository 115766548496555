<form
	[formGroup]="productForm"
	class="noteProductForm text-align-right-rtl"
	(ngSubmit)="onSubmit()"
	(keydown.enter)="$event.preventDefault()"
>
	<div class="product-container">
		<div class="select-container">
			<label translate>Channel <sup>*</sup></label>
			<ng-select
				class="select-text-wrap no-clear-all"
				[notFoundText]="'No Data Found' | translate"
				[loadingText]="'Loading...' | translate"
				[clearable]="false"
				placeholder="{{'Select Channel' | translate}}"
				[items]="marketplaceList"
				formControlName="select_marketplace"
				bindLabel="attributes.name"
				(scrollToEnd)="getMarketplaceList()"
				[ngClass]="{error:productForm.controls['select_marketplace'].invalid && (productForm.controls['select_marketplace'].dirty || productForm.controls['select_marketplace'].touched || submitted)}"
				(change)="onChange($event,'marketplace')"
				[loading]="loadedMarketplaceList"
			>
			</ng-select>
			<div
				class="errorLable"
				*ngIf="productForm.controls['select_marketplace'].invalid && (productForm.controls['select_marketplace'].dirty || productForm.controls['select_marketplace'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span
					*ngIf="productForm.controls['select_marketplace'].errors.required"
					translate
					>Please select Channel</span
				>
			</div>
		</div>
		<div class="select-container">
			<label translate>Product <sup>*</sup></label>
			<ng-select
				(close)="onProductDropdownClose()"
				class="select-text-wrap no-clear-all"
				[notFoundText]="'No Data Found' | translate"
				[loadingText]="'Loading...' | translate"
				[clearable]="false"
				placeholder="{{'Select Product' | translate}}"
				[items]="productList"
				formControlName="select_product"
				bindLabel="attributes.name"
				(scrollToEnd)="getProductList(productForm.controls['select_marketplace']?.value?.id)"
				[ngClass]="{error:productForm.controls['select_product'].invalid && (productForm.controls['select_product'].dirty || productForm.controls['select_product'].touched || submitted)}"
				(change)="onChange($event,'product')"
				[loading]="loadedProductList"
				(search)="searchData($event,'product')"
				tooltipClass="info-custom-tooltip"
				ngbTooltip="{{productForm.controls['select_product'].status === 'DISABLED' ? productForm.controls['select_product'].value : productForm.controls['select_product'].value?.attributes?.name}}"
			>
			</ng-select>
			<div
				class="errorLable"
				*ngIf="productForm.controls['select_product'].invalid && (productForm.controls['select_product'].dirty || productForm.controls['select_product'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span
					*ngIf="productForm.controls['select_product'].errors.required"
					translate
					>Please select product</span
				>
			</div>
		</div>
		<div class="select-container">
			<label translate>Target KPI <sup>*</sup></label>
			<ng-select
				class="select-text-wrap no-clear-all"
				[notFoundText]="'No Data Found' | translate"
				[loadingText]="'Loading...' | translate"
				[clearable]="false"
				placeholder="{{'Select Target KPI' | translate}}"
				[items]="metricList"
				formControlName="select_target_kpi"
				bindLabel="name"
				[ngClass]="{error:productForm.controls['select_target_kpi'].invalid && (productForm.controls['select_target_kpi'].dirty || productForm.controls['select_target_kpi'].touched || submitted)}"
				(change)="onChange($event,'metric')"
				[loading]="loadedkpiList"
			>
				<ng-template ng-label-tmp let-item="item">
					<span translate>{{item.name}}</span>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" let-index="index">
					<span>{{item.name | translate}}</span>
				</ng-template>
			</ng-select>
			<div
				class="errorLable"
				*ngIf="productForm.controls['select_target_kpi'].invalid && (productForm.controls['select_target_kpi'].dirty || productForm.controls['select_target_kpi'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span
					*ngIf="productForm.controls['select_target_kpi'].errors.required"
					translate
					>Please select KPI</span
				>
			</div>
		</div>
		<div>
			<div class="input-container">
				<label for="disabled" translate>Current KPI Value <sup>*</sup></label>
				<input
					type="text"
					name=""
					id="disabled"
					formControlName="current_kpi"
					placeholder="{{'Current KPI Value' | translate}}"
				/>
			</div>
			<div class="input-container range-box">
				<label for="note_product_kpi_value" translate
					>Target KPI Value <sup>*</sup></label
				>
				<div class="d-flex">
					<!-- operator1 -->
					<ng-select
						formControlName="target_kpi_operator_1"
						class="select-text-wrap border-right-none"
						[notFoundText]="'No Data Found' | translate"
						[loadingText]="'Loading...' | translate"
						[items]="targetKPIOperator1Data.list"
						placeholder="{{'Range' | translate}}"
						bindLabel="name"
						[loading]="targetKPIOperator1Data.isLoading"
						[closeOnSelect]="true"
						(change)="onFirstOperatorChange($event)"
						[clearable]="false"
					>
					</ng-select>
					<!-- operator1 ends-->
					<input
						type="number"
						id="note_product_kpi_value"
						placeholder="{{'Enter Value' | translate}}"
						formControlName="note_product_kpi_value"
						autocomplete="off"
						(input)="onKpiChange($event)"
						min="0"
						class="border-both-radius-none"
						[ngClass]="{error:productForm.controls['note_product_kpi_value'].invalid && (productForm.controls['note_product_kpi_value'].dirty || productForm.controls['note_product_kpi_value'].touched || submitted)}"
					/>
					<!-- and -->
					<!-- operator2 -->
					<ng-select
						formControlName="target_kpi_operator_2"
						class="select-text-wrap border-both-none"
						[notFoundText]="'No Data Found' | translate"
						[loadingText]="'Loading...' | translate"
						[items]="targetKPIOperator2Data.list"
						placeholder="{{'Range' | translate}}"
						bindLabel="name"
						[loading]="targetKPIOperator2Data.isLoading"
						[closeOnSelect]="true"
						[clearable]="true"
						(change)="onSecondOperatorChange($event)"
					>
					</ng-select>
					<!-- kpi2 -->
					<input
						type="number"
						id="compProductName2"
						placeholder="{{'Enter Value' | translate}}"
						min="0"
						autocomplete="off"
						formControlName="note_product_kpi_value_2"
						(input)="onNoteProductKpiValueChange2($event)"
						class="border-left-radius-none"
						[ngClass]="{error:((productForm.controls['note_product_kpi_value_2'].dirty || productForm.controls['note_product_kpi_value_2'].touched || submitted) && ((!productForm.controls['note_product_kpi_value'].invalid && productForm.controls['target_kpi_operator_2'].value && productForm.controls['note_product_kpi_value_2'].value===null) || rangeError))}"
					/>
				</div>
				<!-- <ng-template #boolKPI>
                    <div class="d-flex">
                        <input class="w-100" type="text" id="note_product_kpi_value" disabled
                            [value]="productForm.get('current_kpi').value==='No'?'Yes':'No'">
                    </div>
                </ng-template> -->
				<div
					class="errorLable"
					*ngIf="productForm.controls['note_product_kpi_value'].invalid && (productForm.controls['note_product_kpi_value'].dirty || productForm.controls['note_product_kpi_value'].touched || submitted)"
				>
					<i class="icon icon-Error"></i>
					<span
						*ngIf="productForm.controls['note_product_kpi_value'].errors.required"
						translate
						>Please enter KPI value</span
					>
					<span
						*ngIf="!productForm.controls['note_product_kpi_value'].errors.required && productForm.controls['note_product_kpi_value'].errors.invalid"
						translate
						>Please enter valid number</span
					>
				</div>
				<div
					class="errorLable"
					*ngIf="productForm.controls['note_product_kpi_value_2'].invalid && (productForm.controls['note_product_kpi_value_2'].dirty || submitted)"
				>
					<i class="icon icon-Error"></i>
					<span
						*ngIf="productForm.controls['note_product_kpi_value_2'].errors.invalid"
						translate
						>Please enter valid number</span
					>
				</div>
				<div
					class="errorLable"
					*ngIf="!productForm.controls['note_product_kpi_value']?.errors?.required && (productForm.controls['note_product_kpi_value_2'].dirty && rangeError)"
				>
					<i class="icon icon-Error"></i>
					<span translate
						>Please enter number greater than target kpi value</span
					>
				</div>
				<div
					class="errorLable"
					*ngIf="(productForm.controls['note_product_kpi_value_2'].dirty || productForm.controls['note_product_kpi_value_2'].touched || submitted) && !productForm.controls['note_product_kpi_value'].invalid && productForm.controls['target_kpi_operator_2'].value && productForm.controls['note_product_kpi_value_2'].value===null"
				>
					<i class="icon icon-Error"></i>
					<span translate>Please enter second target</span>
				</div>
			</div>
			<div class="toggle_title" translate>Frequency <sup>*</sup></div>
			<div class="radio-container">
				<label class="radio" for="repeat">
					<input
						type="radio"
						id="repeat"
						value="Repeat"
						formControlName="frequency"
					/>
					<div class="circle"></div>
					<span translate>Repeat</span>
				</label>
				<label class="radio" for="once">
					<input
						type="radio"
						id="once"
						value="Once"
						formControlName="frequency"
					/>
					<div class="circle"></div>
					<span translate>Once</span>
				</label>
			</div>
		</div>
		<div class="product-footer">
			<button
				class="btn btn-secondary btn-lg"
				type="button"
				(click)="onCloseAction()"
				translate
			>
				Cancel
			</button>
			<button
				class="btn btn-primary btn-lg"
				type="submit"
				[disabled]="!permissions.write_access || updateButtonState()"
				translate
			>
				{{actionType=='edit'?'Update Notification':'Create Notification'}}
			</button>
		</div>
	</div>
</form>
