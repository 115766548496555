import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from "@angular/core";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";

export interface MetaData {
	current_page: number;
	next_page: number;
	prev_page: number;
	total_count: number;
	total_pages: number;
}
//type Meta = MetaData | {};

@Component({
	selector: "app-common-pagination",
	templateUrl: "./common-pagination.component.html",
	styleUrls: ["./common-pagination.component.scss"]
})
export class CommonPaginationComponent implements OnInit, OnChanges {
	@Input() public meta: MetaData;
	@Input() public maxSize: number = 3;
	@Input() public hidePageSize = false;
	@Input() public gridGoToPage: boolean = true;
	@Output() paginationSize = new EventEmitter();
	@Output() paginationNo = new EventEmitter();
	@Input() public itemPerPage: number;
	public selectedPage_size: number = 10;
	public currentPage = 1;
	public pageSizePerRow: { id: number; value: number }[] = [
		// { id: 1, value: 5 },
		{ id: 2, value: 10 },
		{ id: 3, value: 20 },
		{ id: 4, value: 50 }
	];
	public defaultPageSize = 10;
	public goToPage: number;
	constructor(private notificationService: NotificationService) {}
	ngOnInit(): void {}
	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (changes.meta && changes.meta.currentValue != undefined) {
				this.currentPage =
					(changes.meta.currentValue as MetaData).current_page || 1;
			}
		}
	}

	changePageSize(e: { id: number; value: number }) {
		this.selectedPage_size = e.value;
		this.paginationSize.emit(e.value);
	}
	goToPageFunc() {
		if (this.goToPage > this.meta?.total_pages) {
			this.notificationService.setMessage(
				1100,
				`The grid has only ${ this.meta?.total_pages}  pages`
			);
		} else {
			this.pageChange(this.goToPage);
		}
		this.goToPage = null;
	}
	pageChange(page: number) {
		this.paginationNo.emit(page);
	}
}
