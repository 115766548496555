import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscoverService } from '@ds-private-layouts/discover/discover.service';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { RolePermissions } from '@ds-models/access-matrix.model';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { AccessMatrix } from '@ds-enums/access-matrix.enum';
import { ProductDescriptionComponent } from '@ds-common-components/product-description/product-description.component';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';

@Component({
  selector: 'app-info-product',
  templateUrl: './info-product.component.html',
  styleUrls: ['./info-product.component.scss']
})
export class InfoProductComponent implements OnInit {

  @Output() public variantId = new EventEmitter();
  @Input() public headerType:string;
  @Input() public response;
  @Input() public scorecardMatchingsCountRes;
  @Input() public currencyDetails;
  public variantSelected: string = "";
  public variantsList = [];
  public moduleType: string;
  public permissionsForNotification: RolePermissions = new RolePermissions();
  public permissionsForDiscover: RolePermissions = new RolePermissions();
  public permissionsForBuild: RolePermissions = new RolePermissions();
  public noteObj = {};
  public profileObj = {};
  private $destroy: Subject<boolean> = new Subject();
  constructor(private discoverService: DiscoverService, public router: Router, private modalService: NgbModal, private accessMatrixService: AccessMatrixService, public route: ActivatedRoute, public commonFunctions: CommonFunctionsService, private dataTransferService: DataTransferService) {
    this.permissionsForNotification = this.accessMatrixService.getPermissionByModule(AccessMatrix.Notifications);
    this.permissionsForDiscover = this.accessMatrixService.getPermissionByModule(AccessMatrix.Discover);
    this.permissionsForBuild = this.accessMatrixService.getPermissionByModule(AccessMatrix.Scorecard)
   }

  ngOnInit(): void {
    this.moduleType = this.commonFunctions.getURLSegments()[0];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.response) {
      this.getActionObject();
      this.dataTransferService.sendScorecardBreadcrumb(this.response?.name);
    }
    if(this.response?.description?.trim() && !this.commonFunctions.extractTextFromHtml(this.response?.description)?.trim()){
      this.response['isExplicitDescription'] = true
    }else{
      this.response.description =this.response?.description?.trim()? this.commonFunctions.extractTextFromHtml(this.response?.description) : null
    }
  }
 
  getStarStatus(starValue:string):string{
    if(parseFloat(starValue)<3)return 'danger';
    else if(parseFloat(starValue)>=3 && parseFloat(starValue)<4)return 'warning';
    else return 'success';
  }

  openModal(content) {
    this.modalService.open(content, { centered: true, windowClass: "customModalProduct" });
  }
  
  getVariantsList(productId: number) {
    this.variantsList = [];
    this.discoverService.getVariantsList(productId)
      .pipe(takeUntil(this.$destroy))
      .subscribe((res:any) => {
      this.variantsList = res?.data;
    });
  }
  getVarientsDetails(variantId:number){
   const newURl = `/scorecard/product/${variantId}?view=profile`;
    window.open(newURl, '_blank');
  }

  openDirectMapping(productId: number) {
    this.router.navigate(['manage-mappings'], {
      relativeTo: this.route,
      queryParams: {
        customerProductId: productId
      },
      queryParamsHandling: "merge",
    });
  }

  descriptionReadMore(){
      const modalRef = this.modalService.open(ProductDescriptionComponent,{
        centered: true,
        windowClass:'productDescCustomClass'
      });
      modalRef.componentInstance.descriptionData = this.response;
  }
  getActionObject() {
    this.noteObj = {
      ...{},
      marketplace: this.response?.marketplace,
      name: this.response?.name,
      id: this.response?.product_id
    };
    this.profileObj = {
      ...{},
      url: this.response?.url,
      name: this.response?.name
    }
  }

  ngOnDestroy() {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}