<div class="ds-box customer-grid">
	<div class="d-flex justify-content-between align-items-center mb-3">
		<div class="d-flex align-items-center">
			<span class="ds-box-heading infoHeader me-2 text-capitalize" translate>
				Your {{gridType}}s
			</span>
			<span class="grid-count" *ngIf="availableProducts?.meta?.total_count"
				>{{(availableProducts?.meta?.total_count | numberSuffix : 2)}}</span
			>
		</div>
		<div class="d-flex gap-8">
			<app-search-common
				[searchObj]="searchObj"
				(onSearch)="onSearch($event)"
			></app-search-common>
			<div class="store-filter-container" *ngIf="gridType==='product'">
				<ng-select
					[items]="customerStoreFilter.list"
					[notFoundText]="'No Data Found' | translate"
					[loadingText]="'Loading...' | translate"
					[multiple]="true"
					[closeOnSelect]="customerStoreFilter.closeOnSelect"
					[clearable]="customerStoreFilter.clear"
					bindLabel="name"
					placeholder="{{('Select ' + customerStoreFilter.placeholder) | translate}}"
					(change)="onChange($event)"
					(search)="onStoreSearch($event)"
					(close)="closeMultiDropdown()"
					[loading]="customerStoreFilter.loading"
				>
					<ng-template ng-multi-label-tmp
						><span class="placeholder-temp"
							>{{customerStoreFilter.searchTerm.length ? '' :
							((customerStoreFilter.placeholder | translate) + " (" +
							customerStoreFilter.selected.length + ")")}}</span
						></ng-template
					>
					<ng-template ng-option-tmp let-item="item" let-index="index">
						<div class="multi-select-option-container multi-text-wrap gap-16">
							<span class="item-name">{{item.name}}</span>
							<i
								class="icon icon-Check"
								*ngIf="customerStoreFilter.selected.includes(item)"
							></i>
						</div>
					</ng-template>
				</ng-select>
			</div>
		</div>
	</div>
	<app-ngx-grid-revamp
		[ngxCols]="cols"
		[ngxItems]="availableProducts"
		[moduleType]="moduleType"
		[column]="cols"
		[collection]="availableProducts"
		[loadingData]="loadingData"
		[noData]="NoData"
		(sortData)="sortData($event)"
		[itemPerPage]="itemPerPage"
		[isPaginationWithoutRoute]="true"
		(paginationNo)="onPageChange($event)"
		(paginationSize)="onPageSizeChange($event)"
		[maxSize]="1"
		[showThreeOptionsForPageSize]="true"
		[goToPlaceholder]="'Pg.'"
		[pgType]="'sm'"
	></app-ngx-grid-revamp>
</div>
