<div ngbDropdown class="d-inline-block" display="dynamic">
	<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
		><span
			><i
				class="icon icon-Options"
				placement="bottom"
				ngbTooltip="{{'Actions' | translate}}"
				tooltipClass="info-custom-tooltip"
			></i
		></span>
	</span>
	<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
		<button
			[disabled]="!permissions.write_access"
			class="dropdown-item"
			(click)="onMappedDelete()"
		>
			<i class="icon icon-Trash"></i>
			<span translate>Delete</span>
		</button>
	</div>
</div>
