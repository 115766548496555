import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LabelType, Options } from "@angular-slider/ngx-slider";
import { TIMELINE } from "@ds-shared/enums/common.enum";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";

@Component({
	selector: "app-time-range",
	templateUrl: "./time-range.component.html",
	styleUrls: ["./time-range.component.scss"]
})
export class TimeRangeComponent implements OnInit {
	private _timeline;
	get timeline() {
		return this._timeline;
	}
	@Input() set timeline(value) {
		if (value) {
			this._timeline = value.timeline;
			this.setSliderRange();
		}
	}
	@Output() timeRangeTrigger: EventEmitter<{
		value: number;
		highValue?: number;
		pointerType: number;
	}> = new EventEmitter();
	value: number = 1;
	highValue: number = 7;
	options: Options = {
		floor: 1,
		ceil: 7,
		keyboardSupport: false
	};
	monthArr = {
		0: "Jan",
		1: "Feb",
		2: "Mar",
		3: "Apr",
		4: "May",
		5: "Jun",
		6: "Jul",
		7: "Aug",
		8: "Sep",
		9: "Oct",
		10: "Nov",
		11: "Dec"
	};
	constructor(private commonFunctions: CommonFunctionsService) {}

	ngOnInit(): void {}

	private setSliderRange() {
		switch (this.timeline) {
			case TIMELINE.TODAY: {
				this.value = 0;
				this.highValue = 23;
				this.options = {
					floor: 0,
					ceil: 23,
					keyboardSupport: false,
					translate: (value: number, label: LabelType): string => {
						switch (label) {
							case LabelType.Floor:
								return `${this.options.floor} Hr`;
							case LabelType.Ceil:
								return `${this.options.ceil} Hrs`;
							case LabelType.Low:
								return `${value} ${value > 1 ? "Hrs" : "Hr"}`;
							case LabelType.High:
								return `${this.highValue} ${this.highValue > 1 ? "Hrs" : "Hr"}`;
							default:
								return `${value}`;
						}
					}
				};
				break;
			}
			case TIMELINE.YESTERDAY: {
				this.value = 0;
				this.highValue = 23;
				this.options = {
					floor: 0,
					ceil: 23,
					keyboardSupport: false,
					translate: (value: number, label: LabelType): string => {
						switch (label) {
							case LabelType.Floor:
								return `${this.options.floor} Hr`;
							case LabelType.Ceil:
								return `${this.options.ceil} Hrs`;
							case LabelType.Low:
								return `${value} ${value > 1 ? "Hrs" : "Hr"}`;
							case LabelType.High:
								return `${this.highValue} ${this.highValue > 1 ? "Hrs" : "Hr"}`;
							default:
								return `${value}`;
						}
					}
				};
				break;
			}
			case TIMELINE.CURRENT_WEEK: {
				this.value = 1;
				this.highValue = 7;
				this.options = {
					floor: 1,
					ceil: 7,
					keyboardSupport: false,
					translate: (value: number, label: LabelType): string => {
						switch (label) {
							case LabelType.Floor:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.options.floor
								);
							case LabelType.Ceil:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.options.ceil
								);
							case LabelType.Low:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.value
								);
							case LabelType.High:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.highValue
								);
							default:
								return `${value}`;
						}
					}
				};
				break;
			}
			case TIMELINE.PREVIOUS_MONTH: {
				this.highValue = 30;
				this.options = {
					floor: 1,
					ceil: 30,
					keyboardSupport: false,
					translate: (value: number, label: LabelType): string => {
						switch (label) {
							case LabelType.Floor:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.options.floor
								);
							case LabelType.Ceil:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.options.ceil
								);
							case LabelType.Low:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.value
								);
							case LabelType.High:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.highValue
								);
							default:
								return `${value}`;
						}
					}
				};
				break;
			}
			case TIMELINE.CURRENT_MONTH: {
				this.value = 1;
				this.highValue = 30;
				this.options = {
					floor: 1,
					ceil: 30,
					keyboardSupport: false,
					translate: (value: number, label: LabelType): string => {
						switch (label) {
							case LabelType.Floor:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.options.floor
								);
							case LabelType.Ceil:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.options.ceil
								);
							case LabelType.Low:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.value
								);
							case LabelType.High:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.highValue
								);
							default:
								return `${value}`;
						}
					}
				};
				break;
			}
			default: {
				this.value = 1;
				this.highValue = 7;
				this.options = {
					floor: 1,
					ceil: 7,
					keyboardSupport: false,
					translate: (value: number, label: LabelType): string => {
						switch (label) {
							case LabelType.Low:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.value
								);
							case LabelType.High:
								return (
									this.commonFunctions.getSpecificTranslation("Day") +
									this.highValue
								);
							default:
								return `${value}`;
						}
					}
				};
				break;
			}
		}
	}

	public onRangeChange(eventDetails: {
		value: number;
		highValue?: number;
		pointerType: number;
	}) {
		this.timeRangeTrigger.emit(eventDetails);
	}
}
