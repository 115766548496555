<div
	class="disclaimer-message mb-2 d-flex align-items-center justify-content-between"
	*ngIf="messageType === 'warning' && showMessage['warning'] && message?.length"
>
	<div class="d-flex align-items-center">
		<i class="icon icon-Info-circle"></i>
		<span translate>{{message | translate}}</span>
	</div>
	<i class="icon icon-Close2 cursor-pointer" (click)="onClose('warning')"></i>
</div>

<div
	class="disclaimer-message light-disclaimer-message mb-2 d-flex align-items-center justify-content-between"
	*ngIf="messageType === 'light-warning' && showMessage['light-warning']"
>
	<div class="d-flex align-items-center">
		<i class="icon icon-Info-circle"></i>
		<span translate>{{message | translate}}</span>
	</div>
	<i
		class="icon icon-Close2 cursor-pointer"
		(click)="onClose('light-warning')"
	></i>
</div>

<div
	class="component-info ellipsis"
	*ngIf="messageType === 'note'"
	[ngStyle]="messageStyle"
	showTooltipOnTextOverflow
	[data]="message | translate"
></div>

<div
	class="tiny-disc d-flex align-items-center ellipsis justify-content-between"
	*ngIf="messageType === 'sm-disclaimer'"
	[ngStyle]="messageStyle"
	translate
>
	<i class="icon icon-Info-circle"></i>
	<span translate>{{message}}</span>
</div>

<span class="infoHeader" *ngIf="messageType === 'i-icon-msg'">
	<i
		class="icon icon-Info-circle"
		display="dynamic"
		ngbTooltip="{{message | translate}}"
		tooltipClass="info-custom-tooltip"
	></i>
</span>

<div
	class="info-message mt-2 d-flex align-items-center justify-content-between"
	*ngIf="messageType === 'info' && showMessage['info'] && message?.length"
>
	<div class="d-flex align-items-center">
		<i class="icon icon-Info-circle"></i>
		<span>{{message | translate}}</span>
	</div>
	<i class="icon icon-Close2 cursor-pointer" (click)="onClose('info')"></i>
</div>
