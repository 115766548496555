<div
	class="grid-box-container"
	[ngClass]="items?.length===1?'singleItem':'twoFractions'"
>
	<div
		*ngFor="let prod of items"
		class="grid-box-container-item text-align-right-rtl"
	>
		<div class="item">
			<span class="label-name" translate>
				{{(moduleType.type | titlecase)}} Name
			</span>
			<!-- <app-marketplace-store-logo [storeName]="prod.attributes.item_name" [className]="'segmentation'"></app-marketplace-store-logo> -->
			<span
				showMultiTooltipOnTextOverflow
				[data]="prod?.attributes?.item_name"
				class="name-content"
				(click)="moduleType.type == 'store' && commonFunctions?.redirectToScorecardInfo(prod?.id,'store')"
				[ngClass]="moduleType.type =='store' && 'cursor-pointer on-hover-action'"
				>{{prod?.attributes?.item_name}}</span
			>
		</div>
		<div class="item">
			<span class="label-name" translate> Assortments </span>
			<span class="label-value font-medium">
				{{(prod.attributes?.assortments | numberSuffix: 2 | noValueChecker)}}
			</span>
		</div>
		<div class="item">
			<span class="label-name" translate> Avg. Price </span>
			<span class="price-value">
				<ng-container *ngIf="prod.attributes?.avg_price;else showHyphen">
					{{prod.attributes.currency?.symbol}}{{(prod.attributes.avg_price |
					numberSuffix: 2)}}
				</ng-container>
			</span>
		</div>
		<div class="item">
			<span class="label-name" translate> Avg. Discount </span>
			<!-- <span *ngIf="prod.attributes?.discount;else showHyphen" class="price-value-off positive-delta">
                {{prod.attributes.discount}}% {{'OFF' | translate}}
            </span> -->
			<span
				class="voucher-badge me-0"
				*ngIf="prod.attributes?.discount;else showHyphen"
				>{{prod.attributes.discount}}% {{'OFF' | translate}}</span
			>
		</div>
		<div class="item">
			<span class="label-name" translate> Rating </span>
			<ng-template #starLabel>
				<span class="star">&#x02605;</span>
			</ng-template>
			<div
				*ngIf="prod.attributes?.rating;else showHyphen"
				class="ratingLabel"
				[ngClass]="commonFunctions.getStarStatus(prod?.attributes?.rating)"
			>
				{{prod?.attributes?.rating}}<ngb-rating
					[max]="1"
					[starTemplate]="starLabel"
					[rate]="1"
				></ngb-rating>
			</div>
		</div>
	</div>
</div>

<ng-template #showHyphen>-</ng-template>
