import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	OnDestroy
} from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { NotificationsService } from "@ds-private-layouts/notifications/notifications.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import {
	TargteKPI1Operators,
	TargteKPI2Operators
} from "@ds-static-data/notification.data";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";

@Component({
	selector: "app-notification-brand-details",
	templateUrl: "./notification-brand-details.component.html",
	styleUrls: ["./notification-brand-details.component.scss"]
})
export class NotificationBrandDetailsComponent implements OnInit {
	@Input() notificationData: any;
	public rangeError = false;
	public marketplaceListSubscription: Subscription;
	public marketplaceList: any[] = [];
	public marketplacePageNumber: number = 1;
	public brandListSubscription: Subscription;
	public brandList: any[] = [];
	public initialBrandList = [];
	public initialBrandPageNumber = 1;
	public brandPageNumber: number = 1;
	public metricListSubscription: Subscription;
	public metricList: any[] = [];
	public actionType: string = "";
	public brandForm: UntypedFormGroup;
	public submitted: boolean = false;
	public currentKPISubscription: Subscription;
	public currentKPIValue: any;
	public updateSubscription: Subscription;
	public addSubscription: Subscription;
	public notificationInfo: any;
	public loadedMarketplaceList: boolean = false;
	public loadedBrandList: boolean = false;
	public loadedkpiList: boolean = false;
	public searchBrand: any = "";
	public tagsSubject$ = new Subject<{}>();
	public $destroy: Subject<boolean> = new Subject();
	public permissions: RolePermissions = new RolePermissions();
	public targetKPIOperatorList1 = TargteKPI1Operators;
	public targetKPIOperatorList2 = TargteKPI2Operators;
	public targetKPIOperator1Data = {
		list: this.targetKPIOperatorList1,
		isLoading: false,
		clearLastCallSubscription: null
	};
	public targetKPIOperator2Data = {
		list: this.targetKPIOperatorList2,
		isLoading: false,
		clearLastCallSubscription: null
	};
	private campaignStatusCount = 0;
	@Output() onSuccessAction: EventEmitter<any> = new EventEmitter();
	@Output() onCancelAction: EventEmitter<any> = new EventEmitter();
	constructor(
		private notificationService: NotificationsService,
		private notificationMessageService: NotificationService,
		private route: ActivatedRoute,
		public commonFunctions: CommonFunctionsService,
		private accessMatricService: AccessMatrixService,
		private globalService: GlobalService
	) {
		this.permissions = this.accessMatricService.getPermissionByModule(
			AccessMatrix.Notifications
		);
		this.searchInit();
		this.campaignStatusCheck();
	}

	private campaignStatusCheck() {
		this.globalService
			.getCampaignStatus()
			.pipe(takeUntil(this.$destroy))
			.subscribe((res) => {
				this.campaignStatusCount = res["data"]["profiles"];
			});
	}

	searchInit() {
		this.tagsSubject$
			.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.$destroy))
			.subscribe(
				(res: any) => {
					const { event, searchType } = res;
					this.onSearchFire(event, searchType);
				},
				(err) => {}
			);
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params: any) => {
			this.notificationInfo = params?.notificationInfo
				? JSON.parse(
						this.commonFunctions.getDecodedData(params?.notificationInfo)
				  )
				: null;
		});
		this.getMarketplaceList();
		this.actionType = String(
			this.route["_routerState"]["snapshot"].url
		)?.includes("add-notification")
			? "add"
			: this.commonFunctions.getURLSegments()[2];
		this.brandForm = new UntypedFormGroup({
			select_type: new UntypedFormControl(
				this.getDefaultValue("competitor") != null
					? this.getDefaultValue("competitor")
					: false
			),
			select_marketplace: new UntypedFormControl(
				this.getDefaultValue("marketplace"),
				Validators.required
			),
			select_brand: new UntypedFormControl(
				this.getDefaultValue("name"),
				Validators.required
			),
			select_target_kpi: new UntypedFormControl(
				this.getDefaultValue("target_kpi"),
				Validators.required
			),
			current_kpi: new UntypedFormControl(
				this.getDefaultValue("current_kpi_value"),
				Validators.required
			),
			note_brand_kpi_value: new UntypedFormControl(
				this.getDefaultValue("target_kpi_value"),
				[Validators.required]
			),
			frequency: new UntypedFormControl(
				this.getDefaultValue("frequency") == null
					? "Repeat"
					: this.getDefaultValue("frequency"),
				Validators.required
			),
			target_kpi_operator_1: new UntypedFormControl(
				this.getDefaultValue("operator")
			),
			note_brand_kpi_value_2: new UntypedFormControl(
				this.getDefaultValue("target_kpi_value_2")
			),
			target_kpi_operator_2: new UntypedFormControl(
				this.getDefaultValue("operator_2")
			)
		});
		this.brandForm.controls["current_kpi"].disable();
		this.brandForm.controls["select_brand"].disable();
		this.brandForm.controls["select_target_kpi"].disable();
		this.disableRangeOperators();
		if (this.actionType == "edit") {
			this.brandForm.controls["select_type"].disable();
			this.brandForm.controls["select_marketplace"].disable();
			this.brandForm.controls["target_kpi_operator_1"].enable();
			this.brandForm.controls["note_brand_kpi_value"].enable();
			this.onFirstOperatorChange(
				this.brandForm.controls["target_kpi_operator_1"].value
			);
			if (this.brandForm.controls["target_kpi_operator_2"].value) {
				this.brandForm.controls["note_brand_kpi_value_2"].enable();
			}
		} else if (this.notificationInfo) {
			this.brandForm.controls["select_marketplace"].disable();
			this.brandForm.controls["select_brand"].disable();
			this.brandForm.controls["select_target_kpi"].enable();
			this.getMetricList();
		}
	}

	disableRangeOperators() {
		this.brandForm.controls["note_brand_kpi_value"].disable();
		this.brandForm.controls["target_kpi_operator_1"].disable();
		this.brandForm.controls["target_kpi_operator_2"].disable();
		this.brandForm.controls["note_brand_kpi_value_2"].disable();
	}
	resetRangeOperators() {
		this.brandForm.controls["target_kpi_operator_1"].reset();
		this.brandForm.controls["note_brand_kpi_value"].reset();
		this.brandForm.controls["target_kpi_operator_2"].reset();
		this.brandForm.controls["note_brand_kpi_value_2"].reset();
	}
	getDefaultValue(forLabel: string): any {
		if (this.actionType == "edit") {
			if (forLabel == "current_kpi_value") {
				return this.notificationData?.["attributes"]?.[forLabel] != null
					? this.notificationData?.["attributes"]?.[forLabel]
					: "-";
			} else if (forLabel == "target_kpi") {
				return { name: this.notificationData?.["attributes"]?.[forLabel] };
			} else if (forLabel == "operator" || forLabel == "operator_2") {
				const operatorMap = {
					operator: this.targetKPIOperator1Data,
					operator_2: this.targetKPIOperator1Data
				};
				return operatorMap[forLabel]["list"].find(
					(ele) =>
						ele.value === this.notificationData?.["attributes"]?.[forLabel]
				);
			}
			return this.notificationData?.["attributes"]?.[forLabel];
		} else if (this.notificationInfo) {
			if (forLabel == "marketplace") return this.notificationInfo?.marketplace;
			else if (forLabel == "name") return this.notificationInfo?.name;
			else return null;
		}
		return null;
	}
	getMarketplaceList() {
		if (this.marketplacePageNumber) {
			this.loadedMarketplaceList = true;
			this.marketplaceListSubscription?.unsubscribe();
			this.marketplaceListSubscription = this.notificationService
				.getMarketplaceList(this.marketplacePageNumber)
				.subscribe(
					(res: any) => {
						if (res) {
							this.marketplaceList = [...this.marketplaceList, ...res?.data];
							this.marketplacePageNumber = res?.meta?.next_page;
							this.loadedMarketplaceList = false;
						}
					},
					(err: any) => {
						this.marketplaceList = [];
						this.marketplacePageNumber = 1;
						this.loadedMarketplaceList = false;
					}
				);
		}
	}
	getBrandList(marketplaceId: any) {
		if (this.brandPageNumber) {
			this.loadedBrandList = true;
			this.brandListSubscription?.unsubscribe();
			this.brandListSubscription = this.notificationService
				.getBrandsList(marketplaceId, this.brandPageNumber, this.searchBrand)
				.subscribe(
					(res: any) => {
						if (res) {
							this.brandList = [...this.brandList, ...res?.data];
							this.brandPageNumber = res?.meta?.next_page;
							this.loadedBrandList = false;
							if (
								res?.meta?.current_page === 1 &&
								!this.initialBrandList.length
							) {
								this.initialBrandList = JSON.parse(
									JSON.stringify(this.brandList)
								);
								this.initialBrandPageNumber = this.brandPageNumber;
							}
						}
					},
					(err: any) => {
						this.resetInitials();
						this.loadedBrandList = false;
					}
				);
		}
	}

	resetInitials() {
		this.brandList = [];
		this.initialBrandList = [];
		this.brandPageNumber = 1;
		this.initialBrandPageNumber = 1;
		this.searchBrand = "";
	}
	getMetricList() {
		this.loadedkpiList = true;
		this.metricListSubscription?.unsubscribe();
		this.metricListSubscription = this.notificationService
			.getMetricList("brand", this.brandForm.controls["select_type"]?.value)
			.subscribe(
				(res: any) => {
					if (res) {
						if (!this.campaignStatusCount) {
							res["data"] = res["data"].filter(
								(item) =>
									![
										...this.commonFunctions
											.notificationCampaignRestrictedKPIList
									].includes(item.name)
							);
						}
						this.metricList = [...this.metricList, ...res?.data];
						this.loadedkpiList = false;
					}
				},
				(err: any) => {
					this.metricList = [];
					this.loadedkpiList = false;
				}
			);
	}
	getCurrentKPIValue(brandId: any) {
		this.currentKPISubscription?.unsubscribe();
		this.currentKPISubscription = this.notificationService
			.getCurrentKPIsValue(
				"brand",
				brandId,
				this.brandForm.controls["select_type"]?.value,
				this.brandForm.controls["select_target_kpi"]?.value?.id
			)
			.subscribe(
				(res: any) => {
					if (res) {
						this.currentKPIValue = res?.data?.value;
						this.brandForm
							.get("current_kpi")
							.setValue(
								this.currentKPIValue !== null ? this.currentKPIValue : "-"
							);
					}
				},
				(err: any) => {
					this.currentKPIValue = null;
					this.brandForm.get("current_kpi").setValue("-");
				}
			);
	}

	onResponseAction(response: any, actionType) {
		const moduleName = this.commonFunctions.getModuleName();
		let msg = "";
		if (actionType === "edit") {
			msg =
				"Notifications have been successfully updated. You can view the updated notifications in the Notifications module";
		} else {
			msg =
				"Notifications have been successfully created. You can view the created notifications in the Notifications module";
		}
		if (response) {
			this.onSuccessAction.emit();
			this.notificationMessageService.setMessage(
				response["context_code"],
				moduleName === "notification" ? response["summary"] : msg
			);
		}
	}
	onSubmit() {
		this.submitted = true;
		if (this.brandForm.status == "VALID" && !this.rangeError) {
			const data = {
				notification_metric:
					this.brandForm.controls["select_target_kpi"]?.value?.id,
				target_value: this.brandForm.controls["note_brand_kpi_value"]?.value,
				repeat:
					this.brandForm.controls["frequency"]?.value == "Repeat"
						? true
						: false,
				competitor: this.brandForm.controls["select_type"]?.value,
				brand: this.notificationInfo
					? this.notificationInfo?.id
					: this.brandForm.controls["select_brand"]?.value?.id,
				marketplace: this.brandForm.controls["select_marketplace"]?.value?.id,
				operator:
					this.brandForm.controls["target_kpi_operator_1"]?.value?.value,
				target_value_2:
					this.brandForm.controls["note_brand_kpi_value_2"]?.value,
				operator_2:
					this.brandForm.controls["target_kpi_operator_2"]?.value?.value
			};
			if (this.actionType == "edit") {
				this.updateSubscription?.unsubscribe();
				this.updateSubscription = this.notificationService
					.updateNotification("brand-config", data, this.notificationData?.id)
					.subscribe(
						(res: any) => {
							if (res) this.onResponseAction(res, this.actionType);
						},
						(err: any) => {
							this.notificationMessageService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			} else {
				this.addSubscription?.unsubscribe();
				this.addSubscription = this.notificationService
					.createNotification("brand-config", data)
					.subscribe(
						(res: any) => {
							if (res) this.onResponseAction(res, this.actionType);
						},
						(err: any) => {
							this.notificationMessageService.setMessage(
								err?.error?.context_code,
								err?.error?.summary
							);
						}
					);
			}
		}
	}

	onCloseAction() {
		this.onCancelAction.emit();
	}
	resetForm(type: any) {
		if (type == "marketplace") {
			this.resetInitials();
			this.metricList = [];
			this.resetByType(type);
			this.disableByType(type);
		} else if (type == "brand") {
			this.metricList = [];
			this.resetByType(type);
			this.disableByType(type);
		} else if (type == "metric") {
			this.resetByType(type);
			this.disableByType(type);
		}
	}

	disableByType(type) {
		if (type == "metric" || type == "brand" || type == "marketplace") {
			this.brandForm.controls["current_kpi"].disable();
			this.disableRangeOperators();
		}
		if (type == "brand" || type == "marketplace")
			this.brandForm.controls["select_target_kpi"].disable();
		if (type == "marketplace")
			this.brandForm.controls["select_brand"].disable();
	}

	resetByType(type) {
		if (type == "metric" || type == "brand" || type == "marketplace") {
			this.brandForm.controls["current_kpi"].reset();
			this.resetRangeOperators();
		}
		if (type == "brand" || type == "marketplace")
			this.brandForm.controls["select_target_kpi"].reset();
		if (type == "marketplace") this.brandForm.controls["select_brand"].reset();
	}

	onChange(event: any, type: any) {
		if (type == "marketplace") {
			this.resetForm("marketplace");
			if (event) {
				this.getBrandList(
					this.brandForm.controls["select_marketplace"]?.value?.id
				);
				this.brandForm.controls["select_brand"].enable();
			} else this.brandForm.controls["select_brand"].disable();
		} else if (type == "brand") {
			this.resetForm("brand");
			if (event) {
				this.getMetricList();
				this.brandForm.controls["select_target_kpi"].enable();
			} else this.brandForm.controls["select_target_kpi"].disable();
		} else if (type == "metric") {
			this.resetForm("metric");
			if (event) {
				this.getCurrentKPIValue(
					this.notificationInfo
						? this.notificationInfo?.id
						: this.brandForm.controls["select_brand"]?.value?.id
				);
				this.brandForm.controls["note_brand_kpi_value"].enable();
				this.brandForm.controls["target_kpi_operator_1"].enable();
				this.brandForm.controls["target_kpi_operator_1"].setValue(
					this.targetKPIOperatorList1[4]
				);
			} else {
				this.brandForm.controls["note_brand_kpi_value"].disable();
				this.brandForm.controls["target_kpi_operator_1"].disable();
			}
		} else if (type == "typeChange") {
			if (this.notificationInfo) {
				this.resetForm("brand");
				this.getMetricList();
				this.brandForm.controls["select_target_kpi"].enable();
			} else {
				this.resetForm("marketplace");
				this.brandForm.controls["select_marketplace"].reset();
			}
		}
	}

	public onKpiChange(eve) {
		if (this.brandForm.controls["note_brand_kpi_value_2"]?.value) {
			if (
				this.brandForm.controls["note_brand_kpi_value"].value >=
				this.brandForm.controls["note_brand_kpi_value_2"].value
			)
				this.rangeError = true;
			else this.rangeError = false;
		}

		if (this.brandForm.controls["note_brand_kpi_value"].invalid)
			this.brandForm.controls["note_brand_kpi_value_2"].disable();
		else {
			if (
				(this.brandForm.controls["target_kpi_operator_1"]?.value?.value ===
					">" ||
					this.brandForm.controls["target_kpi_operator_1"]?.value?.value ===
						">=") &&
				this.brandForm.controls["target_kpi_operator_2"]?.value
			) {
				this.brandForm.controls["note_brand_kpi_value_2"].enable();
			}
		}
	}

	updateButtonState() {
		if (this.actionType == "edit") {
			return (
				this.brandForm.status != "VALID" ||
				(this.brandForm.controls["note_brand_kpi_value"]?.value ==
					this.notificationData?.attributes?.target_kpi_value &&
					this.brandForm.controls["frequency"]?.value ==
						this.notificationData?.attributes?.frequency &&
					this.brandForm.controls["note_brand_kpi_value_2"]?.value ==
						this.notificationData?.attributes?.target_kpi_value_2 &&
					this.brandForm.controls["target_kpi_operator_2"]?.value ==
						this.notificationData?.attributes?.operator_2 &&
					this.brandForm.controls["target_kpi_operator_1"]?.value ==
						this.notificationData?.attributes?.operator)
			);
		} else return false;
	}
	searchData(event: any, searchType: any) {
		this.tagsSubject$.next({ event, searchType });
	}

	onSearchFire(event: any, searchType: any) {
		if (searchType == "brand") {
			this.searchBrand = event?.term;
			if ((event?.term && event?.term.length >= 3) || event?.term.length == 0) {
				this.brandPageNumber = 1;
				this.brandList = [];
				this.getBrandList(
					this.brandForm.controls["select_marketplace"]?.value?.id
				);
			}
		}
	}

	onBrandDropdownClose() {
		this.brandList = this.initialBrandList;
		this.brandPageNumber = this.initialBrandPageNumber;
		this.searchBrand = "";
	}

	public onFirstOperatorChange(eve) {
		if (eve?.value === ">" || eve?.value === ">=") {
			this.brandForm.controls["target_kpi_operator_2"].enable();
		} else {
			this.brandForm.controls["target_kpi_operator_2"].disable();
			this.brandForm.controls["target_kpi_operator_2"].reset();
			this.brandForm.controls["note_brand_kpi_value_2"].reset();
			this.brandForm.controls["note_brand_kpi_value_2"].disable();
		}
	}

	public onSecondOperatorChange(eve) {
		if (eve) {
			this.brandForm.controls["note_brand_kpi_value_2"].enable();
		} else {
			this.brandForm.controls["note_brand_kpi_value_2"].disable();
			this.brandForm.controls["note_brand_kpi_value_2"].reset();
		}
	}

	public onNoteBrandKpiValueChange2(eve) {
		if (eve.target.value) {
			if (
				this.brandForm.controls["note_brand_kpi_value"].value >=
				this.brandForm.controls["note_brand_kpi_value_2"].value
			)
				this.rangeError = true;
			else this.rangeError = false;
		} else this.rangeError = false;
	}

	ngOnDestroy(): void {
		this.marketplaceListSubscription?.unsubscribe();
		this.brandListSubscription?.unsubscribe();
		this.metricListSubscription?.unsubscribe();
		this.currentKPISubscription?.unsubscribe();
		this.updateSubscription?.unsubscribe();
		this.addSubscription?.unsubscribe();
		this.tagsSubject$.next(null);
		this.tagsSubject$.unsubscribe();
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
