import { Component, OnInit, Input} from '@angular/core';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';

@Component({
  selector: 'app-build-graph-common-methods',
  templateUrl: './build-graph-common-methods.component.html',
  styleUrls: ['./build-graph-common-methods.component.scss']
})
export class BuildGraphCommonMethodsComponent implements OnInit {
  @Input() data;
  @Input() currency;
  @Input() timeline;
  constructor(public commonFunctions: CommonFunctionsService) { }

  ngOnInit(): void {
  }

  getHour(item){
    if (item==='-'){
      return '00:00';
    }
    else return (item.toString().length === 1 ? "0" + item : item )+ ":00"
  }

}
