<div class="filter-container d-flex align-items-center gap-12 m-0">
	<div class="d-flex gap-0">
		<p
			class="m-0"
			*ngIf="(showFilter.showTimelineText && !showFilter.customRange) && showTimeFilter && !hideSelectedDateText"
		></p>
		<p class="m-0" *ngIf="checkReqSubmodules()" translate>Quarterly Trend (</p>
		<p
			class="m-0"
			*ngIf="(showFilter.showTimelineText && !showFilter.customRange) && showTimeFilter"
		>
			{{getNumberDate(selectedTimeline.startDate)===getNumberDate(selectedTimeline.endDate)?selectedTimeline.startDate?.format('MMM Do YYYY'):selectedTimeline.startDate?.format('MMM Do YYYY')+ ' '+('to' |
			translate)+' '+selectedTimeline.endDate?.format('MMM Do YYYY')}}
		</p>
		<p
			class="m-0"
			*ngIf="showFilter.customRange  && showTimeFilter && !hideSelectedDateText"
		>
			{{getNumberDate(selectedDate.startDate)===getNumberDate(selectedDate.endDate)?selectedDate.startDate?.format('MMM
			Do YYYY'):selectedDate.startDate?.format('MMM Do YYYY')+ ' '+('to' |
			translate)+' '+selectedDate.endDate?.format('MMM Do YYYY')}}
		</p>
		<p class="m-0" *ngIf="checkReqSubmodules()">)</p>
	</div>
	<div
		class="select-container d-flex align-items-center gap-8"
		*ngIf="showFilter.timeline || showFilter.customRange || showFilter.marketplace || showFilter.showCurrency || showFilter.showMarketShare"
	>
		<ng-select
			[items]="timeLineList"
			[(ngModel)]="selectedTimeline"
			*ngIf="showFilter.timeline && showTimeFilter"
			[notFoundText]="'No Data Found' | translate"
			bindLabel="label"
			placeholder="Timeline"
			[clearable]="false"
			(change)="onDataChange($event,FilterType.timeline)"
			(open)="onOpenCloseDropdown('viewBy')"
			(close)="onOpenCloseDropdown('viewBy')"
			[ngbTooltip]="'Timeline' | translate"
			tooltipClass="info-custom-tooltip"
		>
			<ng-template ng-label-tmp let-item="item">
				{{item.label | translate}}
			</ng-template>
			<ng-template ng-option-tmp let-item="item">
				<span class="float-start">{{item.label | translate}}</span>
			</ng-template>
		</ng-select>
		<label
			for="calendar"
			*ngIf="showFilter.customRange && showTimeFilter"
			class="calendar"
			placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
			ngbTooltip="{{'Custom Date' | translate}}"
			tooltipClass="info-custom-tooltip"
			[class.marketShareCalendar]="isTheCalendarForMarketShare"
		>
			<input
				id="calendar"
				[ranges]="showRanges ? ranges : null"
				[alwaysShowCalendars]="true"
				ngxDaterangepickerMd
				[locale]="calendarLocale"
				[linkedCalendars]="true"
				[(ngModel)]="selectedDate"
				(datesUpdated)="onCustomDateUpdate($event)"
				[minDate]="minDate"
				[maxDate]="maxDate"
				[opens]="'left'"
				[showClearButton]="true"
				[showCustomRangeLabel]="isTheCalendarForMarketShare"
				(rangeClicked)="onRangeClick($event)"
				[timePicker]="false"
				name="daterange"
			/>
			<i
				class="icon icon-Calendar ngx-daterangepicker-action"
				(click)="onCalenderIconClick()"
			></i>
		</label>
		<ng-select
			(close)="onMarketplaceClose()"
			class="global-brands select-text-wrap"
			*ngIf="showFilter.marketplace"
			[(ngModel)]="marketplace.selectedMarketplace"
			[notFoundText]="'No Data Found' | translate"
			[loadingText]="'Loading...' | translate"
			[items]="marketplace.list"
			bindLabel="attributes.name"
			placeholder="{{marketplace.placeholder | translate}}"
			(scrollToEnd)="getMarketplaceList()"
			[searchable]="true"
			(change)="onDataChange($event,FilterType.marketplace)"
			(search)="searchData($event)"
			[loading]="marketplace.isLoading"
			[clearable]="true"
			[multiple]="true"
			[closeOnSelect]="false"
			(open)="onOpenCloseDropdown('retail')"
			(close)="onOpenCloseDropdown('retail')"
			[ngbTooltip]="'Channel' | translate"
			tooltipClass="info-custom-tooltip"
		>
			<ng-template ng-multi-label-tmp
				><span class="placeholder-temp"
					>{{marketplace.searchMarketplace?.length ? '' : (('Channel' |
					translate) + " (" + marketplace.selectedMarketplace?.length +
					")")}}</span
				></ng-template
			>
			<ng-template ng-option-tmp let-item="item" let-index="index">
				<div class="multi-select-option-container multi-text-wrap">
					<span class="item-name" translate>{{item.attributes.name}}</span>
					<i class="icon icon-Check" *ngIf="checkSelectedOption(item)"></i>
				</div>
			</ng-template>
		</ng-select>
	</div>
	<div
		class="d-flex align-items-center justify-content-end w-100"
		*ngIf="showFilter.kpiToggle"
	>
		<div class="ds-switch checked-only-switch">
			<div class="switch-name" translate>GMV</div>
			<label class="switch-container d-flex" for="switch-view">
				<input
					id="switch-view"
					name="metric-view"
					type="checkbox"
					(change)="onMetricViewChange(defaultMetricView==='quantity'?'sales':'quantity')"
				/>
				<div class="toggle-content">
					<div class="toggle-bubble"></div>
				</div>
			</label>
			<div class="switch-name" translate>Quantity</div>
		</div>
	</div>
</div>
