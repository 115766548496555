import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy, Inject, ElementRef, ViewChild } from '@angular/core';
import * as  Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import Heatmap from 'highcharts/modules/heatmap';
More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);
import { ActivatedRoute } from '@angular/router';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartWalkthroughComponent } from 'src/app/shared/common-components/chart-walkthrough/chart-walkthrough.component';
import { TOOLTIPCOLOR } from '@ds-shared/enums/common.enum';
import { CurrencyTemplate } from '@ds-shared/models/common.model';
let componentContext;

@Component({
  selector: 'app-calendar-heatmap',
  templateUrl: './calendar-heatmap.component.html',
  styleUrls: ['./calendar-heatmap.component.scss']
})
export class CalendarHeatmapComponent implements OnInit {

  @Input() scorecardInfo;
  @Input() currencyDetails: CurrencyTemplate;
  public Highcharts: typeof Highcharts = Highcharts;
  public date: { year: number, month: number };
  public areaChart: typeof Highcharts;
  public areaChartOptions: any;
  public metric: any;
  public selectedMetric: any;
  public selectedDateAreaGraph: any = null;
  @Input() prev: any;
  @Input() next: any;
  public finalArray: any[] = [];
  public dateSelected: any = "";
  public calendarSize: number = 0;
  @Output() public objForCalendar = new EventEmitter<any>();
  @Output() public objForArea = new EventEmitter<any>()
  @Output() public changeMonthObj = new EventEmitter<any>()
  @Input() public calendarMapData: any
  @Input() public metricList: any;
  @Input() public areaChartData: any;
  @Input() public monthName: any;
  @Input() public year: any;
  @Input() public colorMap: any;
  @Input() calendarPresent;
  @Input() areaPresent;
  public calendarHeatmapTooltip: string = 'Benchmark performance against other mapped competitors based on dates and selected KPI';
  public metricName: any = '';
  public startIngPoint: number;
  public heightVal: any;
  public storeEvent: any;
  public monthBefore90Days: any;
  public restrictBefore90Days: boolean = false;
  public calendarLegends: any = [
    {
      key: "High",
      color: "#0289ff"
    },
    {
      key: "Medium",
      color: "#60b4fc"
    },
    {
      key: "Low",
      color: "#c2dff9"
    }
  ]
  public colorArray: any[] = ['#0287fd', '#ff5800', '#ff8f00', '#feab00', '#ff1847', '#009a2c', '#1b2331', ''];
  public areaLegends: any = [];
  public startDate: any;
  public endDate: any;
  public profileScrapedDateSubscription: Subscription;
  public profileScrapedDate: any = null;
  public isAreaChartZoomed: boolean = false;
  public updateChart: boolean = false;
  private destroy$: Subject<boolean> = new Subject();
  public chartRef;

  constructor( @Inject(DOCUMENT) private document: Document, public commonFunctions: CommonFunctionsService, private dataTransferService: DataTransferService, private modalService: NgbModal) { }
  ngOnInit(): void {
    componentContext = this;
    this.monthBefore90Days = (new Date(new Date()?.setDate(new Date()?.getDate() - 90)))?.toLocaleString('en-US', { month: 'short' });
    this.profileScrapedDateSubscription = this.dataTransferService.profileScrapedDate$.subscribe((data: any) => {
      if (data) {
        this.profileScrapedDate = new Date(data)?.toLocaleString('en-US', { month: 'short' });
      }
    });
    this.dataTransferService.secondaaryMenuToggleSignal$.pipe(filter((res) => res), takeUntil(this.destroy$)).subscribe((res) => {
      setTimeout(() => {
        this.chartRef.reflow();
      }, 0);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.dateSelected) {
      this.dateSelected = new Date().toLocaleDateString('en-CA');
    }
    this.getParsedDataOfCalendarAndArea(changes);
    if (this.monthName?.startsWith(this.monthBefore90Days) || this.monthName?.startsWith(this.profileScrapedDate)) this.restrictBefore90Days = true;
    else this.restrictBefore90Days = false;
  }

  getParsedDataOfCalendarAndArea(changes: any) {
    if (changes?.scorecardInfo && this.dateSelected) {
      // if (this.route.snapshot['_urlSegment'].segments[4].path === 'profile-information') {
      this.dateSelected = new Date().toLocaleDateString('en-CA');
      this.selectedDateAreaGraph = this.getDateRange();
      this.getMetricList();
      this.areaChartData = null;
      // }
    }
    if (changes?.calendarMapData) {
      this.calendarMapData = changes?.calendarMapData?.currentValue?.data
      let dateThreshold = 0;
      if (this.calendarMapData?.length > 0)
        dateThreshold = new Date(this.calendarMapData[0].date).getDay();
      this.finalArray = [];
      let z = 0;
      this.heightVal = '19.375rem';
      for (let p = 0; p < dateThreshold; p++) {
        this.finalArray.push({
          value: 0,
          date: "",
          styleClass: "hide"
        })
      }
      for (let i = 0; i < 7 && z < this.calendarMapData?.length; i++) {
        for (let j = dateThreshold; j < 7 && z < this.calendarMapData?.length; j++) {
          this.finalArray.push({
            value: z + 1,
            date: this.calendarMapData[z]?.date?.length < 10 ? this.calendarMapData[z]?.date?.substring(0, 5) + "0" + this.calendarMapData[z]?.date?.substring(5, this.calendarMapData[z]?.date?.length) : this.calendarMapData[z]?.date,
            styleClass: this.isFutureDate(this.calendarMapData[z]?.date) || this.calendarMapData[z]?.value == null ? "disabled" : this.getStatus(this.calendarMapData[z]?.priority)
          })
          z++
        }
        if (i >= 5) {
          this.heightVal = '20.625rem';
        }
        dateThreshold = 0
      }
      let remainingPlaces = this.finalArray?.length % 7 ? (7 - this.finalArray?.length % 7) : 0;
      for (let i = 0; i < remainingPlaces; i++) {
        this.finalArray.push({
          value: 0,
          date: "",
          styleClass: "hide"
        })
      }
      this.calendarSize = Math.ceil(this.finalArray?.length / 7);
    }

    // logic for area chart starts
    if (changes.areaChartData) {
      this.areaChartData = changes.areaChartData?.currentValue
      let dataForAreaChart: any[] = []
      let dateArray: any[] = [];
      let check = true;
      this.areaLegends = [];
      this.areaChartData?.forEach((element, index) => {
        let z = 0;
        let dataArray: any[] = [];
        this.areaLegends.push({
          key: element?.name,
          color: this.colorArray[index]
        })
        let max = 0;
        for (let i = 0; i < element?.graph_data?.length; i++) {
          if (max < Number(Object.values(element?.graph_data?.[z])))
            max = Number(Object.values(element?.graph_data?.[z]));
          dataArray.push(Number(Object.values(element?.graph_data?.[z])))
          if (check) {
            const date = new Date(String(Object.keys(element?.graph_data?.[z])))
            dateArray.push(date.toLocaleString('default', { month: 'short' }) + " " + date.getDate())
          }
          z++
        }
        check = false;
        dataForAreaChart.push({
          name: element.name,
          data: dataArray,
          color: this.colorArray[index]
        })
      });
      this.callAreaChart(dataForAreaChart, dateArray)
    }
    if (this.scorecardInfo) {
      this.areaChartOptions.tooltip.pointFormat = '<div style="display:flex;flex-direction:row;align-items:center;justify-content:flex-start;gap:0.5rem;margin-top:0rem;"><div style="display:flex;align-items:center;justify-content:center;"><div style="width:0.5rem;height:0.5rem;background-color:{point.color};border-radius:0.125rem;"></div><span style="margin-left:0.25rem;color:#777B83;font-size:0.75rem;;text-align:right;">{series.name} - </span></div><div style=""><b>' + (this.selectedMetric.code === 'selling_price' ? this.commonFunctions.currencySelected.attributes?.symbol : '') + '{point.y:.f}' + (this.selectedMetric.code === 'discount' ? '%' : '') + '</b></div></div>'
      this.updateChart = true;
    }
  }
  getStatus(state) {
    if (state > 2) return 'high'
    else if (state > 1 && state <= 2) return 'medium'
    else if (state == null) return 'low'
    else return 'low';
  }
  splitDate(date): String {
    let split = date.split(' ');
    return split[0] + ' ' + split[1] + ' ' + split[2]
  }

  isFutureDate(dateVal) {
    const Currentdate = new Date();
    dateVal = dateVal.split("-");
    if (Currentdate.getFullYear() < dateVal[0]) {
      return true;
    }
    else if (Currentdate.getFullYear() == dateVal[0]) {
      if (Currentdate.getMonth() + 1 < dateVal[1])
        return true;
      else if (Currentdate.getMonth() + 1 == dateVal[1]) {
        if (Currentdate.getDate() < dateVal[2]) {
          return true;
        }
        else return false;
      }
      else return false;
    }
    else return false;


  }

  callAreaChart(dataForAreaChart, dateArray) {
    this.areaChart = Highcharts;
    this.areaChart.Chart.prototype.showResetZoom = function () { };
    this.areaChartOptions = {
      chart: {
        type: 'spline',
        inverted: false,
        zoomType: 'xy',
        events: {
          load: (e) => {
            this.document.getElementById('zoomChart').addEventListener('click', () => {
              if (e.target.xAxis) {
                e.target.xAxis[0].setExtremes(null, null);
              }
              if (e.target.yAxis) {
                e.target.yAxis[0].setExtremes(null, null);
              }
              this.isAreaChartZoomed = false;
            })
          },
          selection: (e) => this.isAreaChartZoomed = !e.resetSelection
        }
      },
      axis: {
        dataMax: 1,
        dataMin: 31
      },
      credits: { enabled: false },
      title: {
        text: ''
      },
      xAxis: {
        allowDecimals: false,
        type: 'category',
        categories: dateArray,
        labels: {
          style: {
            color: '#8e929a'
          }
        },
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          style: {
            color: '#8e929a'
          }
        }
      },
      tooltip: {
        split: false,
        backgroundColor: undefined,
        borderColor: undefined,
        borderWidth: 0,
        shadow: false,
        hideDelay: 100,
        formatter: this.toolTipFormatter,
        shared: false,
        useHTML: true
      },
      plotOptions: {
        spline: {
          fillOpacity: 0.5,
          lineWidth: 2,
          marker: {
            lineWidth: 1,
            enabled: true,
            symbol: 'circle',
            shadow: false,
            radius: 4,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        },
        series: {
          keys: ['y', 'x', 'value'],
          lineWidth: 0,
          borderWidth: 0,
          showInLegend: false,
          shadow: false,
          marker: { enabled: true },
          states: { hover: { shadow: false } }
        },
      },
      series: dataForAreaChart
    }
  }

  toolTipFormatter(ev): string {
    const dataLabel = this as Highcharts.DataLabelsOptions;
    return `<div
      style="background-color:${TOOLTIPCOLOR["BG"]};min-width:8rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
      <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">on ${dataLabel.x}</p>
      <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
          <div style="display:flex;flex-direction: column;">
              <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">
               ${componentContext.selectedMetric.code === 'selling_price' ? componentContext.currencyDetails?.symbol : ''}${dataLabel.y}${componentContext.selectedMetric.code === 'discount' ? '%' : ''}
							</span>
              <span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${dataLabel['series'].name}</span>
          </div>
      </div>
  </div>`;

  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  };

  onSelect(event) {
    this.metric = event.code
    this.metricName = event.name
    const param = {
      metric: this.metric,
      dateSelected: this.dateSelected
    }
    this.objForCalendar.emit(param);
    this.onDateChange();
    this.areaChartData = null;
    this.isAreaChartZoomed = false
  }

  sendObjForArea(e) {
    this.startIngPoint = e.point.value
    const param = {
      metric: this.metric,
      latestDate: e.point.date
    }
    this.objForArea.emit(param)
  }

  changeMonth(val) {
    this.changeMonthObj.emit(val);
  }

  getMetricList() {
    this.metricList = [
      { name: "Average Selling Price", code: "selling_price" },
      { name: "Average Discount", code: "discount" },
      { name: "Average Ratings", code: "rating" },
      { name: "Number of Products", code: "assortments" }
    ]
    this.selectedMetric = this.metricList[0];
    this.onSelect(this.selectedMetric);
  }
  onDateSelect(index: any): any {
    if (this.finalArray[index]?.styleClass != 'disabled') {
      this.areaPresent = false;
      this.areaChartData = null;
      this.dateSelected = this.finalArray[index]?.date;
      this.onDateChange();
    }

  }
  onDateChange() {
    this.selectedDateAreaGraph = this.getDateRange();
    if (this.dateSelected.split('/').length === 3) {
      const x = this.dateSelected.split('/');
      this.dateSelected = `${x[2]}-${x[0] < 10 ? `0${x[0]}` : x[0]}-${x[1] < 10 ? `0${x[1]}` : x[1]}`;
    }
    const param = {
      metric: this.metric,
      latestDate: this.dateSelected
    }
    this.objForArea.emit(param)
  }

  getDateRange() {
    this.startDate = this.splitDate(this.commonFunctions.formatSimpleDate(new Date(new Date(this.dateSelected).getTime() - (6 * 24 * 60 * 60 * 1000)), 'MMM d YYYY'));
    this.endDate = this.splitDate(this.commonFunctions.formatSimpleDate(this.dateSelected, 'MMM d YYYY'));
    return this.splitDate(this.commonFunctions.formatSimpleDate(new Date(new Date(this.dateSelected).getTime() - (6 * 24 * 60 * 60 * 1000)), 'MMM d YYYY')) + " to " + this.splitDate(this.commonFunctions.formatSimpleDate(this.dateSelected, 'MMM d YYYY'));
  }

  createRange(number) {
    return new Array(number);
  }

  openChartWalkthrough() {
    const modalRef = this.modalService.open(ChartWalkthroughComponent, {
      centered: true,
      windowClass: "chartWalkthroughCustomModal",
      size: "md",
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.walkthroughName = 'heatmap'
  }

  ngOnDestroy() {
    this.dataTransferService.sendProfileScrapedDate(null);
    this.profileScrapedDateSubscription?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
