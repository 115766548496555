/*
 Highmaps JS v10.3.3 (2023-01-20)

 (c) 2009-2021 Torstein Honsi

 License: www.highcharts.com/license
*/
(function (b) {
  "object" === typeof module && module.exports ? (b["default"] = b, module.exports = b) : "function" === typeof define && define.amd ? define("highcharts/modules/heatmap", ["highcharts"], function (r) {
    b(r);
    b.Highcharts = r;
    return b;
  }) : b("undefined" !== typeof Highcharts ? Highcharts : void 0);
})(function (b) {
  function r(b, g, F, q) {
    b.hasOwnProperty(g) || (b[g] = q.apply(null, F), "function" === typeof CustomEvent && window.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: g,
        module: b[g]
      }
    })));
  }
  b = b ? b._modules : {};
  r(b, "Core/Axis/Color/ColorAxisComposition.js", [b["Core/Color/Color.js"], b["Core/Utilities.js"]], function (b, g) {
    var m = b.parse,
      q = g.addEvent,
      z = g.extend,
      t = g.merge,
      u = g.pick,
      A = g.splat,
      n;
    (function (f) {
      function b() {
        var a = this,
          d = this.options;
        this.colorAxis = [];
        d.colorAxis && (d.colorAxis = A(d.colorAxis), d.colorAxis.forEach(function (d, c) {
          d.index = c;
          new y(a, d);
        }));
      }
      function g(a) {
        var d = this,
          c = function (c) {
            c = a.allItems.indexOf(c);
            -1 !== c && (d.destroyItem(a.allItems[c]), a.allItems.splice(c, 1));
          },
          e = [],
          h,
          x;
        (this.chart.colorAxis || []).forEach(function (a) {
          (h = a.options) && h.showInLegend && (h.dataClasses && h.visible ? e = e.concat(a.getDataClassLegendSymbols()) : h.visible && e.push(a), a.series.forEach(function (a) {
            if (!a.options.showInLegend || h.dataClasses) "point" === a.options.legendType ? a.points.forEach(function (a) {
              c(a);
            }) : c(a);
          }));
        });
        for (x = e.length; x--;) a.allItems.unshift(e[x]);
      }
      function n(a) {
        a.visible && a.item.legendColor && a.item.legendItem.symbol.attr({
          fill: a.item.legendColor
        });
      }
      function w() {
        var a = this.chart.colorAxis;
        a && a.forEach(function (a, d, c) {
          a.update({}, c);
        });
      }
      function p() {
        (this.chart.colorAxis && this.chart.colorAxis.length || this.colorAttribs) && this.translateColors();
      }
      function D() {
        var a = this.axisTypes;
        a ? -1 === a.indexOf("colorAxis") && a.push("colorAxis") : this.axisTypes = ["colorAxis"];
      }
      function k(a) {
        var d = this,
          c = a ? "show" : "hide";
        d.visible = d.options.visible = !!a;
        ["graphic", "dataLabel"].forEach(function (a) {
          if (d[a]) d[a][c]();
        });
        this.series.buildKDTree();
      }
      function e() {
        var a = this,
          d = this.options.nullColor,
          c = this.colorAxis,
          e = this.colorKey;
        (this.data.length ? this.data : this.points).forEach(function (h) {
          var l = h.getNestedProperty(e);
          (l = h.options.color || (h.isNull || null === h.value ? d : c && "undefined" !== typeof l ? c.toColor(l, h) : h.color || a.color)) && h.color !== l && (h.color = l, "point" === a.options.legendType && h.legendItem && h.legendItem.label && a.chart.legend.colorizeItem(h, h.visible));
        });
      }
      function c(a) {
        var d = a.prototype.createAxis;
        a.prototype.createAxis = function (a, c) {
          if ("colorAxis" !== a) return d.apply(this, arguments);
          var e = new y(this, t(c.axis, {
            index: this[a].length,
            isX: !1
          }));
          this.isDirtyLegend = !0;
          this.axes.forEach(function (a) {
            a.series = [];
          });
          this.series.forEach(function (a) {
            a.bindAxes();
            a.isDirtyData = !0;
          });
          u(c.redraw, !0) && this.redraw(c.animation);
          return e;
        };
      }
      function a() {
        this.elem.attr("fill", m(this.start).tweenTo(m(this.end), this.pos), void 0, !0);
      }
      function d() {
        this.elem.attr("stroke", m(this.start).tweenTo(m(this.end), this.pos), void 0, !0);
      }
      var h = [],
        y;
      f.compose = function (f, l, v, C, m) {
        y || (y = f);
        -1 === h.indexOf(l) && (h.push(l), f = l.prototype, f.collectionsWithUpdate.push("colorAxis"), f.collectionsWithInit.colorAxis = [f.addColorAxis], q(l, "afterGetAxes", b), c(l));
        -1 === h.indexOf(v) && (h.push(v), l = v.prototype, l.fillSetter = a, l.strokeSetter = d);
        -1 === h.indexOf(C) && (h.push(C), q(C, "afterGetAllItems", g), q(C, "afterColorizeItem", n), q(C, "afterUpdate", w));
        -1 === h.indexOf(m) && (h.push(m), z(m.prototype, {
          optionalAxis: "colorAxis",
          translateColors: e
        }), z(m.prototype.pointClass.prototype, {
          setVisible: k
        }), q(m, "afterTranslate", p, {
          order: 1
        }), q(m, "bindAxes", D));
      };
      f.pointSetVisible = k;
    })(n || (n = {}));
    return n;
  });
  r(b, "Core/Axis/Color/ColorAxisDefaults.js", [], function () {
    return {
      lineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      gridLineWidth: 1,
      tickPixelInterval: 72,
      startOnTick: !0,
      endOnTick: !0,
      offset: 0,
      marker: {
        animation: {
          duration: 50
        },
        width: .01,
        color: "#999999"
      },
      labels: {
        overflow: "justify",
        rotation: 0
      },
      minColor: "#e6ebf5",
      maxColor: "#003399",
      tickLength: 5,
      showInLegend: !0
    };
  });
  r(b, "Core/Axis/Color/ColorAxis.js", [b["Core/Axis/Axis.js"], b["Core/Color/Color.js"], b["Core/Axis/Color/ColorAxisComposition.js"], b["Core/Axis/Color/ColorAxisDefaults.js"], b["Core/Globals.js"], b["Core/Legend/LegendSymbol.js"], b["Core/Series/SeriesRegistry.js"], b["Core/Utilities.js"]], function (b, g, r, q, z, t, u, A) {
    var n = this && this.__extends || function () {
        var f = function (b, e) {
          f = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (c, a) {
            c.__proto__ = a;
          } || function (c, a) {
            for (var d in a) a.hasOwnProperty(d) && (c[d] = a[d]);
          };
          return f(b, e);
        };
        return function (b, e) {
          function c() {
            this.constructor = b;
          }
          f(b, e);
          b.prototype = null === e ? Object.create(e) : (c.prototype = e.prototype, new c());
        };
      }(),
      f = g.parse,
      v = u.series,
      m = A.extend,
      B = A.isNumber,
      w = A.merge,
      p = A.pick;
    g = function (b) {
      function k(e, c) {
        var a = b.call(this, e, c) || this;
        a.beforePadding = !1;
        a.chart = void 0;
        a.coll = "colorAxis";
        a.dataClasses = void 0;
        a.name = "";
        a.options = void 0;
        a.stops = void 0;
        a.visible = !0;
        a.init(e, c);
        return a;
      }
      n(k, b);
      k.compose = function (e, c, a, d) {
        r.compose(k, e, c, a, d);
      };
      k.prototype.init = function (e, c) {
        var a = e.options.legend || {},
          d = c.layout ? "vertical" !== c.layout : "vertical" !== a.layout,
          h = c.visible;
        a = w(k.defaultColorAxisOptions, c, {
          showEmpty: !1,
          title: null,
          visible: a.enabled && !1 !== h
        });
        this.coll = "colorAxis";
        this.side = c.side || d ? 2 : 1;
        this.reversed = c.reversed || !d;
        this.opposite = !d;
        b.prototype.init.call(this, e, a);
        this.userOptions.visible = h;
        c.dataClasses && this.initDataClasses(c);
        this.initStops();
        this.horiz = d;
        this.zoomEnabled = !1;
      };
      k.prototype.initDataClasses = function (e) {
        var c = this.chart,
          a = this.legendItem = this.legendItem || {},
          d = e.dataClasses.length,
          h = this.options,
          b,
          x = 0,
          l = c.options.chart.colorCount;
        this.dataClasses = b = [];
        a.labels = [];
        (e.dataClasses || []).forEach(function (a, e) {
          a = w(a);
          b.push(a);
          if (c.styledMode || !a.color) "category" === h.dataClassColor ? (c.styledMode || (e = c.options.colors, l = e.length, a.color = e[x]), a.colorIndex = x, x++, x === l && (x = 0)) : a.color = f(h.minColor).tweenTo(f(h.maxColor), 2 > d ? .5 : e / (d - 1));
        });
      };
      k.prototype.hasData = function () {
        return !!(this.tickPositions || []).length;
      };
      k.prototype.setTickPositions = function () {
        if (!this.dataClasses) return b.prototype.setTickPositions.call(this);
      };
      k.prototype.initStops = function () {
        this.stops = this.options.stops || [[0, this.options.minColor], [1, this.options.maxColor]];
        this.stops.forEach(function (e) {
          e.color = f(e[1]);
        });
      };
      k.prototype.setOptions = function (e) {
        b.prototype.setOptions.call(this, e);
        this.options.crosshair = this.options.marker;
      };
      k.prototype.setAxisSize = function () {
        var e = this.legendItem && this.legendItem.symbol,
          c = this.chart,
          a = c.options.legend || {},
          d,
          h;
        e ? (this.left = a = e.attr("x"), this.top = d = e.attr("y"), this.width = h = e.attr("width"), this.height = e = e.attr("height"), this.right = c.chartWidth - a - h, this.bottom = c.chartHeight - d - e, this.len = this.horiz ? h : e, this.pos = this.horiz ? a : d) : this.len = (this.horiz ? a.symbolWidth : a.symbolHeight) || k.defaultLegendLength;
      };
      k.prototype.normalizedValue = function (e) {
        this.logarithmic && (e = this.logarithmic.log2lin(e));
        return 1 - (this.max - e) / (this.max - this.min || 1);
      };
      k.prototype.toColor = function (e, c) {
        var a = this.dataClasses,
          d = this.stops,
          h;
        if (a) for (h = a.length; h--;) {
          var b = a[h];
          var f = b.from;
          d = b.to;
          if (("undefined" === typeof f || e >= f) && ("undefined" === typeof d || e <= d)) {
            var l = b.color;
            c && (c.dataClass = h, c.colorIndex = b.colorIndex);
            break;
          }
        } else {
          e = this.normalizedValue(e);
          for (h = d.length; h-- && !(e > d[h][0]););
          f = d[h] || d[h + 1];
          d = d[h + 1] || f;
          e = 1 - (d[0] - e) / (d[0] - f[0] || 1);
          l = f.color.tweenTo(d.color, e);
        }
        return l;
      };
      k.prototype.getOffset = function () {
        var e = this.legendItem && this.legendItem.group,
          c = this.chart.axisOffset[this.side];
        if (e) {
          this.axisParent = e;
          b.prototype.getOffset.call(this);
          var a = this.chart.legend;
          a.allItems.forEach(function (d) {
            d instanceof k && d.drawLegendSymbol(a, d);
          });
          a.render();
          this.chart.getMargins(!0);
          this.added || (this.added = !0, this.labelLeft = 0, this.labelRight = this.width);
          this.chart.axisOffset[this.side] = c;
        }
      };
      k.prototype.setLegendColor = function () {
        var e = this.reversed,
          c = e ? 1 : 0;
        e = e ? 0 : 1;
        c = this.horiz ? [c, 0, e, 0] : [0, e, 0, c];
        this.legendColor = {
          linearGradient: {
            x1: c[0],
            y1: c[1],
            x2: c[2],
            y2: c[3]
          },
          stops: this.stops
        };
      };
      k.prototype.drawLegendSymbol = function (e, c) {
        c = c.legendItem || {};
        var a = e.padding,
          d = e.options,
          h = p(d.itemDistance, 10),
          b = this.horiz,
          f = p(d.symbolWidth, b ? k.defaultLegendLength : 12),
          l = p(d.symbolHeight, b ? 12 : k.defaultLegendLength);
        d = p(d.labelPadding, b ? 16 : 30);
        this.setLegendColor();
        c.symbol || (c.symbol = this.chart.renderer.rect(0, e.baseline - 11, f, l).attr({
          zIndex: 1
        }).add(c.group));
        c.labelWidth = f + a + (b ? h : this.options.labels.x + this.maxLabelLength);
        c.labelHeight = l + a + (b ? d : 0);
      };
      k.prototype.setState = function (e) {
        this.series.forEach(function (c) {
          c.setState(e);
        });
      };
      k.prototype.setVisible = function () {};
      k.prototype.getSeriesExtremes = function () {
        var e = this.series,
          c = e.length,
          a;
        this.dataMin = Infinity;
        for (this.dataMax = -Infinity; c--;) {
          var d = e[c];
          var h = d.colorKey = p(d.options.colorKey, d.colorKey, d.pointValKey, d.zoneAxis, "y");
          var b = d.pointArrayMap;
          var f = d[h + "Min"] && d[h + "Max"];
          if (d[h + "Data"]) var l = d[h + "Data"];else if (b) {
            l = [];
            b = b.indexOf(h);
            var k = d.yData;
            if (0 <= b && k) for (a = 0; a < k.length; a++) l.push(p(k[a][b], k[a]));
          } else l = d.yData;
          f ? (d.minColorValue = d[h + "Min"], d.maxColorValue = d[h + "Max"]) : (l = v.prototype.getExtremes.call(d, l), d.minColorValue = l.dataMin, d.maxColorValue = l.dataMax);
          "undefined" !== typeof d.minColorValue && (this.dataMin = Math.min(this.dataMin, d.minColorValue), this.dataMax = Math.max(this.dataMax, d.maxColorValue));
          f || v.prototype.applyExtremes.call(d);
        }
      };
      k.prototype.drawCrosshair = function (e, c) {
        var a = this.legendItem || {},
          d = c && c.plotX,
          h = c && c.plotY,
          f = this.pos,
          k = this.len;
        if (c) {
          var l = this.toPixels(c.getNestedProperty(c.series.colorKey));
          l < f ? l = f - 2 : l > f + k && (l = f + k + 2);
          c.plotX = l;
          c.plotY = this.len - l;
          b.prototype.drawCrosshair.call(this, e, c);
          c.plotX = d;
          c.plotY = h;
          this.cross && !this.cross.addedToColorAxis && a.group && (this.cross.addClass("highcharts-coloraxis-marker").add(a.group), this.cross.addedToColorAxis = !0, this.chart.styledMode || "object" !== typeof this.crosshair || this.cross.attr({
            fill: this.crosshair.color
          }));
        }
      };
      k.prototype.getPlotLinePath = function (e) {
        var c = this.left,
          a = e.translatedValue,
          d = this.top;
        return B(a) ? this.horiz ? [["M", a - 4, d - 6], ["L", a + 4, d - 6], ["L", a, d], ["Z"]] : [["M", c, a], ["L", c - 6, a + 6], ["L", c - 6, a - 6], ["Z"]] : b.prototype.getPlotLinePath.call(this, e);
      };
      k.prototype.update = function (e, c) {
        var a = this.chart.legend;
        this.series.forEach(function (a) {
          a.isDirtyData = !0;
        });
        (e.dataClasses && a.allItems || this.dataClasses) && this.destroyItems();
        b.prototype.update.call(this, e, c);
        this.legendItem && this.legendItem.label && (this.setLegendColor(), a.colorizeItem(this, !0));
      };
      k.prototype.destroyItems = function () {
        var e = this.chart,
          c = this.legendItem || {};
        if (c.label) e.legend.destroyItem(this);else if (c.labels) {
          var a = 0;
          for (c = c.labels; a < c.length; a++) e.legend.destroyItem(c[a]);
        }
        e.isDirtyLegend = !0;
      };
      k.prototype.destroy = function () {
        this.chart.isDirtyLegend = !0;
        this.destroyItems();
        b.prototype.destroy.apply(this, [].slice.call(arguments));
      };
      k.prototype.remove = function (e) {
        this.destroyItems();
        b.prototype.remove.call(this, e);
      };
      k.prototype.getDataClassLegendSymbols = function () {
        var e = this,
          c = e.chart,
          a = e.legendItem && e.legendItem.labels || [],
          d = c.options.legend,
          h = p(d.valueDecimals, -1),
          b = p(d.valueSuffix, ""),
          f = function (a) {
            return e.series.reduce(function (d, c) {
              d.push.apply(d, c.points.filter(function (d) {
                return d.dataClass === a;
              }));
              return d;
            }, []);
          },
          l;
        a.length || e.dataClasses.forEach(function (d, k) {
          var v = d.from,
            g = d.to,
            n = c.numberFormatter,
            y = !0;
          l = "";
          "undefined" === typeof v ? l = "< " : "undefined" === typeof g && (l = "> ");
          "undefined" !== typeof v && (l += n(v, h) + b);
          "undefined" !== typeof v && "undefined" !== typeof g && (l += " - ");
          "undefined" !== typeof g && (l += n(g, h) + b);
          a.push(m({
            chart: c,
            name: l,
            options: {},
            drawLegendSymbol: t.drawRectangle,
            visible: !0,
            isDataClass: !0,
            setState: function (a) {
              for (var d = 0, c = f(k); d < c.length; d++) c[d].setState(a);
            },
            setVisible: function () {
              this.visible = y = e.visible = !y;
              for (var a = 0, d = f(k); a < d.length; a++) d[a].setVisible(y);
              c.legend.colorizeItem(this, y);
            }
          }, d));
        });
        return a;
      };
      k.defaultColorAxisOptions = q;
      k.defaultLegendLength = 200;
      k.keepProps = ["legendItem"];
      return k;
    }(b);
    Array.prototype.push.apply(b.keepProps, g.keepProps);
    "";
    return g;
  });
  r(b, "Series/ColorMapComposition.js", [b["Core/Series/SeriesRegistry.js"], b["Core/Utilities.js"]], function (b, g) {
    var m = b.seriesTypes.column.prototype,
      q = g.addEvent,
      r = g.defined,
      t;
    (function (b) {
      function g(b) {
        this.moveToTopOnHover && this.graphic && this.graphic.attr({
          zIndex: b && "hover" === b.state ? 1 : 0
        });
      }
      var n = [];
      b.pointMembers = {
        dataLabelOnNull: !0,
        moveToTopOnHover: !0,
        isValid: function () {
          return null !== this.value && Infinity !== this.value && -Infinity !== this.value && (void 0 === this.value || !isNaN(this.value));
        }
      };
      b.seriesMembers = {
        colorKey: "value",
        axisTypes: ["xAxis", "yAxis", "colorAxis"],
        parallelArrays: ["x", "y", "value"],
        pointArrayMap: ["value"],
        trackerGroups: ["group", "markerGroup", "dataLabelsGroup"],
        colorAttribs: function (b) {
          var f = {};
          !r(b.color) || b.state && "normal" !== b.state || (f[this.colorProp || "fill"] = b.color);
          return f;
        },
        pointAttribs: m.pointAttribs
      };
      b.compose = function (b) {
        var f = b.prototype.pointClass;
        -1 === n.indexOf(f) && (n.push(f), q(f, "afterSetState", g));
        return b;
      };
    })(t || (t = {}));
    return t;
  });
  r(b, "Series/Heatmap/HeatmapPoint.js", [b["Core/Series/SeriesRegistry.js"], b["Core/Utilities.js"]], function (b, g) {
    var m = this && this.__extends || function () {
        var b = function (g, f) {
          b = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (b, f) {
            b.__proto__ = f;
          } || function (b, f) {
            for (var g in f) f.hasOwnProperty(g) && (b[g] = f[g]);
          };
          return b(g, f);
        };
        return function (g, f) {
          function v() {
            this.constructor = g;
          }
          b(g, f);
          g.prototype = null === f ? Object.create(f) : (v.prototype = f.prototype, new v());
        };
      }(),
      q = g.clamp,
      r = g.defined,
      t = g.extend,
      u = g.pick;
    b = function (b) {
      function g() {
        var f = null !== b && b.apply(this, arguments) || this;
        f.options = void 0;
        f.series = void 0;
        f.value = void 0;
        f.x = void 0;
        f.y = void 0;
        return f;
      }
      m(g, b);
      g.prototype.applyOptions = function (f, g) {
        (this.isNull || null === this.value) && delete this.color;
        b.prototype.applyOptions.call(this, f, g);
        this.formatPrefix = this.isNull || null === this.value ? "null" : "point";
        return this;
      };
      g.prototype.getCellAttributes = function () {
        var b = this.series,
          g = b.options,
          m = (g.colsize || 1) / 2,
          n = (g.rowsize || 1) / 2,
          w = b.xAxis,
          p = b.yAxis,
          t = this.options.marker || b.options.marker;
        b = b.pointPlacementToXValue();
        var k = u(this.pointPadding, g.pointPadding, 0),
          e = {
            x1: q(Math.round(w.len - w.translate(this.x - m, !1, !0, !1, !0, -b)), -w.len, 2 * w.len),
            x2: q(Math.round(w.len - w.translate(this.x + m, !1, !0, !1, !0, -b)), -w.len, 2 * w.len),
            y1: q(Math.round(p.translate(this.y - n, !1, !0, !1, !0)), -p.len, 2 * p.len),
            y2: q(Math.round(p.translate(this.y + n, !1, !0, !1, !0)), -p.len, 2 * p.len)
          };
        [["width", "x"], ["height", "y"]].forEach(function (c) {
          var a = c[0];
          c = c[1];
          var d = c + "1",
            b = c + "2",
            f = Math.abs(e[d] - e[b]),
            g = t && t.lineWidth || 0,
            l = Math.abs(e[d] + e[b]) / 2;
          a = t && t[a];
          r(a) && a < f && (a = a / 2 + g / 2, e[d] = l - a, e[b] = l + a);
          k && ("y" === c && (d = b, b = c + "1"), e[d] += k, e[b] -= k);
        });
        return e;
      };
      g.prototype.haloPath = function (b) {
        if (!b) return [];
        var f = this.shapeArgs || {},
          g = f.x;
        g = void 0 === g ? 0 : g;
        var m = f.y;
        m = void 0 === m ? 0 : m;
        var n = f.width;
        n = void 0 === n ? 0 : n;
        f = f.height;
        f = void 0 === f ? 0 : f;
        return [["M", g - b, m - b], ["L", g - b, m + f + b], ["L", g + n + b, m + f + b], ["L", g + n + b, m - b], ["Z"]];
      };
      g.prototype.isValid = function () {
        return Infinity !== this.value && -Infinity !== this.value;
      };
      return g;
    }(b.seriesTypes.scatter.prototype.pointClass);
    t(b.prototype, {
      dataLabelOnNull: !0,
      moveToTopOnHover: !0,
      ttBelow: !1
    });
    return b;
  });
  r(b, "Series/Heatmap/HeatmapSeries.js", [b["Core/Color/Color.js"], b["Series/ColorMapComposition.js"], b["Series/Heatmap/HeatmapPoint.js"], b["Core/Legend/LegendSymbol.js"], b["Core/Series/SeriesRegistry.js"], b["Core/Renderer/SVG/SVGRenderer.js"], b["Core/Utilities.js"]], function (b, g, r, q, z, t, u) {
    var m = this && this.__extends || function () {
        var b = function (c, a) {
          b = Object.setPrototypeOf || {
            __proto__: []
          } instanceof Array && function (a, b) {
            a.__proto__ = b;
          } || function (a, b) {
            for (var d in b) b.hasOwnProperty(d) && (a[d] = b[d]);
          };
          return b(c, a);
        };
        return function (c, a) {
          function d() {
            this.constructor = c;
          }
          b(c, a);
          c.prototype = null === a ? Object.create(a) : (d.prototype = a.prototype, new d());
        };
      }(),
      n = z.series,
      f = z.seriesTypes,
      v = f.column,
      E = f.scatter,
      B = t.prototype.symbols;
    t = u.extend;
    var w = u.fireEvent,
      p = u.isNumber,
      D = u.merge,
      k = u.pick;
    u = function (e) {
      function c() {
        var a = null !== e && e.apply(this, arguments) || this;
        a.colorAxis = void 0;
        a.data = void 0;
        a.options = void 0;
        a.points = void 0;
        a.valueMax = NaN;
        a.valueMin = NaN;
        return a;
      }
      m(c, e);
      c.prototype.drawPoints = function () {
        var a = this;
        if ((this.options.marker || {}).enabled || this._hasPointMarkers) n.prototype.drawPoints.call(this), this.points.forEach(function (b) {
          b.graphic && (b.graphic[a.chart.styledMode ? "css" : "animate"](a.colorAttribs(b)), null === b.value && b.graphic.addClass("highcharts-null-point"));
        });
      };
      c.prototype.getExtremes = function () {
        var a = n.prototype.getExtremes.call(this, this.valueData),
          b = a.dataMin;
        a = a.dataMax;
        p(b) && (this.valueMin = b);
        p(a) && (this.valueMax = a);
        return n.prototype.getExtremes.call(this);
      };
      c.prototype.getValidPoints = function (a, b) {
        return n.prototype.getValidPoints.call(this, a, b, !0);
      };
      c.prototype.hasData = function () {
        return !!this.processedXData.length;
      };
      c.prototype.init = function () {
        e.prototype.init.apply(this, arguments);
        var a = this.options;
        a.pointRange = k(a.pointRange, a.colsize || 1);
        this.yAxis.axisPointRange = a.rowsize || 1;
        B.ellipse = B.circle;
        a.marker && (a.marker.r = a.borderRadius);
      };
      c.prototype.markerAttribs = function (a, b) {
        var c = a.shapeArgs || {};
        if (a.hasImage) return {
          x: a.plotX,
          y: a.plotY
        };
        if (b && "normal" !== b) {
          var d = a.options.marker || {};
          a = this.options.marker || {};
          a = a.states && a.states[b] || {};
          d = d.states && d.states[b] || {};
          b = (d.width || a.width || c.width || 0) + (d.widthPlus || a.widthPlus || 0);
          a = (d.height || a.height || c.height || 0) + (d.heightPlus || a.heightPlus || 0);
          return {
            x: (c.x || 0) + ((c.width || 0) - b) / 2,
            y: (c.y || 0) + ((c.height || 0) - a) / 2,
            width: b,
            height: a
          };
        }
        return c;
      };
      c.prototype.pointAttribs = function (a, c) {
        var d = n.prototype.pointAttribs.call(this, a, c),
          e = this.options || {},
          f = this.chart.options.plotOptions || {},
          g = f.series || {},
          k = f.heatmap || {};
        f = a && a.options.borderColor || e.borderColor || k.borderColor || g.borderColor;
        g = a && a.options.borderWidth || e.borderWidth || k.borderWidth || g.borderWidth || d["stroke-width"];
        d.stroke = a && a.marker && a.marker.lineColor || e.marker && e.marker.lineColor || f || this.color;
        d["stroke-width"] = g;
        c && "normal" !== c && (a = D(e.states && e.states[c], e.marker && e.marker.states && e.marker.states[c], a && a.options.states && a.options.states[c] || {}), d.fill = a.color || b.parse(d.fill).brighten(a.brightness || 0).get(), d.stroke = a.lineColor || d.stroke);
        return d;
      };
      c.prototype.translate = function () {
        var a = this.options,
          b = a.marker && a.marker.symbol || "rect",
          c = B[b] ? b : "rect",
          e = -1 !== ["circle", "square"].indexOf(c);
        this.generatePoints();
        this.points.forEach(function (d) {
          var f = d.getCellAttributes(),
            g = Math.min(f.x1, f.x2),
            h = Math.min(f.y1, f.y2),
            k = Math.max(Math.abs(f.x2 - f.x1), 0),
            m = Math.max(Math.abs(f.y2 - f.y1), 0);
          d.hasImage = 0 === (d.marker && d.marker.symbol || b || "").indexOf("url");
          e && (h = Math.abs(k - m), g = Math.min(f.x1, f.x2) + (k < m ? 0 : h / 2), h = Math.min(f.y1, f.y2) + (k < m ? h / 2 : 0), k = m = Math.min(k, m));
          d.hasImage && (d.marker = {
            width: k,
            height: m
          });
          d.plotX = d.clientX = (f.x1 + f.x2) / 2;
          d.plotY = (f.y1 + f.y2) / 2;
          d.shapeType = "path";
          d.shapeArgs = D(!0, {
            x: g,
            y: h,
            width: k,
            height: m
          }, {
            d: B[c](g, h, k, m, {
              r: a.borderRadius
            })
          });
        });
        w(this, "afterTranslate");
      };
      c.defaultOptions = D(E.defaultOptions, {
        animation: !1,
        borderRadius: 0,
        borderWidth: 0,
        nullColor: "#f7f7f7",
        dataLabels: {
          formatter: function () {
            var a = this.series.chart.numberFormatter,
              b = this.point.value;
            return p(b) ? a(b, -1) : "";
          },
          inside: !0,
          verticalAlign: "middle",
          crop: !1,
          overflow: "allow",
          padding: 0
        },
        marker: {
          symbol: "rect",
          radius: 0,
          lineColor: void 0,
          states: {
            hover: {
              lineWidthPlus: 0
            },
            select: {}
          }
        },
        clip: !0,
        pointRange: null,
        tooltip: {
          pointFormat: "{point.x}, {point.y}: {point.value}<br/>"
        },
        states: {
          hover: {
            halo: !1,
            brightness: .2
          }
        }
      });
      return c;
    }(E);
    t(u.prototype, {
      axisTypes: g.seriesMembers.axisTypes,
      colorKey: g.seriesMembers.colorKey,
      directTouch: !0,
      getExtremesFromAll: !0,
      parallelArrays: g.seriesMembers.parallelArrays,
      pointArrayMap: ["y", "value"],
      pointClass: r,
      specialGroup: "group",
      trackerGroups: g.seriesMembers.trackerGroups,
      alignDataLabel: v.prototype.alignDataLabel,
      colorAttribs: g.seriesMembers.colorAttribs,
      drawLegendSymbol: q.drawRectangle,
      getSymbol: n.prototype.getSymbol
    });
    g.compose(u);
    z.registerSeriesType("heatmap", u);
    "";
    "";
    return u;
  });
  r(b, "masters/modules/heatmap.src.js", [b["Core/Globals.js"], b["Core/Axis/Color/ColorAxis.js"]], function (b, g) {
    b.ColorAxis = g;
    g.compose(b.Chart, b.Fx, b.Legend, b.Series);
  });
});
