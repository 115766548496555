<div class="modal-header">
	<h4 class="modal-title" translate>Add New Harmonised Brand</h4>
	<i
		class="icon icon-Close2 cursor-pointer"
		(click)="activeModal.dismiss('Cross click')"
	>
	</i>
</div>
<div class="modal-body">
	<label class="mb-2" for="harmonisedName" translate
		>Harmonised Brand Name</label
	>
	<div class="input-container w-100">
		<input
			type="text"
			[formControl]="harmonisedName"
			name="harmonisedName"
			placeholder="Enter name"
			id="harmonisedName"
			class="w-100"
			[ngClass]="{ error: harmonisedName.invalid && (harmonisedName.dirty || harmonisedName.touched) }"
			[disabled]="true"
		/>
		<div
			class="errorLable"
			*ngIf="harmonisedName.invalid && (harmonisedName.dirty || harmonisedName.touched)"
		>
			<span *ngIf="harmonisedName.errors.required" translate
				>Please enter brand name</span
			>
			<span *ngIf="harmonisedName.errors.fieldMessage" translate
				>{{harmonisedName.errors.fieldMessage}}</span
			>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button
		type="button"
		class="btn btn-secondary btn-lg"
		(click)="activeModal.dismiss('Close click')"
		translate
	>
		Cancel
	</button>
	<button
		type="button"
		class="btn btn-primary btn-lg"
		[disabled]="harmonisedName.invalid"
		(click)="[addHarmonisationData(),harmonisedName.reset(),activeModal.close('Close click')]"
		translate
	>
		Add
	</button>
</div>
