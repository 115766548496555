<div class="action-container d-inline-flex align-items-center gap-8">
	<div ngbDropdown class="d-inline-block position-static" display="dynamic">
		<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
			><span><i class="icon icon-Options"></i></span>
		</span>
		<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
			<button
				[ngClass]="{'cursor-pointer':permissions.read_access}"
				*ngIf="col?.meta?.type==='products' && !col?.isShowAction?.discover"
				[disabled]="!permissionsForDiscover.read_access"
				class="dropdown-item"
				(click)="commonFunctions.openDiscover(item?.id, true)"
			>
				<i class="icon icon-Discover"></i>
				<span translate>Discover</span>
			</button>
			<button
				[ngClass]="{'cursor-pointer':permissions.write_access}"
				*ngIf="!col?.isShowAction?.notification"
				[disabled]="!permissions.write_access"
				class="dropdown-item"
				(click)="openNotification()"
			>
				<i class="icon icon-Notification-on"></i>
				<span translate>Create Notification</span>
			</button>
		</div>
	</div>
</div>
