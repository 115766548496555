import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  Input,
  SimpleChanges,
  OnChanges,
  Inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject, Subscription, map, takeUntil } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { NotificationsService } from "@ds-private-layouts/notifications/notifications.service";
import { DOCUMENT } from "@angular/common";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";

@Component({
	selector: "app-filter-top",
	templateUrl: "./filter-top.component.html",
	styleUrls: ["./filter-top.component.scss"]
})
export class FilterTopComponent implements OnInit, OnDestroy, OnChanges {
	public filters;
	public activeFilters: any = [];
	public selectedFilter: any = [];
	public notificationsFilterSubscription: Subscription;
	public filterById: boolean;
	@Output() changedfilter = new EventEmitter<string>();
	@ViewChild("filterIdentifier")
	filterIdentifier: ElementRef;
	@Input() moduleType;
	@Input() isCompetitor;
	public filterListOfKeywordSubscription: Subscription;
	public segmentation_tag = {
		Prime: "OS",
		Reseller: "RS",
		Greyseller: "GS",
		Other: "OT"
	};
	public maxLengthToshow : number = 8;
	private destroy$:Subject<boolean> = new Subject()
	private campaignStatusCount = 0;
	private initialFilters = [];
	constructor(
		public router: Router,
		public dataTransferService: DataTransferService,
		private notificationsService: NotificationsService,
		public commonFunctions: CommonFunctionsService,
		private globalService: GlobalService,
    	@Inject(DOCUMENT) private document: Document
	) {
		this.campaignStatusCheck();
	}

	ngOnInit(): void {
		if(this.moduleType.type === "products") {
			this.maxLengthToshow = 9;
		}
	}

	private campaignStatusCheck(){
		this.globalService.getCampaignStatus().pipe(takeUntil(this.destroy$)).subscribe((res)=>{
			this.campaignStatusCount= res['data']['profiles'];
			if(this.campaignStatusCount){
				this.filters = [...this.initialFilters]
				this.filterById = true;
				this.resetData();
			}
		})
	}
	

	ngOnChanges(changes: SimpleChanges) {
		if (this.moduleType.name == "notification") {
			this.isCompetitor = changes.isCompetitor.currentValue;
			if (this.moduleType.type == "keywords") {
			} else this.getNotificationsFilter();
		} else if (this.moduleType.name == "build") {
			this.getStoreFilters(this.moduleType.subtype);
		}
	}

	getNotificationsFilter() {
		this.filters = [];
		this.notificationsFilterSubscription?.unsubscribe();
		this.notificationsFilterSubscription = this.notificationsService
			.getMetricList(
				this.moduleType.type,
				this.isCompetitor
			).pipe(
				map((res)=>{
					this.initialFilters = [...res['data']]
					if(!this.campaignStatusCount){
						res['data'] = res['data'].filter(item=>![...this.commonFunctions.notificationCampaignRestrictedKPIList].includes(item.name))
					}
					return res
				})
			)
			.subscribe(
				(res: any) => {
					if (res) {
						this.filters = res["data"];
						this.filterById = true;
						this.resetData();
					}
				},
				(err: any) => {
					this.filters = [];
				}
			);
	}

	resetData() {
		this.filters.unshift({ id: -1, name: "All" });
		this.selectedFilter = [];
		this.setActiveKey(this.filterById);
	}

	getFilterHeight() {
		const height = this.filterIdentifier.nativeElement.offsetHeight;
		this.dataTransferService.filterHeight(height/16);
	}

	getStoreFilters(key) {
		this.filters = [];
		this.filters = [
			{ id: 1, name: "Prime" },
			{ id: 2, name: "Reseller" },
			{ id: 3, name: "Greyseller" },
			{ id: 4, name: "Other" }
		];
		this.filterById = false;
		this.resetData();
	}

	setActiveKey(filterById) {
		this.activeFilters = [];
		this.filters.forEach((v, k) => {
			if (k == 0) {
				if (filterById)
					this.activeFilters.push({ value: v.id, isActive: true });
				else {
					this.activeFilters.push({ value: v.name, isActive: true });
				}
			} else {
				if (filterById)
					this.activeFilters.push({ value: v.id, isActive: false });
				else {
					this.activeFilters.push({ value: v.name, isActive: false });
				}
			}
		});
	}

	onSelectFilter(filter, i) {
		if (Object.keys(this.segmentation_tag).includes(filter))
			filter = this.segmentation_tag[filter];
		if (filter == "All" || filter == -1) {
			if (this.activeFilters[0].isActive == false) {
				this.onSelectAllFilter();
			}
		} else {
			this.activeFilters[0].isActive = false;
			let j = this.selectedFilter.findIndex((f) => f === filter);
			if (j !== -1) {
				this.selectedFilter.splice(j, 1);
				this.activeFilters[i].isActive = !this.activeFilters[i].isActive;
				if (this.selectedFilter.length == 0) this.onSelectAllFilter();
			} else {
				this.selectedFilter.push(filter);
				this.activeFilters[i].isActive = !this.activeFilters[i].isActive;
			}
		}
		this.changedfilter.emit(this.selectedFilter);
	}

	onSelectAllFilter() {
		this.selectedFilter = [];
		this.activeFilters[0].isActive = true;
		this.activeFilters.forEach((v, k) => {
			if (k != 0) this.activeFilters[k].isActive = false;
		});
	}

	ngOnDestroy() {
		if (this.notificationsFilterSubscription)
			this.notificationsFilterSubscription.unsubscribe();
		if (this.filterListOfKeywordSubscription)
			this.filterListOfKeywordSubscription.unsubscribe();
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}