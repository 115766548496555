import { Component, Input, OnInit } from "@angular/core";
import { DashboardService } from "@ds-private-layouts/dashboard/dashboard.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import {
	SingleItemModel
} from "@ds-shared/models/dashboard.model";
import { Subject, finalize, takeUntil, Subscription } from "rxjs";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslationService } from "@ds-common-services/utility-services/translation.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";

@Component({
  selector: 'app-common-preferences',
  templateUrl: './common-preferences.component.html',
  styleUrls: ['./common-preferences.component.scss']
})
export class CommonPreferencesComponent implements OnInit {
  	@Input() public showCurrencyFilter: boolean = false;
	@Input() public showHarmonisation: boolean = true;
	@Input() checkResponseSuccess = false;
	private listOfScreensWithNoCurrency : string [] = ['notification','configuration/data-management', 'discover/share-of-category','discover/product-compare', 'discover/store-compare', 'share-of-search']
	public activeModal;
	public response: any;
	dropdownOpenStatus = {
		viewBy: false,
		currency: false
	};
	public filters = {
		harmonise : {
			list: [
				{ name: "Yes", value: true },
				{ name: "No", value: false }
			],
			selected: { name: "No", value: false },
			isChanged: false
		},
		language : {
			list: [
				{ name: "Arabic", code: "ar", rtl: true },
				{ name: "Bahasa", code: "id", rtl: false },
				{ name: "Chinese", code: "zh", rtl: false },
				{ name: "English", code: "en", rtl: false },
				{ name: "French", code: "fr", rtl: false },
				{ name: "Korean", code: "ko", rtl: false },
				{ name: "Thai", code: "th", rtl: false },
				{ name: "Vietnamese", code: "vi", rtl: false }
			],
			selected: '',
			isChanged: false,

		},
		currency: {
			key: "currency",
			multiple: false,
			list: [],
			selected: null,
			dependencyStatus: null,
			placeholder: "Currency",
			loading: false,
			search: null,
			path: "currency",
			query: [],
			clear: false,
			closeOnSelect: true,
			isChanged: false,
			disabled: false
		},
		enablersList: {
			list: [] as SingleItemModel[],
			selectedEnabler: [] as SingleItemModel[],
			placeholder: "Select Enabler",
			isChanged: false,
			isLoading: true,
			enablerId: ''
		}
	};
	private destroy$: Subject<boolean> = new Subject();
	public cleargetCurrencyListCall: Subscription;
	public userData;
	public latestData: { isLoading: boolean, data: string, subscription: Subscription } = {
		isLoading: false,
		data: "",
		subscription: null
	}
	constructor(
		private dashboardService: DashboardService,
		private localStorageService: LocalStorageService,
		private notificationService: NotificationService,
		public commonFunctions: CommonFunctionsService,
		public dataTransferService: DataTransferService,
		public route: ActivatedRoute,
		private modalService: NgbModal,
		private translationService: TranslationService,
		public translate: TranslateService,
		public router: Router,
		private globalService: GlobalService
	) {
		this.setUpdatedLang();
		this.showHarmonisation = !['scorecard','share-of-search','discover'].some(item => this.router.url.includes(item))
	}

	ngOnInit(): void {
		if(!this.router.url.includes('scorecard')) this.showCurrencyFilter = !!this.listOfScreensWithNoCurrency.find(ele => this.router.url.includes(ele));
			!this.showCurrencyFilter  && this.getCurrencyList();
			if (this.localStorageService.get("user")) {
				this.userData = JSON.parse(
					this.commonFunctions.getDecodedData(
						this.localStorageService.get("user")
					)
				);
				if (this.userData?.data?.enabler_id) {
					this.getEnablerList();
					if (this.userData?.data?.customer)
					this.filters['enablersList'].enablerId = this.userData?.data?.customer?.id;
					this.filters['enablersList'].selectedEnabler =
						this.userData?.data?.customer?.attributes?.name;
				}
				this.filters['currency'].selected = JSON.parse(
					this.commonFunctions.getDecodedData(
						this.localStorageService.get(
							this.localStorageService.currencyListStorageKey
						)
					)
				);
				this.commonFunctions.currencySelected = this.filters['currency'].selected;
			}
			if (this.localStorageService.get("isHarmonisedView")) {
				this.filters['harmonise'].selected = JSON.parse(
					this.commonFunctions.getDecodedData(
						this.localStorageService.get("isHarmonisedView")
					)
				);
			}
		}
	// get APIS 
	public getEnablerList() {
		this.filters['enablersList'].isLoading = true;
		this.dashboardService
			.getEnablerList()
			.pipe(
				finalize(() => (this.filters['enablersList'].isLoading = false)),
				takeUntil(this.destroy$)
			)
			.subscribe(
				(res) => {
					if (!res) return;
					this.filters['enablersList'].list = res["data"]
				},
				() => {}
			);
	}
	public getCurrencyList(): void {
		this.filters['currency'].loading = true;
		this.cleargetCurrencyListCall?.unsubscribe();
		this.cleargetCurrencyListCall = this.dashboardService
			.getCurrencyList()
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					if (res) {
						this.filters['currency'].list = res.data;
						this.filters['currency'].loading = false;
						if (!this.filters['currency'].selected) {
							this.filters['currency'].selected = JSON.parse(
								this.commonFunctions.getDecodedData(
									this.localStorageService.get(
										this.localStorageService.currencyListStorageKey
									)
								)
							);
							this.commonFunctions.currencySelected = this.filters['currency'].selected;
						}
					}
				},
				(err: any) => {
					this.filters['currency'].list = [];
					this.filters['currency'].loading = false;
				}
			);
	}

	// Change Filter API
	onChangeFilter(event, type) {
		if (type === 'language') {
			this.filters['language'].selected = event;
			this.filters['language'].isChanged = true;
		}
		if (type === 'harmonise') {
			this.filters['harmonise'].selected = event;
			this.filters['harmonise'].isChanged = true;
		}
		if (type === 'currency') {
			this.filters['currency'].selected = event;
			this.filters['currency'].isChanged = true;
		}
		if (type === 'enabler') {
			this.filters['enablersList'].enablerId = event.id;
			this.filters['enablersList'].isChanged = true;
			this.filters['currency'].isChanged = true;
			this.filters['currency'].loading = true;
			this.filters['currency'].list = this.filters['enablersList'].list.filter((ele)=>ele.id === event.id)[0].attributes.currency_list;
			this.filters['currency'].loading =false;
			this.filters['currency'].selected = this.filters['currency'].list[0]
		}
	}

	callUserAPi () {
		const userData = this.getUserData();
		const user = userData["data"];
		const payload = {
			id: user.id,
			email: user.email,
		};
		if( this.filters['harmonise'].isChanged ) payload['harmonised_view'] = this.filters['harmonise'].selected.value;
		if( this.filters['currency'].isChanged ) payload['currency_id'] = this.filters['currency'].selected.id;
		if( this.filters['enablersList'].isChanged ) payload['enabler_id'] = this.filters['enablersList'].enablerId
		this.dashboardService
			.editUserInfo(payload)
			.pipe(
				finalize(() => (this.filters['currency'].disabled = false)),
			)
			.subscribe((response: any) => {
				if (response) {
					this.response = response;
					if (response['context_code'] === 1000) {
						this.localStorageService.set(
							"user",
							this.commonFunctions.getEncodedData(JSON.stringify(response))
						);
						if( this.filters['currency'].isChanged) {
							userData["currency"] = this.filters['currency'].selected;
							this.commonFunctions.currencySelected = this.filters['currency'].selected;
								this.localStorageService.set(
									this.localStorageService.currencyListStorageKey,
									this.commonFunctions.getEncodedData(
										JSON.stringify(this.commonFunctions.currencySelected)
									)
								)
							this.globalService.getMarketplaceConfiguration();
						}
						if (this.filters['enablersList'].isChanged) userData["enabler_id"] = this.filters['enablersList'].enablerId;

						let summary = this.response['summary'];

						if (this.filters['language'].isChanged) {
							this.commonFunctions.setLang(this.filters['language'].selected);
							this.setUpdatedLang();
							summary = 'User preferences were updated successfully.'
						}
						
						this.notificationService.setMessage(this.response["context_code"], summary)
						const scorecardUrlArray = ['/scorecard/product/', '/scorecard/brand/', '/scorecard/store/', '/scorecard/category/']
						if(this.filters['harmonise'].isChanged) {
							this.commonFunctions.clearScorecardLocalFilters();
							const checkifScorcardPage = scorecardUrlArray.filter((item)=> this.route['_routerState']['snapshot']['url'].includes(item))
							if(checkifScorcardPage.length > 0) {
								const scorecardProfile = this.route['_routerState']['snapshot']['url'].split("/")[2]
								this.router.navigate([`/scorecard/${scorecardProfile}`])
							}
							else {
								this.refreshPage();
							}
						}
						else {
							this.refreshPage();
						}
						this.filters['harmonise'].isChanged = false;
						this.filters['language'].isChanged = false;
						this.filters['currency'].isChanged = false;
						this.filters['enablersList'].isChanged = false;
						this.activeModal.close();
					}
					else {
						this.filters['harmonise'].isChanged = false;
						this.filters['language'].isChanged = false;
						this.filters['currency'].isChanged = false;
						this.filters['enablersList'].isChanged = false;
						this.notificationService.setMessage(this.response["context_code"], this.response['summary'])
					}
					
				}
			},
			()=> {
				this.notificationService.setMessage(1100, this.commonFunctions.getSpecificTranslation('The preference update was unsuccessful.'))
				this.filters['harmonise'].isChanged = false;
				this.filters['language'].isChanged = false;
				this.filters['currency'].isChanged = false;
				this.filters['enablersList'].isChanged = false;
			}
			);
	}

	public refreshPage() {
		const URL = decodeURIComponent(this.router.url);
		this.router
			.navigateByUrl("/user-management/account-overview", {
				skipLocationChange: true
			})
			.then(() => {
				this.router.navigate([URL]).then(() => {});
			});
	}

	public getUserData() {
		const userData = JSON.parse(
			this.commonFunctions.getDecodedData(this.localStorageService.get("user"))
		);
		return userData;
	}
	public onGlobalConfigOpen(content): void {
		if (this.localStorageService.get("user")) {
			this.userData = JSON.parse(
				this.commonFunctions.getDecodedData(
					this.localStorageService.get("user")
				)
			);
				if ("harmonised_view" in this.userData?.data) {
                	this.filters['harmonise'].selected = this.filters['harmonise'].list.find((item) => item.value === this.userData?.data?.harmonised_view);
            	}
				if (this.userData?.data?.enabler_id) {
					if (this.userData?.data?.customer)
					this.filters['enablersList'].enablerId = this.userData?.data?.customer?.id;
					this.filters['enablersList'].selectedEnabler =
						this.userData?.data?.customer?.attributes?.name;
				}
		}
		this.filters['currency'].selected = JSON.parse(
			this.commonFunctions.getDecodedData(
				this.localStorageService.get(
					this.localStorageService.currencyListStorageKey
				)
			)
		);
		this.filters['language'].selected = this.commonFunctions.getLang();
		this.filters['harmonise'].isChanged = false;
		this.filters['language'].isChanged = false;
		this.filters['currency'].isChanged = false;
		this.filters['enablersList'].isChanged = false;

		this.activeModal = this.modalService.open(content, {
			centered: true,
			windowClass: "customModal globalConfigmModal"
		});
	}

	setUpdatedLang() {
		const lang = this.translationService.getBrowsersLocals();
		this.translate.use(`${lang.code}/locale`);
		this.filters['language'].selected = this.commonFunctions.getLang();
	}

	onApply() {
		if (this.filters['harmonise'].isChanged || this.filters['currency'].isChanged || this.filters['enablersList'].isChanged) this.callUserAPi()
		else if (this.filters['language'].isChanged) {
			this.commonFunctions.setLang(this.filters['language'].selected);
			this.notificationService.setMessage(
				1000,
				this.commonFunctions.getSpecificTranslation(
					`User preferences were updated successfully.`
				)
			);
			this.setUpdatedLang();
			this.filters['language'].isChanged = false;
			this.activeModal.close();
		}
		if(this.filters['currency'].isChanged) {
			const existingGlobalStorage  = this.localStorageService.get(this.localStorageService.globalFilterStorageKey);
			this.localStorageService.set(
				this.localStorageService.globalFilterStorageKey,
				{
					...existingGlobalStorage,
					timeline: this.localStorageService.get(
						this.localStorageService.globalFilterStorageKey
					)?.timeline,
					marketplace: []
				}
			);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	public onOpenCloseDropdown(type) {
		this.dropdownOpenStatus[type] = !this.dropdownOpenStatus[type];
	}
  }