import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-common-services/http-services/http-request.service';
import { PAYLOAD } from '@ds-shared/enums/common.enum';
import { Service, Resource } from 'ngx-jsonapi';

export class Stores extends Resource {
  public attributes = {
    type: '',
    id: '',
  };
}
@Injectable({
  providedIn: 'root'
})
export class StoreListService extends Service<Stores>{
  public resource = Stores;
  public type = 'stores-list';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class StoreListExtraService extends Service<Stores>{
  public resource = Stores;
  public type = 'scorecard';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class ProductCatalogueService extends Service<Stores>{
  public resource = Stores;
  public type = 'products';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class ProductCatalogueExtraService extends Service<Stores>{
  public resource = Stores;
  public type = 'store-overview-products';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root',
})
export class BrandsAssociatedService extends Service<Stores> {
  public resource = Stores;
  public type = 'segmentation-selection';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class StoreScorecardService extends Service<Stores> {

  constructor(public http: HttpRequestService) {
    super();
  }

  public updateSegment(payload: any) {
		return this.http.callPostApi(`scorecard/update-store-segment`, payload);
  }
  getStoreOverviewData(storeId: any, filterDate: any) {
    return this.http.callGetApi(`scorecard/store-overview/banner?store_id=${storeId}${filterDate?.startDate ? `&start_date=${filterDate?.startDate}` : ''}${filterDate?.endDate ? `&end_date=${filterDate?.endDate}` : ''}`);
  }
  public getFollowersData(storeId: any, filterDate: any,optionalParams?){
    return this.http.callGetApi(`scorecard/store-followers?store_id=${storeId}${filterDate?.startDate ? `&start_date=${filterDate?.startDate}` : ''}${filterDate?.endDate ? `&end_date=${filterDate?.endDate}` : ''}`);
  }
  public getRatingsData(storeId: any, filterDate: any,optionalParams?) {
    return this.http.callGetApi(`scorecard/store-ratings?store_id=${storeId}${filterDate?.startDate ? `&start_date=${filterDate?.startDate}` : ''}${filterDate?.endDate ? `&end_date=${filterDate?.endDate}` : ''}`);
  }
  public getContentQualityData(storeId: any, filterDate: any,optionalParams?) {
    return this.http.callGetApi(`scorecard/store-content-quality?store_id=${storeId}${filterDate?.startDate ? `&start_date=${filterDate?.startDate}` : ''}${filterDate?.endDate ? `&end_date=${filterDate?.endDate}` : ''}`);
  }
  public checkCompetitorStatus(storeId: any) {
    return this.http.callGetApi(`scorecard/competitor-check?filter[store_id]=${storeId}`);
  }
}
