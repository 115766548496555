<div class="tag-value" *ngIf="commonFunctions.getMappedObject(item,map)?.length;else noData">
    <span class="tag-0">{{commonFunctions.getMappedObject(item,map)[0]}}</span>
    <div ngbDropdown class="d-inline-block position-static" display="dynamic"
        *ngIf="commonFunctions.getMappedObject(item,map)?.length > 1">
        <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer">
            <span class="note-text tagDropdown">+{{commonFunctions.getMappedObject(item,map).length - 1}}</span>
        </span>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="tagsContent">
            <div class="d-flex">
                <ng-template ngFor let-tag [ngForOf]="commonFunctions.getMappedObject(item,map)" let-i="index">
                    <span class="tags" *ngIf="i > 0">{{tag}}</span>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<ng-template #noData>
    <div>-</div>
</ng-template>