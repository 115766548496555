import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noValueChecker',
  pure: true
})
export class NoValueCheckerPipe implements PipeTransform {

  transform(value: string | number, ...args: unknown[]): unknown {
    if(value===null || value===undefined || (typeof value==='string' && !value.trim().length)){
      return '-';
    }
    return value;
  }

}
