<div class="select-container" [ngStyle]="data.style">
    <ng-select [items]="data.list" [notFoundText]="'No Data Found' | translate" [ngStyle]="data.style" [disabled]="data.disabled"
        [loading]="data.loading" [loadingText]="'Loading...' | translate" [multiple]="data.multiple"
        [closeOnSelect]="data.closeOnSelect" [clearable]="data.clear"
        [bindLabel]="data.bindLabel?data.bindLabel:'attributes.name'" placeholder="{{data.placeholder | translate}}" (change)="onSelect()"
        [(ngModel)]="data.selected" (close)="closeMultiDropdown()" [searchFn]="searchOnline?customSearchFn:null" (search)="onMultiSearch($event)" (scrollToEnd)="pageChange.emit()">
        <ng-container *ngIf="data.multiple;else singleSelection">
            <ng-template ng-multi-label-tmp><span class="placeholder-temp">{{data.search?.length ? '' :
                ((data.placeholder | translate) + " (" + data.selected?.length + ")")}}</span></ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" *ngIf="data.multiple">
            <div class="multi-select-option-container multi-text-wrap">
                <span class="item-name" translate>{{item?.attributes?.name | translate}}</span>
                <i class="icon icon-Check" *ngIf="checkSelectedOption(item)"></i>
            </div>
        </ng-template>
        </ng-container>
        <ng-template #singleSelection>
                <ng-template ng-label-tmp let-item="item">
                    <span translate>{{item?.attributes?.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span>{{item?.attributes?.name | translate}}</span>
                </ng-template>
        </ng-template>
    </ng-select>
</div>