import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
@Component({
	selector: "app-keyword-trends",
	templateUrl: "./keyword-trends.component.html",
	styleUrls: ["./keyword-trends.component.scss"]
})
export class KeywordTrendsComponent implements OnInit {
	@Input() actionData: any = [];
	latestRank = [];
	updatedDate = [];
	max: number = 0;
	constructor() {}

	ngOnInit(): void {
		let maxValue = 0;
		for (let i = 0; i < this.actionData?.length; i++) {
			this.latestRank[i] = Number(this.actionData?.[i]?.rank);
			if (maxValue < this.latestRank[i]) maxValue = this.latestRank[i];
			this.updatedDate[i] = this.actionData?.[i]?.date;
		}
	}

	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options = {
		title: {
			text: ""
		},
		chart: {
			borderRadius: 2,
			backgroundColor: "#ffffff",
			type: "line"
		},
		series: [
			{
				data: [12, 6, 8, 0, 13, 8, 1, 3, 6, 1],
				// data:this.latestRank,
				type: "line",
				color: "#178fff"
			}
		],
		xAxis: {
			categories: [
				"Mon",
				"Tue",
				"Wed",
				"Thu",
				"Fri",
				"Sat",
				"Sun",
				"asd",
				"zxc",
				"qwe"
			],

			// categories:this.updatedDate,
			visible: false,
			labels: {
				enabled: false,
				style: {
					fontSize: "6.25rem",
					color: "#7F8FA4"
				}
			}
		},
		plotOptions: {
			series: {
				shadow: { color: "#dceafb" },
				lineWidth: 2,
				showInLegend: false,
				marker: {
					enabled: false
				},
				states: {
					hover: {
						enabled: false
					}
				}
			}
		},
		tooltip: {
			enabled: false,
			borderWidth: 2,
			borderColor: "#1C1953",
			valueSuffix: " millions"
		},
		credits: {
			enabled: false
		},
		yAxis: {
			min: 0,
			gridLineWidth: 0,
			title: {
				text: ""
			},
			labels: {
				enabled: false,
				style: {
					fontSize: "6.25rem",
					color: "#7F8FA4"
				}
			}
		}
	};
}
