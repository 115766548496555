import { Injectable } from "@angular/core";
import { HttpRequestService } from "@ds-common-services/http-services/http-request.service";
import { forkJoin } from "rxjs";
import { Service, Resource } from "ngx-jsonapi";

export class UserManagement extends Resource {
	public attributes = {
		type: "",
		id: ""
	};
}
@Injectable({
	providedIn: "root"
})
export class AllUserService extends Service<UserManagement> {
	public resource = UserManagement;
	public type = "team-members";
	public ttl = 1;
}

@Injectable({
	providedIn: "root"
})
export class RolesService extends Service<UserManagement> {
	public resource = UserManagement;
	public type = "roles";
	public ttl = 1;
}

@Injectable({
	providedIn: 'root'
})
export class BulkActivityService extends Service<UserManagement>{
	public resource = UserManagement;
	public type = 'get-and-delete-bulk-activities';
	public ttl = 1;
}

@Injectable({
	providedIn: "root"
})
export class UserManagementService extends Service<UserManagement> {
	constructor(public http: HttpRequestService) {
		super();
	}

	createTeamMember(data: any) {
		return this.http.callPostApi("user", data);
	}
	activateDeactivateTeamMember(data) {
		return this.http.callPutApi("user/enable-disable", data);
	}
	deleteTeamMember(id) {
		return this.http.callDeleteApi("user/" + id);
	}
	getUserInfo(id?) {
		if (id != undefined) {
			return this.http.callGetApi("user?user_id=" + id);
		} else {
			return this.http.callGetApi("user");
		}
	}
	changePassword(data) {
		return this.http.callPutApi("change-password", data);
	}
	editUserInfo(data) {
		return this.http.callPutApi("user", data);
	}
	createRole(data: any) {
		return this.http.callPostApi("roles", data);
	}
	updateRole(data: any, roleId: any) {
		data["role_id"] = roleId;
		return this.http.callPutApi("roles", data);
	}
	getRoleInfo(roleId: any) {
		return this.http.callGetApi(`role/${roleId}`);
	}
	getRolesList() {
		return this.http.callGetApi("roles");
	}
	deleteRole(roleId: any) {
		return this.http.callDeleteApi(`roles/${roleId}`);
	}
	getUserRoleInfo() {
		return this.http.callGetApi("role");
	}
	updatePassword(data: any) {
		return this.http.callPutApi("user/change-password", data);
	}
	getUserProfileList(id) {
		return this.http.callGetApi("scorecard/user-profile-list?id=" + id);
	}
	moveProfile(data: any) {
		return this.http.callPutApi("scorecard/move-profiles", data);
	}
	getUserSettings() {
		const getPDPLimit = this.http.callGetApi("get-pdp-limit");
		const getUserSetting = this.http.callGetApi("get-settings");
		return forkJoin([getPDPLimit, getUserSetting]);
	}
	pollTaskStatus() {
		return this.http.callGetApi('common/get-and-delete-bulk-activities?filter[track_status]=true');
	}
	deleteBulkActivity(id) {
		return this.http.callDeleteApi(`common/get-and-delete-bulk-activities?id=${id}`);
	}
	public bulkActivityExcel(id) {
		return this.http.getFileAsBlob(`common/get-bulk-activity-excel?id=${id}`)
	}
}
