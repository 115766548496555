import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service';
import { ProfileRedirectionModel } from '@ds-models/build-profiles.model';
import { ProfileInformationService } from '@ds-private-layouts/scorecard/profile-information/profile-information.service';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { TranslationService } from '@ds-common-services/utility-services/translation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-insight',
  templateUrl: './insight.component.html',
  styleUrls: ['./insight.component.scss']
})
export class InsightComponent implements OnInit, OnDestroy {
  public profiles: any[] = [];
  public isClose: boolean = false;
  public dashboardInfo: string = '';
  public profilesSubscription: Subscription;
  public selectedProfile: any;
  public pageSize: number = 10;
  public pageNumber: number = 1;
  public searchedProfile: string = null;
  public selectedData: any = null;
  public currencySubscription: Subscription;
  public currencyData: Subscription;
  private redirectionDataFromOuterModule: ProfileRedirectionModel;
  public loadedProfileData: boolean = false;
  public tagsSubject$ = new Subject<string>();
  public $destroy: Subject<boolean> = new Subject();
  public initialProfileList = [];
  public initialPageNumber = 1;
  public isProfileSelected: boolean = false;
  constructor(private profileInformationService: ProfileInformationService, public router: Router, private dataTransferService: DataTransferService, public commonFunctions: CommonFunctionsService, private translate: TranslateService, public translationService: TranslationService) {
    this.searchInit();
    this.setUpdatedLang();
  }
  searchInit() {
    this.tagsSubject$
      .pipe(
        tap((term) => {
          this.searchedProfile = term;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.$destroy)
      )
      .subscribe(
        () => {
          this.pageNumber = 1;
          this.getProfiles();
        },
        (err) => { }
      );
  }

  ngOnInit(): void {
    this.redirectionDataFromOuterModule = this.commonFunctions.getFilterFeed();
    this.getProfiles();
    this.dashboardInfo = 'Have a glance at the market performance, competitor movements, and your online merchandising'
    this.clearQueryParams();
    this.checkForOuterModuleData(this.redirectionDataFromOuterModule);
    this.dataTransferService.insightsFirstProfieSelector$.subscribe((res) => {
      if (res && this.profiles?.length) {
        this.selectedData = this.profiles[0]
        this.selectedProfile = this.selectedData.attributes?.name;
        this.isProfileSelected = true;
      }
    });
  }

  getProfiles() {
    this.loadedProfileData = true;
    this.profilesSubscription?.unsubscribe();
    this.profilesSubscription = this.profileInformationService.getProfiles(this.pageSize, this.pageNumber, this.searchedProfile).subscribe(res => {
      if (res) {
        if (this.searchedProfile == null) {
          this.profiles = [...this.profiles, ...res['data']];
          if (!this.selectedData) {
            this.checkForOuterModuleData(this.redirectionDataFromOuterModule)
          }
          if (!this.currencyData && this.profiles.length && this.selectedData) {
            this.getDashboardCurrencyData();
          }
        }
        else {
          this.profiles = res['data'];
        }
        this.pageNumber = res['meta']['next_page'];
        this.loadedProfileData = false;
        if (res['meta']['current_page'] && !this.initialProfileList.length) {
          this.initialProfileList = JSON.parse(JSON.stringify(this.profiles));
          this.initialPageNumber = this.pageNumber;
        }
      }
    }, (err) => {
      this.profiles = [];
      this.initialProfileList = [];
      this.selectedProfile = null;
      this.searchedProfile = null;
      this.pageNumber = 1;
      this.initialPageNumber = 1;
      this.loadedProfileData = false;
    });
  }

  onSearchScroll() {
    if (this.pageNumber) {
      this.getProfiles();
    }
  }

  clearQueryParams() {
    this.router.navigate([], {
      queryParams: {
        'segmentationPage': null,
        'segmentationPageSize': null,
        'watchListPage': null,
        'watchListPageSize': null,
        'scrappedProductPage': null,
        'scrappedProductPageSize': null,
      },
      queryParamsHandling: 'merge'
    })
  }

  public checkForOuterModuleData(redirectionData) {
    if (redirectionData) {
      this.isProfileSelected = true;
      this.selectedProfile = this.redirectionDataFromOuterModule?.attributes?.name;
      this.selectedData = this.redirectionDataFromOuterModule;
    } else {
      this.isProfileSelected = false;
    }
    this.commonFunctions.setFilterFeed(this.selectedData);
  }

  onProfileSelect(eve) {
    if (eve) {
      this.selectedData = eve;
      this.selectedProfile = eve?.attributes?.name;
      this.searchedProfile = null;
      this.clearQueryParams();
      this.getDashboardCurrencyData();
      this.commonFunctions.setFilterFeed(this.selectedData);
    }
  }

  onSearchProfile(ev) {
    this.tagsSubject$.next(ev.term);
  }
  getDashboardCurrencyData() {
    this.currencySubscription?.unsubscribe();
    this.currencySubscription = this.profileInformationService.getDashboardCurrencyData(this.selectedData?.id, this.selectedData?.attributes?.is_customer).subscribe((res: any) => {
      if (res) {
        this.currencyData = res?.data;
        this.dataTransferService.senddashboardCurrencyData(res?.data);
      }
    })
  }

  onClose() {
    this.isClose = !this.isClose;
  }

  onProfileDropdownClose() {
    this.profiles = this.initialProfileList;
    this.pageNumber = this.initialPageNumber;
    this.searchedProfile = null;
  }
  setUpdatedLang() {
    let lang = this.translationService.getBrowsersLocals();
    this.translate.use(`${lang.code}/locale`);
  }

  ngOnDestroy(): void {
    if (this.profilesSubscription) this.profilesSubscription.unsubscribe();
    this.currencySubscription?.unsubscribe();
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
    this.tagsSubject$.next(null);
    this.tagsSubject$.unsubscribe();
    this.dataTransferService.guideEnableIndicator = false;
  }

}
