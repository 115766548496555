import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-face',
  templateUrl: './product-face.component.html',
  styleUrls: ['./product-face.component.scss']
})
export class ProductFaceComponent implements OnInit {
  @Input() product;
  public currencyDetails;
  private destroy$: Subject<boolean> = new Subject()
  constructor(public commonFunctions: CommonFunctionsService, private router: Router, public dataTransferService: DataTransferService) {
    this.currencyDetails = this.commonFunctions.currencySelected.attributes;
    this.getCurrencyForSpecificComponents();
   }

  ngOnInit(): void {
  }

  private getCurrencyForSpecificComponents(){
    this.dataTransferService.dashboardCurrencyData$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if(this.router.url.includes('profile-information')) this.currencyDetails = res;
    });
  }

}
