import { Component, OnInit, Input } from "@angular/core";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";

@Component({
	selector: "app-segmentation-grid",
	templateUrl: "./segmentation-grid.component.html",
	styleUrls: ["./segmentation-grid.component.scss"]
})
export class SegmentationGridComponent implements OnInit {
	@Input() items;
	@Input() moduleType;

	constructor(public commonFunctions: CommonFunctionsService) {}

	ngOnInit(): void {}
}
