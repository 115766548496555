<div class="d-flex justify-content-between align-items-center">
	<div class="matrix-roles-header text-align-right-rtl" translate>
		{{actionType === "edit"?'Update Role':'Create New Role'}}
	</div>
	<i
		class="icon icon-Close2 cursor-pointer"
		placement="left"
		ngbTooltip="Close"
		tooltipClass="info-custom-tooltip"
		(click)="onCloseAction()"
	></i>
</div>
<form
	[formGroup]="rolesForm"
	class="rolesForm text-align-right-rtl"
	(ngSubmit)="onSubmit()"
	(keydown.enter)="$event.preventDefault()"
>
	<div class="matrix-roles-container">
		<div class="input-container w-100">
			<label for="roleName" translate>Role Name <sup>*</sup></label>
			<input
				type="text"
				class="w-100"
				id="roleName"
				placeholder=""
				autocomplete="off"
				[ngClass]="{ error: rolesForm.controls['role_name'].invalid && (rolesForm.controls['role_name'].dirty || rolesForm.controls['role_name'].touched || submitted) }"
				formControlName="role_name"
			/>
			<div
				class="errorLable"
				*ngIf=" rolesForm.controls['role_name'].invalid && (rolesForm.controls['role_name'].dirty || rolesForm.controls['role_name'].touched || submitted) "
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="rolesForm.controls['role_name'].errors.required" translate
					>Please enter role name</span
				>
				<span
					*ngIf="rolesForm.controls['role_name'].errors.fieldMessage"
					translate
					>{{rolesForm.controls['role_name'].errors.fieldMessage}}</span
				>
			</div>
		</div>
		<div class="module-access-container">
			<table>
				<thead>
					<tr>
						<th translate>Select Access Rights</th>
						<th translate>READ ONLY</th>
						<th translate>EXPORT/READ</th>
						<th translate>FULL ACCESS</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let code of moduleCodes">
						<td *ngFor="let key of accessMatrixKeys;let i=index">
							<div *ngIf="i===0" translate>
								{{moduleNameMapping[this.accessModuleMapping.get(code)[key]]}}
							</div>
							<div class="d-flex align-items-center justify-content-center">
								<div
									*ngIf="i>0"
									class="check-container"
									(click)="i > 0 && updateAccessModuleMapping(code,key)"
									[ngClass]="{'active': accessModuleMapping.get(code)[key],'disabled':((accessModuleMapping.get(code)['write_access'] || accessModuleMapping.get(code)['export_access']) && key==='read_access') || (accessModuleMapping.get(code)['full_access'] && key!=='full_access')}"
								>
									<i class="icon icon-Tick"></i>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="roles-footer">
			<button
				class="btn btn-secondary btn-lg"
				type="button"
				(click)="onCloseAction()"
				translate
			>
				Cancel
			</button>
			<button
				class="btn btn-primary btn-lg"
				type="submit"
				[disabled]="checkUpdate()"
				translate
			>
				{{ actionType === "edit" ? "Update Role" : "Create Role" }}
			</button>
		</div>
	</div>
</form>
