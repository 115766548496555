<div class="header-container text-align-right-rtl">
	<span translate>Edit Personal Information</span>
</div>
<form
	[formGroup]="userForm"
	class="userForm text-align-right-rtl"
	(ngSubmit)="onSubmit()"
	(keydown.enter)="$event.preventDefault()"
>
	<div class="d-flex edit-image">
		<div class="avatar-top">
			<div class="avatar">
				<img src="assets/images/User Avatar.svg" alt="blank" />
			</div>
		</div>
		<div
			class="d-inline-block align-self-end icon-title-text upload-photo"
			translate
		>
			<i class="icon icon-Camera" placement="left"></i>
			<span class="upload-text" translate>Upload Photo</span>
		</div>
	</div>
	<div class="row">
		<div class="input-container col-6">
			<label for="disabled" translate>First Name <sup>*</sup></label>
			<input
				type="text"
				placeholder="{{'Enter First Name' | translate}}"
				formControlName="first_name"
				[ngClass]="{error:userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched || submitted)}"
			/>
			<div
				class="errorLable"
				*ngIf="userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="userForm.controls['first_name'].errors.required" translate
					>Please Enter Name</span
				>
				<span
					*ngIf="userForm.controls['first_name'].errors.fieldMessage"
					translate
					>{{userForm.controls['first_name'].errors.fieldMessage}}</span
				>
			</div>
		</div>
		<div class="input-container col-6">
			<label translate>Last Name <sup>*</sup></label>
			<input
				type="text"
				placeholder="{{'Enter Last Name' | translate}}"
				formControlName="last_name"
				[ngClass]="{error:userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched || submitted)}"
			/>
			<div
				class="errorLable"
				*ngIf="userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="userForm.controls['last_name'].errors.required" translate
					>Please Enter Last Name</span
				>
				<span
					*ngIf="userForm.controls['last_name'].errors.fieldMessage"
					translate
					>{{userForm.controls['last_name'].errors.fieldMessage}}</span
				>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="input-container col-6">
			<label translate>Email Id <sup>*</sup></label>
			<input
				type="text"
				autocomplete="new-email"
				name="teamMemberEmail"
				placeholder="{{'Enter Email' | translate}}"
				class="disabled"
				formControlName="email"
				[ngClass]="{error:userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched || submitted)}"
			/>
			<div
				class="errorLable"
				*ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched || submitted)"
			>
				<i class="icon icon-Error"></i>
				<span *ngIf="userForm.controls['email'].errors.required" translate
					>Please Enter Email</span
				>
				<span *ngIf="userForm.controls['email'].errors.email" translate
					>Enter valid email address</span
				>
			</div>
		</div>
		<div class="input-container contact-container col-6">
			<label translate>Contact <sup>*</sup></label>
			<span class="plus">+</span>
			<input
				pattern="^(?=.*\d)(?=.*[1-9]).{2,3}$"
				maxlength="3"
				type="text"
				class="countryCode"
				(keypress)="numberOnly($event)"
				(paste)="onPasswordPaste()"
				formControlName="countryCode"
				(keypress)="noSpace($event)"
				[ngClass]="{error:userForm.controls['countryCode'].invalid && (userForm.controls['countryCode'].dirty || userForm.controls['countryCode'].touched || submitted) || userForm.controls['contact'].invalid && (userForm.controls['contact'].dirty || userForm.controls['contact'].touched)}"
			/>
			<input
				pattern="^(?=.*\d)(?=.*[1-9]).{6,15}$"
				maxlength="15"
				type="text"
				(keypress)="numberOnly($event)"
				(paste)="onPasswordPaste()"
				placeholder="{{'Enter Contact' | translate}}"
				formControlName="contact"
				(keypress)="noSpace($event)"
				class="contact"
				[ngClass]="{error:userForm.controls['contact'].invalid && (userForm.controls['contact'].dirty || userForm.controls['contact'].touched || submitted) || userForm.controls['countryCode'].invalid && (userForm.controls['countryCode'].dirty || userForm.controls['countryCode'].touched)}"
			/>
			<div
				class="errorLable"
				*ngIf="userForm.controls['contact'].invalid && (userForm.controls['contact'].dirty || userForm.controls['contact'].touched || submitted) || userForm.controls['countryCode'].invalid && (userForm.controls['countryCode'].dirty || userForm.controls['countryCode'].touched)"
			>
				<i class="icon icon-Error"></i>
				<span translate>Please Enter Valid Contact</span>
			</div>
		</div>
	</div>
	<!-- <div class="row">
        <div class="input-container address-container pe-4 col-6">
            <label translate>Address <sup>*</sup></label>
            <input type="text" placeholder="{{'Enter Address' | translate}}" formControlName="address" class="address disabled"
                [ngClass]="{error:userForm.controls['address'].invalid && (userForm.controls['address'].dirty || userForm.controls['address'].touched || submitted) }">
            <div class="errorLable"
                *ngIf="userForm.controls['address'].invalid && (userForm.controls['address'].dirty || userForm.controls['address'].touched || submitted)">
                <i class="icon  icon-Error"></i>
                <span translate>Please Enter Valid Address</span>
            </div>
        </div>
    </div> -->

	<div class="store-footer">
		<button
			class="btn btn-secondary btn-lg"
			type="button"
			(click)="onCloseAction()"
			translate
		>
			Cancel
		</button>
		<button
			class="btn btn-primary btn-lg"
			type="submit"
			[disabled]="checkUpdate()"
			translate
		>
			Submit
		</button>
	</div>
</form>
