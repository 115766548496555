import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject} from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Options} from '@angular-slider/ngx-slider';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';

@Component({
  selector: 'app-price-range-filter',
  templateUrl: './price-range-filter.component.html',
  styleUrls: ['./price-range-filter.component.scss']
})
export class PriceRangeFilterComponent implements OnInit {

  @Input() public priceRangeFilters: { min_price: number, max_price: number };
  public priceChangeEmitter: Subject<Object> = new Subject();
  public minPrice: number;
  public maxPrice: number;
  public defaultMinPrice: number;
  public defaultMaxPrice: number;
  public priceChangeMinControl: UntypedFormControl = new UntypedFormControl("");
  public priceChangeMaxControl: UntypedFormControl = new UntypedFormControl("");
  public options: Options = {
    floor: 0,
    ceil: 0
  };
  @Output() public priceRangeChange = new EventEmitter<any>();
  constructor(public commonFunctions: CommonFunctionsService) { 
    this.onPriceChangeEmitter();
  }

  onPriceChangeEmitter() {
    this.priceChangeEmitter.pipe(debounceTime(1500), distinctUntilChanged()).subscribe((data: any) => {
      const { type, changeContext } = data;
      if (type == 'range') {
        this.minPrice = changeContext.value;
        this.maxPrice = changeContext.highValue;
      }
      if (type == 'min') {
        this.minPrice = this.priceChangeMinControl?.value ? this.priceChangeMinControl?.value : this.defaultMinPrice;
      }
      else if (type == 'max') {
        this.maxPrice = this.priceChangeMaxControl?.value ? this.priceChangeMaxControl?.value : this.defaultMaxPrice;
      }
      this.setPriceFilter();
    })
  }
  ngOnInit(): void {
  }
  public ngOnChanges(changes: SimpleChanges): void {
    this.options = {
      floor: 0,
      ceil: 0
    };
    if (changes?.priceRangeFilters?.currentValue) {
      this.defaultMinPrice = this.priceRangeFilters.min_price;
      this.minPrice = this.priceRangeFilters.min_price;
      this.priceChangeMinControl.setValue(this.minPrice);
      this.defaultMaxPrice = this.priceRangeFilters.max_price;
      this.maxPrice = this.priceRangeFilters.max_price;
      this.priceChangeMaxControl.setValue(this.maxPrice);
      this.options = {
        floor: this.defaultMinPrice,
        ceil: this.defaultMaxPrice
      };
    }
  }
  onPriceChange(type, changeContext?: any): void {
    if (this.priceRangeFilters) {
      if (type === 'range') {
        this.minPrice = changeContext.value;
        this.maxPrice = changeContext.highValue;
        this.setPriceFilter();
      }
      else this.priceChangeEmitter.next({ type, changeContext });
    }
  }
  setPriceFilter() {
    this.minPrice = this.minPrice <= this.defaultMaxPrice ? this.minPrice < this.defaultMinPrice ? this.defaultMinPrice : this.minPrice : this.defaultMaxPrice;
    this.maxPrice = this.maxPrice <= this.defaultMaxPrice ? this.maxPrice < this.defaultMinPrice ? this.defaultMinPrice : this.maxPrice : this.defaultMaxPrice;
    if (this.minPrice > this.maxPrice) {
      const temp = this.minPrice;
      this.minPrice = this.maxPrice;
      this.maxPrice = temp;
    }
    this.priceChangeMinControl.setValue(this.minPrice);
    this.priceChangeMaxControl.setValue(this.maxPrice);
    this.priceRangeChange.emit({
      min_price: this.minPrice,
      max_price: this.maxPrice
    });
  }
  clearPriceRange() {
    this.minPrice = this.defaultMinPrice;
    this.maxPrice = this.defaultMaxPrice;
    this.priceChangeMinControl.setValue(this.minPrice);
    this.priceChangeMaxControl.setValue(this.maxPrice);
    this.priceRangeChange.emit(null);
  }

}
