import {
	Component,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges
} from "@angular/core";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";

@Component({
	selector: "app-marketplace-store-logo",
	templateUrl: "./marketplace-store-logo.component.html",
	styleUrls: ["./marketplace-store-logo.component.scss"]
})
export class MarketplaceStoreLogoComponent implements OnInit, OnChanges {
	@Input() public storeName: any;
	@Input() public className: any;
	public snClass: any;
	public storeTooltip;
	constructor(public commonFunctions: CommonFunctionsService) {}
	ngOnChanges(changes: SimpleChanges) {
		this.storeTooltip = this.storeName;
		if (changes.storeName) {
			this.getStoreLogo();
		}
	}

	ngOnInit() {}

	getStoreLogo() {
		if (this.storeName && this.storeName != undefined) {
			if (this.storeName?.trim().indexOf(" ") != -1) {
				this.storeName = this.storeName
					?.trim()
					?.match(/\b\w/g)
					?.slice(0, 2)
					?.join("");
				this.storeName = this.storeName?.toUpperCase();
			} else {
				this.storeName = this.storeName
					?.trim()
					?.match(/\b\w/g)
					?.slice(0, 1)
					?.concat(this.storeName?.trim()?.match(/\b\w/g)?.slice(0, 1))
					?.join("");
				this.storeName = this.storeName?.toUpperCase();
			}

			if (this.storeName.charAt(0) >= "A" && this.storeName.charAt(0) <= "E") {
				this.snClass = "slColorScheme1";
			} else if (
				this.storeName.charAt(0) >= "F" &&
				this.storeName.charAt(0) <= "J"
			) {
				this.snClass = "slColorScheme2";
			} else if (
				this.storeName.charAt(0) >= "K" &&
				this.storeName.charAt(0) <= "O"
			) {
				this.snClass = "slColorScheme3";
			} else if (
				this.storeName.charAt(0) >= "P" &&
				this.storeName.charAt(0) <= "T"
			) {
				this.snClass = "slColorScheme4";
			} else if (
				this.storeName.charAt(0) >= "U" &&
				this.storeName.charAt(0) <= "Z"
			) {
				this.snClass = "slColorScheme5";
			} else if (
				this.storeName.charAt(0) >= "0" &&
				this.storeName.charAt(0) <= "9"
			) {
				this.snClass = "slColorScheme5";
			}
		}
	}
}
