import { Injectable } from "@angular/core";
import { HttpRequestService } from "@ds-shared/common-services/http-services/http-request.service";
import { PAYLOAD } from "@ds-shared/enums/common.enum";
import { IMarketplace } from "@ds-shared/models/common.model";
import { MarketShareGlobalFilter } from "@ds-shared/models/sellout.model";

@Injectable({
	providedIn: "root"
})
export class CategoryMarketShareService {
	constructor(public http: HttpRequestService) {}
	private appendMarketplace(payload: MarketShareGlobalFilter) {
		if (payload.marketPlace?.length) {
			return (
				"&" +
				payload.marketPlace
					.map(
						(item: IMarketplace, i: number) =>
							`filter[${PAYLOAD.MARKETPLACE_ID}][${i}]=${item.id}`
					)
					.join("&")
			);
		}
		return "";
	}
	callCategoryFilter() {
		return this.http.callGetApi(
			`common/categories?filter[currency_id]=7&showLevel=yes&filter[level]=3&filter[timeline]=current_month&filter[module]=pricing-analysis`
		);
	}

	callProductCollectionMarketShare(

		globalPayload: MarketShareGlobalFilter,
		selectedBrand: string,
		dateRange: { start_date: string; end_date: string },
		selectedCategories: string,
		optionalParams: string
	) {
		return this.http.callGetApi(
			`sellout/category-market-share-product-line?filter[${
				PAYLOAD.CURRENCY_ID
			}]=${globalPayload.currency_id}&filter[${PAYLOAD.SHARE_ON}]=${
				globalPayload.viewBy
			}&filter[${PAYLOAD.START_DATE}]=${dateRange.start_date}&filter[${
				PAYLOAD.END_DATE
			}]=${dateRange.end_date}&filter[brands][0]=${selectedBrand}${
				selectedCategories ? selectedCategories : ""
			}${optionalParams ? optionalParams : ""}${this.appendMarketplace(globalPayload)}`
		);
	}
	callProductCollectionContributionTrend(
		globalPayload: MarketShareGlobalFilter,
		selectedBrand: string,
		selectedCategories: string,
		contributedProductSegments: string,
		optionalParams: string
	) {
		return this.http
			.callGetApi(`sellout/category-market-share-product-line-trend?
			filter[${PAYLOAD.CURRENCY_ID}]=${globalPayload.currency_id}&filter[${
			PAYLOAD.SHARE_ON
		}]=${globalPayload.viewBy}&filter[${PAYLOAD.START_DATE}]=${
			globalPayload.timeline.startDate
		}&filter[${PAYLOAD.END_DATE}]=${
			globalPayload.timeline.endDate
		}&filter[brands][0]=${selectedBrand}${
			selectedCategories ? selectedCategories : ""
		}${contributedProductSegments ? contributedProductSegments : ""}${
			optionalParams ? optionalParams : ""
		}${this.appendMarketplace(globalPayload)}
		`);
	}

	callMarketShareContributionBySKUGrpTrend(
		globalPayload: MarketShareGlobalFilter,
		selectedBrand: string,
		selectedCategories: string,
		optionalParams: string,
		selectedProductSegmentParams: string
	) {
		return this.http.callGetApi(`sellout/sku-peer-group-contribution-trend?
		filter[${PAYLOAD.CURRENCY_ID}]=${globalPayload.currency_id}&filter[${
			PAYLOAD.SHARE_ON
		}]=${globalPayload.viewBy}&filter[${PAYLOAD.START_DATE}]=${
			globalPayload.timeline.startDate
		}&filter[${PAYLOAD.END_DATE}]=${
			globalPayload.timeline.endDate
		}&filter[brands][0]=${selectedBrand}${
			selectedCategories ? selectedCategories : ""
		}${optionalParams ? optionalParams : ""}${
			selectedProductSegmentParams ? selectedProductSegmentParams : ""
		}${this.appendMarketplace(globalPayload)}
	`);
	}
}
