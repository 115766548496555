<div class="store-info-container" *ngIf="bannerData.data;else noInfo">
    <table>
        <tbody>
            <tr>
                <td rowspan="2">
                    <section class="d-inline-flex">
                        <div class="img-container d-inline-block">
                            <img class="img-responsive" *ngIf="bannerData.data?.image" src="{{bannerData.data?.image}}" alt="">
                            <img *ngIf="!bannerData.data" src="assets/images/no-image.svg" alt="">
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header">Store</span>
                        <div class="d-flex align-items-center">
                            <span class="verifiedName d-block ellipsis common-text" *ngIf="bannerData.data?.name;else showHyphen"
                                showMultiTooltipOnTextOverflow [data]="bannerData.data?.name">
                            </span>
                            <a *ngIf="bannerData.data.url" [href]="bannerData.data.url" target="_blank" class='navigate-link d-flex align-items-center'
                                placement="{{commonFunctions.rtlStatus? 'left' : 'right'}}" ngbTooltip="{{'Go to Marketplace Store' | translate}}"
                                tooltipClass="info-custom-tooltip"><i class="icon icon-Arrow-up-2 d-inline-block"></i></a>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Channel</span>
                        <div class="market-logo d-flex align-items-center gap-8" *ngIf="bannerData.data?.marketplace_name;else showHyphen">
                            <app-marketplace-store-logo [storeName]="bannerData.data?.marketplace_name"></app-marketplace-store-logo>
                            <span class="common-text">{{bannerData.data?.marketplace_name}}</span>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header">Price Range</span>
                        <span class="common-text"
                            *ngIf="(bannerData.data?.min_price || bannerData.data?.max_price);else showHyphen">{{bannerData.data?.min_price?(bannerData.data.currency?.symbol+""+(bannerData.data?.min_price
                            | numberSuffix: 2)):""}}{{(bannerData.data?.min_price &&
                            bannerData.data?.max_price)?" - ":""}}{{bannerData.data?.max_price?(bannerData.data.currency?.symbol+""+(bannerData.data?.max_price | numberSuffix: 2)):""}}</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header">Average Discount</span>
                        <span class="voucher-badge me-0" *ngIf="commonFunctions.isIntegerOrDefined(bannerData.data?.discount);else showHyphen">{{bannerData.data?.discount}}% {{'OFF' | translate}}</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header">Average Ratings</span>
                        <ng-template #starLabel>
                            <span class="star">&#x02605;</span>
                        </ng-template>
                        <div class="ratingLabel" [ngClass]="commonFunctions.getStarStatus(bannerData.data?.rating)" *ngIf="bannerData.data?.rating;else showHyphen">
                            {{bannerData.data?.rating}}<ngb-rating [max]="1" [starTemplate]="starLabel" [rate]="1"></ngb-rating>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-inline-flex">
                        <app-scorecard-actions [bannerData]="bannerData.data" [profileObj]="profileObj" [noteObj]="noteObj" [scorecardType]="'store'"></app-scorecard-actions>
                    </section>
                </td>
            </tr>
            <tr>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header">Seller Segments</span>
                        <span class="badgeView {{commonFunctions.differentBadges[bannerData.data?.segment].class}}"
                            *ngIf="bannerData.data?.segment;else showHyphen" translate>{{commonFunctions.differentBadges[bannerData.data?.segment].name}}</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Unit Sold</span>
                        <div class="market-logo d-flex align-items-center gap-8">
                            <span class="common-text" *ngIf="commonFunctions.isIntegerOrDefined(bannerData.data?.unit_sold); else showHyphen">{{bannerData.data.unit_sold | numberSuffix: 2}}</span>
                        </div>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Include by criteria</span>
                        <span>-</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Tracked by</span>
                        <ng-container *ngIf="bannerData.data?.tracked_by?.length;else showHyphen">
                            <div class="tracked-by-parent d-flex align-items-center main-content" ngbDropdown display="dynamic">
                                <div>{{(bannerData.data?.tracked_by[0].profile_type.split('')[0]|uppercase)}}</div>
                                <div class="separator">:</div>
                                <div class="profile-name text-truncate"
                                    [ngClass]="{'single-profile-name-width':bannerData.data?.tracked_by.length===1}"
                                    showTooltipOnTextOverflow [data]="bannerData.data?.tracked_by[0].profile_name"
                                    id="scorecardTrackedBy"></div>
                                <span class="link cursor-pointer" id="dropdownBasic1" ngbDropdownToggle
                                    *ngIf="bannerData.data?.tracked_by.length>1">+{{bannerData.data?.tracked_by.length - 1}}</span>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown customTooltipPositionContainer">
                                    <ng-container *ngFor="let tag of bannerData.data?.tracked_by.slice(1);let last = last">
                                        <div class="tracked-by-parent d-flex align-items-center child-content" [ngClass]="{'mb-1':!last}">
                                            <div>{{tag.profile_type.split('')[0]|uppercase}}</div>
                                            <div class="separator">:</div>
                                            <div class="profile-name text-truncate dropdown-profile-name-width customTooltipPosition" showTooltipOnTextOverflow
                                                [data]="tag.profile_name"></div>
                                        </div>
                                    </ng-container>
                        
                                </div>
                            </div>
                        </ng-container>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header">Active Products</span>
                        <span class="common-text">{{bannerData.data?.product_count | number}}</span>
                    </section>
                </td>
                <td>
                    <section class="d-flex flex-column">
                        <span class="header" translate>Last Updated</span>
                        <span class="common-text" *ngIf="bannerData.data?.updated_at;else showHyphen">{{commonFunctions.formatSimpleDate(bannerData.data?.updated_at)}}</span>
                    </section>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #showHyphen>-</ng-template>
<ng-template #noInfo>
    <div *ngIf='!bannerData.data' class="no-data-common">
        <div *ngIf="!bannerData.loading" class="no-data-found" translate>
            <div class="img"><i class="icon icon-Search"></i></div>
            <div class="no-data-text" translate>No data present</div>
        </div>
        <div *ngIf="bannerData.loading" class="loading-panel">
            <img ngSrc="/assets/images/spinner.svg" alt=""  height="200" width="200"/>
        </div>
    </div>
</ng-template>