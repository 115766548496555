import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-custom-calendar-heatmap',
  templateUrl: './custom-calendar-heatmap.component.html',
  styleUrls: ['./custom-calendar-heatmap.component.scss']
})
export class CustomCalendarHeatmapComponent implements OnInit {

  @Input() public heatmapData = [];
  @Input() public isLoading: boolean = false;
  @Output() public onMonthChange = new EventEmitter<any>();
  @Output() public onDateChange = new EventEmitter<any>();
  public finalArray: any[] = [];
  public calendarSize: number = 0;
  public calendarMapData: any[] = [];
  public currentDate = moment();
  public dateSelected = moment().format('YYYY-MM-DD');
  public activeMonth = moment();
  public dateBefore90Days = moment().subtract(90, 'days');

  constructor() { }

  ngOnInit(): void {
    
  }

  changeMonth(val) {
    this.activeMonth = val === 'prev' ? this.activeMonth.subtract(1, 'months') : this.activeMonth.add(1, 'months');
    this.onMonthChange.emit(this.activeMonth);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.heatmapData) {
      this.getParsedDataOfCalendarHeatmap(changes?.heatmapData?.currentValue);
    }
  }

  private getParsedDataOfCalendarHeatmap(data): void{
    this.calendarMapData = data;
    let dateThreshold = 0;
    if (this.calendarMapData?.length > 0)
      dateThreshold = new Date(this.calendarMapData[0].date).getDay();
    this.finalArray = [];
    let z = 0;
    for (let p = 0; p < dateThreshold; p++) {
      this.finalArray.push({
        value: 0,
        date: "",
        styleClass: "hide"
      })
    }
    for (let i = 0; i < 7 && z < this.calendarMapData?.length; i++) {
      for (let j = dateThreshold; j < 7 && z < this.calendarMapData?.length; j++) {
        this.finalArray.push({
          value: z + 1,
          date: this.calendarMapData[z]?.date?.length < 10 ? this.calendarMapData[z]?.date?.substring(0, 5) + "0" + this.calendarMapData[z]?.date?.substring(5, this.calendarMapData[z]?.date?.length) : this.calendarMapData[z]?.date,
          styleClass: moment(this.calendarMapData[z]?.date).isAfter(this.currentDate) || this.calendarMapData[z]?.value == null ? "disabled" : this.getStatus(this.calendarMapData[z]?.priority)
        })
        z++
      }
      dateThreshold = 0
    }
    let remainingPlaces = this.finalArray?.length % 7 ? (7 - this.finalArray?.length % 7) : 0;
    for (let i = 0; i < remainingPlaces; i++) {
      this.finalArray.push({
        value: 0,
        date: "",
        styleClass: "hide"
      })
    }
    this.calendarSize = Math.ceil(this.finalArray?.length / 7);
  }

  getStatus(state) {
    if (state > 2) return 'high'
    else if (state > 1 && state <= 2) return 'medium'
    else if (state == null) return 'low'
    else return 'low';
  }

  createRange(number) {
    return new Array(number);
  }

  onDateSelect(index: any): any {
    if (this.finalArray[index]?.styleClass !== 'disabled') {
      this.dateSelected = this.finalArray[index]?.date;
      this.onDateChange.emit(this.dateSelected);
    }
  }

}
