/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { StepLineChartModel } from "@ds-models/charts.model";
import { filter, Subject, takeUntil } from "rxjs";
let componentContext;
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";

@Component({
	selector: "app-step-line-chart",
	templateUrl: "./step-line-chart.component.html",
	styleUrls: ["./step-line-chart.component.scss"]
})
export class StepLineChartComponent implements OnInit, AfterViewInit {
	@Input() public data: StepLineChartModel[];
	@Input() public categories;
	@Input() public scrollbarThreshold = 8;
	@Input() public caller = "default";
	@Input() public meta = {};

	public Highcharts: typeof Highcharts = Highcharts; // required
	public highchartsData: typeof Highcharts;
	public chartRef;
	public chartOptions;
	private destroy$: Subject<boolean> = new Subject();
	constructor(private dataTransferService: DataTransferService,
	private commonFunctions: CommonFunctionsService,
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;
		Highcharts.setOptions({
			lang: {
				thousandsSep: ","
			}
		});
	}

	ngAfterViewInit() {
		this.renderBrandSalesChart();
	}

	private renderBrandSalesChart() {
		this.highchartsData = Highcharts;
		this.chartOptions = {
			chart: {
				events: {
					load: function () {
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
					}
				}
			},
			credits: {
				enabled: false
			},
			tooltip: {
				useHTML: true,
				formatter: this.toolTipFormatter,
				followPointer: true,
				backgroundColor: undefined,
				borderColor: undefined,
				borderRadius: 2,
				borderWidth: 0,
				shadow: false,
				shared: false,
				outside: true,
				hideDelay: 100,
				split: false,
			},
			title: {
				floating: true,
				text: "",
				style: { fontSize: "1.188rem" }
			},
			xAxis: {
				type: "category",
				categories: this.categories,
				scrollbar: {
					enabled: this.categories.length > this.scrollbarThreshold,
					barBackgroundColor: "#aaaaaae5",
					barBorderRadius: 7,
					barBorderWidth: 0,
					buttonBackgroundColor: "#B3B3B3",
					buttonBorderWidth: 0,
					buttonBorderRadius: 7,
					trackBackgroundColor: "none",
					trackBorderWidth: 0,
					trackBorderRadius: 8,
					trackBorderColor: "#CCC",
					rifleColor: "transparent"
				},
				labels: {
					enabled: true,
					style: {
						fontFamily: "Poppins Regular",
						fontWeight: "400",
						color: "#8D9199",
						fontSize: "0.6875rem"
					}
				},
				max: (this.categories?.length > this.scrollbarThreshold) ? (this.scrollbarThreshold - 1) : (this.categories?.length - 1),
			},
			yAxis: {
				title: "",
				gridLineColor: "transparent",
				labels: {
					enabled: true,
					style: {
						fontFamily: "Poppins Regular",
						fontWeight: "400",
						color: "#8D9199",
						fontSize: "0.6875rem"
					}
				},
			},
			plotOptions: {
				line: {
					dataLabels: {
						enabled: true,
						formatter: this.formatterFunction
					},
					enableMouseTracking: true
				},
				series: {
					cursor: 'pointer',
					marker: {
						enabled: false,
						symbol: "circle",
						shadow: false,
						radius: 2,
						states: {
							hover: {
								enabled: true
							}
						}
					}
				}
			},
			legend: {
				enabled: false,
				align: "left"
			},
			series: this.data
		};
	}
	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (componentContext.caller) {
			case "dailySalesOfBrand":
				return `<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:0.0625rem solid #eaedf3;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
			 				<div style="font-size:0.625rem;color:${TOOLTIPCOLOR["TEXT"]}">on ${dataLabel.x} (${dataLabel['series']['name']})</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${componentContext.meta?.criterion === 'sales' ? componentContext.commonFunctions.currencySelected.attributes.symbol : ''}${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)}</span>
								<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${componentContext.meta?.criterion === 'sales' ? componentContext.commonFunctions.getSpecificTranslation('GMV') : componentContext.commonFunctions.getSpecificTranslation('Quantity Sold')}</span>
							</div>
						</div>`
			default:
				return "";
		}
	}

	formatterFunction(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions
		return componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};
}
