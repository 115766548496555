import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ProfileInformationService } from "@ds-private-layouts/scorecard/profile-information/profile-information.service";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import { RolePermissions } from "@ds-models/access-matrix.model";

@Component({
	selector: "app-mapped-action",
	templateUrl: "./mapped-action.component.html",
	styleUrls: ["./mapped-action.component.scss"]
})
export class MappedActionComponent implements OnInit, OnDestroy {
	@Input() info_type: any;
	@Input() productDetail: any;
	@Output() onDeleteAction: EventEmitter<any> = new EventEmitter();
	public productId: number;
	public profileId: number;
	public deleteProdutMappingSubscription: Subscription;
	public deleteKeywordMappingSubscription: Subscription;
	public permissions: RolePermissions = new RolePermissions();
	constructor(
		private route: ActivatedRoute,
		private profileInformationService: ProfileInformationService,
		private notificationService: NotificationService,
		private accessMatrixService: AccessMatrixService
	) {
		this.permissions = this.accessMatrixService.getPermissionByModule(
			AccessMatrix.Scorecard
		);
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params: any) => {
			this.productId = parseInt(params?.["customerProductId"]);
			this.profileId = parseInt(params?.["profileId"]);
		});
	}

	onMappedDelete() {
		if (this.info_type == "competitor") {
			this.deleteProdutMappingSubscription?.unsubscribe();
			this.deleteProdutMappingSubscription = this.profileInformationService
				.deletePoductMapping(this.productId, this.productDetail?.id)
				.subscribe(
					(res: any) => {
						if (res) {
							this.onDeleteAction.emit("competitor");
							this.notificationService.setMessage(
								res["context_code"],
								res["summary"]
							);
						}
					},
					(err: any) => {
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
					}
				);
		} else if (this.info_type == "keyword") {
			this.deleteKeywordMappingSubscription?.unsubscribe();
			this.deleteKeywordMappingSubscription = this.profileInformationService
				.deleteKeywordMapping(this.productDetail?.id)
				.subscribe(
					(res: any) => {
						if (res) {
							this.onDeleteAction.emit("keyword");
							this.notificationService.setMessage(
								res["context_code"],
								res["summary"]
							);
						}
					},
					(err: any) => {
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
					}
				);
		}
	}
	ngOnDestroy() {
		this.deleteProdutMappingSubscription?.unsubscribe();
		this.deleteKeywordMappingSubscription?.unsubscribe();
	}
}
