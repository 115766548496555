import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { AccessMatrix } from '@ds-enums/access-matrix.enum';
import { RolePermissions } from '@ds-models/access-matrix.model';
import {MATCHTYPE, MATCHTYPEBADGECLASS} from '@ds-enums/discover.enum'
@Component({
  selector: 'app-available-product-grid',
  templateUrl: './available-product-grid.component.html',
  styleUrls: ['./available-product-grid.component.scss']
})
export class AvailableProductGridComponent implements OnInit {

  @Input() ngxItems: any;
  @Output() quickOpen = new EventEmitter();
  @Output() noteOpen : EventEmitter<any> = new EventEmitter();
  @Output() deepOpen = new EventEmitter();
  public MatchesType = MATCHTYPE;
  public MatchesTypeClass = MATCHTYPEBADGECLASS;
  @Input() public isStore: boolean;
  @Output() trendCompareOpen = new EventEmitter();
  public permissionForNotification: RolePermissions = new RolePermissions();
  constructor(public commonFunctions: CommonFunctionsService, private accessMatrixService: AccessMatrixService) {
    this.permissionForNotification = this.accessMatrixService.getPermissionByModule(AccessMatrix.Notifications);
  }
  ngOnInit(): void {
  }
  onClick(x) { }
  openNotification(y) { }

}
