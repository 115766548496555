<div class="d-flex">
	<button ngbTooltip="Download" class="dropdown-item dropdown-btn" (click)="imageDownloadObj?.isGraph ? openDownloadAsPopup() : onDownloadReport()" *ngIf="showDropdownList.downloadReport"
		[disabled]="downloadProgress.disabled || !permissions.export_access || disableIcon">
		<i class="icon icon-Download"></i>
	</button>	
<ng-template [ngIf]="reportType?.length || showDropdownList.saveWidget || redirectModuleDetail">
	<div ngbDropdown class="d-inline-block position-static" display="dynamic">
		<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
			><span><i class="icon icon-Options"></i></span>
		</span>
		<div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown">
			<ng-container *ngIf="reportType?.length">
				<div *ngFor="let type of reportType; let i = index">
					<button
						class="dropdown-item"
						(click)="onDownloadReport(type)"
						[disabled]="downloadProgress.disabled || !permissions.export_access"
					>
						<i class="icon icon-Download"></i>
						<span translate>Download {{type}} Report</span>
					</button>
				</div>
			</ng-container>
			<button
				class="dropdown-item"
				*ngIf="showDropdownList.saveWidget"
				(click)="openModal()"
				[disabled]="saveWidget.disabled"
			>
				<i class="icon icon-Save"></i>
				<span translate>Save As Report</span>
			</button>
			<button
				class="dropdown-item"
				*ngIf="redirectModuleDetail"
				(click)="navigateToModule(redirectModuleDetail.path)"
			>
				<i class="icon icon-Navigation"></i>
				<span translate>See More Details on {{redirectModuleDetail.name}}</span>
			</button>
		</div>
	</div>
</ng-template>
<ng-template #saveWidgetNamePopUp let-modal>
	<div class="modal-header">
		<div class="heading" translate>
			Report Name
		</div>
		<i
			class="icon icon-Close2 cursor-pointer"
			(click)="modal.dismiss('Cross click')"
		>
		</i>
	</div>
	<div class="modal-body">
		<div class="input-container">
			<input type="text" value="{{name}}" (input)="onNameChange($event)"/>
		</div>
	</div>
    <div class="modal-footer">
        <button type="button" [disabled]="disabledSaveButton" class="btn btn-primary btn-sm" (click)="[onSaveWidget(), modal.close('Close click')]"
            translate>Save</button>
    </div>
</ng-template>

<!-- Download Options -->
<ng-template #downloadAs let-modal>
	<div class="modal-header p-0">
		<h4 translate class="modal-title text-capitalize">{{imageDownloadObj.graphName}}</h4>
		<i
			class="icon icon-Close2 cursor-pointer"
			(click)="modal.dismiss('Cross click')"
		>
		</i>
	</div>
	<div class="modal-body ps-0">
		<div class="input-container mt-3">
			<div class="d-flex gap-16 align-items-center">
				<input type="radio" id="01" value="image"  name="downloadRadio" (change)="onChange($event)" /> 
				<label for="01" class="mb-0">Download As Image</label>
			</div>
			<div class="d-flex gap-16 align-items-center mt-2" *ngIf="!imageDownloadObj.onlyImage">
				<input type="radio" value="excel" id="02" name="downloadRadio"(change)="onChange($event)" />
				<label for="02" class="mb-0">Download As Excel</label>
			</div>
		</div>
	</div>
    <div class="modal-footer">
        <button type="button" [disabled]="!downloadType" class="btn btn-primary btn-lg" (click)="[modal.close('Close click'),onSelectDownloadType()]"
            translate>Download </button>
		<button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
            translate>Cancel </button>
    </div>
</ng-template>
</div>

