/* eslint-disable */
import {
	Component,
	OnInit,
	Input,
	SimpleChanges,
	AfterViewInit
} from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
let componentContext;
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { SEGMENT, TOOLTIPCOLOR } from "@ds-shared/enums/common.enum";
import { SAVE_WIDGET } from "@ds-shared/enums/reporting.enum";
import { Subject, filter, takeUntil } from "rxjs";

@Component({
	selector: "app-stacked-column-chart",
	templateUrl: "./stacked-column-chart.component.html",
	styleUrls: ["./stacked-column-chart.component.scss"]
})
export class StackedColumnChartComponent implements OnInit, AfterViewInit {
	@Input() public series;
	@Input() public categories;
	@Input() public showLegends = false;
	@Input() public caller = "default";
	@Input() public pointWidth = 24;
	@Input() public isMultipleYaxis = false;
	@Input() public leftYAxisTitle: string | boolean = "";
	@Input() public rightYAxisTitle: string | boolean = "";
	@Input() public scrollbarThreshold = 12;
	@Input() public isDate;
	@Input() public stackingStyle: string = "normal";
	@Input() public groupPadding: number | boolean = 0;
	@Input() public meta = {};
	@Input() public yAxisParams = {};
	@Input() public minPointLength = 0;
	@Input() public yAxisType = "logarithmic";
	@Input() public redrawChart: boolean = false;
	public Highcharts: typeof Highcharts = Highcharts;
	public highchartsData: typeof Highcharts;
	public highchartsDataOptions;
	readonly MONTHS = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];
	private chartRef;
	private destroy$: Subject<boolean> = new Subject();
	constructor(
		private dataTransferService: DataTransferService,
		private commonFunctions: CommonFunctionsService
	) {
		this.dataTransferService.secondaaryMenuToggleSignal$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res) => {
				setTimeout(() => {
					this.chartRef.reflow();
				}, 0);
			});
	}

	ngOnInit(): void {
		componentContext = this;

		Highcharts.setOptions({
			lang: {
				thousandsSep: ","
			}
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.renderGraph();
		}, 0);
	}

	ngOnChanges(changes: SimpleChanges) {
		componentContext = this;
	}

	public renderGraph() {
		this.highchartsData = Highcharts;
		this.highchartsDataOptions = {
			chart: {
				type: "column",
				events: {
					load: function () {
						// Re-Draw Chart
						if (
							componentContext.redrawChart &&
							(componentContext.caller === "overallBrandMarketShare" ||
								componentContext.caller === "storeMarketShare")
						) {
							componentContext.reDrawChartForMarketShare(this);
						}
						// ---
						document
							.querySelectorAll(".highcharts-scrollbar-button")
							.forEach((el) => {
								el.remove();
							});
						document
							.querySelectorAll(".highcharts-scrollbar-arrow")
							.forEach((el) => {
								el.remove();
							});
					}
				}
			},
			title: {
				verticalAlign: "middle",
				floating: true,
				text: "",
				style: false
			},
			tooltip: {
				split: false,
				backgroundColor: undefined,
				borderColor: undefined,
				borderWidth: 0,
				shadow: false,
				hideDelay: 100,
				formatter: this.toolTipFormatter,
				shared: false,
				useHTML: true
			},
			xAxis: {
				categories: this.categories,
				labels: {
					formatter: (ev) => {
						if (componentContext.commonFunctions.isDateStringValid(ev.value)) {
							return `${
								this.MONTHS[new Date(ev.value).getMonth()] +
								" " +
								new Date(ev.value).getDate()
							}`;
						} else if (
							[
								"storeMarketShare",
								"overallBrandMarketShare",
								"categoryMarketShare"
							].includes(componentContext.caller)
						) {
							if (ev.isFirst || ev.isLast)
								return "Week of " + ev.value.split("_")[0];
							else return "Week of " + ev.value.split("_")[0].split(", ")[0];
						}
						return ev.value; // clean, unformatted number for year
					}
				},
				scrollbar: {
					enabled: this.categories?.length > this.scrollbarThreshold,
					barBackgroundColor: "#aaaaaae5",
					barBorderRadius: 7,
					barBorderWidth: 0,
					buttonBackgroundColor: "#B3B3B3",
					buttonBorderWidth: 0,
					buttonBorderRadius: 7,
					trackBackgroundColor: "none",
					trackBorderWidth: 0,
					trackBorderRadius: 8,
					trackBorderColor: "#CCC",
					rifleColor: "transparent"
				},
				max:
					this.categories?.length > this.scrollbarThreshold
						? this.scrollbarThreshold - 1
						: this.categories?.length - 1
			},
			plotOptions: {
				column: {
					stacking: this.stackingStyle,
					minPointLength: this.minPointLength,
					cursor: "pointer",
					dataLabels: {
						enabled: true,
						inside: true,
						useHTML: true,
						formatter: function () {
							if (
								[
									"storeMarketShare",
									"overallBrandMarketShare",
									"categoryMarketShare"
								].includes(componentContext.caller)
							) {
								return `${
									this.y > 5
										? `<div>${
												Math.round(
													(this["point"]["realValue"]
														? this["point"]["realValue"]
														: this.y) * 10
												) / 10
										  }% ${
												this["series"]["userOptions"]["name"].length > 8
													? this["series"]["userOptions"]["name"].slice(0, 6) +
													  "..."
													: this["series"]["userOptions"]["name"]
										  }<div>`
										: ""
								}`;
							}
							return "";
						},
						style: {
							fontSize: "8px"
						}
					}
				},
				series: {
					cursor: "pointer",
					borderWidth: 0,
					groupPadding: this.groupPadding,
					pointPadding: 0,
					pointWidth: this.pointWidth,
					color: "#FF5E00",
					events: {
						click: (event) => {
							this.dataTransferService.sendGraphSliceData({
								...event.point.series.userOptions,
								category: event.point.category,
								pointIndex: event.point.index,
								caller: this.caller,
							});
						}
					}
				}
			},
			yAxis: this.isMultipleYaxis
				? [
						{
							gridLineWidth: 2,
							title: {
								text: this.leftYAxisTitle,
								y: -30,
								// x:-20,
								style: {
									fontFamily: "Poppins Regular",
									fontSize: "0.625rem",
									fontWeight: "400",
									letterSpacing: "0.01em",
									color: "#727487",
									marginRight: "5px"
								}
							},
							type: "logarithmic",
							labels: {
								formatter: function () {
									if (this.total > 5) {
										return ` 
										<span style="color:#BDBDBD;font-size:0.625rem;font-family:Poppins Regular;font-weight:400;">${Highcharts.numberFormat(
											this.value,
											0
										)}</span>`;
									} else return null;
								}
							}
							// tickInterval: 80
						},
						{
							gridLineWidth: 2,
							title: {
								text: this.rightYAxisTitle,
								y: -30,
								style: {
									fontFamily: "Poppins Regular",
									fontSize: "0.625rem",
									fontWeight: "400",
									letterSpacing: "0.01em",
									color: "#727487"
								}
							},
							labels: {
								enabled: true,
								formatter: this.yAxisFormatter,
								style: {
									fontFamily: "Poppins Regular",
									fontWeight: "400",
									color: "#8D9199",
									fontSize: "0.625rem"
								}
							},
							opposite: true
						}
				  ]
				: {
						...this.yAxisParams,
						title: {
							text: this.leftYAxisTitle,
							y: -30,
							// x:-20,
							style: {
								fontFamily: "Poppins Regular",
								fontSize: "0.625rem",
								fontWeight: "400",
								letterSpacing: "0.01em",
								color: "#727487",
								marginRight: "5px"
							}
						},
						type: this.yAxisType,
						labels: {
							formatter: function () {
								if (
									["salesTrend", "sqtrend"].includes(componentContext.caller)
								) {
									return ` 
									<span style="color:#BDBDBD;font-size:0.625rem;font-family:Poppins Regular;font-weight:400;">${componentContext.commonFunctions.getSuffixedNumberFormat(
										this.value
									)}</span>
									`;
								}
								return ` 
		  <span style="color:#BDBDBD;font-size:0.625rem;font-family:Poppins Regular;font-weight:400;">${Highcharts.numberFormat(
				this.value,
				0
			)}${
									["atp", "storeMarketShare", "categoryMarketShare"].includes(
										componentContext.caller
									)
										? "%"
										: ""
								}</span>
		  `;
							}
						}
						// tickInterval: 80
				  },
			credits: {
				enabled: false
			},
			legend: {
				enabled: this.showLegends
			},
			series: this.series
		};
	}

	yAxisFormatter() {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		switch (componentContext.caller) {
			case "pricingHistory":
				return `${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["value"]
				)}`;
			default:
				return componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["value"]
				);
		}
	}

	toolTipFormatter(ev): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		const caller =
			componentContext.caller === SAVE_WIDGET
				? dataLabel["point"]["series"]["userOptions"]["caller"]
				: componentContext.caller;
		const symbol = dataLabel["series"]["userOptions"]?.currency?.symbol;
		switch (caller) {
			case "atp":
				return `<div
      style="background-color:${
				TOOLTIPCOLOR["BG"]
			};min-width:12rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
      <p style="color:${
				TOOLTIPCOLOR["TEXT"]
			};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["key"]
				)} (${dataLabel.y}%)</p>
      <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
          <div style="display:flex;flex-direction: column;">
              <span style="color:${
								TOOLTIPCOLOR["VALUE"]
							};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel["point"]["series"]["userOptions"]["data_value"][
						dataLabel["point"]["index"]
					]
				)}</span>
              <span style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
          </div>
      </div>
  </div>`;
			case "xSimpleYSimple":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${
						TOOLTIPCOLOR["VALUE"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
					</span>
					<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					componentContext.caller === SAVE_WIDGET
						? componentContext.commonFunctions.getSpecificTranslation(
								dataLabel["series"]["userOptions"]["leftYAxisTitle"]
						  )
						: componentContext.commonFunctions.getSpecificTranslation(
								dataLabel["series"]["name"]
						  )
				}</span>
				</div>
			</div>`;
			case "xUnitYUnit":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
<div style="display:flex;flex-direction: column;margin-top:0.25rem">
	<span style="color:${
		TOOLTIPCOLOR["VALUE"]
	};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)} ${componentContext.commonFunctions.getSpecificTranslation(
					"units"
				)}</span>
	</span>
	<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">
		${componentContext.commonFunctions.getSpecificTranslation(
			dataLabel["series"]["name"]
		)}</span>
</div>
</div>`;
			case "xCurrencyYCurrency":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${
						TOOLTIPCOLOR["VALUE"]
					};font-size:0.875rem;">${symbol}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
					</span>
					<span style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
				</div>
			</div>`;
			case "xCurrencyYUnit":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["yAxis"] === 0 ? symbol : ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)} ${
					dataLabel["series"]["userOptions"]["yAxis"] !== 0
						? componentContext.commonFunctions.getSpecificTranslation("units")
						: ""
				}</span>
					</span>
					<span style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
				</div>
			</div>`;
			case "xUnitYSimple":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["yAxis"] === 0 ? "unit" : ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
					</span>
					<span style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
				</div>
			</div>`;
			case "xUnitYCurrency":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["yAxis"] === 0 ? "" : symbol
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}${
					dataLabel["series"]["userOptions"]["yAxis"] !== 0 ? "units" : ""
				}</span>
					</span>
					<span style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
				</div>
			</div>`;
			case "xCurrencyYSimple":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["yAxis"] === 0 ? symbol : ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
					</span>
					<span style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
				</div>
			</div>`;
			case "xSimpleYCurrency":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["yAxis"] === 0 ? "" : symbol
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
					</span>
					<span style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
				</div>
			</div>`;
			case "xSimpleYUnit":
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#121737">
	<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${
					componentContext.isDate ||
					dataLabel["series"]["userOptions"]["isDate"]
						? dataLabel["x"].toString().includes(":")
							? "at " + componentContext.tConvert(dataLabel["x"])
							: "on " +
							  componentContext.MONTHS[new Date(dataLabel["x"]).getMonth()] +
							  " " +
							  new Date(dataLabel["x"]).getDate()
						: dataLabel["x"]
				}</p>
				<div style="display:flex;flex-direction: column;margin-top:0.25rem">
					<span style="color:${
						TOOLTIPCOLOR["VALUE"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}${
					dataLabel["series"]["userOptions"]["yAxis"] !== 0 ? "units" : ""
				}</span>
					</span>
					<span style="color:${
						TOOLTIPCOLOR["TEXT"]
					};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"]
				)}</span>
				</div>
			</div>`;
			case "sqtrend":
				const sellout_symbol =
					dataLabel["series"]["userOptions"]["currency"]["symbol"];
				const startDate =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"start_date"
					];
				const endDate =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"end_date"
					];
				return `<div
      style="background-color:${
				TOOLTIPCOLOR["BG"]
			};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
      <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
			
					${dataLabel["key"]} ( ${
					componentContext.MONTHS[new Date(startDate).getMonth()] +
					" " +
					new Date(startDate).getDate()
				} - ${
					componentContext.MONTHS[new Date(endDate).getMonth()] +
					" " +
					new Date(endDate).getDate()
				} )
				</p>
      <div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
          <div style="display:flex;flex-direction: column;">
              <span style="color:${
								TOOLTIPCOLOR["VALUE"]
							};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["caller"] === "sales"
						? sellout_symbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
              <span style="color:${
								TOOLTIPCOLOR["TEXT"]
							};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"] === "sales" ? "Sales" : "Quantity Sold"
				)}</span>
          </div>
      </div>
  </div>`;
			case "salesTrend":
				const salesSymbol = dataLabel["series"]["userOptions"]["currency"];
				const sqStartDate =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"start_date"
					];
				const sqEndDate =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"end_date"
					];
				return `<div
		style="background-color:${
			TOOLTIPCOLOR["BG"]
		};min-width:10rem;border-radius:0.375rem;height:5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
		<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">

				${dataLabel["key"]} ( ${
					componentContext.MONTHS[new Date(sqStartDate).getMonth()] +
					" " +
					new Date(sqStartDate).getDate()
				} - ${
					componentContext.MONTHS[new Date(sqEndDate).getMonth()] +
					" " +
					new Date(sqEndDate).getDate()
				} )
			</p>
		<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
		<div style="display:flex;flex-direction: column;">
		<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["caller"] === "gmv"
						? salesSymbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
		<span style="color:${
			TOOLTIPCOLOR["TEXT"]
		};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["name"] === "GMV" ? "Sales" : "Quantity Sold"
				)}</span>
		</div>
		</div>
		</div>`;
			case "sellout-brand-overall-trend":
				const start_Date =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"start_date"
					];
				const end_Date =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"end_date"
					];
				return `<div style="background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;height:6.5rem;display:flex;flex-direction: column;padding:0.625rem 0.875rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;row-gap:0.5rem;">
  						<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
							${dataLabel["key"]} ( ${
					componentContext.MONTHS[new Date(start_Date).getMonth()] +
					" " +
					new Date(start_Date).getDate()
				} - ${
					componentContext.MONTHS[new Date(end_Date).getMonth()] +
					" " +
					new Date(end_Date).getDate()
				} )
						</p>
						<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
								${dataLabel["series"]["userOptions"]["name"]}
						</span>
					<div style="display:flex;gap:1rem;flex-direction:row;justify-content:space-between">
						<div style="display:flex;flex-direction: column;">
							<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					componentContext.meta?.criterion === "sales"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol
						: ""
				}${componentContext.commonFunctions.getSuffixedNumberFormat(
					dataLabel.y
				)}</span>
							<span style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.875rem;">${
					componentContext.meta?.criterion === "sales"
						? componentContext.commonFunctions.getSpecificTranslation("GMV")
						: componentContext.commonFunctions.getSpecificTranslation(
								"Quantity Sold"
						  )
				}</span>
						</div>
					</div>
					</div>`;
			case "onlineChannel":
				const start_date =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"start_date"
					];
				const end_date =
					dataLabel["series"]["userOptions"]["dateObj"][dataLabel.x][
						"end_date"
					];
				return `<div style="display:flex;flex-direction:column;background-color:${
					TOOLTIPCOLOR["BG"]
				};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
							<div style="font-size:0.625rem;">		
							<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
							${dataLabel["key"]} ( ${
					componentContext.MONTHS[new Date(start_date).getMonth()] +
					" " +
					new Date(start_date).getDate()
				} - ${
					componentContext.MONTHS[new Date(end_date).getMonth()] +
					" " +
					new Date(end_date).getDate()
				} )
						</p>
						(${dataLabel["point"]["mp"]})</div>
						<div style="display:flex;" class="gap-16">
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["name"] !== "Quantity Sold"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol +
						  componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel.y
						  )
						: componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel.y
						  )
				}</span>
								<span style="color:${
									TOOLTIPCOLOR["TEXT"]
								};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["userOptions"]["name"]
				)}</span>
							</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["dealer_name"] === null
						? "-"
						: componentContext.commonFunctions.getSpecificTranslation(
								dataLabel["point"]["dealer_name"]
						  )
				}</span>
								<span style="color:${
									TOOLTIPCOLOR["TEXT"]
								};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Dealer"
				)}</span>
							</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["registration_number"] === null
						? "-"
						: componentContext.commonFunctions.getSpecificTranslation(
								dataLabel["point"]["registration_number"]
						  )
				}</span>
								<span style="color:${
									TOOLTIPCOLOR["TEXT"]
								};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Reg. No."
				)}</span>
							</div>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["point"]["segment"] === null
						? "-"
						: componentContext.commonFunctions.getSpecificTranslation(
								SEGMENT[dataLabel["point"]["segment"]]
						  )
				}</span>
								<span style="color:${
									TOOLTIPCOLOR["TEXT"]
								};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					"Segment"
				)}</span>
							</div>
						</div>
					</div>`;
			case "overallBrandMarketShare":
				return `
					  <div style="display:flex;flex-direction:column;background-color:${
							TOOLTIPCOLOR["BG"]
						};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
					  	<div style="font-size:0.625rem;">	
							<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
							${componentContext.commonFunctions.getSpecificTranslation(
								dataLabel["series"]["userOptions"]["name"]
							)} (Week of ${dataLabel["key"].split("_")[0]} (${
					Math.round(
						(dataLabel["point"]["realValue"]
							? this["point"]["realValue"]
							: dataLabel.y) * 10
					) / 10
				}%))
							</p>
							<div style="display:flex;flex-direction: column;margin-top:0.5rem">
								<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["share_on"] !== "quantity_sold"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol +
						  componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["series"]["userOptions"]["value"][
									dataLabel["point"]["index"]
								]
						  )
						: componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["series"]["userOptions"]["value"][
									dataLabel["point"]["index"]
								]
						  )
				}</span>
								<span style="color:${
									TOOLTIPCOLOR["TEXT"]
								};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["userOptions"]["share_on"] !== "quantity_sold"
						? "GMV"
						: "Quantity Sold"
				)}</span>
							</div>
						</div>
					  </div>
				`;
			case "competitor-price-monitoring":
				return `
				<div style="display:flex;flex-direction:column;background-color:${TOOLTIPCOLOR["BG"]};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
					<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${dataLabel['key']} (${componentContext.commonFunctions.getSpecificTranslation(dataLabel['series']['userOptions'].name)})</p>
					<div style="display:flex; gap: 1rem;">
						<div style="display:flex;flex-direction: column;margin-top:0.5rem">
							<span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${(dataLabel.y ? componentContext.commonFunctions.currencySelected.attributes.symbol : '' )+ componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel.y)}</span>
							<p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">${componentContext.commonFunctions.getSpecificTranslation('Average Price')}</p>
						</div>
					</div>
				</div>
				`;
			case "storeMarketShare":
				return `
			<div style="display:flex;flex-direction:column;background-color:${
				TOOLTIPCOLOR["BG"]
			};min-width:10rem;border-radius:0.375rem;padding:0.5rem 0.625rem;border:none;margin:0;font-family:Poppins Regular;font-weight:400;color:#ffffff">
				<div style="font-size:0.625rem;">	
				  <p style="color:${TOOLTIPCOLOR["TEXT"]};font-size:0.625rem;margin:0">
					  Week of ${dataLabel["key"].split("_")[0]} (${
					Math.round(
						(dataLabel["point"]["realValue"]
							? dataLabel["point"]["realValue"]
							: dataLabel.y) * 10
					) / 10
				}%)
				  </p>
				  <div style="display:flex;flex-direction: column;margin-top:0.5rem">
					  <span style="color:${TOOLTIPCOLOR["VALUE"]};font-size:0.875rem;">${
					dataLabel["series"]["userOptions"]["share_on"] !== "quantity_sold"
						? componentContext.commonFunctions.currencySelected.attributes
								.symbol +
						  componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["series"]["userOptions"]["value"][
									dataLabel["point"]["index"]
								]
						  )
						: componentContext.commonFunctions.getSuffixedNumberFormat(
								dataLabel["point"]["series"]["userOptions"]["value"][
									dataLabel["point"]["index"]
								]
						  )
				}</span>
					  <span style="color:${
							TOOLTIPCOLOR["TEXT"]
						};font-size:0.875rem;">${componentContext.commonFunctions.getSpecificTranslation(
					dataLabel["series"]["userOptions"]["share_on"] === "gmv"
						? "GMV"
						: "Quantity Sold"
				)}</span>
				  </div>
			  </div>
			</div>`;
			default:
				return "";
		}
	}

	formatterFunction(): string {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		return componentContext.commonFunctions.getSuffixedNumberFormat(
			dataLabel.y
		);
	}

	oppositeYAxisFormatter() {
		const dataLabel = this as Highcharts.DataLabelsOptions;
		const caller =
			componentContext.caller === SAVE_WIDGET
				? componentContext.commonFunctions.getSpecificTranslation(
						dataLabel["axis"]["series"][0]["userOptions"]["caller"]
				  )
				: componentContext.commonFunctions.getSpecificTranslation(
						componentContext.caller
				  );
		switch (caller) {
			case "pricing":
			case "pricingHistory":
				if (
					dataLabel["axis"]["userOptions"]["title"]["text"] === "DISCOUNT (%)"
				)
					return `${dataLabel["value"]}%`;
				return `${dataLabel["value"]}`;
			case "sellout":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xCurrencyYCurrency":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xUnitYUnit":
				return `
		${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
		`;
			case "xCurrencyYUnit":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xUnitYSimple":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xCurrencyYSimple":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xSimpleYUnit":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xUnit":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xCurrency":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xSimple":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xSimpleYCurrency":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xUnitYCurrency":
				return `
	${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
	`;
			case "xSimpleYSimple":
				return `
${componentContext.commonFunctions.getSuffixedNumberFormat(dataLabel["value"])}
`;
			default:
				return "";
		}
	}

	chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
		this.chartRef = chart;
	};

	reDrawChartForMarketShare(chart) {
		let minColHeightVal = 6;
		chart.series.forEach(function (s) {
			s.points.forEach(function (p) {
				if (p.y > 0 && p.y < 5) {
					p.update(
						{
							y: minColHeightVal,
							realValue: p.y
						},
						false
					);
				}
			});
		});
		chart.redraw();
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
