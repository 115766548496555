<div class="root-wrapper">
    <div class="content-wrapper sticky-wrapper">
        <section class="sticky-header py-3">
            <div class="d-flex justify-content-between align-items-center">
                <app-breadcrumb-common [data]="NotificationBreadcrumb"></app-breadcrumb-common>
                <app-common-preferences></app-common-preferences>
            </div>
        </section>
        <div class="content-wrapper-inner p-0">
            <div class="sort-outer-container border-bottom-0 ">
                <div class="sort-container border-bottom-0 p-3">
                    <div class="sort-item">
                        <app-search-common [searchObj]="searchObj" (onSearch)="onSearch($event)"></app-search-common>
                    </div>
                    <div class="d-flex justify-content-end gap-8">
                        <button [disabled]="!permissions.write_access" class="btn super-btn btn-lg"
                            (click)="addNotification()" translate>
                            <i class="icon  icon-Add"></i>
                            Create Notification
                        </button>
                        <div class="notification-icon cursor-pointer d-flex align-items-center justify-content-center position-relative"
                            (click)="openNotification()">
                            <i class="icon icon-Notification-on" placement="bottom"
                                ngbTooltip="{{'Recent Notification' | translate}}" tooltipClass="info-custom-tooltip"></i>
                            <div class="notification-count" *ngIf="allNotificationsResponse?.length">
                                {{recentCount}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row notification-data-content">
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{'d-none' : !isOpen}">
    <div class="custom-right-panel">
        <div class="overlay"></div>
        <div class="right-panel">
            <h3 translate>Notifications</h3>
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" class="nav-pills">
                <ng-container *ngFor="let noti of notificationTypeList; let i = index">
                    <li [ngbNavItem]=i>
                        <a ngbNavLink translate>{{noti.name}}</a>
                        <ng-template ngbNavContent>
                            <ng-container *ngIf="noti.name === 'Recent'">
                                <app-recent-notifications [allNotificationsData]="allNotificationsResponse"
                                    [pageNumber]="recentPageNumber" [isRecentPresent]="isRecentPresent"
                                    (refreshRecentNotifications)="onrefreshRecentNotifications($event)"
                                    (closeFunction)="openNotification()">
                                </app-recent-notifications>
                            </ng-container>
                            <ng-container *ngIf="noti.name === 'Historic'">
                                <app-historic-notifications [allNotificationsData]="allHistoricNotificationsResponse"
                                    [pageNumber]="historicPageNumber" [isHistoricPresent]="isHistoricPresent"
                                    (refreshHistoricNotifications)="onrefreshHistoricNotifications($event)"
                                    (closeFunction)="openHistoricNotification()">
                                </app-historic-notifications>
                            </ng-container>
                        </ng-template>
                    </li>
                </ng-container>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2">
            </div>
        </div>
    </div>
</div>
