import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Subscription, filter, Subject, finalize, tap } from "rxjs";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { ModuleName, Modules, SubMenuName } from "@ds-enums/modules.enum";
import { MenuModel } from "@ds-models/menu.model";
import { GlobalService } from "@ds-common-services/http-services/global.service";

@Component({
	selector: "app-menu",
	templateUrl: "./menu.component.html",
	styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit, OnDestroy {
  public activeIds: string[] = [];
  public currentMenuId: number;
  public updateUserPermissionSubscription: Subscription;
  public lang: any;
  public MODULES = Modules;
  public showSecondaryMenu: boolean = false;
  constructor(private localStorageService: LocalStorageService, public router: Router, private accessMatrixService: AccessMatrixService, private dataTransferService: DataTransferService, public commonFunctions: CommonFunctionsService,
    private globalService: GlobalService, private mixpanelService: MixpanelService, private renderer: Renderer2) {
    this.showHideSecondaryMenu();
    this.commonFunctions.renderer = this.renderer;
    const savedUserData = this.commonFunctions.getSavedUserData();

    if (savedUserData.hideSubmenus) {
      this.menuList.forEach(item => {
        item.subMenuList = item.subMenuList.filter(submenu => !savedUserData.hideSubmenus[submenu.path]);
      });
    }
    this.router.events
      .pipe(filter((e: any): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((evt: RouterEvent) => {
        if (evt instanceof NavigationEnd) {
          this.showHideSecondaryMenu();
        }
      });
  }
  onMenuClick(menu, index) {
    this.localStorageService.set('currentMenu', index)
    const parentListEl = document.getElementsByClassName('menu-wrapper') as HTMLCollectionOf<HTMLElement>;
    if (parentListEl[0].style.width === '3.5rem') parentListEl[0].style.width = '13.5rem'

    if (menu.isPermission) {
      this.mixpanelService.track('Visited ' + menu.moduleName + ' Module', {});
      this.commonFunctions.clearScorecardLocalFilters();
    }
  }

  menuList: MenuModel[] = [
    {
      path: '/dashboard',
      icon: 'icon icon-Apps',
      tooltip: ModuleName.Dashboard,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'DASHBOARD',
      subMenuList: [],
      sortOrder: 1,
      moduleName: Modules.dashboard
    },
    {
      path: '/pricing',
      icon: 'icon icon-Wallet',
      tooltip: ModuleName.Pricing,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'DASHBOARD',
      activePath: "/pricing/analysis",
      subMenuList: [{
        id: "Pricing Analysis",
        name: "Pricing Analysis",
        path: "/pricing/analysis",
        icon: "icon-Price-Analysis-2",
      },
      {
        name: "Promotion Analysis",
        path: "/pricing/promotion-analysis",
        icon: "icon-Promotion-Analysis",
      },
      {
        name: "Price Monitoring",
        path: "/pricing/price-monitoring",
        icon: "icon-Price",
      }
      ],
      sortOrder: 2,
      moduleName: Modules.pricing
    },
    {
      path: '/availability',
      icon: 'icon icon-Product',
      tooltip: ModuleName.Availability,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'DASHBOARD',
      subMenuList: [{
        name: "Availability",
        path: "/availability/inventory",
        icon: "icon-History",
        code: 'inventory',
        count: 0,
        showCount: true
      },
      {
        name: "Variants",
        path: "/availability/variants",
        icon: "icon-Copy",
        count: 0,
        code: 'variants',
        showCount: true
      },
      {
        name: "Share of Assortments",
        path: "/availability/share-of-assortments",
        icon: "icon-Share-of-Assortment",
        showCount: true
      },
      {
        name: "Postal Code Monitoring",
        path: "/availability/postal-code-monitoring",
        icon: "icon-Envelope",
      }
      ],
      sortOrder: 4,
      moduleName: Modules.availability

    },
    {
      path: '/sales',
      icon: 'icon icon-Price1',
      tooltip: ModuleName.Sales,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'DASHBOARD',
      subMenuList: [
        {
          name: SubMenuName.MARKET_SHARE_ANALYSIS,
          path: "/sales/market-share",
          icon: "icon-Chart-pie-alt",
        },
        {
          name: SubMenuName.BRAND_MARKET_SHARE,
          path: "/sales/brand-market-share",
          icon: "icon-Daily-Store-Trend",
        },
        {
          name: "Dealer Analysis",
          path: "/sales/dealer-analysis",
          icon: "icon-Shopping-basket",
        },
        {
          name: "SKU Analysis",
          path: "/sales/sku-analysis",
          icon: "icon-Box",
        },
        {
          name: "OMS Integration",
          path: "/sales/oms",
          icon: "icon-Integrations",
        },
		{
			name: SubMenuName.MARKET_SHARE_BY_SELLER_SEGMENTS,
			path: "/sales/store-market-share",
			icon: "icon-Store"
		},
		{
			name: SubMenuName.CATEGORY_MARKET_SHARE,
			path: "/sales/category-market-share",
			icon: "icon-Categories"
		}
      ],
      sortOrder: 3,
      moduleName: 'Sales'
    },
    {
      path: '/scorecard',
      icon: 'icon  icon-Book',
      tooltip: ModuleName.Scorecard,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'scorecard',
      subMenuList: [{
        name: SubMenuName.SCORECARD_PRODUCT,
        path: "scorecard/product",
        icon: "icon-Product",
        code: "product",
        count: 0,
        showCount: false
      },
      {
        name: SubMenuName.SCORECARD_STORE,
        path: "scorecard/store",
        icon: "icon-Store",
        code: "store",
        count: 0,
        showCount: false
      },
      {
        name: SubMenuName.SCORECARD_BRAND,
        path: "scorecard/brand",
        icon: "icon-Brand",
        code: "brand",
        count: 0,
        showCount: false
      }
      ],
      sortOrder: 8,
      moduleName: Modules.scorecard
    },
    {
      path: '/content-quality',
      icon: 'icon icon-Award',
      tooltip: ModuleName.Content_Quality,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'content-quality',
      subMenuList: [],
      sortOrder: 5,
      moduleName: Modules['content-quality']
    },
    {
      path: '/discover',
      icon: 'icon icon-Discover',
      tooltip: ModuleName.Discover,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'discover',
      subMenuList: [{
        name: "Share of Category",
        icon: "icon-Book-check",
        path: "/discover/share-of-category",
      },
      {
        name: "Compare Performance",
        icon: "icon-Book-check",
        path: "/discover/compare-performance",
      },
      ],
      sortOrder: 6,
      moduleName: Modules.discover
    },
    {
      path: '/reporting',
      icon: 'icon icon-Chart-pie',
      tooltip: ModuleName.Reporting,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'reporting',
      subMenuList: [{
        name: "Build Report",
        icon: "icon-Pulse",
        path: "/reporting/build-report",
      },
      {
        name: "Saved Reports",
        icon: "icon-Save",
        path: "/reporting/saved-reports",
      },
      ],
      sortOrder: 9,
      moduleName: Modules.reporting
    },
    {
      path: '/notification',
      icon: 'icon icon-Notification-Intelligence',
      tooltip: ModuleName.Notification_Intelligence,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'notification',
      subMenuList: [{
        name: "Product",
        icon: "icon-Product",
        path: "notification/products",
      },
      {
        name: "Store",
        icon: "icon-Store",
        path: "notification/stores",
      },
      {
        name: "Brand",
        icon: "icon-Brand",
        path: "notification/brands",
      },
      {
        name: "Keyword",
        icon: "icon-Key",
        path: "notification/keywords",
      },
      {
        name: "Category",
        icon: "icon-Layout",
        path: "notification/categories",
      }],
      sortOrder: 10,
      moduleName: Modules.notification
    },
    {
      path: '/share-of-search',
      icon: 'icon icon-Price-Analysis',
      tooltip: ModuleName.Share_Of_Search,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: false,
      forceCheckSubmenuPermission: false,
      testAttribute: 'share-of-search',
      subMenuList: [
        {
          name: "Keyword",
          count: 0,
          code: 'keyword',
          icon: "icon-Key",
          path: "/share-of-search/keyword",
          showCount: false
        },
      ],
      sortOrder: 7,
      moduleName: Modules['share-of-search']
    },
    {
      path: '/user-management',
      icon: 'icon icon-User',
      tooltip: ModuleName.User_Management,
      tooltipClass: 'menu-custom-tooltip',
      tooltipPlacement: 'right',
      isPermission: true,
      skipPermission: true,
      forceCheckSubmenuPermission: true,
      sortOrder: 11,
      moduleName: Modules['user-management'],
      subMenuList: [
        {
          name: "Account Overview",
          icon: "icon-Contacts",
          path: "user-management/account-overview",
        },
        {
          name: "Team Members",
          icon: "icon-User-plus",
          path: "user-management/team-members",
        },
        {
          name: "Access Matrix",
          icon: "icon-User-block",
          path: "user-management/access-matrix/roles",
        },
        {
          name: "Data Management",
          icon: "icon-Folder-lock",
          path: "/user-management/data-management",
        }
      ]
    },


  ]
  ngOnInit(): void {
    this.currentMenuId = this.localStorageService.get('currentMenu');
    if (this.currentMenuId) this.activeIds = [`${this.currentMenuId}`]
    this.getMenuData();
    this.checkForCounts();
    this.updateUserPermissionSubscription?.unsubscribe();
    this.updateUserPermissionSubscription = this.dataTransferService.refreshOnAdminDataRoleUpdate$.subscribe((data: any) => {
      if (data) {
        this.getMenuData();
      }
    });
  }

  private checkForCounts() {
    const moduleWithCounts: string[] = [Modules.availability]
    this.menuList.forEach((subModule, index) => {
      if (
        !moduleWithCounts.includes(subModule.moduleName)
      )
        return;
      this.globalService
        .secondaryMenuCount(subModule.moduleName)
        .subscribe((res) => {
          this.menuList[index].subMenuList = this.menuList[
            index
          ].subMenuList.map((subModule) => {
            return {
              ...subModule,
              count: res["data"][subModule.code]
            };
          });
        });

    })
  }

  showHideSecondaryMenu() {
    this.showSecondaryMenu = this.commonFunctions.secondaryMenuBlockerRoutes.some(route => this.router.url.split('/')[1] === route) ? false : true;
    this.commonFunctions.watchSecondaryMenu();
  }

  getMenuData() {
    this.menuList = this.menuList.map((item) => {
      if (item.path && !item.skipPermission) {
        const permissions = this.accessMatrixService.getPermissionByModule(item.path.split('/')[1]);
        item.isPermission = permissions.full_access || permissions.read_access;
      }
      if (item.forceCheckSubmenuPermission) {
        item.subMenuList = item.subMenuList.map((subMenu) => {
          if (subMenu.path && !subMenu.skipPermission) {
            const permissions = this.accessMatrixService.getPermissionByModule(subMenu.path.split('/')[1]);
            subMenu.isPermission = permissions.full_access || permissions.read_access;
          }
          return subMenu
        })
      }
      return item;
    }).filter(item => item.sortOrder).sort((a, b) => a.sortOrder - b.sortOrder)
  }

  logoutOption() {
    this.localStorageService.clear();
    // this.dataTransferService.moduleDetail.moduleType=null;
    // this.dataTransferService.sendGlobalStoreId('');
    this.router.navigate(['/']);
  }

  public logout() {
    this.globalService.userLogout().pipe(finalize(() => { this.globalService.logout() })).subscribe(() => { })
  }

  onSubmoduleClick(menu) {
    this.commonFunctions.clearScorecardLocalFilters();
    this.router.navigate([menu.path]);
  }
  closeAccordion() {
    const parentListEl = document.getElementsByClassName('app-structure')[0];
    if (parentListEl.classList.contains('wrapped-menu')) {
      this.activeIds = [];
    }
    else {
      this.activeIds = [`${this.localStorageService.get('currentMenu')}`]
    }
  }

  ngOnDestroy(): void {
    this.updateUserPermissionSubscription?.unsubscribe();
  }
}
