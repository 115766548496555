<div class="calendar-heatmap-container ds-box mb-0">
    <div class="container-header">
        <div class="ds-box-heading" translate>Calendar Heatmap<i class="icon icon-Info-circle"
                placement="{{commonFunctions.rtlStatus ? 'left' : 'right'}}"
                ngbTooltip="{{calendarHeatmapTooltip |translate}}" tooltipClass="info-custom-tooltip"></i></div>
        <div class="calenderMapSelection">
            <ng-select [items]="metricList" [notFoundText]="'No Data Found' | translate"
                [loadingText]="'Loading...' | translate" [clearable]="false" bindLabel="name"
                (change)="onSelect($event)" placeholder="Select KPI" class="no-clear-all" [(ngModel)]="selectedMetric">
                <ng-template ng-label-tmp let-item="item">
                    <span translate>{{item.name}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span>{{item.name | translate}}</span>
                </ng-template>
            </ng-select>
            <!-- <div ngbDropdown class="d-inline-block" placement="bottom-right" #myDrop1="ngbDropdown"
                class="card-dropdown">
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button class="dropdown-item"><i class="icon  icon-Forward"></i><span>Share</span></button>
                    <button class="dropdown-item"><i class="icon  icon-Upload"></i><span>Export</span></button>
                </div>
            </div> -->
        </div>
    </div>
    <div class="container-body">
        <div [ngClass]="{'small':heightVal=='19.375rem','large':heightVal=='20.625rem','selling-price-graphs':true}">
            <div class="graph-container">
                <div class="graph-1">
                    <div class="date-manager">
                        <button (click)="changeMonth('prev')" [disabled]="!prev || (prev && restrictBefore90Days)"
                            class="ms-1">
                            <i class="icon icon-Caret-left"></i>
                        </button>
                        <span class="date">{{monthName | translate}} {{year}}</span>
                        <button (click)="changeMonth('next')" [disabled]="!next" class="me-1">
                            <i class="icon icon-Caret-right"></i>
                        </button>
                    </div>
                    <table *ngIf="calendarMapData && calendarMapData?.length>0">
                        <tr>
                            <th translate>Sun</th>
                            <th translate>Mon</th>
                            <th translate>Tue</th>
                            <th translate>Wed</th>
                            <th translate>Thu</th>
                            <th translate>Fri</th>
                            <th translate>Sat</th>
                        </tr>
                        <tr *ngFor="let item of createRange(calendarSize); let i = index">
                            <td *ngFor="let item of createRange(7); let j = index" (click)="onDateSelect(i*7+j)"
                                [ngClass]="{'hide':finalArray[i*7+j]?.styleClass=='hide','disabled':finalArray[i*7+j]?.styleClass=='disabled','low':finalArray[i*7+j]?.styleClass=='low','medium':finalArray[i*7+j]?.styleClass=='medium','high':finalArray[i*7+j]?.styleClass=='high','active':finalArray[i*7+j]?.date==dateSelected}">
                                {{finalArray[i*7+j]?.value}}</td>
                        </tr>
                    </table>
                    <div *ngIf='!calendarMapData || (calendarMapData?.length==0)' class="no-data-common no-calendar">
                        <div *ngIf="calendarPresent" class="no-data-found" translate>
                            <div class="img"><i class="icon icon-Search"></i></div>
                            <div class="no-data-text" translate>No data present</div>
                        </div>
                        <div *ngIf="!calendarPresent" class="loading-panel">
                            <img ngSrc="/assets/images/spinner.svg" alt="" height="200" width="200"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="graph-container">
                <div class="graph-2 position-relative">
                    <div class="d-flex justify-content-between align-items-center">

                        <h6 class="chartTitle text-align-right-rtl">{{'Your Profile vs Top Competitors' | translate}}
                            <span *ngIf="selectedDateAreaGraph" class="selectedDate">{{'for' | translate}}
                                <i>{{metricName | translate}}</i>
                                {{'from' | translate}} {{startDate}} {{'to' | translate}} {{endDate}}</span></h6>
                        <!-- <button *ngIf="areaChartData" class="btn icon-only-btn explore-chart cursor-pointer"
                            (click)="openChartWalkthrough()">
                            <i class="icon icon-Current-location"
                                placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}"
                                ngbTooltip="{{'Chart Walk-through' | translate}}"
                                tooltipClass="info-custom-tooltip"></i>
                        </button> -->
                    </div>

                    <highcharts-chart [callbackFunction]="chartCallback" *ngIf="areaChartData && heightVal=='19.375rem'"
                        [Highcharts]="areaChart" [options]="areaChartOptions" [(update)]="updateChart"
                        class="smallChart"></highcharts-chart>
                    <highcharts-chart [callbackFunction]="chartCallback" *ngIf="areaChartData && heightVal=='20.625rem'"
                        [Highcharts]="areaChart" [options]="areaChartOptions" [(update)]="updateChart"
                        class="largeChart"></highcharts-chart>
                    <Button [ngClass]="{'d-none':!this.isAreaChartZoomed}" id="zoomChart" placement="left"
                        ngbTooltip="{{'Reset Zoom' | translate}}" tooltipClass="info-custom-tooltip"
                        class="btn icon-only-btn reset-zoom">
                        <i class="icon icon-Refresh"></i>
                    </Button>
                    <div class="no-data" *ngIf="!selectedDateAreaGraph">
                        <div class="image-content"><img src="../assets/images/Product.svg" alt=""></div>
                        <div>Select Date, to see Data</div>
                    </div>
                    <div *ngIf='selectedDateAreaGraph && !areaChartData' class="no-data-common">
                        <div *ngIf="areaPresent" class="no-data-found" translate>
                            <div class="img"><i class="icon icon-Search"></i></div>
                            <div class="no-data-text" translate>No data present</div>
                        </div>
                        <div *ngIf="!areaPresent" class="loading-panel">
                            <img ngSrc="/assets/images/spinner.svg" alt=""  height="200" width="200"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lengends">
            <div class="legends-container">
                <div class="info-text" *ngFor="let legendItem of calendarLegends; let i = index">
                    <div class="info-color" [ngStyle]="{'background-color':legendItem?.color}"></div><span
                        class="text">{{legendItem?.key | translate}}</span>
                </div>
            </div>
            <div class="legends-container legends-margin">
                <div class="info-text" *ngFor="let legendItem of areaLegends; let i = index">
                    <div class="info-color" [ngStyle]="{'background-color':legendItem?.color}"></div><span
                        class="text">{{legendItem?.key}}</span>
                </div>
                <div *ngIf="areaLegends?.length===0">-</div>
            </div>
        </div>
    </div>
</div>