import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { ProfileInformationService } from "@ds-private-layouts/scorecard/profile-information/profile-information.service";
import {
	debounceTime,
	distinctUntilChanged,
	takeUntil,
	tap
} from "rxjs/operators";
import { RolePermissions } from "@ds-models/access-matrix.model";
import { AccessMatrixService } from "@ds-common-services/utility-services/access-matrix.service";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductSummaryComponent } from "src/app/shared/common-components/scorecard/summary-overlay/product-summary/product-summary.component";
import { CommonFiltersComponent } from "./common-filters/common-filters.component";

enum STATUS {
	"ACTIVE" = "ACTIVE",
	"DISCARDED" = "DISCARDED"
}
@Component({
	selector: "app-manage-products",
	templateUrl: "./manage-products.component.html",
	styleUrls: ["./manage-products.component.scss"]
})
export class ManageProductsComponent implements OnInit {
	public activeProd: any = [];
	public activeProdLoading: boolean = false;
	public discardedProdLoading: boolean = false;
	public discardedProd: any = [];
	public tempMovedActiveProduct: any = [];
	public tempMovedDiscardedProduct: any = [];
	public pageNumber: number = 1;
	public count = { active: null, discarded: null };
	public searchedActiveProd: UntypedFormControl = new UntypedFormControl("");
	public searchedDiscardedProd: UntypedFormControl = new UntypedFormControl("");
	public activeProductSubscription: Subscription;
	public discardedProductSubscription: Subscription;
	public profileDataSubscription: Subscription;
	public moveProductSubscription: Subscription;
	public sort = "";
	public profileData: any = { profileId: null, isCustomer: null };
	public profileId: any;
	public dashboardCurrencySubscription: Subscription;
	public dashboardCurrencyData: any;
	public pageSize: number = 10;
	public activePageNo: number = 1;
	public discardedPageNo: number = 1;
	public $destroy: Subject<boolean> = new Subject();
	public sortActiveList: any;
	public sortDeactiveList: any;
	public mouseActionType: any;
	public sortDirection: any;
	public permissions: RolePermissions = new RolePermissions();
	public STATUS = STATUS;
	public checkAllStatus = {
		active: false,
		discarded: false
	};
	public additionalFilter = { isActive: {}, isDiscarded: {} };
	public optionalFilters = { isActive: null, isDiscarded: null };
	public stateChange = { to_and_fro: false };
	@ViewChild(CommonFiltersComponent)
	commonFiltersComponent: CommonFiltersComponent;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private profileInformationService: ProfileInformationService,
		private notificationService: NotificationService,
		public renderer: Renderer2,
		private dataTransferService: DataTransferService,
		public commonFunctions: CommonFunctionsService,
		public accessMatrixService: AccessMatrixService,
		private ngbModalService: NgbModal
	) {
		this.permissions = this.accessMatrixService.getPermissionByModule(
			AccessMatrix.Scorecard
		);
		this.activeProductSearchInit();
		this.discardedProductSearchInit();
	}

	activeProductSearchInit() {
		this.searchedActiveProd?.valueChanges
			.pipe(
				tap((term) => {
					this.activeProdLoading = true;
					this.activePageNo = 1;
					this.activeProd = [];
					this.tempMovedActiveProduct = [];
					this.checkAllStatus.active = false;
				}),
				debounceTime(500),
				distinctUntilChanged(),
				takeUntil(this.$destroy)
			)
			.subscribe(() => {
				this.getActiveProducts();
			});
	}

	discardedProductSearchInit() {
		this.searchedDiscardedProd?.valueChanges
			.pipe(
				tap((term) => {
					this.discardedProdLoading = true;
					this.discardedProd = [];
					this.discardedPageNo = 1;
					this.tempMovedDiscardedProduct = [];
					this.checkAllStatus.discarded = false;
				}),
				debounceTime(500),
				distinctUntilChanged(),
				takeUntil(this.$destroy)
			)
			.subscribe(() => {
				this.getDiscardedProducts();
			});
	}

	ngOnInit(): void {
		this.dashboardCurrencySubscription?.unsubscribe();
		this.dashboardCurrencySubscription =
			this.dataTransferService.dashboardCurrencyData$.subscribe((data: any) => {
				this.dashboardCurrencyData = data;
			});
		this.profileDataSubscription = this.route.queryParams.subscribe(
			(e: any) => {
				if (e?.profileId) {
					this.profileData.profileId = parseInt(e?.profileId);
					this.additionalFilter = {
						...this.additionalFilter,
						isActive: {
							...this.additionalFilter.isActive,
							"filter[is_discarded]": false,
							"filter[profile_ids]": [this.profileData.profileId]
						},
						isDiscarded: {
							...this.additionalFilter.isDiscarded,
							"filter[is_discarded]": true,
							"filter[profile_ids]": [this.profileData.profileId]
						}
					};
				}
				if (e?.is_customer == "true") this.profileData.isCustomer = true;
				else this.profileData.isCustomer = false;
			}
		);
		this.getActiveProducts();
		this.getDiscardedProducts();
		this.getSortList();
	}
	getActiveProducts() {
		this.activeProductSubscription?.unsubscribe();
		if (this.activePageNo != null) {
			this.activeProdLoading = true;
			this.activeProductSubscription = this.profileInformationService
				.getActiveProducts(
					this.profileData.profileId,
					this.profileData.isCustomer,
					this.searchedActiveProd.value?.trim(),
					this.sort,
					this.pageSize,
					this.activePageNo,
					this.optionalFilters["isActive"]
				)
				.subscribe(
					(res: any) => {
						if (res) {
							if (res["context_code"] == 1000) {
								this.activeProdLoading = false;
								if (this.activeProd)
									for (const data of res["data"])
										this.activeProd = [...this.activeProd, data];
								else this.activeProd = res["data"];
								this.activePageNo = res["meta"]["next_page"];
								this.count.active = res?.meta?.total_count;
								if (this.checkAllStatus.active) {
									this.activeProd.forEach((item, idx) => {
										this.activeProd[idx].isChecked = true;
										this.tempMovedActiveProduct.push(item);
									});
								}
							}
						}
					},
					(err) => {
						this.activeProdLoading = false;
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
						this.activeProd = null;
						this.count.active = null;
					}
				);
		}
	}

	getDiscardedProducts() {
		this.discardedProductSubscription?.unsubscribe();
		if (this.discardedPageNo != null) {
			this.discardedProdLoading = true;
			this.discardedProductSubscription = this.profileInformationService
				.getDiscardedProducts(
					this.profileData.profileId,
					this.profileData.isCustomer,
					this.searchedDiscardedProd.value?.trim(),
					this.sort,
					this.pageSize,
					this.discardedPageNo,
					this.optionalFilters["isDiscarded"]
				)
				.subscribe(
					(res: any) => {
						if (res) {
							if (res["context_code"] == 1000) {
								if (this.discardedProd)
									for (const data of res["data"])
										this.discardedProd = [...this.discardedProd, data];
								else this.discardedProd = res["data"];
								this.discardedPageNo = res["meta"]["next_page"];
								this.discardedProdLoading = false;
								this.count.discarded = res?.meta?.total_count;
								if (this.checkAllStatus.discarded) {
									this.discardedProd.forEach((item, idx) => {
										this.discardedProd[idx].isChecked = true;
										this.tempMovedDiscardedProduct.push(item);
									});
								}
							}
						}
					},
					(err) => {
						this.discardedProdLoading = false;
						this.notificationService.setMessage(
							err?.error?.context_code,
							err?.error?.summary
						);
						this.discardedProd = null;
						this.count.discarded = null;
					}
				);
		}
	}
	public checkAll(event, status: STATUS) {
		if (status === STATUS.ACTIVE) {
			this.activeProd.forEach((item, idx) => {
				this.activeProd[idx].isChecked = event.target.checked;
				this.tempMovedActiveProduct.push(item);
			});
			if (!event.target.checked) {
				this.tempMovedActiveProduct = [];
				this.checkAllStatus.active = false;
			} else this.checkAllStatus.active = true;
		} else if (status === STATUS.DISCARDED) {
			this.discardedProd.forEach((item, idx) => {
				this.discardedProd[idx].isChecked = event.target.checked;
				this.tempMovedDiscardedProduct.push(item);
			});
			if (!event.target.checked) {
				this.tempMovedDiscardedProduct = [];
				this.checkAllStatus.discarded = false;
			} else this.checkAllStatus.discarded = true;
		}
	}
	checkActiveProd(eve, i, prod) {
		if ("isChecked" in this.activeProd[i]) {
			this.activeProd[i].isChecked = !this.activeProd[i].isChecked;
		} else {
			this.activeProd[i].isChecked = true;
		}
		this.manipulateActiveInTemp(i, prod);
		this.checkAllStatus.active = this.activeProd.every(
			(item) => item.isChecked
		);
	}

	private manipulateActiveInTemp(index: number, prod) {
		if (this.activeProd[index].isChecked) {
			this.tempMovedActiveProduct.push(prod);
		} else {
			this.tempMovedActiveProduct.forEach((element, key) => {
				if (element.id == this.activeProd[index].id) {
					this.tempMovedActiveProduct.splice(key, 1);
				}
			});
		}
	}

	checkDiscardedProd(eve, i, prod) {
		if ("isChecked" in this.discardedProd[i]) {
			this.discardedProd[i].isChecked = !this.discardedProd[i].isChecked;
		} else {
			this.discardedProd[i].isChecked = true;
		}
		this.manipulateDiscardedInTemp(i, prod);
		this.checkAllStatus.discarded = this.discardedProd.every(
			(item) => item.isChecked
		);
	}

	private manipulateDiscardedInTemp(index: number, prod) {
		if (this.discardedProd[index].isChecked) {
			this.tempMovedDiscardedProduct.push(prod);
		} else {
			this.tempMovedDiscardedProduct.forEach((element, key) => {
				if (element.id == this.discardedProd[index].id) {
					this.tempMovedDiscardedProduct.splice(key, 1);
				}
			});
		}
	}

	onMoveProduct(type) {
		// this.searchedActiveProd.reset('', { emitEvent: false });
		// this.searchedDiscardedProd.reset('', { emitEvent: false });
		this.sort = null;
		let actionBody = {};

		if (type == "active") {
			actionBody = {
				action_on_all: this.checkAllStatus.active,
				is_discarded: true,
				profile_id: this.profileData.profileId
			};
			if (!this.checkAllStatus.active) {
				actionBody["product_ids"] = this.tempMovedActiveProduct.map((item) => {
					return item.id;
				});
			}
		}
		if (type == "discarded") {
			actionBody = {
				action_on_all: this.checkAllStatus.discarded,
				is_discarded: false,
				profile_id: this.profileData.profileId
			};
			if (!this.checkAllStatus.discarded) {
				actionBody["product_ids"] = this.tempMovedDiscardedProduct.map(
					(item) => {
						return item.id;
					}
				);
			}
		}
		this.activeProdLoading = true;
		this.discardedProdLoading = true;
		if (this.moveProductSubscription)
			this.moveProductSubscription.unsubscribe();
		this.moveProductSubscription = this.profileInformationService
			.postActiveToAndFroDiscardProducts(
				this.optionalFilters[type === "active" ? "isActive" : "isDiscarded"],
				actionBody["is_discarded"]
					? this.searchedActiveProd.value?.trim()
					: this.searchedDiscardedProd.value?.trim(),
				actionBody
			)
			.subscribe(
				(response: any) => {
					if (response) {
						if (response["context_code"] == 1000) {
							this.notificationService.setMessage(
								response["context_code"],
								response["summary"]
							);
							this.stateChange = { ...this.stateChange, to_and_fro: true };
							this.optionalFilters = { isActive: null, isDiscarded: null };
							this.resetProducts();
							this.additionalFilter = JSON.parse(
								JSON.stringify(this.additionalFilter)
							);
						}
					}
				},
				(err) => {
					this.notificationService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
					this.resetProducts();
				}
			);
	}

	resetProducts() {
		this.activeProd = null;
		this.discardedProd = null;
		this.tempMovedActiveProduct = [];
		this.tempMovedDiscardedProduct = [];
		this.checkAllStatus.active = false;
		this.checkAllStatus.discarded = false;
		this.activePageNo = 1;
		this.discardedPageNo = 1;
		this.searchedActiveProd.reset("", { emitEvent: false });
		this.searchedDiscardedProd.reset("", { emitEvent: false });
		this.getActiveProducts();
		this.getDiscardedProducts();
	}

	onActiveScroll() {
		this.getActiveProducts();
	}

	onDiscardedScroll() {
		this.getDiscardedProducts();
	}

	getSortList() {
		this.sortActiveList = {
			name: true,
			price: true,
			discount: true
		};
		this.sortDeactiveList = {
			name: true,
			price: true,
			discount: true
		};
	}

	sortInGrid(sortType: any) {
		this.mouseActionType = sortType;
		const sortKey = this.mouseActionType.split("-")[0];
		const type = this.mouseActionType.split("-")[1];
		if (type == "active") {
			this.tempMovedActiveProduct = [];
			this.sort = this.sortActiveList[sortKey] ? sortKey : `-${sortKey}`;
			this.sortActiveList[sortKey] = !this.sortActiveList[sortKey];
			this.sortDirection = this.sortActiveList[sortKey]
				? "icon-Arrow-down"
				: "icon-Arrow-up";
			this.activeProdLoading = true;
			this.activeProd = [];
			this.activePageNo = 1;
			this.getActiveProducts();
		} else if (type == "deactive") {
			this.tempMovedDiscardedProduct = [];
			this.sort = this.sortDeactiveList[sortKey] ? sortKey : `-${sortKey}`;
			this.sortDeactiveList[sortKey] = !this.sortDeactiveList[sortKey];
			this.sortDirection = this.sortDeactiveList[sortKey]
				? "icon-Arrow-down"
				: "icon-Arrow-up";
			this.discardedProdLoading = true;
			this.discardedProd = [];
			this.discardedPageNo = 1;
			this.getDiscardedProducts();
		}
	}
	mouseAction(actionValue: any) {
		this.mouseActionType = actionValue;
		if (this.mouseActionType != "") {
			const sortKey = this.mouseActionType.split("-")[0];
			const type = this.mouseActionType.split("-")[1];
			if (type == "active") {
				this.sortDirection = this.sortActiveList[sortKey]
					? "icon-Arrow-down"
					: "icon-Arrow-up";
			} else if (type == "deactive") {
				this.sortDirection = this.sortDeactiveList[sortKey]
					? "icon-Arrow-down"
					: "icon-Arrow-up";
			}
		}
	}

	openDirectMapping(productId: any) {
		// if (this.route.snapshot["_urlSegment"].segments[0]?.path === 'build') {
		//   this.router.navigate(
		//     [
		//       `${this.route.snapshot["_urlSegment"].segments[0]}/${this.route.snapshot["_urlSegment"].segments[1]}/${this.route.snapshot["_urlSegment"].segments[2]}/manage-mappings`,
		//     ],
		//     {
		//       queryParams: {
		//         profileId: null,
		//         is_customer: null,
		//         customerProductId: productId,
		//       },
		//       queryParamsHandling: "merge",
		//     }
		//   );
		// } else {
		//   this.router.navigate(['/scorecard/profile/manage-mappings'], {
		//     queryParams: {
		//       profileId: null,
		//       is_customer: null,
		//       customerProductId: productId
		//     },
		//     queryParamsHandling: "merge",
		//   });
		// }

		this.router.navigate(["manage-mappings"], {
			relativeTo: this.route,
			queryParams: {
				customerProductId: productId
			},
			queryParamsHandling: "merge"
		});
	}

	openProductSummaryOverlay(id) {
		const componentRef = this.ngbModalService.open(ProductSummaryComponent, {
			centered: true
		});
		componentRef.componentInstance.productId = id;
	}
	onCloseAction() {
		const queryParams = { ...this.route["snapshot"].queryParams };
		if ("is_customer" in queryParams) queryParams["is_customer"] = null;
		if ("profileId" in queryParams) queryParams["profileId"] = null;
		this.router.navigate([".."], {
			relativeTo: this.route,
			queryParams: queryParams,
			queryParamsHandling: "merge"
		});
	}
	onOptionalFilterUpdate(event: any, type: string) {
		this.optionalFilters[type] = { ...event };
		Object.keys(event).forEach((item) => {
			this.optionalFilters[type][`filter[${item}_names]`] =
				this.commonFunctions.getMappedData(
					event[item].selected,
					event[item].meta?.key
				);
			delete this.optionalFilters[type][item];
		});
		if (type === "isActive") {
			this.activePageNo = 1;
			this.activeProd = [];
			this.tempMovedActiveProduct = [];
			this.checkAllStatus.active = false;
			this.getActiveProducts();
		} else if (type === "isDiscarded") {
			this.discardedPageNo = 1;
			this.discardedProd = [];
			this.tempMovedDiscardedProduct = [];
			this.checkAllStatus.discarded = false;
			this.getDiscardedProducts();
		}
	}

	ngOnDestroy() {
		if (this.moveProductSubscription)
			this.moveProductSubscription.unsubscribe();
		if (this.activeProductSubscription)
			this.activeProductSubscription.unsubscribe();
		if (this.discardedProductSubscription)
			this.discardedProductSubscription.unsubscribe();
		this.dashboardCurrencySubscription?.unsubscribe();
		this.$destroy.next(true);
		this.$destroy.unsubscribe();
	}
}
