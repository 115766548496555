<div class="rangeContainer priceRange d-flex align-items-center gap-16">
    <div class="title d-flex justify-content-between align-items-center">
        <span translate>Price Range</span>
    </div>
    <div class="d-flex align-items-center gap-8">
        <div class="valContainer" [ngClass]="{'cursor-not-allowed':!priceRangeFilters}">
            {{commonFunctions.getSpecificTranslation('MIN') + commonFunctions.currencySelected?.attributes?.symbol}} <input *ngIf="priceRangeFilters" type="number" [attr.data-test]="'min-range-test'" [value]="minPrice"
                [min]="defaultMinPrice" [max]="defaultMaxPrice" (input)="onPriceChange('min')"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                [formControl]="priceChangeMinControl">
            <div *ngIf="!priceRangeFilters">--</div>
        </div>
        <ngx-slider *ngIf="options?.ceil" [(value)]="minPrice" [(highValue)]="maxPrice" [options]="options"
            (userChangeEnd)="onPriceChange('range', $event)"></ngx-slider>
        <ngx-slider *ngIf="!options?.ceil" [(value)]="minPrice" [(highValue)]="maxPrice" [options]="options"></ngx-slider>
        <div class="valContainer" [ngClass]="{'cursor-not-allowed':!priceRangeFilters}">
            {{commonFunctions.getSpecificTranslation('MAX') + commonFunctions.currencySelected?.attributes?.symbol}} <input *ngIf="priceRangeFilters" type="number" [attr.data-test]="'max-range-test'" [value]="maxPrice"
                [min]="defaultMinPrice" [max]="defaultMaxPrice" (input)="onPriceChange('max')"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                [formControl]="priceChangeMaxControl">
            <div *ngIf="!priceRangeFilters">--</div>
        </div>
    </div>
</div>